import { FIELDS_ENUM } from "@/enums/language/fieldsEnum";
import { HISTORY_STATUS } from "@/pages/OrderList/constants";
import { translateModule } from "@/utils/string/translate";

export function getPageTitle(reportType, orderType = "") {
  let titleLabel = "";
  const translateFields = translateModule("fields");
  const translateOrderList = translateModule("pages.orderList");

  if (reportType) {
    if (reportType === HISTORY_STATUS[0] && orderType !== "") {
      titleLabel = `${translateOrderList(reportType)} ( ${translateFields(
        FIELDS_ENUM.orderType
      )}: ${orderType} )`;
    } else if (HISTORY_STATUS.includes(reportType)) {
      titleLabel = `${translateOrderList(reportType)}`;
    } else {
      titleLabel = `${translateFields(FIELDS_ENUM.status)}: ${translateOrderList(reportType)}`;
    }
  }
  return titleLabel;
}

export function getFilterParameters(filters) {
  const filterParameters = {};
  filters
    .filter((param) => !param.exclude)
    .forEach((param) => {
      if (param.key === "startTime") {
        filterParameters[param.key] = `${param.value}T00:00:00`;
      } else if (param.key === "endTime") {
        filterParameters[param.key] = `${param.value}T23:59:59`;
      } else if (param.key === "orderIds" || param.key === "poNumbers") {
        filterParameters[param.key] =
          param.values && Array.isArray(param.values) ? param.values.join(",") : param.value;
      } else {
        filterParameters[param.key] = param.value;
      }
    });

  return filterParameters;
}

export function getFormatedOrderDetailData(orderDetail, groupKeys) {
  let orderDetailData = [];
  orderDetail.forEach((module) => {
    if (groupKeys.length > 0) {
      if (groupKeys.includes(module.moduleName)) {
        module.validations?.forEach((validation) => {
          orderDetailData.push({
            ...validation.validationstepData,
            validationstepName: validation.validationstepName,
            // moduleName: module.moduleName,
          });
        });
      }
    } else {
      orderDetailData.push({
        moduleName: module.moduleName,
      });
    }
  });

  return orderDetailData;
}
