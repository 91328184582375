import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue from "@okta/okta-vue";

import pagesURL from "@/router/routes.js";

const defaultIssuer = "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7";
const issuersOverride = {
  prod: "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7",
  "prod-echo": "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7",
};

const origin = window.location.origin.replace("-origin", "");

const authConfig = {
  issuer: issuersOverride[process.env.VUE_APP_ENV] || defaultIssuer,
  clientId: "nike.niketech.audit-events-ui",
  redirectUri: `${origin}${pagesURL.CALLBACK}`,
  postLogoutRedirectUri: `${window.location.origin}${pagesURL.SESSION_EXPIRED}`,
  cookies: {
    secure: false,
  },
};

export function registerOkta(app) {
  const oktaAuth = new OktaAuth(authConfig);

  app.use(OktaVue, { oktaAuth });
}
