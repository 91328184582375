import RULES from '@/enums/validationRules/ruleKeys';
import VALIDATORS from '@/enums/validationRules/validators';

import errorMessages from './errorMessages';

export const validatorsByFieldName = {
  // TODO: All field names should be defined in constants
  validators: {
    referenceDocumentNumber: [
      {
        argument: 10,
        validate: VALIDATORS[RULES.MAX_LENGTH],
        errorMessage: errorMessages.maxLengthExceeded,
      },
      {
        validate: VALIDATORS[RULES.NUMERIC_NO_LIMIT],
        errorMessage: errorMessages.numericOnly,
      },
    ],
    referenceDocumentItemNumber: [
      {
        argument: 6,
        validate: VALIDATORS[RULES.MAX_LENGTH],
        errorMessage: errorMessages.maxLengthExceeded,
      },
      {
        validate: VALIDATORS[RULES.NUMERIC_NO_LIMIT],
        errorMessage: errorMessages.numericOnly,
      },
    ],
  },

  errorPrefix: 'Expected format:',
};
