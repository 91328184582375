import { ExcelStyle } from "@ag-grid-community/core";

export const TABLE_KEY = {
  LINES_TABLE: "linesTable",
  HEADER_TABLE: "headerTable",
  GROUPED_TABLES: "groupedTables",
};

export enum ResolveActions {
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  REPROCESS = "REPROCESS",
}

export const RESOLVE_ACTIONS = [
  { text: "Update", value: ResolveActions.UPDATE },
  { text: "Reprocess", value: ResolveActions.REPROCESS },
  { text: "Delete", value: ResolveActions.DELETE },
];

export const PO_CELL_STATUS = {
  PENDING: "PENDING",
  INCORRECT: "INCORRECT",
  INCORRECT_SECONDARY: "INCORRECT_SECONDARY",
};

export const EXCEL_STYLES: ExcelStyle[] = [
  {
    id: "indicatorRenderer",
    interior: {
      color: "#0034E3",
      pattern: "Solid",
    },
  },
  {
    id: "indicatorRendererSuccess",
    interior: {
      color: "#007D48",
      pattern: "Solid",
    },
  },
  {
    id: "indicatorRendererException",
    interior: {
      color: "#D30005",
      pattern: "Solid",
    },
  },
  {
    id: "seeDetailsMessage",
    font: {
      size: 20,
    },
  },
];

export const HEADER_PAGE_DATA_TO_APPEND = [
  { cells: [] },
  { cells: [] },
  {
    cells: [
      {},
      {},
      {
        data: {
          value: "See next page for Line level data",
          type: "String",
        },
        styleId: "seeDetailsMessage",
      },
    ],
  },
];

export enum ExceptionStatus {
  EXCEPTION = "Exception",
  ACKNOWLEDGED = "Acknowledged",
  INCORRECT = "Incorrect",
}

export const HISTORY_STATUS = ["RECEIVED", "ARCHIVED", "SPLIT"];
