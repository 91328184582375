export const duplicateLinesDefaultColDefs = [
  {
    headerCheckboxSelection: true,
    width: 50,
    field: 'selected',
    headerName: '',
    cellRenderer: 'CheckboxCellRenderer',
  },
  { field: 'soldToNumber.value', headerName: 'Sold To' },
  { field: 'shipToNumber.value', headerName: 'Ship To' },
  { field: 'customerPONumber.value', headerName: 'PO Number' },
  { field: 'contractNumber.value', headerName: 'Contract #' },
  { field: 'crd.value', headerName: 'CRD' },
  { field: 'materialNumber.value', headerName: 'Material #' },
];
