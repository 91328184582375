import state from "./productionSupport.state";
import getters from "./productionSupport.getters";
import mutations from "./productionSupport.mutations";
import actions from "./productionSupport.actions";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
