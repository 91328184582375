import BaseCreator from './BaseCreator';

import { RETURN_SALES } from '../constansts/config';

import BaseValidator from '../gridValidators/BaseValidator';

export default class ReturnSalesCreator extends BaseCreator {
  constructor(orders, sizeColumnsToAdd) {
    super({
      orders,
      sizeColumnsToAdd,
      orderTypeName: 'Return Sales Order',
      columnOrder: RETURN_SALES.COLUMN_ORDER,
      viewOnlyFields: RETURN_SALES.VIEW_ONLY_FIELDS,
      lineLevelFields: RETURN_SALES.LINE_LEVEL_FIELDS,
      headerLevelFields: RETURN_SALES.HEADER_LEVEL_FIELDS,
      groupingFieldKeys: RETURN_SALES.GROUPING_FIELD_KEYS,
    });

    this._validator = new BaseValidator({
      requiredFields: RETURN_SALES.REQUIRED_FIELDS,
    });
  }
}
