<template>
  <v-app id="app" class="app__container">
    <div v-if="shouldShowLoader" class="app__loader-wrapper">
      <Spinner :size="SpinnerSize.LARGE" />
    </div>
    <EnvBanner />
    <div class="app__page">
      <div class="app__page-navbar">
        <VerticalNavbar />
      </div>
      <div class="app__page-content">
        <AppHeader />
        <div :class="['app__main', getWidthClass]">
          <router-view v-if="!isLoading || isPublicUrl" />
        </div>
      </div>
    </div>

    <notifications
      group="network"
      position="top center"
      :max="5"
      :ignoreDuplicates="true"
    >
      <template #body="{ close, item }">
        <NotificationBody v-bind="item" :close="close" />
      </template>
    </notifications>

    <notifications
      group="upload"
      position="top center"
      width="450px"
      :max="1"
    >
      <template #body="{ close, item }">
        <NotificationBody v-bind="item" :close="close" />
      </template>
    </notifications>

    <UserConfirmation
      ref="sessionInactivityModal"
      yesConfirmationText="modals.sessionInactivityModal.keepWorking"
      noConfirmationText="modals.sessionInactivityModal.logout"
      :title="$t('modals.sessionInactivityModal.yourSessionExpire')"
    />

    <Portals />
  </v-app>
</template>

<script>
import {
  Spinner, SpinnerSize,
} from 'podium';
import {
  mapGetters, mapActions, mapState,
} from 'vuex';
import routes, { redesignedRoutes } from '@/router/routes.js';

import AppHeader from '@/components/layout/Header/index.vue';
import EnvBanner from '@/components/layout/EnvBanner';

import Portals from '@/components/layout/Portals';
import VerticalNavbar from '@/components/layout/NavBar/VerticalNavbar';

import UserConfirmation from '@/components/modals/UserConfirmation';

import NotificationBody from '@/components/ui/NotificationBody/NotificationBody.vue';
import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';
import colors from '@/enums/colors';

import { checkSubmissionsFinish } from '@/pages/MassOrderCreation/submissionMethods';

import { getAccessTokenObject } from '@/api/tokens/auth.token';
import store from './store';
import { initPendo } from '@/plugins/pendo';

export default {
  name: 'App',

  components: {
    Spinner,
    Portals,
    AppHeader,
    EnvBanner,
    VerticalNavbar,
    NotificationBody,
    UserConfirmation,
  },

  data() {
    return {
      SpinnerSize,
      authenticated: false,
      autoLogoutTimerId: null,
    };
  },

  computed: {
    ...mapState('user', {
      user: 'user',
      hasAccessToken: 'hasAccessToken',
      userHasPermissions: 'userHasPermissions',
    }),
    ...mapGetters('SOC', {
      orderTypes: 'getOrderTypes',
    }),

    getWidthClass() {
      const SOCroutes = this.orderTypes.map(route => `${routes.SOC.BASE}/${route.name}`);

      const wide = [
        routes.MY_ORDERS.BASE,
        routes.MY_APPROVALS.BASE,
        ...SOCroutes,
      ];

      const routeMatched = this.$route.matched;
      const currentPath = decodeURI(this.$route.path);
      const matchedPath = routeMatched.some(match => wide.includes(match.path) || wide.includes(currentPath)) || currentPath.includes(routes.MY_ORDERS.BASE);

      return matchedPath ? 'app__main--wide' : '';
    },

    shouldShowLoader() {
      return this.isLoading && !this.isNotAllowedUrl && !this.isSessionExpiredUrl;
    },

    isLoading() {
      return this.userHasPermissions === undefined;
    },

    isPublicUrl() {
      return this.isNotAllowedUrl || this.isCallbackUrl || this.isSessionExpiredUrl;
    },

    isNotAllowedUrl() {
      return this.$route.path === routes.NOT_ALLOWED;
    },

    isCallbackUrl() {
      return this.$route.path === routes.CALLBACK;
    },

    isSessionExpiredUrl() {
      return this.$route.path === routes.SESSION_EXPIRED;
    },

    backgroundColor() {
      const isRedesigned = redesignedRoutes.includes(this.$route.path);

      return isRedesigned ? colors.podiumGrey3 : colors['primary-white'];
    },
  },

  watch: {
    hasAccessToken: {
      handler() {
        if (this.hasAccessToken) {
          this.getCurrentUser().then(() => initPendo(this.user));
        }
      },
      immediate: true,
    },
  },

  created() {
    this.setInitialUserAppActiveState();
    this.setBrowserStorageEventListeners();
    this.setIntervalCheckUserActivityAndOktaToken();
    this.checkOktaToken();
    checkSubmissionsFinish();
  },

  methods: {
    ...mapActions('user', ['logout', 'getCurrentUser']),

    /**
     * Functionality that meets Nike information security program
     * For web applications, utilize the least possible timeframe that does not impede a user's ability to perform business functions.
     * @link https://nisp.nike.com/iam/iam-24
     */
    setIntervalCheckUserActivityAndOktaToken() {
      const minute = 60000;
      setInterval(this.checkUserActivity, minute);
    },

    checkUserActivity() {
      const oktaAccessToken = getAccessTokenObject();

      if (oktaAccessToken) {
        const lastUserActivityMilliseconds = JSON.parse(
          localStorage.getItem(localStorageKey.lastUserAppActivity),
        );
        const currentUnixTimestamp = new Date().getTime();
        const minute = 60_000;
        const maxAllowedUserInactivityMilliseconds = 55 * minute;

        if (
          currentUnixTimestamp - lastUserActivityMilliseconds
          > maxAllowedUserInactivityMilliseconds
        ) {
          localStorage.setItem(localStorageKey.userAppActiveState, false);
          this.handleUserInactiveState();
        }
      }
    },

    checkOktaToken() {
      const oktaAccessToken = getAccessTokenObject();

      if (oktaAccessToken) {
        store.commit('user/SET_HAS_ACCESS_TOKEN', true);
      }
    },

    setAutologoutTimer(timer = 0) {
      return (
        this.autoLogoutTimerId
        || setTimeout(() => {
          this.$refs.sessionInactivityModal.hide();
          this.logout({ postLogoutRedirectUri: routes.SESSION_EXPIRED });
        }, timer)
      );
    },

    async handleUserInactiveState() {
      const oktaAccessToken = getAccessTokenObject();

      if (this.isPublicUrl || !oktaAccessToken) {
        return;
      }

      const minute = 60_000;
      this.autoLogoutTimerId = this.setAutologoutTimer(5 * minute);

      if (!this.$refs.sessionInactivityModal?.isVisible) {
        const keepSessionAlive = await this.$refs.sessionInactivityModal.show();
        this.removeAutologoutTimer();

        if (keepSessionAlive !== null) {
          localStorage.setItem(localStorageKey.lastUserAppActivity, new Date().getTime());
          localStorage.setItem(localStorageKey.userAppActiveState, true);

          if (!keepSessionAlive) {
            this.logout();
          }
        }
      }
    },

    setBrowserStorageEventListeners() {
      window.addEventListener(
        'storage',
        (event) => {
          switch (event.key) {
            case localStorageKey.userAppActiveState: {
              this.handleUserAppActiveStateChange(event);
              break;
            }
            case localStorageKey.oktaTokenStorage: {
              this.handleOktaSessionChange(JSON.parse(event.newValue));
              break;
            }
            default:
          }
        },
        false,
      );
    },

    setInitialUserAppActiveState() {
      localStorage.setItem(
        localStorageKey.userAppActiveState,
        localStorage.getItem(localStorageKey.isLoggedIn) || false,
      );
    },

    removeAutologoutTimer() {
      clearTimeout(this.autoLogoutTimerId);
      this.autoLogoutTimerId = null;
    },

    handleUserAppActiveStateChange(event) {
      const newValue = JSON.parse(event.newValue);
      const oldValue = JSON.parse(event.oldValue);

      if (newValue === oldValue) {
        return;
      }

      if (newValue) {
        this.$refs.sessionInactivityModal.hide();
      } else {
        this.handleUserInactiveState();
      }
    },

    handleOktaSessionChange(oktaTokenNewValue) {
      if (!oktaTokenNewValue?.accessToken && !this.isPublicUrl) {
        this.$refs.sessionInactivityModal.hide();

        const userActiveState = JSON.parse(
          localStorage.getItem(localStorageKey.userAppActiveState),
        );
        this.logout({ postLogoutRedirectUri: userActiveState ? '' : routes.SESSION_EXPIRED });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/colors.scss";

.app {
  &__container {
    background-color: v-bind(backgroundColor);
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }

  &__loader-wrapper {
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  &__main {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: auto;
    height: 100%;

    &--wide {
      padding: 0 3%;
    }
  }

  &__inactivity-modal-description {
    @extend .now-body-2;
    margin: 35px 0;
    text-align: center;
  }

  &__page {
    display: flex;
    overflow: hidden;
    height: 100%;

    &-navbar {
      height: 100%;
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }
}

// Should be changed to our custom notification component or
// should find a new library that supports custom width resizing
:deep(.vue-notification-group) {
  width: max-content !important;
  max-width: 50%;
  position: fixed !important;
  left: 50% !important;
  top: 10px !important;
  transform: translateX(-50%);

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

:deep(.vue-notification-wrapper) {
  display: inline-block;
  width: auto;
  max-width: 50vw;
}
</style>
