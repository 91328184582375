// TODO: We need to merge these 2 imports in the future
import { FIELD_NAMES } from '@/enums/fieldNames';
import { fieldNames as socFields } from '@/enums/singleOrderCreation/fieldNames';

import { translateFields } from '@/utils/string/translate';

export const COLUMN_NAMES_MAPPER = {
  orderType: 'order type',

  [FIELD_NAMES.SOLD_TO_NUMBER]: translateFields(socFields.soldTo),
  [FIELD_NAMES.SHIP_TO_NUMBER]: translateFields(socFields.shipTo),
  [FIELD_NAMES.CUSTOMER_PO_NUMBER]: translateFields(socFields.purchaseOrderNumber),
  [FIELD_NAMES.CONTRACT_NUMBER]: translateFields(socFields.contractNumber),
  [FIELD_NAMES.CRD]: translateFields(socFields.crd),
  [FIELD_NAMES.CANCEL_DATE]: translateFields(socFields.cancelDate),
  [FIELD_NAMES.VALID_TO_DATE]: translateFields(socFields.validToDate),
  [FIELD_NAMES.VALID_FROM_DATE]: translateFields(socFields.validFromDate),
  [FIELD_NAMES.DEPARTMENT_CODE]: translateFields(socFields.departmentCode),
  [FIELD_NAMES.MATERIAL_NUMBER]: translateFields(socFields.materialNumber),
  [FIELD_NAMES.REASON_CODE]: translateFields(socFields.reasonCode),
  [FIELD_NAMES.DELIVERY_BLOCK]: translateFields(socFields.deliveryBlock),
  [FIELD_NAMES.SOURCING_METHOD_GA]: translateFields(socFields.sourcingMethodGa),
  [FIELD_NAMES.SHIPPING_CONDITION]: translateFields(socFields.shippingCondition),
  [FIELD_NAMES.NIKE_INTERNAL_COMMENTS]: translateFields(socFields.nikeInternalComments),
  [FIELD_NAMES.ADDRESS_OVERRIDE]: translateFields(socFields.addressOverride),
  [FIELD_NAMES.NAME_1]: translateFields(socFields.name),
  [FIELD_NAMES.NAME_2]: translateFields(socFields.nameCO),
  [FIELD_NAMES.STREET_1]: translateFields(socFields.streetNumberName),
  [FIELD_NAMES.STREET_2]: translateFields(socFields.shopFloorSuiteEtc),
  [FIELD_NAMES.CITY]: translateFields(socFields.city),
  [FIELD_NAMES.POSTAL_CODE]: translateFields(socFields.postalCode),
  [FIELD_NAMES.COUNTRY]: translateFields(socFields.country),
  [FIELD_NAMES.REGION]: translateFields(socFields.region),
  [FIELD_NAMES.DISTRICT]: translateFields(socFields.district),
  [FIELD_NAMES.TELEPHONE]: translateFields(socFields.telephone),
  [FIELD_NAMES.FORWARDING_AGENT]: translateFields(socFields.forwardingAgent),
  [FIELD_NAMES.TRANSPORTATION_ZONE]: translateFields(socFields.transportationZone),
  [FIELD_NAMES.NAME_OF_ORDERER]: translateFields(socFields.nameOfOrderer),
  [FIELD_NAMES.USAGE_CODE]: translateFields(socFields.usageCodeLifi),
  [FIELD_NAMES.MARK_FOR]: translateFields(socFields.markFor),
  [FIELD_NAMES.PLANT_CODE]: translateFields(socFields.plantCode),
  [FIELD_NAMES.UNIT_ALLOTMENT]: translateFields(socFields.unitAllotment),
  [FIELD_NAMES.INVOICE_NUMBER]: translateFields(socFields.invoiceNumber),
  [FIELD_NAMES.RETURN_AUTHORIZATION_NUMBER]: translateFields(socFields.returnAuthorizationsNumber),
  [FIELD_NAMES.NUMBER_OF_CARTONS]: translateFields(socFields.numberOfCartons),
  [FIELD_NAMES.VAT_RATE]: translateFields(socFields.vatRate),
  [FIELD_NAMES.REQUIREMENT_SEGMENT]: translateFields(socFields.requirementSegment),
  [FIELD_NAMES.VAS_CODES]: translateFields(socFields.vasCodes),
  [FIELD_NAMES.VAS_FIELD_1]: translateFields(socFields.vasField1),
  [FIELD_NAMES.VAS_FIELD_2]: translateFields(socFields.vasField2),
  [FIELD_NAMES.VAS_FIELD_3]: translateFields(socFields.vasField3),
  [FIELD_NAMES.VAS_LONG_TEXT]: translateFields(socFields.vasLongText),
  [FIELD_NAMES.VAS_CHARGE_CODE]: translateFields(socFields.vasChargeCode),
  [FIELD_NAMES.INTERNAL_ORDER_REFERENCE_NUMBER]: translateFields(socFields.internalOrderReferenceNumber),
};
