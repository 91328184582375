import state from './purchaseOrder.state';
import getters from './purchaseOrder.getters';
import actions from './purchaseOrder.actions';
import mutations from './purchaseOrder.mutations';

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
