export default {
  modalMode: '',
  initialData: [], // all data we've got from BE
  displayedDuplicates: [], // currently displayed set of duplicates
  duplicatesChangelog: [], // map of changes for all sets of duplicates [0: [ { action: '', lines: [...]} ]]
  isDuplicatesLoading: false,
  selectedDuplicateLines: [],
  isDuplicatesSaveInProgress: false,
  indexOfDisplayedDuplicatesSet: null,
};
