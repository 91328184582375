import * as gettersEnum from './constansts/getters';

import filterPanelGetters from './filterPanel/getters';
import issueTypeColumn from './issueTypeColumn/getters';
import ordersColumnGetters from './ordersColumn/getters';
import orderTypeColumnGetters from './orderTypeColumn/getters';
import customerOrderColumnGetters from './customerOrderColumn/getters';

const {
  GET_SUCCEEDED_FILES,
  GET_NOT_SUCCEEDED_FILES,
  GET_SUCCEEDED_FILES_MAPPED_BY_ID,
  GET_IS_DUPLICATE_LINES_AVAILABLE,
} = gettersEnum;

export default {
  ...issueTypeColumn,
  ...filterPanelGetters,
  ...ordersColumnGetters,
  ...orderTypeColumnGetters,
  ...customerOrderColumnGetters,

  [GET_NOT_SUCCEEDED_FILES]: ({ notSucceededFiles }) => notSucceededFiles || [],

  [GET_SUCCEEDED_FILES]: ({ succeededFiles }) => (Array.isArray(succeededFiles) ? succeededFiles : []),

  getOrderTypeMapping: ({ orderTypesMapping }) => (Array.isArray(orderTypesMapping) ? orderTypesMapping : []),

  [GET_SUCCEEDED_FILES_MAPPED_BY_ID]: (_, { getSucceededFiles }) => {
    return getSucceededFiles.reduce((acc, file) => {
      acc[file.fileId] = file;

      return acc;
    }, {});
  },

  [GET_IS_DUPLICATE_LINES_AVAILABLE]: (_, { getSelectedOrdersFilteredByIssueType }) => {
    return getSelectedOrdersFilteredByIssueType.some(
      ({ hasDuplicateMaterialSizes }) => hasDuplicateMaterialSizes,
    );
  },

  getOrdersOfSelectedFilenames: (
    _,
    {
      getSucceededFiles, getSucceededFilesMappedById, getSelectedFilenameIds,
    },
  ) => {
    const files = !getSelectedFilenameIds.length
      ? getSucceededFiles
      : getSelectedFilenameIds.map((fileId) => getSucceededFilesMappedById[fileId]);

    return files.map(({ orders, fileId }) => orders.map((order) => ({ ...order, fileId }))).flat();
  },
};
