import { markRaw } from "vue";
import { ToggleButtonGroup, Select, TextField } from "@nike/now.podium-vue";

import BaseSingleDatepicker from "@/components/ui/BaseSingleDatepicker";

import { translateModule } from "@/utils/string/translate";

import { FIELDS_ENUM } from "@/enums/language/fieldsEnum";

import { FormField, MaterialClassificationResults, FormInputResults } from "./types";

const translateFields = translateModule("fields");
const translateGlobalFilters = translateModule("pages.homePage.globalFilters");

export const FIELD_KEYS = {
  START_DATE: "startTime",
  END_DATE: "endTime",
  ORDER_TYPE: "orderType",
  PO_TYPE: "poType",
  ORDER_ID: "orderIds",
  PO_NUMBER: "poNumbers",
  SALES_ORG: "salesOrg",
} as const;

export const FILTERS_CATEGORIES = [
  { name: "Header Filters", id: "header" },
  { name: "Line Filters", id: "line" },
];

export const VAS_VALUES = {
  WITH_WAS: "With Vas",
  NO_VAS: "No Vas",
};

export const LINE_DC_BYPASS_ELIGIBILITY_VALUES = {
  ELIGIBLE: "Eligible",
  NOT_ELIGIBLE: "Not Eligible",
};

const optionsGetterForMaterialClassification = (results: MaterialClassificationResults) => {
  return results.map(({ code, description }) => ({
    value: code,
    text: `${code} - ${description}`,
  }));
};

const optionsGetterForFormInputs = (results: FormInputResults) => {
  return results.map(({ id, alias }) => ({ value: id, text: alias }));
};

const headerFields: Array<FormField> = [
  {
    key: FIELD_KEYS.START_DATE,
    component: markRaw(BaseSingleDatepicker),
    label: translateFields(FIELDS_ENUM.startDate),
    isRangeDatepicker: false,
  },
  {
    key: FIELD_KEYS.END_DATE,
    component: markRaw(BaseSingleDatepicker),
    label: translateFields(FIELDS_ENUM.endDate),
    isRangeDatepicker: false,
  },
  {
    key: FIELD_KEYS.ORDER_TYPE,
    component: markRaw(Select),
    componentType: "select",
    label: translateFields(FIELDS_ENUM.orderType),
    multiple: false,
    items: [
      {
        text: "RSTO",
        value: "RSTO",
      },
      {
        text: "STO",
        value: "STO",
      },
      {
        text: "ZCON",
        value: "ZCON",
      },
      {
        text: "ZDIG",
        value: "ZDIG",
      },
      {
        text: "ZFIN",
        value: "ZFIN",
      },
      {
        text: "ZFRE",
        value: "ZFRE",
      },
      {
        text: "ZRFR",
        value: "ZRFR",
      },
      {
        text: "ZRST",
        value: "ZRST",
      },
      {
        text: "ZSTA",
        value: "ZSTA",
      },
      {
        text: "ZSYS",
        value: "ZSYS",
      },
      {
        text: "ZCSG",
        value: "ZCSG",
      },
      {
        text: "ZTRD",
        value: "ZTRD",
      },
    ],
  },
  {
    key: FIELD_KEYS.PO_TYPE,
    component: markRaw(Select),
    isSearchable: true,
    multiple: false,
    label: translateFields(FIELDS_ENUM.poType),
    items: [
      {
        text: "API",
        value: "API",
      },
      {
        text: "C3PO",
        value: "C3PO",
      },
      {
        text: "CLLB",
        value: "CLLB",
      },
      {
        text: "CONV",
        value: "CONV",
      },
      {
        text: "DIGI",
        value: "DIGI",
      },
      {
        text: "ECS",
        value: "ECS",
      },
      {
        text: "EDI",
        value: "EDI",
      },
      {
        text: "EXTF",
        value: "EXTF",
      },
      {
        text: "GSM",
        value: "GSM",
      },
      {
        text: "INTF",
        value: "INTF",
      },
      {
        text: "JES",
        value: "JES",
      },
      {
        text: "MANL",
        value: "MANL",
      },
      {
        text: "NBY",
        value: "NBY",
      },
      {
        text: "NBYE",
        value: "NBYE",
      },
      {
        text: "NET",
        value: "NET",
      },
      {
        text: "NTSB",
        value: "NTSB",
      },
      {
        text: "SMT",
        value: "SMT",
      },
      {
        text: "UII",
        value: "UII",
      },
      {
        text: "WRSO",
        value: "WRSO",
      },
      {
        text: "ZS03",
        value: "ZS03",
      },
      {
        text: "ZS04",
        value: "ZS04",
      },
      {
        text: "ZS05",
        value: "ZS05",
      },
      {
        text: "ZS06",
        value: "ZS06",
      },
      {
        text: "ZS09",
        value: "ZS09",
      },
      {
        text: "ZS10",
        value: "ZS10",
      },
    ],
  },
  {
    key: FIELD_KEYS.ORDER_ID,
    component: markRaw(TextField),
    iconRight: "SearchLargeDefaultOnLight",
    label: translateFields(FIELDS_ENUM.orderId),
  },
  {
    key: FIELD_KEYS.PO_NUMBER,
    component: markRaw(TextField),
    iconRight: "SearchLargeDefaultOnLight",
    label: translateFields(FIELDS_ENUM.poNumber),
  },
  {
    key: FIELD_KEYS.SALES_ORG,
    component: markRaw(Select),
    componentType: "select",
    label: translateFields(FIELDS_ENUM.salesOrg),
    multiple: false,
    items: [
      {
        text: "1020 - NIKE US",
        value: "1020",
      },
      {
        text: "9800 - NIKE China",
        value: "9800",
      },
      {
        text: "9700 - NIKE Taiwan",
        value: "9700",
      },
      {
        text: "9610 - NIKE Macau",
        value: "9610",
      },
      {
        text: "9600 - NIKE Hong Kong",
        value: "9600",
      },
    ],
  },
  // {
  //   key: FIELD_KEYS.DEPARTMENT_CODE,
  //   component: markRaw(Select),
  //   isSearchable: true,
  //   multiple: true,
  //   label: translateFields(FIELDS_ENUM.departmentCode),
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   onInputAction: "globalFilters/fetchOptions",
  //   optionsEndpoint: "getDepartmentCodesForGlobalFilters",
  //   optionsGetter: optionsGetterForFormInputs,
  // },
  // {
  //   key: FIELD_KEYS.MARK_FOR,
  //   component: markRaw(TextField),
  //   iconRight: "SearchLargeDefaultOnLight",
  //   label: translateFields(FIELDS_ENUM.markFor),
  //   validation: {
  //     pattern: "^[0-9]{1,10}$",
  //     errorMessage: "Expected format: 1-10 digits",
  //   },
  // },
  // {
  //   key: FIELD_KEYS.MARKETPLACE_UNIT,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.marketplaceUnit),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.MODIFIED_BY,
  //   component: markRaw(Select),
  //   label: translateFields(FIELDS_ENUM.modifiedBy),
  //   multiple: true,
  //   onInputAction: "globalFilters/fetchUser",
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   isSearchable: true,
  // },
  // {
  //   key: FIELD_KEYS.STATUS,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   displayLabelsAsValues: true,
  //   label: translateFields(FIELDS_ENUM.orderStatus),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.REASON_CODE,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.reasonCode),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.SALES_ORG,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.salesOrg),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.SHIP_TO,
  //   component: markRaw(Select),
  //   onInputAction: "globalFilters/fetchOptions",
  //   optionsEndpoint: "getShipTosForGlobalFilters",
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   isSearchable: true,
  //   multiple: true,
  //   label: translateFields(FIELDS_ENUM.shipTo),
  //   optionsGetter: optionsGetterForFormInputs,
  // },
  // {
  //   key: FIELD_KEYS.SOLD_TO,
  //   component: markRaw(Select),
  //   onInputAction: "globalFilters/fetchOptions",
  //   optionsEndpoint: "getSoldTosForGlobalFilters",
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   isSearchable: true,
  //   multiple: true,
  //   label: translateFields(FIELDS_ENUM.soldTo),
  //   optionsGetter: optionsGetterForFormInputs,
  // },
  // {
  //   key: FIELD_KEYS.SOURCE_SYSTEM,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.sourceSystem),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.SUB_CHANNEL,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.subChannel),
  //   multiple: true,
  // },
];

// const lineFields: Array<FormField> = [
//   {
//     key: FIELD_KEYS.LINE_CANCEL_DATE,
//     component: markRaw(BaseDatepicker),
//     label: translateFields(FIELDS_ENUM.cancelDateLineLevel),
//     isRangeDatepicker: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_CATEGORY,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.category),
//     multiple: true,
//     isSearchable: true,
//     onInputAction: 'globalFilters/fetchOptions',
//     optionsEndpoint: 'fetchMaterialCategory',
//     emptyListText: translateGlobalFilters('startTypingToSeeOptions'),
//     optionsGetter: optionsGetterForMaterialClassification,
//   },
//   {
//     key: FIELD_KEYS.LINE_CRD,
//     component: markRaw(BaseDatepicker),
//     label: translateFields(FIELDS_ENUM.crdLineLevel),
//     isRangeDatepicker: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_DC_BYPASS_ELIGIBILITY,
//     component: markRaw(ToggleButtonGroup),
//     label: translateFields(FIELDS_ENUM.dcBypassEligibility),
//     items: [
//       {
//         text: translateGlobalFilters('all'),
//         value: undefined,
//       },
//       {
//         text: translateGlobalFilters('eligible'),
//         value: LINE_DC_BYPASS_ELIGIBILITY_VALUES.ELIGIBLE,
//       },
//       {
//         text: translateGlobalFilters('notEligible'),
//         value: LINE_DC_BYPASS_ELIGIBILITY_VALUES.NOT_ELIGIBLE,
//       },
//     ],
//   },
//   {
//     key: FIELD_KEYS.LINE_DIVISION,
//     component: markRaw(Select),
//     componentType: 'select',
//     label: translateFields(FIELDS_ENUM.division),
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_GENDER,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.gender),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_ITEM_CATEGORY,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.itemCategory),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_KBI_INDICATOR,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.launchCode),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_LEAGUE_INDICATOR,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.leagueIndicator),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_MATERIAL_NUMBER,
//     component: markRaw(TextField),
//     iconRight: 'SearchLargeDefaultOnLight',
//     label: translateFields(FIELDS_ENUM.materialNumber),
//     validation: {
//       pattern: '^([a-zA-Z0-9]{6})-([a-zA-Z0-9]{3})$',
//       errorMessage: 'Expected Format: BQ3757-101, SX7676-100',
//     },
//   },
//   {
//     key: FIELD_KEYS.LINE_SUB_CATEGORY,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.subCategory),
//     multiple: true,
//     isSearchable: true,
//     onInputAction: 'globalFilters/fetchOptions',
//     optionsEndpoint: 'fetchMaterialSubCategory',
//     emptyListText: translateGlobalFilters('startTypingToSeeOptions'),
//     optionsGetter: optionsGetterForMaterialClassification,
//   },
//   {
//     key: FIELD_KEYS.LINE_VAS,
//     component: markRaw(ToggleButtonGroup),
//     label: translateGlobalFilters('vas'),
//     items: [
//       {
//         text: translateGlobalFilters('all'),
//         value: undefined,
//       },
//       {
//         text: translateGlobalFilters('withVas'),
//         value: VAS_VALUES.WITH_WAS,
//       },
//       {
//         text: translateGlobalFilters('noVas'),
//         value: VAS_VALUES.NO_VAS,
//       },
//     ],
//   },
// ];

// const adjustedLineFields = lineFields.map(field => ({ ...field, isLineItem: true }));

export const FIELDS = [...headerFields];
