import router from '@/router';
import routes from '@/router/routes';

import store from '@/store';
import { orderStatuses } from '@/enums/order/orderStatus';
import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';

import { showSuccessBanner, showErrorBanner } from '@/utils/notifications';
import { getSubmissionById, getSubmissionDetailsById } from '@/api/now/massOrderCreation';

export function setSubmissionIdToStorage(submissionId) {
  let activeSubmissions = JSON.parse(localStorage.getItem(localStorageKey.submissionIds));
  if (!activeSubmissions) {
    activeSubmissions = [];
  }

  activeSubmissions.push(submissionId);
  localStorage.setItem(localStorageKey.submissionIds, JSON.stringify(activeSubmissions));
  checkSubmissionsFinish();
}

export function checkSubmissionsFinish() {
  const activeSubmissions = JSON.parse(localStorage.getItem(localStorageKey.submissionIds));
  if (!activeSubmissions?.length) {
    return;
  }

  for (let i = 0; i < activeSubmissions.length; i++) {
    const submissionId = activeSubmissions[i];

    const fetchSubmissionInterval = setInterval(() => {
      getSubmissionById(submissionId)
        .then(({ data }) => {
          if (!data) {
            clearInterval(fetchSubmissionInterval);
            activeSubmissions[i] = null;
          } else if (data.totalOrders === data.processedOrders) {
            getSubmissionDetails(submissionId);
            activeSubmissions[i] = null;
            clearInterval(fetchSubmissionInterval);
          }
        })
        .catch((error) => {
          showErrorBanner({ title: 'Error', text: error.message });

          clearInterval(fetchSubmissionInterval);
          activeSubmissions[i] = null;
        })
        .finally(() => {
          const filteredActiveSubmissions = activeSubmissions.filter(
            (submissionId) => submissionId !== null,
          );

          if (filteredActiveSubmissions?.length) {
            localStorage.setItem(
              localStorageKey.submissionIds,
              JSON.stringify(filteredActiveSubmissions),
            );
          } else {
            localStorage.removeItem(localStorageKey.submissionIds);
          }
        });
    }, 1000);
  }
}

export function getSubmissionDetails(submissionId) {
  getSubmissionDetailsById(submissionId)
    .then(({ data }) => {
      const [totalOrdersCount, failedOrdersCount, processedOrdersCount] = groupOrdersByStatus(data);
      const currentTime = new Date().getTime();

      let showedSubmissionsDetails = JSON.parse(localStorage.getItem(localStorageKey.showedSubmissionsDetails)) || [];
      showedSubmissionsDetails = showedSubmissionsDetails.filter(
        (submissionDetails) => submissionDetails.expirationTime > currentTime,
      );

      const isSubmissionDetailsShowed = showedSubmissionsDetails.some(
        (submissionDetails) => submissionDetails.id === submissionId,
      );
      if (isSubmissionDetailsShowed) {
        return;
      }

      if (failedOrdersCount === 0) {
        showSuccessBanner({
          title: 'Success',
          text: `${totalOrdersCount} orders have been successfully submitted`,
        });
      } else if (data.some(el => !el.error) && failedOrdersCount > 0) {
        showErrorBanner({
          title: 'Error',
          text: `${failedOrdersCount} orders out of ${totalOrdersCount} have not been submitted`,
        });
      } else if (data.map(el => el.error.includes('103'))) {
        const error = JSON.parse(data.map(el => el.error));

        data.forEach(el => showErrorBanner({
          title: 'Error',
          text: `Too many lines in the order ${el.customerPoNumber}. Order has ${error.apiErrors.message.split('but found:')[1]} lines. Max limit is 9000.`,
        }),
        );

        showErrorBanner({
          title: 'Error',
          text: `${failedOrdersCount} orders out of ${totalOrdersCount} have not been submitted`,
        });

        if (processedOrdersCount > 0) {
          showSuccessBanner({
            title: 'Success',
            text: `${processedOrdersCount} orders out of ${totalOrdersCount} have been successfully submitted`,
          });
        }

        if (router.currentRoute?.path === routes.MOC.L1) {
          store.dispatch('massOrderCreation/L1/fetchInitialData');
        }
      }

      showedSubmissionsDetails.push({
        id: submissionId,
        expirationTime: currentTime + 1000 * 60 * 10, // +10 minutes,
      });

      localStorage.setItem(
        localStorageKey.showedSubmissionsDetails,
        JSON.stringify(showedSubmissionsDetails),
      );
    })
    .catch((error) => {
      showErrorBanner({ title: 'Error', text: error.message });
    });
}

export function groupOrdersByStatus(orders) {
  let failedOrdersCount = 0;
  let processedOrdersCount = 0;
  let totalOrdersCount = 0;

  const failedStatuses = [orderStatuses.SENT_FAILED, orderStatuses.CONTAINS_ERRORS];
  const processedStatuses = [orderStatuses.SENT];

  orders.forEach(({ status }) => {
    if (failedStatuses.includes(status)) {
      failedOrdersCount++;
    } else if (processedStatuses.includes(status)) {
      processedOrdersCount++;
    }

    totalOrdersCount++;
  });

  return [totalOrdersCount, failedOrdersCount, processedOrdersCount];
}
