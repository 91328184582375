const SET_MODAL_MODE = 'SET_MODAL_MODE';
const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
const SET_DISPLAYED_DUPLICATES = 'SET_DISPLAYED_DUPLICATES';
const SET_DUPLICATES_CHANGELOG = 'SET_DUPLICATES_CHANGELOG';
const SET_IS_DUPLICATES_LOADING = 'SET_IS_DUPLICATES_LOADING';
const SET_SELECTED_DUPLICATE_LINES = 'SET_SELECTED_DUPLICATE_LINES';
const SET_IS_DUPLICATES_SAVE_IN_PROGRESS = 'SET_IS_DUPLICATES_SAVE_IN_PROGRESS';
const SET_INDEX_OF_DISPLAYED_DUPLICATES_SET = 'SET_INDEX_OF_DISPLAYED_DUPLICATES_SET';

export default {
  SET_MODAL_MODE,
  SET_INITIAL_DATA,
  SET_DISPLAYED_DUPLICATES,
  SET_DUPLICATES_CHANGELOG,
  SET_IS_DUPLICATES_LOADING,
  SET_SELECTED_DUPLICATE_LINES,
  SET_IS_DUPLICATES_SAVE_IN_PROGRESS,
  SET_INDEX_OF_DISPLAYED_DUPLICATES_SET,
};
