import { uniqBy } from "lodash";
import i18n from "@/plugins/i18n";
import purchaseOrdersRepository from "@/api/now/purchaseOrder";
import myOrdersRepository from "@/api/now/myOrders";

import { showErrorBanner, showSuccessBanner } from "@/utils/notifications";

import actions from "./constants/actions";
import mutations from "./constants/mutations";

import { getDefaultUserPreferences } from "@/pages/PurchaseOrder/utils.js";

import { mergeUserPreferences } from "@/pages/Home/Orders/utils";

import { mockHeaderSTOFields, mockLinesSTOFields } from "@/pages/PurchaseOrder/moksSTO.js";

import { TABLE_KEY } from "@/pages/PurchaseOrder/constants";
import { columnDefsForSTO as linesDefs } from "@/pages/PurchaseOrder/Tables/LinesTable/UngroupedExceptions/constants";
// import { columnDefsForSTO as headerDefs } from "@/pages/PurchaseOrder/Tables/OrderListTable/constants";
import { columnDefs as groupedDefs } from "@/pages/PurchaseOrder/Tables/LinesTable/GroupedExceptions/constants";

import { adjustCases } from "@/utils/exceptions/cases";

import { validatorsByFieldName } from "@/enums/order/validation";

import Validator from "@/utils/validation/validator";
import { setErrorMessage } from "@/utils/validation/orders";

const purchaseOrderValidator = new Validator(validatorsByFieldName);

export default {
  async [actions.FETCH_PURCHASE_ORDER_BY_ID]({ commit }, orderId) {
    try {
      const idData = parseOrderId(orderId);
      const { data } = await purchaseOrdersRepository.getPurchaseOrderById(idData);

      commit(mutations.SET_ORDER_ID, orderId);
      commit(mutations.SET_PURCHASE_ORDER, data);
      commit(mutations.DATA_FAILED_TO_LOAD, false);
    } catch (error) {
      console.error(error);
      commit(mutations.DATA_FAILED_TO_LOAD, true);
      throw error;
    }
  },

  async [actions.RESOLVE_EXCEPTIONS]({ state, dispatch, commit }) {
    try {
      const cases = adjustCases(state.resolvedExceptions);

      const { data } = await purchaseOrdersRepository.resolveCases(cases);

      if (data.duplicates.length) {
        showErrorBanner({
          title: i18n.global.t("pages.purchaseOrder.resolveExceptions.duplicatesMessage"),
        });
      } else if (data.errors.length) {
        showErrorBanner({
          title: i18n.global.t("notifications.errorMessages.http.systemErrorTryAgainOrSupport"),
        });
      }

      commit(mutations.SET_CASES_RELOADING, true);
      // TODO: figure out a better solution to wait for BE to update data
      setTimeout(() => {
        dispatch(actions.REFETCH_PO_AFTER_SUBMIT);
      }, 3000);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async [actions.REFETCH_PO_AFTER_SUBMIT]({ state, commit, dispatch }) {
    const { orderId } = state;

    try {
      const idData = parseOrderId(orderId);
      const { data } = await purchaseOrdersRepository.getPurchaseOrderById(idData);

      if (!data.cases.length) {
        showSuccessBanner({
          title: i18n.global.t("pages.purchaseOrder.resolveExceptions.allCasesSubmitted"),
        });
        dispatch(actions.SET_EXCEPTIONS_GROUPED, false);
      } else {
        showSuccessBanner({
          title: i18n.global.t("pages.purchaseOrder.resolveExceptions.selectedCasesSubmitted"),
        });
      }

      dispatch(actions.RESET_STORE);

      commit(mutations.SET_ORDER_ID, orderId);
      commit(mutations.SET_PURCHASE_ORDER, data);
      commit(mutations.DATA_FAILED_TO_LOAD, false);
      commit(mutations.SET_CASES_RELOADING, false);
    } catch (error) {
      console.error(error);
      commit(mutations.DATA_FAILED_TO_LOAD, true);
      throw error;
    }
  },

  [actions.SET_GRID_API]({ commit }, { tableId, gridApi }) {
    commit(mutations.SET_GRID_API, { tableId, gridApi });
  },

  [actions.INIT_GRID_PAGINATION]({ commit }, tableId) {
    commit(mutations.INIT_GRID_PAGINATION, tableId);
  },

  [actions.SET_PAGE_NUMBER]({ commit }, { tableId, pageNumber }) {
    commit(mutations.SET_PAGE_NUMBER, { tableId, pageNumber });
  },

  [actions.SET_PAGE_SIZE]({ commit }, { tableId, pageSize }) {
    commit(mutations.SET_PAGE_SIZE, { tableId, pageSize });
  },

  [actions.RESET_PAGE_NUMBER]({ commit }, tableId) {
    commit(mutations.SET_PAGE_NUMBER, { tableId, pageNumber: 0 });
  },

  [actions.SET_GROUPS]({ commit }, groups) {
    commit(mutations.SET_GROUPS, groups);
  },

  [actions.SET_SELECTED_GROUP]({ commit }, groupId) {
    commit(mutations.SET_SELECTED_GROUP_ID, groupId);
  },

  [actions.TOGGLE_GROUP]({ commit, state }, { groupId, expanded }) {
    const updatedGroups = state.groups.map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          expanded,
        };
      }

      return group;
    });

    commit(mutations.SET_GROUPS, updatedGroups);
  },

  [actions.SET_EXCEPTIONS_GROUPED]({ commit }, exceptionsGrouped) {
    commit(mutations.SET_EXCEPTIONS_GROUPED, exceptionsGrouped);

    commit(mutations.SET_RESOLVED_EXCEPTIONS, []);
    commit(mutations.SET_GROUPED_SELECTED_CASES, []);
  },

  [actions.SET_GROUPED_SELECTED_CASES]({ commit, state }, selectedCases) {
    const cases = selectedCases.map((selectedCase) => {
      return {
        caseId: selectedCase.caseId,
        orderId: selectedCase.orderId,
        caseGroup: selectedCase.caseGroup,
        caseType: selectedCase.caseType,
        caseTypeDisplayName: selectedCase.caseTypeDisplayName,
        caseLevel: selectedCase.caseLevel,
        wrongfulValues: selectedCase.wrongfulValues,
        allowedActions: selectedCase.allowedActions,
        caseAction: selectedCase.caseAction,
        decisionCriteria: selectedCase.decisionCriteria,
      };
    });
    commit(mutations.SET_GROUPED_SELECTED_CASES, cases);

    if (state.resolvedExceptions.length) {
      const newResolved = mapSelectedToResolvedForGrouped(selectedCases, state.resolvedExceptions);

      commit(mutations.SET_RESOLVED_EXCEPTIONS, newResolved);
    }
  },

  [actions.RESET_STORE]({ commit }) {
    commit(mutations.SET_PURCHASE_ORDER, {});
    commit(mutations.SET_RESOLVED_EXCEPTIONS, []);

    commit(mutations.SET_GRID_API, []);
  },

  async [actions.FIX_WRONGFUL_VALUE]({ commit, state }, params) {
    const newResolved = state.exceptionsGrouped
      ? mapWithResolvedExceptionsForGrouped(
          params,
          state.groupedSelectedCases,
          state.resolvedExceptions
        )
      : mapWithResolvedExceptions(params, state.resolvedExceptions);

    commit(mutations.SET_RESOLVED_EXCEPTIONS, newResolved);
  },

  async [actions.FETCH_USER_PREFERENCES]({ commit, state }) {
    const { data } = await myOrdersRepository.fetchUserPreferences();

    const { orderType } = state.purchaseOrder;

    updateUserPreferences(commit, data, orderType);
  },

  async [actions.SAVE_USER_PREFERENCES]({ commit, state }, userPreferences) {
    const { orderType } = state.purchaseOrder;

    if (orderType.value !== "STO" && orderType?.value !== "RSTO") {
      if (
        userPreferences.poHeaderColumns &&
        !userPreferences.poHeaderColumns.includes(mockHeaderSTOFields[0])
      ) {
        userPreferences.poHeaderColumns.push(...mockHeaderSTOFields);
      }

      if (
        userPreferences.lineItemColumns &&
        !userPreferences.lineItemColumns.includes(mockLinesSTOFields[0])
      ) {
        userPreferences.lineItemColumns.push(...mockLinesSTOFields);
      }
    }

    const { data } = await myOrdersRepository.saveUserPreferences(userPreferences);

    updateUserPreferences(commit, data, orderType);
  },
};

function parseOrderId(orderId) {
  return orderId.split("=").reduce((obj, cur, index, array) => {
    if (index % 2 === 0) {
      obj[cur] = array[index + 1];
    }

    return obj;
  }, {});
}

// TODO: Will be changed in future when BE will implement request userSettings by key
function updateUserPreferences(commit, data, orderType) {
  const { lineItemColumns = [], poHeaderColumns = [], groupedCasesColumns = [] } = data;

  const defaultLinesPreferences = getDefaultUserPreferences(linesDefs);

  let mergedLinesPreferences = mergeUserPreferences(defaultLinesPreferences, lineItemColumns);

  // const defaultHeaderPreferences = getDefaultUserPreferences(headerDefs);

  // let mergedHeaderPreferences = mergeUserPreferences(defaultHeaderPreferences, poHeaderColumns);
  let mergedHeaderPreferences = mergeUserPreferences(poHeaderColumns);

  const defaultGroupedPreferences = getDefaultUserPreferences(groupedDefs);

  let mergedGroupedPreferences = mergeUserPreferences(
    defaultGroupedPreferences,
    groupedCasesColumns
  );

  if (orderType?.value === "STO" || orderType?.value === "RSTO") {
    mergedLinesPreferences = mergedLinesPreferences.map((column) => {
      if (column.name === "Sales Order Number") {
        return { ...column, name: "STO Number" };
      }
      if (column.name === "Reference Document Line Item") {
        return { ...column, name: "STO Line Item Number" };
      }
      if (column.id === "plantCode") {
        column.id = "destinationPlantCode";
      }

      return column;
    });
  }

  if (orderType?.value !== "STO" && orderType?.value !== "RSTO") {
    // TODO: Let's filter the array by the specific column names. Let's not use magic numbers.

    const columnsToDelete = [
      "purchaseOrderDocumentTypeCode",
      "supplierNodeTypeCode",
      "shipmentTypeCode",
      "proposedDeliveryDate",
      "stockTransferReasonCode",
      "vendorProductCode",
      "deliveryUnit",
    ];

    mergedHeaderPreferences = mergedHeaderPreferences.filter(
      (obj) => !columnsToDelete.includes(obj.id)
    );

    mergedLinesPreferences = mergedLinesPreferences.filter(
      (obj) => !columnsToDelete.includes(obj.id)
    );
    mergedLinesPreferences = mergedLinesPreferences.map((column) => {
      if (column.name === "STO Number") {
        return { ...column, name: "Sales Order Number" };
      }
      if (column.name === "STO Line Item Number") {
        return { ...column, name: "Reference Document Line Item" };
      }
      if (column.id === "destinationPlantCode") {
        column.id = "plantCode";
      }

      return column;
    });
  } else if (!mergedHeaderPreferences.includes(mockHeaderSTOFields[0])) {
    mergedHeaderPreferences.push(...mockHeaderSTOFields);
  } else if (!mergedLinesPreferences.includes(mockLinesSTOFields[0])) {
    mergedLinesPreferences.push(...mockLinesSTOFields);
  }

  mergedHeaderPreferences = uniqBy(mergedHeaderPreferences, "id");
  mergedGroupedPreferences = uniqBy(mergedGroupedPreferences, "id");
  mergedLinesPreferences = uniqBy(mergedLinesPreferences, "id");

  commit(mutations.SET_USER_PREFERENCES, {
    tableId: TABLE_KEY.LINES_TABLE,
    userPreferences: mergedLinesPreferences,
  });
  commit(mutations.SET_USER_PREFERENCES, {
    tableId: TABLE_KEY.HEADER_TABLE,
    userPreferences: mergedHeaderPreferences,
  });
  commit(mutations.SET_USER_PREFERENCES, {
    tableId: TABLE_KEY.GROUPED_TABLES,
    userPreferences: mergedGroupedPreferences,
  });
}

function mapWithResolvedExceptionsForGrouped(params, selectedCases, existingResolvedExceptions) {
  const { action, fixedValue, wrongfulValueIndex } = params;
  const resolvedCases = existingResolvedExceptions.length
    ? existingResolvedExceptions
    : selectedCases;

  const shouldUpdateFixedValue = action === "UPDATE" && (fixedValue || fixedValue === "");

  const updatedCases = resolvedCases.map((resolvedCase) => {
    const updatedCase = shouldUpdateFixedValue
      ? updateFixValue(resolvedCase, params)
      : updateAction(resolvedCase, action);

    setErrorMessage(updatedCase, wrongfulValueIndex, purchaseOrderValidator);

    return updatedCase;
  });

  return updatedCases;
}

function mapSelectedToResolvedForGrouped(selectedCases, resolvedCases) {
  const { caseAction, wrongfulValues } = resolvedCases[0];

  return selectedCases.map((selectedCase) => {
    const updatedWrongfulValues = selectedCase.wrongfulValues.map((value, index) => {
      return {
        ...value,
        action: caseAction,
        fixedValue: wrongfulValues[index].fixedValue,
      };
    });

    return {
      ...selectedCase,
      caseAction,
      wrongfulValues: updatedWrongfulValues,
    };
  });
}

function mapWithResolvedExceptions(params, existingResolvedExceptions) {
  const { action, fixedValue, case: caseData, wrongfulValueIndex } = params;
  const caseIndex = existingResolvedExceptions.findIndex(
    (existingCase) => existingCase.caseId === caseData.caseId
  );
  const caseToUpdate = caseIndex > -1 ? existingResolvedExceptions[caseIndex] : caseData;

  const shouldUpdateFixedValue = action === "UPDATE" && (fixedValue || fixedValue === "");

  const updatedCase = shouldUpdateFixedValue
    ? updateFixValue(caseToUpdate, params)
    : updateAction(caseToUpdate, action);

  setErrorMessage(updatedCase, wrongfulValueIndex, purchaseOrderValidator);

  if (caseIndex > -1) {
    existingResolvedExceptions[caseIndex] = updatedCase;

    return existingResolvedExceptions;
  }

  return [...existingResolvedExceptions, updatedCase];
}

function updateAction(caseData, action) {
  const wrongfulValues = caseData.wrongfulValues.map((value) => ({ ...value, action }));

  return {
    ...caseData,
    wrongfulValues,
    caseAction: action,
  };
}

function updateFixValue(caseData, params) {
  const { action, fixedValue, wrongfulValueIndex } = params;

  const wrongfulValues = caseData.wrongfulValues.map((value, index) => {
    return index === wrongfulValueIndex
      ? {
          ...value,
          action,
          fixedValue,
        }
      : value;
  });

  return {
    ...caseData,
    wrongfulValues,
  };
}
