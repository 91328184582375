import BaseCreator from './BaseCreator';

import { FREE_OF_CHARGE_RETURNS_SALES } from '../constansts/config';

import BaseValidator from '../gridValidators/BaseValidator';

export default class FreeOfChargeReturnsSalesCreator extends BaseCreator {
  constructor(orders, sizeColumnsToAdd) {
    super({
      orders,
      sizeColumnsToAdd,
      orderTypeName: 'Free-of-Charge Returns Sales Order',
      columnOrder: FREE_OF_CHARGE_RETURNS_SALES.COLUMN_ORDER,
      viewOnlyFields: FREE_OF_CHARGE_RETURNS_SALES.VIEW_ONLY_FIELDS,
      lineLevelFields: FREE_OF_CHARGE_RETURNS_SALES.LINE_LEVEL_FIELDS,
      headerLevelFields: FREE_OF_CHARGE_RETURNS_SALES.HEADER_LEVEL_FIELDS,
      groupingFieldKeys: FREE_OF_CHARGE_RETURNS_SALES.GROUPING_FIELD_KEYS,
    });

    this._validator = new BaseValidator({
      requiredFields: FREE_OF_CHARGE_RETURNS_SALES.REQUIRED_FIELDS,
    });
  }
}
