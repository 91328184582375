import store from '@/store';

import {
  ORDER_TYPE_KEY,
  CONTRACT,
  STANDARD,
  RETURN_SALES,
  FREE_OF_CHARGE,
  FREE_OF_CHARGE_RETURNS_SALES,
} from '../constansts/config';

import BaseCreator from './BaseCreator';

export default class SuperUserCreator extends BaseCreator {
  constructor(orders, sizeColumnsToAdd) {
    const { selectedOrderTypeId } = store.state.massOrderCreation.L1;

    const config = determineOrderTypeConfig(selectedOrderTypeId);

    const ordersOfSelectedOrderType = orders.filter((order) => {
      return order[ORDER_TYPE_KEY].id === selectedOrderTypeId;
    });

    super({
      orders: ordersOfSelectedOrderType,
      sizeColumnsToAdd,
      columnOrder: config.COLUMN_ORDER,
      viewOnlyFields: config.VIEW_ONLY_FIELDS,
      lineLevelFields: config.LINE_LEVEL_FIELDS,
      headerLevelFields: config.HEADER_LEVEL_FIELDS,
      groupingFieldKeys: config.GROUPING_FIELD_KEYS,
    });
  }
}

export function determineOrderTypeConfig(orderTypeId) {
  switch (orderTypeId) {
    case 1:
      return CONTRACT;
    case 3:
      return FREE_OF_CHARGE;
    case 5: case 18:
      return STANDARD;
    case 6:
      return RETURN_SALES;
    case 7: case 19:
      return FREE_OF_CHARGE_RETURNS_SALES;
    default:
  }
}
