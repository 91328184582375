import {
  MASS_ORDER_CREATION_L1,
  MASS_ORDER_CREATION_L2,
  MASS_ORDER_CREATION_FILE_UPLOAD,
  MASS_ORDER_CREATION_DUPLICATE_LINES,
} from '@/store/moduleNames';

export function createNameWithL1Module(name) {
  return `${MASS_ORDER_CREATION_L1}/${name}`;
}

export function createNameWithL2Module(name) {
  return `${MASS_ORDER_CREATION_L2}/${name}`;
}

export function createNameWithFileUploadModule(name) {
  return `${MASS_ORDER_CREATION_FILE_UPLOAD}/${name}`;
}

export function createNameWithDuplicateLinesModule(name) {
  return `${MASS_ORDER_CREATION_DUPLICATE_LINES}/${name}`;
}
