import mutationTypes from './constansts/mutationTypes';

import * as actionsEnum from './constansts/actions';
import { fileUploadStatuses } from '../fileUpload/constansts/fileUploadStatuses';
import state from './state';

import filterPanelActions from './filterPanel/actions';
import orderColumnActions from './ordersColumn/actions';
import orderTypeColumnActions from './orderTypeColumn/actions';
import issueTypeColumnActions from './issueTypeColumn/actions';
import customerOrderColumnActions from './customerOrderColumn/actions';

import {
  sendOrders,
  fetchOrders,
  fetchOrderTypesMapping,
  fetchNotSucceededFiles,
} from '@/api/now/massOrderCreation';

import { MASS_ORDER_CREATION_FILE_UPLOAD } from '@/store/moduleNames';
import {
  POLL_FILES_PROCESS_STATUS,
  SET_RECENTLY_UPLOADED_FILES,
} from '@/store/modules/massOrderCreation/fileUpload/constansts/actions';

import { showSuccessBanner, showErrorBanner } from '@/utils/notifications';
import { setSubmissionIdToStorage } from '@/pages/MassOrderCreation/submissionMethods';

import { orderStatuses } from '@/enums/order/orderStatus';

const OFOA_ID_KEY = 'ofoaId';
const initialState = { ...state };

const {
  SEND_ORDERS,
  FETCH_ORDERS,
  FETCH_INITIAL_DATA,
  HANDLE_REJECTED_ORDERS,
  HANDLE_PROCESSED_ORDERS,
  RESET_SELECTED_FILENAME_IDS,
} = actionsEnum;

export default {
  ...filterPanelActions,
  ...orderColumnActions,
  ...orderTypeColumnActions,
  ...issueTypeColumnActions,
  ...customerOrderColumnActions,

  async [FETCH_INITIAL_DATA]({ commit, dispatch }) {
    commit(mutationTypes.RESET_STATE, initialState);
    commit(mutationTypes.SET_IS_INITIAL_DATA_FETCHING, true);

    try {
      Promise.all([fetchOrders(), fetchOrderTypesMapping(), fetchNotSucceededFiles()])
        .then((initalData) => {
          const pendingFiles = initalData[2].data.filter(
            ({ status }) => status === fileUploadStatuses.PENDING,
          );
          const failedFiles = initalData[2].data.filter(
            ({ status }) => status === fileUploadStatuses.FAIL,
          );

          // Filter Orders with SUBMITTING status
          const succeededFiles = initalData[0].data.files;
          const succeededFilesWithoutSubmittingOrders = succeededFiles.filter((file) => {
            let filteredOrders;
            if (file?.orders) {
              filteredOrders = file.orders.filter(
                (order) => order.status !== orderStatuses.SUBMITTING,
              );
              file.orders = filteredOrders;
            }

            if (!filteredOrders?.length) {
              return false;
            }

            return file;
          });

          commit(mutationTypes.SET_SUCCEEDED_FILES, succeededFilesWithoutSubmittingOrders);
          commit(mutationTypes.SET_ORDER_TYPES_MAPPING, initalData[1].data);
          commit(mutationTypes.SET_NOT_SUCCEEDED_FILES, failedFiles);

          if (pendingFiles.length) {
            dispatch(
              `${MASS_ORDER_CREATION_FILE_UPLOAD}/${SET_RECENTLY_UPLOADED_FILES}`,
              pendingFiles,
              { root: true },
            );

            dispatch(`${MASS_ORDER_CREATION_FILE_UPLOAD}/${POLL_FILES_PROCESS_STATUS}`, null, {
              root: true,
            });
          }
        })
        .finally(() => {
          commit(mutationTypes.SET_IS_INITIAL_DATA_FETCHING, false);
          commit(mutationTypes.SET_IS_SUBMIT_ORDERS_IN_PROGRESS, false);
        });
    } catch (error) {
      console.error(error);
    }
  },

  [SEND_ORDERS]({
    commit, dispatch, getters: { getSelectedOrderIds },
  }) {
    commit(mutationTypes.SET_IS_INITIAL_DATA_FETCHING, true);
    commit(mutationTypes.SET_IS_SUBMIT_ORDERS_IN_PROGRESS, true);

    sendOrders(getSelectedOrderIds)
      .then(({ data }) => {
        showSuccessBanner({ title: 'Success', text: 'Orders sent for submission' });
        dispatch(FETCH_INITIAL_DATA);

        setSubmissionIdToStorage(data.submissionId);
      })
      .catch((error) => {
        commit(mutationTypes.SET_IS_INITIAL_DATA_FETCHING, false);
        showErrorBanner({ title: 'Error', text: error.message });
      })
      .finally(() => {
        commit(mutationTypes.SET_IS_SUBMIT_ORDERS_IN_PROGRESS, false);
      });
  },

  [HANDLE_REJECTED_ORDERS](_, orders = []) {
    const rejectedOrderPOs = orders
      .filter(({ status }) => status === orderStatuses.CONTAINS_ERRORS)
      .map(({ customerPoNumber }) => customerPoNumber);

    if (!rejectedOrderPOs.length) {
      return;
    }

    const PONumbers = rejectedOrderPOs.join(', ');
    const text = rejectedOrderPOs.length === 1
      ? `Order with PO Number: ${PONumbers} wasn't submitted`
      : `Orders with PO Number: ${PONumbers} weren't submitted`;

    showErrorBanner({ title: 'Error', text });
  },

  [HANDLE_PROCESSED_ORDERS]({ dispatch }, orders = []) {
    const processedOrderPOs = orders
      .filter((order) => order[OFOA_ID_KEY]?.length)
      .map(({ customerPoNumber }) => customerPoNumber);

    if (!processedOrderPOs.length) {
      return;
    }

    const PONumbers = processedOrderPOs.join(', ');
    const text = processedOrderPOs.length === 1
      ? `Order with PO Number: ${PONumbers} was successfuly submitted`
      : `Orders with PO Number: ${PONumbers} were successfuly submitted`;

    showSuccessBanner({ title: 'Success', text });

    dispatch(FETCH_ORDERS);
    dispatch(RESET_SELECTED_FILENAME_IDS);
  },
};
