const orderSummary = {
  dashboardLayoutSnapshot: [],
  summary: {
    totalExceptions: 0,
    totalPOExceptions: 0,
    totalUnitsImpacted: 0,
    orderLifeCycleCount: {},
    orderTypeSummary: {},
  },

  isOrdersInfoLoading: false,
};

const filters = {
  region: "GC",
  filterForm: [],
  appliedFilterFields: [],
  componentKey: 0,
  fieldsWithErrors: new Set(),
  searchQuery: false,

  isLoading: false,

  selectedFiltersName: "",
  defaultFiltersName: "",
  savedFilters: true,

  isFormEdited: false,
};

const orderList = {
  ordersListErrors: {},
  orderType: "",
  reportType: "",
  orderList: [],
};
const orderDetail = {
  orderDetailErrors: {},
  orderDetail: [],
  isOrderDetailLoading: false,
};

const orderListTable = {
  isOrdersListLoading: false,

  tableParams: {},
  gridApi: {},

  datasource: {},
  columnDef: [],

  orderId: null,
  downloadPayloadParams: {
    orderId: null,
    orderType: null,
    orderStats: null,
  },
  pageSize: 50,
  pageNumber: 0,
  pageTokens: [null],

  tableFilters: {},
  orderMiscDetails: [],

  totalAvailablePagesNumber: null,
  totalAvailableOrdersNumber: null,
};

export default {
  ...orderSummary,
  ...filters,
  ...orderList,
  ...orderListTable,
  ...orderDetail,
};
// Path: src/store/modules/productionSupport/productionSupport.state.js
