export default {
  SET_GRID_API(state, gridApi) {
    state.gridApi = gridApi;
  },

  SET_ORDER_LIST_ERRORS(state, ordersListErrors) {
    state.ordersListErrors = ordersListErrors;
  },

  SET_ORDER_DETAIL_ERRORS(state, orderDetailErrors) {
    state.orderDetailErrors = orderDetailErrors;
  },

  SET_TOTAL_AVAILABLE_ORDERS_NUMBER(state, totalAvailableOrdersNumber) {
    state.totalAvailableOrdersNumber = totalAvailableOrdersNumber;
  },

  SET_TOTAL_AVAILABLE_PAGES_NUMBER(state, totalAvailablePagesNumber) {
    state.totalAvailablePagesNumber = totalAvailablePagesNumber;
  },

  SET_DASHBOARD_LAYOUT_SNAPSHOT(state, snapshot) {
    state.dashboardLayoutSnapshot = snapshot;
  },

  SET_MY_ORDERS_QUANTITY(state, myOrdersQuantity) {
    state.myOrdersQuantity = myOrdersQuantity;
  },

  SET_REGION(state, region) {
    state.region = region;
  },

  SET_REPORT_TYPE(state, reportType) {
    state.reportType = reportType;
  },

  SET_ORDER_TYPE(state, orderType) {
    // state.appliedFilterFields = ;
    state.orderType = orderType;
  },

  SET_TABLE_PARAMS(state, tableParams) {
    state.tableParams = tableParams;
  },

  SET_ORDER_ID(state, orderId) {
    state.orderId = orderId;
  },

  SET_DOWNLOAD_PAYLOAD_ORDER_PARAMS(state, params) {
    state.downloadPayloadParams = { ...params };
  },

  SET_PAGE_SIZE(state, pageSize) {
    state.pageSize = pageSize;
  },

  SET_PAGE_NUMBER(state, pageNumber) {
    state.pageNumber = pageNumber;
  },

  SET_PAGE_TOKEN(state, { pageNumber, token }) {
    state.pageTokens[pageNumber] = token;
  },

  SET_IS_ORDERS_INFO_LOADING(state, isOrdersInfoLoading) {
    state.isOrdersInfoLoading = isOrdersInfoLoading;
  },

  SET_IS_ORDERS_LIST_LOADING(state, isOrdersListLoading) {
    state.isOrdersListLoading = isOrdersListLoading;
  },

  SET_IS_ORDER_DETAIL_LOADING(state, isOrderDetailLoading) {
    state.isOrderDetailLoading = isOrderDetailLoading;
  },

  SET_SUMMARY(state, summary) {
    state.summary = summary;
  },

  SET_ORDER_LIST(state, orderList) {
    state.orderList = orderList;
  },

  SET_ORDER_DETAIL(state, orderDetail) {
    state.orderDetail = orderDetail;
  },

  SET_FILTER_FORM(state, form) {
    state.filterForm = form;
  },

  SET_APPLY_FILTER_FIELDS(state, fields) {
    state.appliedFilterFields = fields;
  },

  SET_FILTER_FIELD(state, field) {
    state.filterForm.set(field.key, field);
  },

  SET_IS_FORM_EDITED(state, isFormEdited) {
    state.isFormEdited = isFormEdited;
  },

  RESET_FIELD_ERRORS(state) {
    state.fieldsWithErrors = new Set();
  },

  SET_SELECTED_FILTERS_NAME(state, selectedFiltersName) {
    state.selectedFiltersName = selectedFiltersName;
  },

  SET_SEARCH_QUERY(state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  ADD_FIELD_WITH_ERROR(state, label) {
    state.fieldsWithErrors.add(label);
  },

  REMOVE_FIELD_WITH_ERROR(state, label) {
    state.fieldsWithErrors.delete(label);
  },

  SET_DATA_SOURCE(state, datasource) {
    state.datasource = datasource;
  },

  ADD_ORDER_MISC_ROW(state, { orderData, rowIndex }) {
    const orderMiscellaneousData = {
      addIndex: rowIndex + 1,
      add: [{ ...orderData, miscellaneous: true }],
    };
    state.gridApi.applyServerSideTransaction(orderMiscellaneousData);
  },

  SET_ORDER_MISC_DETAILS(state, data) {
    state.orderMiscDetails = data;
  },
};
