export default {
  SET_PAGE_NUMBER: (state, pageNumber) => {
    state.pageNumber = pageNumber;
  },

  SET_PAGE_SIZE: (state, pageSize) => {
    state.pageSize = pageSize;
  },

  SET_TOTAL_RESULTS: (state, totalResults) => {
    state.totalResults = totalResults;
  },

  SET_EXCEPTIONS: (state, exceptions) => {
    state.exceptionDetails = exceptions;
  },

  SET_STATS: (state, stats) => {
    state.stats = stats;
  },

  SET_LOADING: (state, isLoading) => {
    state.isLoading = isLoading;
  },

  SET_COLUMN_DEFS: (state, columnDefs) => {
    state.columnDefs = columnDefs;
  },

  SET_USER_PREFERENCES: (state, userPreferences) => {
    state.userPreferences = userPreferences;
  },

  SET_PAGE_TOKEN: (state, { pageNumber, token }) => {
    state.pageTokens[pageNumber] = token;
  },

  RESET_PAGE_TOKEN: (state) => {
    // first page always has a `null` token
    state.pageTokens = [null];
  },

  SET_CASE_GROUPS: (state, caseGroups) => {
    state.caseGroups = caseGroups;
  },

  SET_EXCEPTION_TYPES: (state, caseTypes) => {
    state.caseTypes = caseTypes;
  },

  SET_SELECTED_EXCEPTION_GROUPS: (state, selectedExceptionGroups) => {
    state.selectedExceptionGroups = selectedExceptionGroups;
  },

  SET_SELECTED_EXCEPTION_TYPES: (state, selectedExceptionType) => {
    state.selectedExceptionTypes = selectedExceptionType;
  },

  CLEAR_GROUPS_AND_TYPES: (state) => {
    state.selectedExceptionGroups = [];
    state.selectedExceptionTypes = [];
  },

  SET_RELATED_ORDERS: (state, relatedOrders) => {
    state.relatedOrders = relatedOrders;
  },

  SET_CASE_RELATED_VALUE: (state, caseRelatedValue) => {
    state.caseRelatedValue = caseRelatedValue;
  },

  SET_IS_RELATED_ORDERS_LOADING: (state, isRelatedOrdersLoading) => {
    state.isRelatedOrdersLoading = isRelatedOrdersLoading;
  },

  SET_IS_RESOLVE_CASE_LOADING: (state, isResolveCaseLoading) => {
    state.isResolveCaseLoading = isResolveCaseLoading;
  },

  SET_EXPORT_UNGROUPED_CASES_FILENAME: (state, fileName) => {
    state.exportUngroupedCasesFileName = fileName;
  },

  SET_CASE_TYPES_DISPLAY_NAMES: (state, caseTypesDisplayNames) => {
    state.caseTypesDisplayNames = caseTypesDisplayNames;
  },

  SET_GROUPED_FLOW: (state, isGrouped) => {
    state.isGroupedFlow = isGrouped;
  },

  ADD_GROUPED_API: (state, { caseType, api }) => {
    state.groupedExceptionApis.set(caseType, api);
  },

  REMOVE_GROUPED_API: (state, caseType) => {
    state.groupedExceptionApis.delete(caseType);
  },

  RESET_GROUPED_APIS: (state) => {
    state.groupedExceptionApis = new Map();
  },
};
