import moment from 'moment-business-days';

import ValidationRulesEnum from '@/enums/validationRules/MOC';

import { determineCancelDateMinDate, isReturnOrderType } from '@/utils/globalGoldenRules';
import {
  addLeadingZero, isDateFormatValid, isValidISOFormat, isShortDateFormatValid,
} from '@/utils/dates';

import { issueTypesTextMapper } from '@/store/modules/massOrderCreation/constants/issueTypesMapper.js';

import { FIELD_NAMES } from '@/enums/fieldNames';

import OrderIssue from './Issue';

import { disabledReasonCodes } from '@/enums/validationRules/dropdownValues';

const MISSING_VALUE = 'Missing value';
const INCORRECT_FORMAT = 'Incorrect formatting';
const INVOICE_NUMBER_NOT_APPLICABLE = 'Not applicable for selected reason code';

export default class BaseValidator {
  constructor({ requiredFields }) {
    this._rowId = null;
    this._subId = null;
    this._value = null;
    this._lineId = null;
    this._orderId = null;
    this._pattern = null;
    this._orderType = null;
    this._columnName = null;
    this._expectedFormat = null;
    this._requiredFields = requiredFields;
  }

  validateValue({
    colDef, newValue: value, data,
  }) {
    this._value = value;
    this._rowId = data.rowId;
    this._subId = colDef.subId;
    this._lineId = data.lineId;
    this._orderId = data.orderId;
    this._columnName = colDef.field;
    this._orderType = data.orderType;
    this._productEngine = colDef.productEngine;
    this._data = data;

    const {
      pattern = null,
      expectedFormat = '',
      isDateFormat = false,
    } = ValidationRulesEnum.formatRules[this._columnName] || {};

    const sizesPattern = ValidationRulesEnum.formatRules.sizes.pattern;

    this._expectedFormat = expectedFormat || '';
    this._pattern = this._productEngine ? sizesPattern : pattern;

    const requiredIssue = this._validateRequiredFields();

    const invoiceNumberIssue = this._validateInvoiceNumber();

    if (requiredIssue) {
      return requiredIssue;
    }

    if (isDateFormat) {
      return this._validateDateFormat() || this._validateGoldenRules(data) || null;
    }

    if (invoiceNumberIssue) {
      return invoiceNumberIssue;
    }

    return this._validateByRegex() || null;
  }

  _validateInvoiceNumber() {
    const invalidReasonCode = disabledReasonCodes.includes(this._data.reasonCode?.value);

    if (invalidReasonCode && this._data.invoiceNumber?.value) {
      return this._createIssue(this._createIssueDescription(INVOICE_NUMBER_NOT_APPLICABLE), FIELD_NAMES.INVOICE_NUMBER);
    }
  }

  _validateRequiredFields() {
    if (!this._requiredFields.includes(this._columnName)) {
      return null;
    }

    if (!this._value?.trim().length) {
      return this._createIssue(this._createIssueDescription(MISSING_VALUE));
    }
  }

  _validateByRegex() {
    const testValue = this._value?.trim();

    if (!testValue || new RegExp(this._pattern).test(String(testValue))) {
      return null;
    }

    return this._createIssue(this._createIssueDescription(INCORRECT_FORMAT));
  }

  _validateDateFormat() {
    const dateTransformed = this._transformDate(this._value);
    const isDateError = !isDateFormatValid(dateTransformed) || !isShortDateFormatValid(dateTransformed);

    if (!isDateError) {
      return null;
    }

    return this._createIssue(this._createIssueDescription(INCORRECT_FORMAT));
  }

  _validateGoldenRules(rowData) {
    switch (this._columnName) {
      case FIELD_NAMES.CRD:
        return this._validateCrd(rowData);
      case FIELD_NAMES.CANCEL_DATE:
        return this._validateCancelDate(rowData.crd?.value, rowData.cancelDate?.value);
      case FIELD_NAMES.VALID_FROM_DATE:
        return this._validateValidFromDate(rowData);
      case FIELD_NAMES.VALID_TO_DATE:
        return this._validateValidToDate(rowData.validFromDate?.value, rowData.validToDate?.value);
      default:
        break;
    }
  }

  _createIssueDescription(issueType) {
    return `${issueType} ${this._expectedFormat ? this._createExpectedFormatMessage() : ''}`.trim();
  }

  _createExpectedFormatMessage() {
    return `(expected format: ${this._expectedFormat})`;
  }

  _createIssue(description, columnName = this._columnName) {
    return new OrderIssue({
      columnName,
      description,
      rowId: this._rowId,
      subId: this._subId,
      lineId: this._lineId,
      orderId: this._orderId,
      productEngine: this._productEngine,
    });
  }

  _transformDate(value = '') {
    const splittedDate = value.split('/').filter((dateNumber) => !!dateNumber);

    return [addLeadingZero(splittedDate[0]), addLeadingZero(splittedDate[1]), splittedDate[2]].join(
      '/',
    );
  }

  _validateCrd(rowData) {
    const minDate = moment().startOf('day');
    const momentDate = moment(rowData.crd?.value).startOf('day');

    if (momentDate.isBefore(minDate)) {
      const message = issueTypesTextMapper.CRD_BEFORE_TODAY;

      return this._createIssue(message);
    }

    return this._validateCancelDate(rowData.crd?.value, rowData.cancelDate?.value, true) || null;
  }

  _validateCancelDate(crd, cancelDate, dependencyCheck = false) {
    const isCRDFormatInvalid = !isValidISOFormat(crd);
    const isCancelDateFormatInvalid = !isValidISOFormat(cancelDate);

    const minDate = isCRDFormatInvalid
      ? null
      : moment(crd).add(determineCancelDateMinDate(this._orderType), 'days').startOf('day');
    const momentDate = isCancelDateFormatInvalid ? null : moment(cancelDate).startOf('day');

    if (isCRDFormatInvalid || isCancelDateFormatInvalid || momentDate.isBefore(minDate)) {
      const message = isReturnOrderType(this._orderType)
        ? issueTypesTextMapper.CANCEL_DATE_BEFORE_CRD_PLUS_30_DAYS
        : issueTypesTextMapper.CANCEL_DATE_BEFORE_CRD_PLUS_5_DAYS;

      return dependencyCheck
        ? this._createIssue(message, FIELD_NAMES.CANCEL_DATE)
        : this._createIssue(message);
    }
  }

  _validateValidFromDate(rowData) {
    const nowDate = moment().startOf('day');
    const momentDate = moment(rowData.validFromDate?.value).startOf('day');

    if (momentDate.isBefore(nowDate)) {
      return this._createIssue(issueTypesTextMapper.VALID_FROM_BEFORE_TODAY);
    }

    return (
      this._validateValidToDate(rowData.validFromDate?.value, rowData.validToDate?.value, true)
      || null
    );
  }

  _validateValidToDate(validFromDate, validToDate, dependencyCheck = false) {
    const isValidToFormatInvalid = !isValidISOFormat(validToDate);
    const isValidFromFormatInvalid = !isValidISOFormat(validFromDate);

    const minDate = isValidFromFormatInvalid
      ? null
      : moment(validFromDate).add(1, 'days').startOf('day');
    const momentDate = isValidToFormatInvalid ? null : moment(validToDate).startOf('day');

    const message = `${issueTypesTextMapper.VALID_TO_BEFORE_VALID_FROM} plus 1`;

    if (isValidFromFormatInvalid || isValidToFormatInvalid || momentDate.isBefore(minDate)) {
      return dependencyCheck
        ? this._createIssue(message, FIELD_NAMES.VALID_TO_DATE)
        : this._createIssue(message);
    }
  }
}
