import RegExpEnum from '@/enums/RegExp';

const FORMAT_MESSAGES = {
  BOOLEAN: 'Y,y,N,n',
  NUMERIC: 'numeric',
  ALPHABETIC: 'alphabetic',
  ALPHA_NUMERIC: 'alphanumeric',
  UNIQUE_VALUES: 'unique values',
  COMMA_SEPARATED: 'comma separated',
  DATE: 'DD/MM/YYYY or D/M/YYYY',
  NUMERIC_WITH_DASHES: 'numeric (dash allowed)',
  ALPHA_NUMERIC_WITH_DASHES_SPACES: 'alphanumeric (dash and space allowed)',
  ALPHA_NUMERIC_WITH_SPACES: 'alphanumeric (space allowed)',
  ALPHA_NUMERIC_WITH_SPECIAL_CHARS: 'alphanumeric (special chars allowed)',
};

const MAX_LENGTH = 'max length:';
const MAX_COMMENT_LENGTH = 'max comment length:';
const COMMENT_LENGTH = 'comment length:';

export default {
  formatRules: {
    cancelDate: {
      isDateFormat: true,
      pattern: RegExpEnum.Date,
      expectedFormat: FORMAT_MESSAGES.DATE,
    },

    customerPONumber: {
      pattern: `${RegExpEnum.Any}${RegExpEnum.Length_35}`,
      expectedFormat: `${MAX_LENGTH} 35; e.g. 20200328LBJBBAL100`,
    },

    validFromDate: {
      isDateFormat: true,
      pattern: RegExpEnum.Date,
      expectedFormat: FORMAT_MESSAGES.DATE,
    },

    deliveryBlock: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_2}`,
      expectedFormat: `${MAX_LENGTH} 2; ${FORMAT_MESSAGES.ALPHA_NUMERIC}`,
    },

    departmentCode: {
      pattern: `${RegExpEnum.Numeric}${RegExpEnum.Length_10}`,
      expectedFormat: `${MAX_LENGTH} 10, ${FORMAT_MESSAGES.NUMERIC}`,
    },

    reasonCode: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_3}`,
      expectedFormat: `${MAX_LENGTH} 3; ${FORMAT_MESSAGES.ALPHA_NUMERIC}; e.g. A12`,
    },

    markFor: {
      pattern: `${RegExpEnum.Numeric}${RegExpEnum.Length_10}`,
      expectedFormat: `${MAX_LENGTH} 10; ${FORMAT_MESSAGES.NUMERIC}; e.g. 1234567890`,
    },

    numberOfCartons: {
      pattern: `${RegExpEnum.Numeric}${RegExpEnum.Length_4}`,
      expectedFormat: `${MAX_LENGTH} 4; ${FORMAT_MESSAGES.NUMERIC}; e.g. 1234`,
    },

    crd: {
      isDateFormat: true,
      pattern: RegExpEnum.Date,
      expectedFormat: FORMAT_MESSAGES.DATE,
    },

    vasCodes: {
      pattern: `${RegExpEnum.AlphaNumeric_Comma}${RegExpEnum.Length_19}`,
      expectedFormat: `${MAX_LENGTH} 19; ${FORMAT_MESSAGES.ALPHA_NUMERIC}; ${FORMAT_MESSAGES.COMMA_SEPARATED}; ${FORMAT_MESSAGES.UNIQUE_VALUES}; e.g. L03,L02`,
    },

    shipToNumber: {
      pattern: `${RegExpEnum.Numeric}${RegExpEnum.Length_10}`,
      expectedFormat: `${MAX_LENGTH} 10; ${FORMAT_MESSAGES.NUMERIC}; e.g. 1234567890`,
    },

    sourcingMethodGA: {
      pattern: RegExpEnum.YN_1,
      expectedFormat: FORMAT_MESSAGES.BOOLEAN,
    },

    vatRate: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_2}`,
      expectedFormat: `${MAX_LENGTH} 2; ${FORMAT_MESSAGES.ALPHA_NUMERIC}; e.g. C3`,
    },

    plantCode: {
      pattern: `${RegExpEnum.Numeric}${RegExpEnum.Length_4}`,
      expectedFormat: `${MAX_LENGTH} 4; ${FORMAT_MESSAGES.NUMERIC}; e.g. 1234`,
    },

    unitAllotment: {
      pattern: RegExpEnum.AlphaNumericSpecialChars_70,
      expectedFormat: `${MAX_LENGTH} 70; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_SPECIAL_CHARS}`,
    },

    vasLongText: {
      pattern: '^(\\s*\\w+\\s*=[^,=\\n]*,*)*$',
      expectedFormat: `${FORMAT_MESSAGES.ALPHA_NUMERIC}; ${FORMAT_MESSAGES.UNIQUE_VALUES}; e.g. L02=comment`,
    },

    vasField3: {
      pattern: '^(\\s*\\w+\\s*=[^,=\\n]{0,20},*)*$',
      expectedFormat: `${FORMAT_MESSAGES.ALPHA_NUMERIC}; ${FORMAT_MESSAGES.COMMA_SEPARATED}; ${FORMAT_MESSAGES.UNIQUE_VALUES}; ${MAX_COMMENT_LENGTH} 20; e.g. L03=comment,S04=comment`,
    },

    vasField2: {
      pattern: '^(\\s*\\w+\\s*=[^,=\\n]{0,20},*)*$',
      expectedFormat: `${FORMAT_MESSAGES.ALPHA_NUMERIC}; ${FORMAT_MESSAGES.COMMA_SEPARATED}; ${FORMAT_MESSAGES.UNIQUE_VALUES}; ${MAX_COMMENT_LENGTH} 20; e.g. L03=comment,S04=comment`,
    },

    vasField1: {
      pattern: '^(\\s*\\w+\\s*=[^,=\\n]{0,20},*)*$',
      expectedFormat: `${FORMAT_MESSAGES.ALPHA_NUMERIC}; ${FORMAT_MESSAGES.COMMA_SEPARATED}; ${FORMAT_MESSAGES.UNIQUE_VALUES}; ${MAX_COMMENT_LENGTH} 20; e.g. L03=comment,S04=comment`,
    },

    vasChargeCode: {
      pattern: '^(\\s*\\w+\\s*=[^,=\\n]{3},*)*$',
      expectedFormat: `${FORMAT_MESSAGES.ALPHA_NUMERIC}; ${FORMAT_MESSAGES.COMMA_SEPARATED}; ${FORMAT_MESSAGES.UNIQUE_VALUES}; ${COMMENT_LENGTH} 3; e.g. L03=XYZ,A04=QWE`,
    },

    approved: {
      pattern: RegExpEnum.YN_1,
      expectedFormat: FORMAT_MESSAGES.BOOLEAN,
    },

    invoiceNumber: {
      pattern: `${RegExpEnum.Numeric}${RegExpEnum.Length_10}`,
      expectedFormat: `${MAX_LENGTH} 10; ${FORMAT_MESSAGES.NUMERIC}; e.g. 1234567890`,
    },

    returnAuthorizationsNumber: {
      pattern: RegExpEnum.AlphaNumericSpecialChars_35,
      expectedFormat: `${MAX_LENGTH} 35; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_SPECIAL_CHARS}; e.g. FY16-GT059`,
    },

    soldToNumber: {
      pattern: `${RegExpEnum.Numeric}${RegExpEnum.Length_10}`,
      expectedFormat: `${MAX_LENGTH} 10; ${FORMAT_MESSAGES.NUMERIC}; e.g. 1234567890`,
    },

    requirementSegment: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_5}`,
      expectedFormat: `${MAX_LENGTH} 5; ${FORMAT_MESSAGES.ALPHA_NUMERIC}`,
    },

    validToDate: {
      isDateFormat: true,
      pattern: RegExpEnum.Date,
      expectedFormat: FORMAT_MESSAGES.DATE,
    },

    materialNumber: {
      pattern: '^([a-zA-Z0-9]{6})-([a-zA-Z0-9]{3})$',
      expectedFormat: `${FORMAT_MESSAGES.NUMERIC_WITH_DASHES}; e.g. 123456-123`,
    },

    shippingCondition: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_2}`,
      expectedFormat: `${MAX_LENGTH} 2; ${FORMAT_MESSAGES.ALPHA_NUMERIC}`,
    },

    nameOfOrderer: {
      pattern: RegExpEnum.AlphaNumericSpecialChars_35,
      expectedFormat: `${MAX_LENGTH} 35; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_SPECIAL_CHARS}; e.g. John Smith`,
    },

    contractNumber: {
      pattern: `${RegExpEnum.Numeric}${RegExpEnum.Length_10}`,
      expectedFormat: `${MAX_LENGTH} 10; ${FORMAT_MESSAGES.NUMERIC}; e.g. 1234567890`,
    },

    internalOrderReferenceNumber: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_20}`,
      expectedFormat: `${MAX_LENGTH} 20; ${FORMAT_MESSAGES.ALPHA_NUMERIC}`,
    },

    addressOverride: {
      pattern: RegExpEnum.YN_1,
      expectedFormat: FORMAT_MESSAGES.BOOLEAN,
    },

    name1: {
      pattern: `${RegExpEnum.AlphaNumeric_Space}${RegExpEnum.Length_40}`,
      expectedFormat: `${MAX_LENGTH} 40; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_SPACES}; e.g. John Smith`,
    },

    name2: {
      pattern: `${RegExpEnum.AlphaNumeric_Space}${RegExpEnum.Length_40}`,
      expectedFormat: `${MAX_LENGTH} 40; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_SPACES}; e.g. John Smith`,
    },

    street1: {
      pattern: RegExpEnum.AlphaNumericSpecialChars_60,
      expectedFormat: `${MAX_LENGTH} 60; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_SPECIAL_CHARS}`,
    },

    street2: {
      pattern: RegExpEnum.AlphaNumericSpecialChars_40,
      expectedFormat: `${MAX_LENGTH} 40; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_SPECIAL_CHARS}`,
    },

    postalCode: {
      pattern: `${RegExpEnum.AlphaNumeric_Dash_Space}${RegExpEnum.Length_10}`,
      expectedFormat: `${MAX_LENGTH} 10; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_DASHES_SPACES}`,
    },

    city: {
      pattern: `${RegExpEnum.Alphabetic_Space}${RegExpEnum.Length_40}`,
      expectedFormat: `${MAX_LENGTH} 40; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_SPACES}; e.g. Hong Kong`,
    },

    country: {
      pattern: `${RegExpEnum.Alphabetic}${RegExpEnum.Length_2}`,
      expectedFormat: `${MAX_LENGTH} 2; ${FORMAT_MESSAGES.ALPHABETIC}; e.g. UK`,
    },

    region: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_3}`,
      expectedFormat: `${MAX_LENGTH} 3; ${FORMAT_MESSAGES.ALPHA_NUMERIC}`,
    },

    district: {
      pattern: `${RegExpEnum.AlphaNumeric_Space}${RegExpEnum.Length_40}`,
      expectedFormat: `${MAX_LENGTH} 40; ${FORMAT_MESSAGES.ALPHA_NUMERIC_WITH_SPACES}`,
    },

    telephone: {
      pattern: RegExpEnum.AlphaNumericSpecialChars_30,
      expectedFormat: `${MAX_LENGTH} 30`,
    },

    forwardingAgent: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_10}`,
      expectedFormat: `${MAX_LENGTH} 10; ${FORMAT_MESSAGES.ALPHA_NUMERIC}`,
    },

    nikeInternalComments: {
      pattern: `${RegExpEnum.Any}${RegExpEnum.Length_500}`,
      expectedFormat: `${MAX_LENGTH} 500`,
    },

    transportationZone: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_10}`,
      expectedFormat: `${MAX_LENGTH} 10; ${FORMAT_MESSAGES.ALPHA_NUMERIC}`,
    },

    contractOwnerName: {
      pattern: `${RegExpEnum.AlphaNumeric}${RegExpEnum.Length_12}`,
      expectedFormat: `${MAX_LENGTH} 12; ${FORMAT_MESSAGES.ALPHA_NUMERIC}`,
    },

    usageCode: {
      pattern: '^FI$|LI$',
      expectedFormat: 'FI or LI',
    },

    sizes: {
      pattern: '^[1-9][0-9]*$',
    },
  },

  goldenRules: {
    validFromDate: {
      rules: [
        {
          relatedField: 'now',
          daysToAdd: 0,
          operation: 'more or equal',
        },
      ],
    },

    cancelDate: {
      rules: [
        {
          relatedField: 'crd',
          daysToAdd: 5,
          operation: 'more',
        },
      ],
    },

    crd: {
      rules: [
        {
          relatedField: 'now',
          daysToAdd: 0,
          operation: 'more or equal',
        },
      ],
    },

    validToDate: {
      rules: [
        {
          relatedField: 'validFromDate',
          daysToAdd: 0,
          operation: 'more',
        },
      ],
    },
  },
};
