import PORenderer from "@/components/ui/AgGridTable/NewCellRenderers/PORenderer.vue";
import ActionsRenderer from "@/components/ui/AgGridTable/NewCellRenderers/ActionsRenderer.vue";
import NoRowCellRenderer from "@/components/ui/AgGridTable/CellRenderers/NoRowsCellRenderer.vue";
import OrderStatusRenderer from "@/components/ui/AgGridTable/CellRenderers/OrderStatusRenderer.vue";
import LoadingCellRenderer from "@/components/ui/AgGridTable/CellRenderers/LoadingCellRenderer.vue";
import OrderIndicatorRenderer from "@/components/ui/AgGridTable/CellRenderers/OrderIndicatorRenderer.vue";

import {
  unSortIcon,
  filterIcon,
  sortDesIcon,
  sortAscIcon,
} from "@/components/ui/AgGridTable/icons";

import colors from "@/enums/colors";

import { translateModule } from "@/utils/string/translate";

import { getCellValue } from "@/utils/agGrid";
import {
  editHandler,
  deleteHandler,
  errorDetailHandler,
  resolveActionHandler,
  resubmitActionHandler,
} from "./ActionHandlers";
import { orderStatuses } from "@/enums/order/orderStatus";

import { FIELDS_ENUM } from "@/enums/language/fieldsEnum";

const translateFields = translateModule("fields");

const translateOrders = translateModule("pages.homePage.orders");

const translateActions = translateModule("pages.myOrders");

export const ORDER_OWNERSHIP_TYPES = {
  MY_ORDERS: "MY_ORDERS",
  ALL_ORDERS: "ALL_OFOA_ORDERS",
};

export const COLUMN_KEYS = {
  INDICATOR: "indicator",
  PO_NUMBER: "poNumber",
  ORDER_TYPE: "orderType",
  ORDER_STATUS: "status",
  ORDER_CREATED_DATE: "createdAt",
  ORDER_CREATED_BY: "createdBy",
  SOURCE_SYSTEM: "sourceSystem",
  DATE_MODIFIED: "modifiedAt",
  MODIFIED_BY: "modifiedBy",
  SOLD_TO: "soldToNumber",
  SHIP_TO: "shipToNumber",
  CRD: "crd",
  CANCEL_DATE: "cancelDate",
  VALID_FROM: "validFromDate",
  VALID_TO: "validToDate",
  REASON_CODE: "reasonCode",
  SAP_SALES_ORDER_NUMBER: "sapOrderNumber",
  OFOA_ORDER: "ofoaId",
  SALES_ORG: "salesOrg",
  DEPARTMENT_CODE: "departmentCode",
  MARKETPLACE_UNIT: "marketplaceUnit",
  BUSINESS_TYPE: "businessType",
  CHANNEL: "channel",
  SUB_CHANNEL: "subChannel",
  ACCOUNT_TYPE: "accountType",
  MARK_FOR: "markFor",
  ACTIONS: "Actions",
};

export const COLUMN_DATE_KEYS = [
  COLUMN_KEYS.ORDER_CREATED_DATE,
  COLUMN_KEYS.DATE_MODIFIED,
  COLUMN_KEYS.CANCEL_DATE,
  COLUMN_KEYS.VALID_FROM,
  COLUMN_KEYS.VALID_TO,
];

export const homeOrdersTabs = [
  {
    id: ORDER_OWNERSHIP_TYPES.MY_ORDERS,
    name: translateOrders("myOrders"),
  },
  {
    id: ORDER_OWNERSHIP_TYPES.ALL_ORDERS,
    name: translateOrders("allOrders"),
  },
];

const rawColumnDefs = [
  {
    field: COLUMN_KEYS.INDICATOR,
    headerName: translateFields(FIELDS_ENUM.statusIndicator),
    lockPosition: "left",
    width: 36,
    maxWidth: 36,
    filter: false,
    resizable: false,
    cellClass: "home-orders-table__cell home-orders-table__cell--indicator",
    cellRenderer: OrderIndicatorRenderer,
  },
  {
    field: COLUMN_KEYS.PO_NUMBER,
    headerName: translateFields(FIELDS_ENUM.purchaseOrderNumber),
    width: 360,
    minWidth: 360,
    resizable: true,
    cellRenderer: PORenderer,
    cellClass: "home-orders-table__cell",
  },
  {
    field: COLUMN_KEYS.ORDER_TYPE,
    headerName: translateFields(FIELDS_ENUM.orderType),
  },
  {
    field: COLUMN_KEYS.ORDER_STATUS,
    headerName: translateFields(FIELDS_ENUM.orderStatus),
    cellRenderer: OrderStatusRenderer,
  },
  {
    field: COLUMN_KEYS.ORDER_CREATED_DATE,
    headerName: translateFields(FIELDS_ENUM.createDate),
    cellClass: "home-orders-table__cell home-orders-table__cell--right-aligned",
    type: "rightAligned",
  },
  {
    field: COLUMN_KEYS.ORDER_CREATED_BY,
    headerName: translateFields(FIELDS_ENUM.createdBy),
  },
  {
    field: COLUMN_KEYS.SOURCE_SYSTEM,
    headerName: translateFields(FIELDS_ENUM.sourceSystem),
  },
  {
    field: COLUMN_KEYS.DATE_MODIFIED,
    headerName: translateFields(FIELDS_ENUM.dateModified),
    cellClass: "home-orders-table__cell home-orders-table__cell--right-aligned",
    type: "rightAligned",
  },
  {
    field: COLUMN_KEYS.MODIFIED_BY,
    headerName: translateFields(FIELDS_ENUM.modifiedBy),
  },
  {
    field: COLUMN_KEYS.SOLD_TO,
    headerName: translateFields(FIELDS_ENUM.soldTo),
  },
  {
    field: COLUMN_KEYS.SHIP_TO,
    headerName: translateFields(FIELDS_ENUM.shipTo),
  },
  {
    field: COLUMN_KEYS.CRD,
    headerName: translateFields(FIELDS_ENUM.crd),
    cellClass: "home-orders-table__cell home-orders-table__cell--right-aligned",
    type: "rightAligned",
  },
  {
    field: COLUMN_KEYS.CANCEL_DATE,
    headerName: translateFields(FIELDS_ENUM.cancelDate),
    cellClass: "home-orders-table__cell home-orders-table__cell--right-aligned",
    type: "rightAligned",
  },
  {
    field: COLUMN_KEYS.VALID_FROM,
    headerName: translateFields(FIELDS_ENUM.validFromDate),
    cellClass: "home-orders-table__cell home-orders-table__cell--right-aligned",
    type: "rightAligned",
  },
  {
    field: COLUMN_KEYS.VALID_TO,
    headerName: translateFields(FIELDS_ENUM.validToDate),
    cellClass: "home-orders-table__cell home-orders-table__cell--right-aligned",
    type: "rightAligned",
  },
  {
    field: COLUMN_KEYS.REASON_CODE,
    headerName: translateFields(FIELDS_ENUM.reasonCode),
  },
  {
    field: COLUMN_KEYS.SAP_SALES_ORDER_NUMBER,
    headerName: translateFields(FIELDS_ENUM.sapOrderNumber),
  },
  {
    field: COLUMN_KEYS.OFOA_ORDER,
    headerName: translateFields(FIELDS_ENUM.ofoaOrderId),
  },
  {
    field: COLUMN_KEYS.SALES_ORG,
    headerName: translateFields(FIELDS_ENUM.salesOrg),
  },
  {
    field: COLUMN_KEYS.DEPARTMENT_CODE,
    headerName: translateFields(FIELDS_ENUM.departmentCode),
  },
  {
    field: COLUMN_KEYS.MARKETPLACE_UNIT,
    headerName: translateFields(FIELDS_ENUM.marketplaceUnit),
  },
  {
    field: COLUMN_KEYS.BUSINESS_TYPE,
    headerName: translateFields(FIELDS_ENUM.businessType),
  },
  {
    field: COLUMN_KEYS.ACCOUNT_TYPE,
    headerName: translateFields(FIELDS_ENUM.accountType),
  },
  {
    field: COLUMN_KEYS.CHANNEL,
    headerName: translateFields(FIELDS_ENUM.channel),
  },
  {
    field: COLUMN_KEYS.SUB_CHANNEL,
    headerName: translateFields(FIELDS_ENUM.subChannel),
  },
  {
    field: COLUMN_KEYS.MARK_FOR,
    headerName: translateFields(FIELDS_ENUM.markFor),
  },
  {
    width: 50,
    maxWidth: 50,
    pinned: "right",
    suppressMenu: true,
    sortable: false,
    resizable: false,
    cellRendererSelector: () => ({
      component: ActionsRenderer,
      params: {
        actions: [
          {
            name: translateActions("edit"),
            conditions: {
              status: [orderStatuses.DRAFT],
            },
            handler: editHandler,
          },
          {
            name: translateActions("deleteOrder"),
            conditions: {
              status: [orderStatuses.DRAFT],
            },
            handler: deleteHandler,
          },
          {
            name: translateActions("errorDetail"),
            conditions: {
              status: [orderStatuses.FAILED_IN_S4],
            },
            handler: errorDetailHandler,
          },
          {
            name: translateActions("edit"),
            conditions: {
              status: [orderStatuses.REWORK],
            },
            handler: editHandler,
          },
          {
            name: translateActions("deleteOrder"),
            conditions: {
              status: [orderStatuses.REWORK],
            },
            handler: deleteHandler,
          },
          {
            name: translateActions("edit"),
            conditions: {
              status: [orderStatuses.CONTAINS_ERRORS],
            },
            handler: editHandler,
          },
          {
            name: translateActions("deleteOrder"),
            conditions: {
              status: [orderStatuses.CONTAINS_ERRORS],
            },
            handler: deleteHandler,
          },
          {
            name: translateActions("resolve"),
            conditions: {
              status: [orderStatuses.EXCEPTION],
            },
            handler: resolveActionHandler,
          },
          {
            name: translateActions("resubmit"),
            conditions: {
              status: [orderStatuses.SENT_FAILED],
            },
            handler: resubmitActionHandler,
          },
          {
            name: translateActions("deleteOrder"),
            conditions: {
              status: [orderStatuses.SENT_FAILED],
            },
            handler: deleteHandler,
          },
        ],
      },
    }),
  },
];

export const columnDefs = rawColumnDefs.map((def) => ({
  valueGetter: !def.cellRenderer && getCellValue(def.field),
  ...def,
}));

export const renderOptions = {
  rowModelType: "serverSide",
  maxConcurrentDatasourceRequests: 1,
  loadingCellRenderer: LoadingCellRenderer,
  noRowsOverlayComponent: NoRowCellRenderer,
};

export const gridOptions = {
  animateRows: true,
  suppressMenuHide: true,
  suppressCellFocus: true,
  enableCellTextSelection: true,
  rowClass: "home-orders-table__row",
  rowHeight: 54,
  defaultColDef: {
    sortable: true,
    unSortIcon: false,
    cellClass: "home-orders-table__cell",
    suppressMovable: true,
    resizable: true,
  },
  icons: {
    menu: filterIcon(colors.podiumGrey1),
    sortUnSort: unSortIcon(),
    sortAscending: sortAscIcon(),
    sortDescending: sortDesIcon(),
  },
};
