import { parseGlobalFilters, getSearchFilters } from '@/utils/globalFilters';
import { parseManageExceptionsFilters } from './helpers';

export default {
  caseFilters: ({
    pageNumber, pageSize, pageTokens,
  }, _, rootState, rootGetters) => {
    const paginationToken = pageTokens.length >= pageNumber ? pageTokens[pageNumber] : pageTokens[pageTokens.length - 1];

    let globalFilters;

    const { searchQuery, appliedFilterFields } = rootState.globalFilters;

    if (searchQuery) {
      const searchQueries = rootGetters['globalFilters/separatedSearchQueries'];

      globalFilters = getSearchFilters(searchQueries);
    } else {
      globalFilters = parseGlobalFilters(appliedFilterFields);
    }

    return {
      filters: globalFilters,
      pageSize,
      pageNumber,
      paginationToken,
      sortOrder: 'DESC',
      sortBy: 'CREATED_AT',
    };
  },

  manageExceptionsFilters: ({ selectedExceptionGroups, selectedExceptionTypes }) => {
    if (!selectedExceptionGroups.length || !selectedExceptionTypes.length) {
      return [];
    }

    const filters = [];

    const parsedExceptionGroups = parseManageExceptionsFilters('CASE_GROUP', selectedExceptionGroups);
    const parsedExceptionTypes = parseManageExceptionsFilters('CASE_TYPE', selectedExceptionTypes);

    [parsedExceptionGroups, parsedExceptionTypes].forEach((parsedFilters) => {
      if (parsedFilters) {
        filters.push(parsedFilters);
      }
    });

    return filters;
  },

  isAnyCaseTypeAvailable: ({ caseTypes }) => {
    return caseTypes?.some(({ caseType }) => caseType.counter);
  },

  groupsWithNonZeroCases: ({ caseGroups }) => {
    return caseGroups
      .filter(({ counter }) => counter)
      .map(group => {
        const typesWithCases = group.caseTypes?.filter(({ counter }) => counter);

        return {
          ...group,
          caseTypes: typesWithCases || [],
        };
      });
  },
};
