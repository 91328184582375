export default class OrderIssue {
  constructor({
    rowId,
    colId,
    subId,
    lineId,
    orderId,
    isUiIssue,
    columnName,
    description,
    isLineIssue,
    productEngine,
    isColumnIssue,
  }) {
    if (colId) {
      this.colId = colId;
    } else if (productEngine) {
      this.colId = subId
        ? `${columnName}_${subId}_${productEngine}`
        : `${columnName}_${productEngine}`;
    } else {
      this.colId = columnName;
    }

    this.rowId = rowId || null;
    this.lineId = lineId || null;
    this.orderId = orderId || null;
    this.isUiIssue = isUiIssue || false;
    this.columnName = columnName || '';
    this.description = description || '';
    this.isLineIssue = isLineIssue || false;
    this.productEngine = productEngine || null;
    this.isColumnIssue = isColumnIssue || false;
  }
}
