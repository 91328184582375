import employeeRepository from "@/api/now/employee";
import filterPanelRepository from "@/api/now/caseManagement/filterPanel";
import { FIELDS } from "@/pages/OrderSummary/GlobalFilters/config.ts";
import hardcodedListValues from "./hardcodedListValues";

import { updateFieldsItems, validateField } from "./helpers";

export default {
  createFilterForm({ state, commit, dispatch }) {
    if (state.filterForm?.size) {
      return;
    }

    const filterForm = new Map();

    FIELDS.forEach((field) => {
      filterForm.set(field.key, {
        ...field,
        exclude: false,
      });
    });

    commit("SET_FILTER_FORM", filterForm);
    dispatch("getInitialFilterOptions");
  },

  async getInitialFilterOptions({ commit, dispatch, state, rootState }) {
    const updatedFilterForm = new Map(state.filterForm);

    updateFieldsItems({
      filterForm: updatedFilterForm,
      geos: rootState.user.geo,
    });

    try {
      const { data } = await filterPanelRepository.fetchGlobalFilterParams();

      updateFieldsItems({
        filterForm: updatedFilterForm,
        geos: rootState.user.geo,
      });

      commit("SET_FILTER_FORM", updatedFilterForm);

      await dispatch("fetchSavedFilters");

      dispatch("applyFilters");
    } catch (e) {
      console.error(e);
    }
  },

  updateFilterField({ commit, state, dispatch }, field) {
    const updatedField = { ...field };

    if (updatedField.value === undefined) {
      updatedField.exclude = false;
      updatedField.errorMessage = "";

      commit("REMOVE_FIELD_WITH_ERROR", updatedField.label);
    }

    if (updatedField?.value?.length && updatedField.validation) {
      const errorMessage = validateField(updatedField);

      updatedField.errorMessage = errorMessage;

      if (errorMessage) {
        commit("ADD_FIELD_WITH_ERROR", updatedField.label);
      } else {
        commit("REMOVE_FIELD_WITH_ERROR", updatedField.label);
      }
    }

    commit("SET_FILTER_FIELD", updatedField);

    if (updatedField.dependencies) {
      const { value } = updatedField;

      field.dependencies.forEach(({ field, handler }) => {
        const fieldToUpdate = handler(state.filterForm.get(field), value);

        dispatch("updateFilterField", fieldToUpdate);
      });
    }

    commit("SET_IS_FORM_EDITED", true);
  },

  async fetchOptions({ commit, state }, params) {
    const { key, value } = params;

    const filterField = { ...state.filterForm.get(key) };

    if (!value) {
      filterField.items = [];
      commit("SET_FILTER_FIELD", filterField);

      return;
    }

    try {
      commit("SET_FILTER_FIELD", {
        ...filterField,
        isLoading: true,
        items: [],
      });

      const { data } = await filterPanelRepository[filterField.optionsEndpoint]({ query: value });

      filterField.items = filterField?.optionsGetter(data.results) || [];
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_FILTER_FIELD", { ...filterField, isLoading: false });
    }
  },

  async fetchUser({ commit, state }, field) {
    const userField = { ...state.filterForm.get(field.key) };
    if (!field.value) {
      userField.items = [];
      commit("SET_FILTER_FIELD", userField);

      return;
    }

    commit("SET_FILTER_FIELD", {
      ...userField,
      isLoading: true,
      items: [],
    });

    try {
      const { data } = await employeeRepository.getEmployeeByQuery(field.value);

      userField.items = data.map(({ nikeId, email }) => ({ value: nikeId, text: email }));
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_FILTER_FIELD", { ...userField, isLoading: false });
    }
  },

  updateFilters({ commit }, updatedFields) {
    commit("SET_APPLY_FILTER_FIELDS", updatedFields);
  },

  clearSingleFilter({ state, commit, dispatch }, fieldKey) {
    const filteredFields = state.appliedFilterFields.filter(({ key }) => key !== fieldKey);

    const filteredFormField = { ...state.filterForm.get(fieldKey) };

    const updatedField = {
      ...filteredFormField,
      value: undefined,
      exclude: false,
    };

    commit("SET_FILTER_FIELD", updatedField);
    commit("SET_IS_FORM_EDITED", true);

    dispatch("updateFilters", filteredFields);
  },

  clearFilters({ state, commit, dispatch }) {
    state.filterForm.forEach((field) => {
      const updatedField = {
        ...field,
        value: undefined,
        exclude: false,
        errorMessage: "",
      };

      commit("SET_FILTER_FIELD", updatedField);
    });

    commit("RESET_FIELD_ERRORS");
    commit("SET_SELECTED_FILTERS_NAME", "");
    dispatch("updateFilters", []);

    commit("SET_IS_FORM_EDITED", true);
  },

  applyFilters({ state, dispatch }) {
    const filterFields = [];

    state.filterForm.forEach(({ key, label, value, exclude }) => {
      if (value) {
        const isDatePicker = FIELDS.find(
          (configField) => configField.key === key
        ).isRangeDatepicker;
        const mergeSign = isDatePicker ? " - " : ", ";
        const newValue = Array.isArray(value) ? value.join(mergeSign) : value;

        filterFields.push({
          key,
          exclude,
          name: label,
          value: newValue,
        });
      }
    });

    dispatch("updateFilters", filterFields);
  },

  setSearchQuery({ commit }, searchQuery) {
    commit("SET_SEARCH_QUERY", searchQuery);
  },
};
