export default {
  SET_GRID_API: 'setGridApi',
  SET_PAGE_SIZE: 'setPageSize',
  SET_PAGE_NUMBER: 'setPageNumber',
  RESET_PAGE_NUMBER: 'resetPageNumber',
  INIT_GRID_PAGINATION: 'initGridPagination',

  FETCH_PURCHASE_ORDER_BY_ID: 'fetchPurchaseOrderByid',
  REFETCH_PO_AFTER_SUBMIT: 'refetchPOAfterSubmit',

  SAVE_USER_PREFERENCES: 'saveUserPreferences',
  FETCH_USER_PREFERENCES: 'fetchUserPreferences',

  SET_GROUPS: 'setGroups',
  TOGGLE_GROUP: 'toggleGroup',
  SET_SELECTED_GROUP: 'setSelectedGroup',
  SET_EXCEPTIONS_GROUPED: 'setExceptionsGrouped',
  SET_GROUPED_SELECTED_CASES: 'setGroupedSelectedCases',

  RESOLVE_EXCEPTIONS: 'resolveExceptions',
  FIX_WRONGFUL_VALUE: 'fixWrongfulValue',

  RESET_STORE: 'resetStore',
};
