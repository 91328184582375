import { cloneDeep } from 'lodash';

import { FIELD_NAMES } from '@/enums/fieldNames';

import { isValidISOFormat } from '@/utils/dates';

import {
  ERROR_KEY,
  DATE_KEYS,
  ROW_ID_KEY,
} from '@/store/modules/massOrderCreation/L2/constansts/config';

export default class ModifiedLinesTransformer {
  constructor({
    modifiedLines, orderTypeConfig, fieldNamesMapper,
  }) {
    this._modifiedLines = cloneDeep(modifiedLines);
    this._orderTypeConfig = orderTypeConfig;
    this._fieldNamesMapper = fieldNamesMapper;
  }

  createSaveStructure() {
    this._removeProperties();
    this._transformProperties();

    return this._modifiedLines;
  }

  _removeProperties() {
    Object.keys(this._modifiedLines).forEach((key) => {
      const line = this._modifiedLines[key];

      this._removeAgGridProperties(line);
      this._removeEmptyValues(line);
    });
  }

  _removeAgGridProperties(line) {
    delete line[ERROR_KEY];
    delete line[ROW_ID_KEY];
  }

  _removeEmptyValues(line) {
    Object.keys(line).forEach((key) => {
      const field = line[key];

      if (line[key] === '') {
        delete line[key];
      }

      if (this._orderTypeConfig.PRODUCT_ENGINE_KEYS.includes(key)) {
        Object.keys(field).forEach((size) => {
          delete field[size].incorrect;

          if (
            field[size].value === ''
            || field[size].value === undefined
            || field[size].value === null
          ) {
            delete field[size];
          }
        });
      } else if (
        typeof field === 'object'
        && (line[key].value === '' || line[key].value === undefined)
      ) {
        delete line[key];
      }
    });
  }

  _transformProperties() {
    Object.keys(this._modifiedLines).forEach((key) => {
      const line = this._modifiedLines[key];

      this._transformLineId(line);
      this._transformOrderType(line);
      this._transformSizes(line);
      this._transformDates(line);

      if (line[FIELD_NAMES.VAS_CODES]) {
        this._transformVasCodes(line);
      }

      this._addNames(line);
    });
  }

  _transformLineId(line) {
    line.id = Number(line.lineId);
    delete line.lineId;
  }

  _transformOrderType(line) {
    line.orderType = {
      id: line.orderTypeId,
    };
    delete line.orderTypeId;
  }

  _transformVasCodes(line) {
    line[FIELD_NAMES.VAS_CODES] = line[FIELD_NAMES.VAS_CODES].value
      ?.split(',')
      .map((code) => ({ value: code, name: line[FIELD_NAMES.VAS_CODES].name }));
  }

  _transformSizes(line) {
    this._orderTypeConfig.PRODUCT_ENGINE_KEYS.forEach((productEngine) => {
      line[productEngine] = Object.keys(line[productEngine]).map((sizeKey) => {
        if (!line[productEngine][sizeKey].name) {
          line[productEngine][sizeKey].name = sizeKey;
        }

        return line[productEngine][sizeKey];
      });
    });
  }

  _transformDates(line) {
    DATE_KEYS.forEach((key) => {
      const field = line[key];
      const isDateValid = field ? isValidISOFormat(field.value) : false;

      if (field && isDateValid) {
        line[key] = {
          ...field,
          value: field.value.split('T')[0],
        };
      }
    });
  }

  _addNames(line) {
    const names = [
      FIELD_NAMES.SOLD_TO_NUMBER,
      FIELD_NAMES.SHIP_TO_NUMBER,
      FIELD_NAMES.CUSTOMER_PO_NUMBER,
      FIELD_NAMES.CONTRACT_NUMBER,
      FIELD_NAMES.CRD,
      FIELD_NAMES.CANCEL_DATE,
      FIELD_NAMES.VALID_TO_DATE,
      FIELD_NAMES.VALID_FROM_DATE,
      FIELD_NAMES.DEPARTMENT_CODE,
      FIELD_NAMES.MATERIAL_NUMBER,
      FIELD_NAMES.REASON_CODE,
      FIELD_NAMES.DELIVERY_BLOCK,
      FIELD_NAMES.SOURCING_METHOD_GA,
      FIELD_NAMES.SHIPPING_CONDITION,
      FIELD_NAMES.NIKE_INTERNAL_COMMENTS,
      FIELD_NAMES.ADDRESS_OVERRIDE,
      FIELD_NAMES.NAME_1,
      FIELD_NAMES.NAME_2,
      FIELD_NAMES.STREET_1,
      FIELD_NAMES.STREET_2,
      FIELD_NAMES.CITY,
      FIELD_NAMES.POSTAL_CODE,
      FIELD_NAMES.COUNTRY,
      FIELD_NAMES.REGION,
      FIELD_NAMES.DISTRICT,
      FIELD_NAMES.TELEPHONE,
      FIELD_NAMES.FORWARDING_AGENT,
      FIELD_NAMES.TRANSPORTATION_ZONE,
      FIELD_NAMES.NAME_OF_ORDERER,
      FIELD_NAMES.USAGE_CODE,
      FIELD_NAMES.MARK_FOR,
      FIELD_NAMES.PLANT_CODE,
      FIELD_NAMES.UNIT_ALLOTMENT,
      FIELD_NAMES.INVOICE_NUMBER,
      FIELD_NAMES.RETURN_AUTHORIZATION_NUMBER,
      FIELD_NAMES.NUMBER_OF_CARTONS,
      FIELD_NAMES.VAT_RATE,
      FIELD_NAMES.REQUIREMENT_SEGMENT,
      FIELD_NAMES.VAS_FIELD_1,
      FIELD_NAMES.VAS_FIELD_2,
      FIELD_NAMES.VAS_FIELD_3,
      FIELD_NAMES.VAS_LONG_TEXT,
      FIELD_NAMES.INTERNAL_ORDER_REFERENCE_NUMBER,
    ];

    Object.keys(line).forEach((key) => {
      if (names.includes(key) && !line[key]?.name) {
        line[key].name = this._fieldNamesMapper[key];
      }
    });
  }
}
