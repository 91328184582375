import { FIELD_NAMES } from '@/enums/fieldNames';

export const DATE_FORMAT_FIELDS = [
  FIELD_NAMES.CRD,
  FIELD_NAMES.CREATED_AT,
  FIELD_NAMES.MODIFIED_AT,
  FIELD_NAMES.CANCEL_DATE,
  FIELD_NAMES.VALID_TO_DATE,
  FIELD_NAMES.VALID_FROM_DATE,
];

export const USER_EDITABLE_DATE_FIELDS = [
  FIELD_NAMES.CRD,
  FIELD_NAMES.CANCEL_DATE,
  FIELD_NAMES.VALID_TO_DATE,
  FIELD_NAMES.VALID_FROM_DATE,
];
