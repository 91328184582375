import pagesURL from "./routes";

import LoginCallback from "./LoginCallback.vue";

const OrderSummary = () => import(/* webpackChunkName: "OrderSummary" */ "@/pages/OrderSummary");

const OrderList = () =>
  import(/* webpackChunkName: "OrderList" */ "@/pages/OrderList/OrderList.vue");

const OrderDetail = () =>
  import(/* webpackChunkName: "OrderDetail" */ "@/pages/OrderDetail/OrderDetail.vue");

const NotAllowed = () => import(/* webpackChunkName: "NotAllowed" */ "@/pages/NotAllowed");
const SessionExpired = () =>
  import(/* webpackChunkName: "SessionExpired" */ "@/pages/SessionExpired");
const ServiceDown = () => import(/* webpackChunkName: "ServiceDown" */ "@/pages/ServiceDown");
const PlannedOutage = () => import(/* webpackChunkName: "PlannedOutage" */ "@/pages/PlannedOutage");
const StubPageControls = () =>
  import(/* webpackChunkName: "StubPageControls" */ "@/pages/StubPageControls/StubPageControls");
const Exceptions = () =>
  import(/* webpackChunkName: "Exceptions" */ "@/pages/Exceptions/Exceptions");

const Resources = () => import(/* webpackChunkName: "Resources" */ "@/pages/Resources/Resources");

const routes = [
  {
    path: pagesURL.ROOT,
    name: "OrderSummary",
    meta: {
      requiresAuth: true,
      hasSearch: false,
    },
    component: OrderSummary,
  },
  {
    path: pagesURL.ORDER_LIST_BY_STATUS,
    meta: { requiresAuth: true },
    name: "OrderListByStatus",
    component: OrderList,
  },
  {
    path: pagesURL.ORDER_LIST_BY_TYPE,
    meta: { requiresAuth: true },
    name: "OrderListByType",
    component: OrderList,
  },
  {
    path: pagesURL.ORDER_DETAIL_BY_ID,
    meta: { requiresAuth: true },
    name: "OrderDetailById",
    component: OrderDetail,
  },
  {
    path: pagesURL.CALLBACK,
    name: "Callback",
    component: LoginCallback,
  },
  {
    path: pagesURL.NOT_ALLOWED,
    name: "NotAllowed",
    component: NotAllowed,
    props: true,
  },
  {
    path: pagesURL.SESSION_EXPIRED,
    name: "SessionExpired",
    meta: { requiresUnauth: true },
    component: SessionExpired,
  },
  {
    path: pagesURL.SERVICE_DOWN,
    name: "ServiceDown",
    meta: { isMaintenance: true, requiresAuth: true },
    component: ServiceDown,
  },
  {
    path: pagesURL.PLANNED_OUTAGE,
    name: "PlannedOutage",
    meta: { isMaintenance: true, requiresAuth: true },
    component: PlannedOutage,
  },
  {
    path: pagesURL.EXCEPTIONS,
    name: "Exceptions",
    meta: {
      isMaintenance: true,
      requiresAuth: true,
      hasSearch: true,
    },
    component: Exceptions,
  },
  {
    path: pagesURL.STUB_PAGE_CONTROLS,
    name: "StubPageControls",
    meta: { isMaintenance: true, requiresAuth: true },
    component: StubPageControls,
  },

  {
    path: "/resources",
    name: "Resources",
    meta: { requiresAuth: true },
    component: Resources,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "Home" },
  },
];

export default routes;
