export default {
  // Filter panel
  fetchedOrders: {},
  selectedFileId: null,
  selectedOrderIds: [],
  isOrdersFetching: false,
  isOnlyLinesWithIssuesFilterSelected: false,

  // Issue Types Filter
  issueTypesCategories: [],
  selectedIssueTypesFilters: [],
  selectedIssueTypesCategory: '',
  isIssueTypesCategoriesFetching: false,

  // Grid
  gridApi: null,
  columnApi: null,
  linesLimit: 100,
  cachedLines: [],
  fromLineId: null,
  gridCreator: null,
  fromOrderId: null,
  modifiedLines: {},
  sizeColumnsToAdd: [],
  fromColumnName: null,
  searchDirection: null,
  linesValidationStatus: {},
  hasNextPageWithLines: null,
  hasDuplicateLinesError: false,
  maxModifiedLinesNumber: 200,
  productEnginesWithSizes: [],

  // Issue navigator
  beIssues: {},
  uiIssues: {},
  orderIssues: {},
  fieldMapping: [],
  fixedBeIssues: [],
  selectedIssue: null,
  isLinesSaving: false,
  isIssuesLoading: false,
  totalIssuesCount: 0,
  isLinesSubmitting: false,
  isAllIssuesLoaded: false,
  isIssueNavigatorOpen: true,
  isKeyGroupingFieldChanged: false,
};
