import BaseCreator from './BaseCreator';

import { STANDARD } from '../constansts/config';

import BaseValidator from '../gridValidators/BaseValidator';

export default class StandardCreator extends BaseCreator {
  constructor(orders, sizeColumnsToAdd) {
    super({
      orders,
      sizeColumnsToAdd,
      orderTypeName: 'Standard Order',
      columnOrder: STANDARD.COLUMN_ORDER,
      viewOnlyFields: STANDARD.VIEW_ONLY_FIELDS,
      lineLevelFields: STANDARD.LINE_LEVEL_FIELDS,
      headerLevelFields: STANDARD.HEADER_LEVEL_FIELDS,
      groupingFieldKeys: STANDARD.GROUPING_FIELD_KEYS,
      addressOverrideFields: STANDARD.ADDRESS_OVERRIDE_FIELDS,
    });

    this._validator = new BaseValidator({
      requiredFields: STANDARD.REQUIRED_FIELDS,
    });
  }
}
