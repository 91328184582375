import { FIELDS_ENUM } from '@/enums/language/fieldsEnum';

import { translateModule } from '@/utils/string/translate';

const translateFields = translateModule('fields');

export const mapFieldName = (description: string, name: string) => {
  if (fieldMapper[description]) {
    if (name === FIELDS_ENUM.msrInnerPackQuantity) {
      return translateFields(FIELDS_ENUM.msrInnerPackQuantity);
    }

    if (name === FIELDS_ENUM.purchaseOrderDocumentTypeCode) {
      return translateFields(FIELDS_ENUM.stoType);
    }

    return translateFields(fieldMapper[description]);
  }

  return description;
};

const fieldMapper: {
  [key: string]: typeof FIELDS_ENUM[keyof typeof FIELDS_ENUM]
} = {
  'Customer PO Type': FIELDS_ENUM.sourceSystem,
  'Customer PO Number': FIELDS_ENUM.purchaseOrderNumber,
  'Customer Purchase Order': FIELDS_ENUM.purchaseOrderNumber,
  'Customer Sold To': FIELDS_ENUM.soldTo,
  'Customer Ship To': FIELDS_ENUM.shipTo,
  'Sales Org Code': FIELDS_ENUM.salesOrg,
  'Sales Order Type': FIELDS_ENUM.orderType,
  'Sales Order Number': FIELDS_ENUM.sapOrderNumber,
  'Order Created Date': FIELDS_ENUM.createDate,
  'Order Submit Date': FIELDS_ENUM.createDate,
  'ShipTo Store': FIELDS_ENUM.shipTo,
  'Requested Delivery Date': FIELDS_ENUM.crd,
  'Product Requested Delivery Date': FIELDS_ENUM.crdLineLevel,
  'Cancellation Date': FIELDS_ENUM.cancelDate,
  'Item Cancellation Date': FIELDS_ENUM.cancelDateLineLevel,
  'Contract Valid Start Date': FIELDS_ENUM.validFromDate,
  'Contract Validity Start Date': FIELDS_ENUM.validFromDate,
  'Contract Valid End Date': FIELDS_ENUM.validToDate,
  'Contract Validity End Date': FIELDS_ENUM.validToDate,
  'RSO Reason Code': FIELDS_ENUM.reasonCode,
  'Order Reason Code': FIELDS_ENUM.stockTransferReasonCode,
  'Stock Transport Reason Code': FIELDS_ENUM.stockTransferReasonCode,
  'Sales Organization': FIELDS_ENUM.salesOrg,
  'Customer Channel Code': FIELDS_ENUM.channel,
  'Business Type Code': FIELDS_ENUM.businessType,
  'Customer Business Type Code': FIELDS_ENUM.businessType,
  'Case Type': FIELDS_ENUM.exceptionType,
  'Contract Customer PO Number': FIELDS_ENUM.contractPurchaseOrderNumber,
  'Customer Contract PO Number': FIELDS_ENUM.contractPurchaseOrderNumber,
  'Customer Distribution Channel': FIELDS_ENUM.distributionChannel,
  'Destination Plant Code': FIELDS_ENUM.destinationCode,
  'Internal Order Reference Number': FIELDS_ENUM.internalOrderNumber,
  'Manual VAT Percentage': FIELDS_ENUM.vatRate,
  'Global Category': FIELDS_ENUM.category,
  'Division / Product Engine (Item)': FIELDS_ENUM.division,
  'Product Code': FIELDS_ENUM.materialNumber,
  'Product ID': FIELDS_ENUM.materialNumber,
  'Material (Product ID)': FIELDS_ENUM.materialNumber,
  Plant: FIELDS_ENUM.plantCode,
  'Reference Document Number': FIELDS_ENUM.contractNumber,
  'RSO Invoice Number': FIELDS_ENUM.contractNumber,
  'Reference Document Item Number': FIELDS_ENUM.contractLineItemNumber,
  'RSO Invoice Item Number': FIELDS_ENUM.contractLineItemNumber,
  'Usage Indicator': FIELDS_ENUM.usageCode,
  'Usage indicator': FIELDS_ENUM.usageCode,
  'Order Item Quantity (Size)': FIELDS_ENUM.orderItemQuantity,
  'VAS Field1': FIELDS_ENUM.vasField1,
  'VAS Field2': FIELDS_ENUM.vasField2,
  'VAS Field3': FIELDS_ENUM.vasField3,
  'Vas Field Text3': FIELDS_ENUM.vasField3,
  'EDI PO Type': FIELDS_ENUM.ediPurchaseOrderType,
  'Material Gender Age': FIELDS_ENUM.genderAge,
  'League Description': FIELDS_ENUM.league,
  'VAS Increment': FIELDS_ENUM.vasIncrement,
  'Customer Business Model Code': FIELDS_ENUM.businessModelIndicator,
};
