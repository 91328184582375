export const updateFieldsItems = ({ filterForm, geos }) => {
  filterForm.set("geos", geos);
};

export const validateField = (field) => {
  const { pattern, errorMessage } = field.validation;

  const regex = new RegExp(pattern);
  const valuesToCheck = field.value.split(",").map((value) => value.trim());

  const hasErrors = valuesToCheck.some((value) => !regex.test(value));

  return hasErrors ? errorMessage : "";
};
