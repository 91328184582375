import RULES from '@/enums/validationRules/ruleKeys';
import VALIDATION_REGEXES from '@/enums/validationRules/validationRegexes';

const VALIDATORS = {
  [RULES.REQUIRED]: (value) => (Array.isArray(value) ? value.length > 0 : Boolean(value)),
  [RULES.PRODUCT_CODE_GTIN_REQUIRED]: (value) => (Array.isArray(value) ? value.length > 0 : Boolean(value)),
  [RULES.MIN_LENGTH]: (value, minLength) => (value ? value.length >= minLength : true),
  [RULES.MAX_LENGTH]: (value, maxLength) => (value ? value.length <= maxLength : true),
  [RULES.ALPHA_NUMERIC]: (value) => (value ? VALIDATION_REGEXES.ALPHA_NUMERIC.test(value) : true),
  [RULES.MATERIAL_NUMBER]: (value) => (value ? VALIDATION_REGEXES.MATERIAL_NUMBER.test(value) : true),
  [RULES.NUMERIC_NO_LIMIT]: (value) => (value ? VALIDATION_REGEXES.NUMERIC_NO_LIMIT.test(value) : true),
  [RULES.MAX_ARRAY_LENGTH]: (values, maxLength) => (values ? values.length <= maxLength : true),
};

export default VALIDATORS;
