import { createStore } from 'vuex';

import { MASS_ORDER_CREATION } from './moduleNames';
import massOrderCreationL1Module from './modules/massOrderCreation/L1';
import massOrderCreationL2Module from './modules/massOrderCreation/L2';
import massOrderCreationFileUploadModule from './modules/massOrderCreation/fileUpload';
import massOrderCreationDuplicateLinesModule from './modules/massOrderCreation/duplicateLines';

import modules from './modules';

const store = createStore({
  modules: {
    ...modules,

    [MASS_ORDER_CREATION]: {
      namespaced: true,
      modules: {
        L1: massOrderCreationL1Module,
        L2: massOrderCreationL2Module,
        fileUpload: massOrderCreationFileUploadModule,
        duplicateLines: massOrderCreationDuplicateLinesModule,
      },
    },
  },
});

export function registerStore(app) {
  app.use(store);
}

export default store;
