export const RESET_ON_ROUTE_CHANGE = 'resetOnRouteChange';

// FILTER PANEL
export const SET_SELECTED_FILE_ID = 'setSelectedFileId';
export const SET_SELECTED_ORDER_IDS = 'setSelectedOrderIds';
export const RESET_SELECTED_ORDER_IDS = 'resetSelectedOrderIds';
export const SET_ALL_ORDERS_OF_FILE_SELECTED = 'setAllOrdersOfFileSelected';
export const FETCH_LINES_FOR_SELECTED_ORDERS = 'fetchLinesForSelectedOrders';
export const TOGGLE_IS_ONLY_LINES_WITH_ISSUES_FILTER_SELECTED = 'toggleIsOnlyLinesWithIssuesFilterSelected';

// ISSUE TYPES FILTER
export const FETCH_ORDER_ISSUE_TYPES = 'fetchOrderIssueTypes';
export const FETCH_ISSUE_TYPE_FILTER_COUNTS = 'fetchIssueTypeFilterCounts';
export const SET_SELECTED_ISSUE_TYPE_FILTER = 'setSelectedIssueTypeFilter';
export const SET_SELECTED_ISSUE_TYPES_CATEGORY = 'setSelectedIssueTypesCategory';

// GRID
export const ADD_ISSUE = 'addIssue';
export const SET_GRID_API = 'setGridApi';
export const REMOVE_ISSUE = 'removeIssue';
export const REPLACE_ISSUE = 'replaceIssue';
export const SET_COLUMN_API = 'setColumnApi';
export const VALIDATE_VALUE = 'validateValue';
export const RESET_GRID_DATA = 'resetGridData';
export const SET_GRID_CREATOR = 'setGridCreator';
export const START_EDITING_CELL = 'startEditingCell';
export const REFRESH_DATA_SOURCE = 'refreshDataSource';
export const SET_SIZE_COLUMNS_TO_ADD = 'setSizeColumnsToAdd';
export const SET_PRODUCT_ENGINES_WITH_SIZES = 'setProductEnginesWithSeries';

// ISSUE NAVIGATOR
export const STORE_ISSUES = 'storeIssues';
export const SELECT_ISSUE = 'selectIssue';
export const SUBMIT_ORDERS = 'submitOrders';
export const DETERMINE_ISSUES = 'determineIssues';
export const SELECT_PREV_ISSUE = 'selectPrevIssue';
export const SELECT_NEXT_ISSUE = 'selectNextIssue';
export const SAVE_MODIFIED_LINES = 'saveModifiedLines';
export const STORE_MODIFIED_LINE = 'storeModifiedLine';
export const RESET_SELECTED_ISSUE = 'resetSelectedIssue';
export const OPEN_ISSUE_NAVIGATOR = 'openIssueNavigator';
export const CLOSE_ISSUE_NAVIGATOR = 'closeIssueNavigator';
export const DETERMINE_CACHED_LINES = 'determineCachedLines';
export const SET_VALIDATION_STATUS_FOR_CELL = 'setValidationStatusForCell';

// RESET
export const REFETCH_DATA = 'refetchData';
export const RESET_PAGINATION_RELATED_FIELDS = 'resetPaginationRelatedFields';
export const REFRESH_VIEW_ON_ORDER_ID_SELECTION = 'refreshViewOnOrderIdSelections';
export const RESET_ISSUE_NAVIGATOR_RELATED_FIELDS = 'resetIssueNavigatorRelatedFields';
