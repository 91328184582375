import { sumBy } from 'lodash';

import { getOrderIssueTypes, fetchIssueTypeFilterCounts } from '@/api/now/massOrderCreation';

import {
  REFRESH_DATA_SOURCE,
  FETCH_ORDER_ISSUE_TYPES,
  FETCH_ISSUE_TYPE_FILTER_COUNTS,
  SET_SELECTED_ISSUE_TYPE_FILTER,
  SET_SELECTED_ISSUE_TYPES_CATEGORY,
  TOGGLE_IS_ONLY_LINES_WITH_ISSUES_FILTER_SELECTED,
} from '@/store/modules/massOrderCreation/L2/constansts/actions';

import { GET_SELECTED_ORDER_IDS } from '@/store/modules/massOrderCreation/L2/constansts/getters';

import mutationTypes from '@/store/modules/massOrderCreation/L2/constansts/mutationsTypes.js';

import { issueTypesCategories } from './constants';

export default {
  async [FETCH_ORDER_ISSUE_TYPES]({ commit }) {
    commit(mutationTypes.SET_IS_ISSUE_TYPES_CATEGORIES_FETCHING, true);

    try {
      const { data: issueTypes } = await getOrderIssueTypes();

      commit(mutationTypes.SET_ISSUE_TYPES_CATEGORIES, mapIssueTypesToCategories(issueTypes));
    } catch (err) {
      console.log(err);
    } finally {
      commit(mutationTypes.SET_IS_ISSUE_TYPES_CATEGORIES_FETCHING, false);
    }
  },

  async [FETCH_ISSUE_TYPE_FILTER_COUNTS]({ commit, state }, { fileId, orderIds }) {
    commit(mutationTypes.SET_IS_ISSUE_TYPES_CATEGORIES_FETCHING, true);

    try {
      const {
        data: { counts, totalIssues },
      } = await fetchIssueTypeFilterCounts(fileId, orderIds);
      const issueCategories = state.issueTypesCategories;

      commit(mutationTypes.SET_TOTAL_ISSUES_COUNT, totalIssues);
      commit(
        mutationTypes.SET_ISSUE_TYPES_CATEGORIES,
        getIssueTypesCategoriesWithCounts(issueCategories, counts),
      );
    } catch (err) {
      console.error(err);
    } finally {
      commit(mutationTypes.SET_IS_ISSUE_TYPES_CATEGORIES_FETCHING, false);
    }
  },

  [SET_SELECTED_ISSUE_TYPE_FILTER]({
    commit, getters, dispatch, state,
  }, { issueType, isChecked }) {
    const { selectedIssueTypesFilters } = state;
    const selectedIssueTypesToSave = isChecked
      ? [...selectedIssueTypesFilters, issueType]
      : selectedIssueTypesFilters.filter((issue) => issue !== issueType);

    commit(mutationTypes.SET_SELECTED_ISSUE_TYPES_FILTERS, selectedIssueTypesToSave);

    commit(mutationTypes.SET_FROM_LINE_ID, -1);
    commit(mutationTypes.SET_FROM_ORDER_ID, -1);

    if (getters[GET_SELECTED_ORDER_IDS].length) {
      dispatch(REFRESH_DATA_SOURCE);
    }
  },

  [SET_SELECTED_ISSUE_TYPES_CATEGORY](
    {
      commit, getters, dispatch, state,
    },
    { categoryId, isChecked },
  ) {
    const issueTypesCategory = state.issueTypesCategories.find(
      ({ errorGroup }) => errorGroup === categoryId,
    );
    const selectedIssueTypesToSave = isChecked
      ? issueTypesCategory.issueTypes.map((issue) => issue.errorType)
      : [];
    const issueTypesCategoryToSave = isChecked ? categoryId : '';

    commit(mutationTypes.SET_SELECTED_ISSUE_TYPES_FILTERS, selectedIssueTypesToSave);
    commit(mutationTypes.SET_SELECTED_ISSUE_TYPES_CATEGORY, issueTypesCategoryToSave);

    commit(mutationTypes.SET_FROM_LINE_ID, -1);
    commit(mutationTypes.SET_FROM_ORDER_ID, -1);

    if (getters[GET_SELECTED_ORDER_IDS].length) {
      dispatch(REFRESH_DATA_SOURCE);
    }
  },

  [TOGGLE_IS_ONLY_LINES_WITH_ISSUES_FILTER_SELECTED]({
    commit, getters, dispatch, state,
  }) {
    commit(
      mutationTypes.SET_IS_ONLY_LINES_WITH_ISSUES_FILTER_SELECTED,
      !state.isOnlyLinesWithIssuesFilterSelected,
    );

    commit(mutationTypes.SET_FROM_LINE_ID, -1);
    commit(mutationTypes.SET_FROM_ORDER_ID, -1);

    if (getters[GET_SELECTED_ORDER_IDS].length) {
      dispatch(REFRESH_DATA_SOURCE);
    }
  },
};

export function mapIssueTypesToCategories(issueTypes) {
  const result = [];

  issueTypesCategories.forEach((category) => {
    const issueTypesForCategory = issueTypes.filter(
      ({ errorGroup }) => errorGroup === category.errorGroup,
    );

    result.push({
      ...category,
      issueTypes: issueTypesForCategory,
    });
  });

  return result;
}

function getIssueTypesCategoriesWithCounts(issueCategories, counts) {
  return issueCategories.map((category) => {
    const issueTypesCategoryCounts = counts.filter(
      ({ errorGroup }) => errorGroup === category.errorGroup,
    );

    category.issueTypes = mapCountsWithIssueTypes(category.issueTypes, issueTypesCategoryCounts);
    category.count = getCategoryCount(category.issueTypes);

    return category;
  });
}

function mapCountsWithIssueTypes(issueTypes, issueTypesFilterCounts) {
  const result = [...issueTypes];

  issueTypesFilterCounts.forEach((issueTypesFilterCount) => {
    const issueTypeIndex = result.findIndex(
      ({ errorType }) => errorType === issueTypesFilterCount.errorType,
    );

    result[issueTypeIndex].count = issueTypesFilterCount.count;
  });

  return result;
}

function getCategoryCount(issueTypes) {
  return sumBy(issueTypes, 'count');
}
