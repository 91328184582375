import moment from 'moment-timezone';

import dateFormats from '@/enums/dates/dateFormats';
import timeFormats from '@/enums/dates/timeFormats';

import { DATE_FORMAT_FIELDS } from '@/enums/dates/dateFields';

import RegExp from '@/enums/RegExp';

export function formatDate(date, dateFormat = dateFormats.EU) {
  return moment(date).format(dateFormat);
}

export function convertDateFormat(value, fromFormat, toFormat = dateFormats.CHINA) {
  return moment(value, fromFormat).format(toFormat);
}

export function addLeadingZero(arg = '') {
  return arg.toString().padStart(2, '0');
}

export function getFormattedCurrentTime(date = new Date(), separator = ':') {
  return [
    addLeadingZero(date.getHours()),
    addLeadingZero(date.getMinutes()),
    addLeadingZero(date.getSeconds()),
  ].join(separator);
}

export function createISODate(
  selectedDate,
  currentTime = getFormattedCurrentTime(),
  timezone = '',
) {
  const dateWithTimezone = moment.tz(
    `${selectedDate}T${currentTime}`,
    timezone || moment.tz.guess(),
  );

  return moment.utc(dateWithTimezone).toISOString();
}

export function transfromIsoDatesToTableFormat(order) {
  if (!order) {
    return {};
  }

  return DATE_FORMAT_FIELDS.reduce((acc, dateField) => {
    if (!order[dateField]) {
      return acc;
    }

    acc[dateField] = formatDate(order[dateField]);

    return acc;
  }, {});
}

export function isDateFormatValid(date, dateFormat = RegExp.Date) {
  return moment(date, dateFormat, true).isValid();
}

export function isShortDateFormatValid(date) {
  return isDateFormatValid(date, RegExp.ShortDate);
}

export function isValidISOFormat(date) {
  return moment(date, moment.ISO_8601, true).isValid();
}

export function getMomentFromFullDate({ date, time }) {
  return moment(`${date} ${time}`, `${dateFormats.ISO} ${timeFormats.hr24}`);
}

export function generateFormattedTodayDate() {
  return moment().format('YYYY-MM-DD');
}

export function getFormattedTimePlusMinutes({
  time, format, minutes,
}) {
  return moment(time, format).add(minutes, 'm').format(timeFormats.hr24);
}

export function isPastDate(date) {
  const today = moment().startOf('day');
  const parsedDate = moment(date).startOf('day');

  return parsedDate.isBefore(today);
}

export function convertStringToISO(value) {
  let newDate;

  if (!value || (value.length !== 10 && typeof value !== 'string')) {
    return null;
  }

  newDate = value.replace(/\//g, '-');

  if (newDate.indexOf('-') === 2) {
    const dateArray = newDate.split('-');
    const year = dateArray[2];
    const yearTmp = dateArray[0];
    dateArray[2] = yearTmp;
    dateArray[0] = year;

    newDate = dateArray.join('-');
  }

  return newDate;
}

export function validateTime(time, format) {
  return !time.length || moment(time, format).isValid();
}

export function substractStringDays(date1, date2) {
  return moment(date1).diff(moment(date2), 'days');
}

export function isDateField({ name, description }) {
  return [name, description].join().toLowerCase().includes('date');
}
