import { FIELD_NAMES } from '@/enums/fieldNames';

const {
  CRD,
  CITY,
  NAME_1,
  NAME_2,
  REGION,
  COUNTRY,
  VAT_RATE,
  DISTRICT,
  STREET_1,
  STREET_2,
  MARK_FOR,
  TELEPHONE,
  VAS_CODES,
  USAGE_CODE,
  PLANT_CODE,
  UNIT_ALLOTMENT,
  POSTAL_CODE,
  VAS_FIELD_1,
  VAS_FIELD_2,
  VAS_FIELD_3,
  CANCEL_DATE,
  REASON_CODE,
  VALID_TO_DATE,
  VAS_LONG_TEXT,
  VAS_CHARGE_CODE,
  SOLD_TO_NUMBER,
  SHIP_TO_NUMBER,
  DELIVERY_BLOCK,
  VALID_FROM_DATE,
  MATERIAL_NUMBER,
  DEPARTMENT_CODE,
  NAME_OF_ORDERER,
  CONTRACT_NUMBER,
  INVOICE_NUMBER,
  FORWARDING_AGENT,
  ADDRESS_OVERRIDE,
  NUMBER_OF_CARTONS,
  CUSTOMER_PO_NUMBER,
  SOURCING_METHOD_GA,
  SHIPPING_CONDITION,
  TRANSPORTATION_ZONE,
  REQUIREMENT_SEGMENT,
  NIKE_INTERNAL_COMMENTS,
  RETURN_AUTHORIZATION_NUMBER,
  INTERNAL_ORDER_REFERENCE_NUMBER,
} = FIELD_NAMES;

const ERROR_KEY = 'error';
const ROW_ID_KEY = 'rowId';
const LINE_ID_KEY = 'lineId';
const ORDER_ID_KEY = 'orderId';
const ORDER_TYPE_KEY = 'orderType';
const ORDER_TYPE_ID = 'orderTypeId';
const TOTAL_QUANTITY = 'totalQuantity';
const ORDER_ERROR_STATUS = 'CONTAINS_ERRORS';
const SUPER_USER_TEMPALTE = 'superUserTemplate';
const DISABLED_CELL_CLASS = 'moc-l2-grid__cell--disabled';

const FW_SIZES = 'fwSizes';
const APP_SIZES = 'appSizes';
const EQP_SIZES = 'eqpSizes';
const CUSTOM_SIZES = 'customSizes';

const ADDRESS_ID = 'addressId';

const PRODUCT_ENGINE_KEYS = [FW_SIZES, APP_SIZES, EQP_SIZES, CUSTOM_SIZES];

const PRODUCT_ENGINE_MAPPER = {
  APPAREL: APP_SIZES,
  FOOTWEAR: FW_SIZES,
  EQUIPMENT: EQP_SIZES,
  CUSTOM: CUSTOM_SIZES,
};

const DATE_KEYS = [CRD, CANCEL_DATE, VALID_TO_DATE, VALID_FROM_DATE];

const HEADER_LEVEL_FIELDS = [CRD, MARK_FOR, SHIP_TO_NUMBER, SOLD_TO_NUMBER, CUSTOMER_PO_NUMBER];

const DEFAULT_GROUPING_FIELD_KEYS = [
  CRD,
  MARK_FOR,
  SHIP_TO_NUMBER,
  SOLD_TO_NUMBER,
  CUSTOMER_PO_NUMBER,
];

const VIEW_ONLY_FIELDS = [ORDER_TYPE_KEY, ...PRODUCT_ENGINE_KEYS];

const CONTRACT = {
  COLUMN_ORDER: [
    ORDER_TYPE_KEY,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    CUSTOMER_PO_NUMBER,
    CRD,
    CANCEL_DATE,
    VALID_FROM_DATE,
    VALID_TO_DATE,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    NIKE_INTERNAL_COMMENTS,
    NAME_OF_ORDERER,
    USAGE_CODE,
    MARK_FOR,
    REQUIREMENT_SEGMENT,
    PLANT_CODE,
  ],

  HEADER_LEVEL_FIELDS,

  LINE_LEVEL_FIELDS: [
    USAGE_CODE,
    PLANT_CODE,
    CANCEL_DATE,
    VALID_TO_DATE,
    VALID_FROM_DATE,
    MATERIAL_NUMBER,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    NAME_OF_ORDERER,
    REQUIREMENT_SEGMENT,
    NIKE_INTERNAL_COMMENTS,
  ],

  GROUPING_FIELD_KEYS: DEFAULT_GROUPING_FIELD_KEYS,

  VIEW_ONLY_FIELDS,

  REQUIRED_FIELDS: [
    CRD,
    CANCEL_DATE,
    VALID_TO_DATE,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    VALID_FROM_DATE,
    MATERIAL_NUMBER,
    CUSTOMER_PO_NUMBER,
  ],

  PRODUCT_ENGINE_KEYS,
};

const FREE_OF_CHARGE = {
  COLUMN_ORDER: [
    ORDER_TYPE_KEY,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    CUSTOMER_PO_NUMBER,
    CONTRACT_NUMBER,
    CRD,
    CANCEL_DATE,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    DELIVERY_BLOCK,
    SOURCING_METHOD_GA,
    SHIPPING_CONDITION,
    NIKE_INTERNAL_COMMENTS,
    ADDRESS_OVERRIDE,
    NAME_1,
    NAME_2,
    STREET_1,
    STREET_2,
    CITY,
    POSTAL_CODE,
    COUNTRY,
    REGION,
    DISTRICT,
    TELEPHONE,
    FORWARDING_AGENT,
    TRANSPORTATION_ZONE,
    NAME_OF_ORDERER,
    USAGE_CODE,
    MARK_FOR,
    PLANT_CODE,
    UNIT_ALLOTMENT,
    REQUIREMENT_SEGMENT,
    VAS_CODES,
    VAS_FIELD_1,
    VAS_FIELD_2,
    VAS_FIELD_3,
    VAS_LONG_TEXT,
    VAS_CHARGE_CODE,
    INTERNAL_ORDER_REFERENCE_NUMBER,
  ],

  HEADER_LEVEL_FIELDS,

  LINE_LEVEL_FIELDS: [
    CONTRACT_NUMBER,
    CANCEL_DATE,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    DELIVERY_BLOCK,
    SOURCING_METHOD_GA,
    SHIPPING_CONDITION,
    NIKE_INTERNAL_COMMENTS,
    ADDRESS_OVERRIDE,
    NAME_1,
    NAME_2,
    STREET_1,
    STREET_2,
    CITY,
    POSTAL_CODE,
    COUNTRY,
    REGION,
    DISTRICT,
    TELEPHONE,
    FORWARDING_AGENT,
    TRANSPORTATION_ZONE,
    NAME_OF_ORDERER,
    USAGE_CODE,
    PLANT_CODE,
    UNIT_ALLOTMENT,
    REQUIREMENT_SEGMENT,
    VAS_CODES,
    VAS_FIELD_1,
    VAS_FIELD_2,
    VAS_FIELD_3,
    VAS_LONG_TEXT,
    VAS_CHARGE_CODE,
    INTERNAL_ORDER_REFERENCE_NUMBER,
  ],

  GROUPING_FIELD_KEYS: DEFAULT_GROUPING_FIELD_KEYS,

  VIEW_ONLY_FIELDS,

  REQUIRED_FIELDS: [
    CRD,
    CANCEL_DATE,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    MATERIAL_NUMBER,
    CUSTOMER_PO_NUMBER,
  ],

  ADDRESS_OVERRIDE_FIELDS: [
    NAME_1,
    NAME_2,
    STREET_1,
    STREET_2,
    CITY,
    POSTAL_CODE,
    COUNTRY,
    REGION,
    DISTRICT,
  ],

  PRODUCT_ENGINE_KEYS,
};

const STANDARD = {
  COLUMN_ORDER: [
    ORDER_TYPE_KEY,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    CUSTOMER_PO_NUMBER,
    CONTRACT_NUMBER,
    CRD,
    CANCEL_DATE,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    DELIVERY_BLOCK,
    SOURCING_METHOD_GA,
    SHIPPING_CONDITION,
    NIKE_INTERNAL_COMMENTS,
    ADDRESS_OVERRIDE,
    NAME_1,
    NAME_2,
    STREET_1,
    STREET_2,
    CITY,
    POSTAL_CODE,
    COUNTRY,
    REGION,
    DISTRICT,
    TELEPHONE,
    FORWARDING_AGENT,
    TRANSPORTATION_ZONE,
    NAME_OF_ORDERER,
    USAGE_CODE,
    MARK_FOR,
    PLANT_CODE,
    REQUIREMENT_SEGMENT,
    VAS_CODES,
    VAS_FIELD_1,
    VAS_FIELD_2,
    VAS_FIELD_3,
    VAS_LONG_TEXT,
    VAS_CHARGE_CODE,
  ],

  HEADER_LEVEL_FIELDS,

  GROUPING_FIELD_KEYS: DEFAULT_GROUPING_FIELD_KEYS,

  VIEW_ONLY_FIELDS,

  LINE_LEVEL_FIELDS: [
    CONTRACT_NUMBER,
    CANCEL_DATE,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    DELIVERY_BLOCK,
    SOURCING_METHOD_GA,
    SHIPPING_CONDITION,
    NIKE_INTERNAL_COMMENTS,
    ADDRESS_OVERRIDE,
    NAME_1,
    NAME_2,
    STREET_1,
    STREET_2,
    CITY,
    POSTAL_CODE,
    COUNTRY,
    REGION,
    DISTRICT,
    TELEPHONE,
    FORWARDING_AGENT,
    TRANSPORTATION_ZONE,
    NAME_OF_ORDERER,
    USAGE_CODE,
    PLANT_CODE,
    REQUIREMENT_SEGMENT,
    VAS_CODES,
    VAS_FIELD_1,
    VAS_FIELD_2,
    VAS_FIELD_3,
    VAS_LONG_TEXT,
    VAS_CHARGE_CODE,
  ],

  REQUIRED_FIELDS: [
    CRD,
    CANCEL_DATE,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    MATERIAL_NUMBER,
    CUSTOMER_PO_NUMBER,
  ],

  ADDRESS_OVERRIDE_FIELDS: [
    NAME_1,
    NAME_2,
    STREET_1,
    STREET_2,
    CITY,
    POSTAL_CODE,
    COUNTRY,
    REGION,
    DISTRICT,
  ],

  PRODUCT_ENGINE_KEYS,
};

const RETURN_SALES = {
  COLUMN_ORDER: [
    ORDER_TYPE_KEY,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    CUSTOMER_PO_NUMBER,
    CRD,
    CANCEL_DATE,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    REASON_CODE,
    NAME_OF_ORDERER,
    PLANT_CODE,
    INVOICE_NUMBER,
    RETURN_AUTHORIZATION_NUMBER,
    NUMBER_OF_CARTONS,
    VAT_RATE,
  ],

  GROUPING_FIELD_KEYS: [CRD, SHIP_TO_NUMBER, SOLD_TO_NUMBER, CUSTOMER_PO_NUMBER],

  HEADER_LEVEL_FIELDS: [CRD, SHIP_TO_NUMBER, REASON_CODE, CUSTOMER_PO_NUMBER],

  LINE_LEVEL_FIELDS: [
    SOLD_TO_NUMBER,
    CANCEL_DATE,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    NAME_OF_ORDERER,
    PLANT_CODE,
    INVOICE_NUMBER,
    RETURN_AUTHORIZATION_NUMBER,
    NUMBER_OF_CARTONS,
    VAT_RATE,
    NIKE_INTERNAL_COMMENTS,
  ],

  VIEW_ONLY_FIELDS,

  REQUIRED_FIELDS: [
    CRD,
    REASON_CODE,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    MATERIAL_NUMBER,
    CUSTOMER_PO_NUMBER,
  ],

  PRODUCT_ENGINE_KEYS,
};

const FREE_OF_CHARGE_RETURNS_SALES = {
  COLUMN_ORDER: [
    ORDER_TYPE_KEY,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    CUSTOMER_PO_NUMBER,
    CRD,
    CANCEL_DATE,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    REASON_CODE,
    NAME_OF_ORDERER,
    PLANT_CODE,
    UNIT_ALLOTMENT,
    INTERNAL_ORDER_REFERENCE_NUMBER,
    INVOICE_NUMBER,
    RETURN_AUTHORIZATION_NUMBER,
    NUMBER_OF_CARTONS,
    VAT_RATE,
  ],

  HEADER_LEVEL_FIELDS: [CRD, SHIP_TO_NUMBER, REASON_CODE, CUSTOMER_PO_NUMBER],

  LINE_LEVEL_FIELDS: [
    SOLD_TO_NUMBER,
    CANCEL_DATE,
    DEPARTMENT_CODE,
    MATERIAL_NUMBER,
    NAME_OF_ORDERER,
    PLANT_CODE,
    UNIT_ALLOTMENT,
    INTERNAL_ORDER_REFERENCE_NUMBER,
    INVOICE_NUMBER,
    RETURN_AUTHORIZATION_NUMBER,
    NUMBER_OF_CARTONS,
    VAT_RATE,
    NIKE_INTERNAL_COMMENTS,
  ],

  GROUPING_FIELD_KEYS: DEFAULT_GROUPING_FIELD_KEYS,

  VIEW_ONLY_FIELDS,

  REQUIRED_FIELDS: [
    CRD,
    REASON_CODE,
    SOLD_TO_NUMBER,
    SHIP_TO_NUMBER,
    MATERIAL_NUMBER,
    CUSTOMER_PO_NUMBER,
  ],

  PRODUCT_ENGINE_KEYS,
};

export {
  FW_SIZES,
  EQP_SIZES,
  APP_SIZES,
  DATE_KEYS,
  ERROR_KEY,
  ADDRESS_ID,
  ROW_ID_KEY,
  LINE_ID_KEY,
  CUSTOM_SIZES,
  ORDER_ID_KEY,
  ORDER_TYPE_ID,
  TOTAL_QUANTITY,
  ORDER_TYPE_KEY,
  ORDER_ERROR_STATUS,
  HEADER_LEVEL_FIELDS,
  SUPER_USER_TEMPALTE,
  DISABLED_CELL_CLASS,
  PRODUCT_ENGINE_KEYS,
  PRODUCT_ENGINE_MAPPER,
  CONTRACT,
  STANDARD,
  RETURN_SALES,
  FREE_OF_CHARGE,
  FREE_OF_CHARGE_RETURNS_SALES,
};
