import repository from '@/api/repositories/serverRepository';
import {
  CASES,
  ORDER,
} from '@/api/endpoints';

export default {
  getPurchaseOrderById: (orderId = {}) => {
    if (orderId.nowId && !orderId.ofoaId) {
      return repository.get(`${ORDER.ORDERS}?nowId=${orderId.nowId}`);
    }

    return repository.get(`${ORDER.ORDERS}?ofoaId=${orderId.ofoaId}`);
  },

  resolveCases: (cases) => {
    return repository.post(CASES.RESOLVE, { cases });
  },
};
