import { translateModule } from '@/utils/string/translate';
import { showSuccessBanner, showErrorBanner } from '@/utils/notifications';
import exceptionsRepository from '@/api/now/exceptions';
import myOrdersRepository from '@/api/now/myOrders';

import {
  parseCases,
  getRelatedOrders,
  adjustColumnDefs,
  getCaseTypesDisplayNames,
  reorderLockedPreferences,
} from './helpers';

export default {
  fetchUserPreferences: async({ commit, dispatch }) => {
    commit('SET_LOADING', true);

    try {
      const { data } = await myOrdersRepository.fetchUserPreferences();
      const { ungroupedCasesColumns } = data;

      const reorderedPreferences = reorderLockedPreferences(ungroupedCasesColumns);

      commit('SET_USER_PREFERENCES', reorderedPreferences);
      dispatch('fetchExceptions');
    } catch (err) {
      console.log(err);
    }
  },

  saveUserPreferences: async({ commit, dispatch }, userPreferences) => {
    try {
      await myOrdersRepository.saveUserPreferences({ ungroupedCasesColumns: userPreferences });

      const reorderedPreferences = reorderLockedPreferences(userPreferences);

      commit('SET_USER_PREFERENCES', reorderedPreferences);

      dispatch('adjustColumnDefs');
    } catch (err) {
      console.error(err);
    }
  },

  adjustColumnDefs: async({
    commit, dispatch, state,
  }) => {
    const {
      parsedColumnDefs, hasNewColumns, updatedUserPreferences,
    } = adjustColumnDefs(state.columnDefs, state.userPreferences);

    if (hasNewColumns) {
      dispatch('saveUserPreferences', updatedUserPreferences);
    }

    commit('SET_COLUMN_DEFS', parsedColumnDefs);
  },

  fetchExceptions: async({
    state, commit, getters, dispatch,
  }) => {
    commit('SET_LOADING', true);
    commit('SET_IS_RELATED_ORDERS_LOADING', true);

    try {
      const filters = [...getters.caseFilters.filters, ...getters.manageExceptionsFilters];

      const { data } = await exceptionsRepository.fetchExceptions({ ...getters.caseFilters, filters });
      const { totalCases, stats } = data;
      const { parsedCases, columnDefs } = parseCases(data.cases);

      const caseTypesDisplayNames = getCaseTypesDisplayNames(data.stats.caseGroups);

      commit('SET_CASE_TYPES_DISPLAY_NAMES', caseTypesDisplayNames);
      commit('SET_PAGE_TOKEN', {
        pageNumber: data.pageNumber + 1,
        token: data?.nextPaginationToken,
      });

      commit('SET_TOTAL_RESULTS', totalCases);
      commit('SET_EXCEPTIONS', [...parsedCases]);
      commit('SET_COLUMN_DEFS', columnDefs);
      commit('SET_STATS', stats);

      commit('SET_CASE_GROUPS', stats.caseGroups);

      const exceptionTypes = stats.caseGroups?.reduce((mappedCaseTypes, {
        displayName, caseTypes, group,
      }) => {
        const mappedGroup = caseTypes.map(caseType => ({
          group, caseType, displayName,
        }));

        mappedCaseTypes = [...mappedCaseTypes, ...mappedGroup];

        return mappedCaseTypes;
      }, []);

      const isManageExceptions = getters.manageExceptionsFilters.length;
      const menageExceptionTypes = exceptionTypes.filter(({ caseType }) => state.selectedExceptionTypes.includes(caseType.type));

      commit('SET_EXCEPTION_TYPES', isManageExceptions ? menageExceptionTypes : exceptionTypes);

      await dispatch('adjustColumnDefs');
    } catch (error) {
      console.error(error);

      commit('SET_EXCEPTIONS', []);
      commit('RESET_PAGE_TOKEN');
      commit('SET_IS_RELATED_ORDERS_LOADING', false);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  fetchExceptionType: async({ getters }, {
    caseType, group, pageNumber, pageSize, paginationToken,
  }) => {
    const adjustedGroupedFilters = [{
      column: 'CASE_GROUP',
      operator: 'EQUALS',
      value: group,
    },
    {
      column: 'CASE_TYPE',
      operator: 'EQUALS',
      value: caseType,
    }];

    const filters = [...getters.caseFilters.filters, ...adjustedGroupedFilters];

    const { data } = await exceptionsRepository.fetchExceptions({
      ...getters.caseFilters, filters, pageNumber, pageSize, paginationToken,
    });

    return { ...data, cases: parseCases(data.cases).parsedCases };
  },

  fetchRelatedOrders: async({ commit, getters }, cases) => {
    commit('SET_IS_RELATED_ORDERS_LOADING', true);

    const mappedCases = cases.map(({ caseType, wrongfulValues }) => ({
      caseType,
      wrongfulValue: wrongfulValues.find(({ primary }) => primary === true)?.value || wrongfulValues[0]?.value,
      name: wrongfulValues[0]?.name,
    }));

    try {
      const relatedOrders = await getRelatedOrders(cases, mappedCases, getters);

      commit('SET_RELATED_ORDERS', relatedOrders);
      commit('SET_CASE_RELATED_VALUE', mappedCases);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_IS_RELATED_ORDERS_LOADING', false);
    }
  },

  resolveCases: async({ dispatch, commit }, cases) => {
    const translate = translateModule('notifications.sucessMessages');

    commit('SET_IS_RESOLVE_CASE_LOADING', true);
    try {
      const { data } = await exceptionsRepository.resolveCases(cases);
      const errorsNum = data?.errors?.length;
      const acceptedNum = data?.accepted?.length;

      if (errorsNum) {
        showErrorBanner({
          title: 'Error',
          text: `${errorsNum > 1 ? translate('casesUnresolved', { count: errorsNum }) : translate('caseUnresolved')}`,
        });
      }

      if (acceptedNum) {
        showSuccessBanner({
          title: 'Success',
          text: `${acceptedNum > 1 ? translate('casesResolved', { count: acceptedNum }) : translate('caseResolved')}`,
        });
      }

      dispatch('fetchExceptions');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_IS_RESOLVE_CASE_LOADING', false);
    }
  },

  setSelectedExceptionTypes: ({ commit }, exceptionTypes) => {
    commit('SET_SELECTED_EXCEPTION_TYPES', exceptionTypes);
  },

  setPageNumber: ({ commit, dispatch }, pageNumber) => {
    commit('SET_PAGE_NUMBER', pageNumber);

    dispatch('fetchExceptions');
  },

  setPageSize: ({ commit, dispatch }, pageSize) => {
    commit('SET_PAGE_SIZE', pageSize);
    commit('SET_PAGE_NUMBER', 0);

    dispatch('fetchExceptions');
  },

  addGroupedApi: ({ commit }, exportData) => {
    commit('ADD_GROUPED_API', exportData);
  },

  removeGroupedApi: ({ commit }, caseType) => {
    commit('REMOVE_GROUPED_API', caseType);
  },

  setGroupedFlow: ({ commit }, isGroupedFlow) => {
    commit('RESET_GROUPED_APIS');
    commit('SET_GROUPED_FLOW', isGroupedFlow);
  },
};
