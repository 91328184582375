import { omit, cloneDeep } from 'lodash';

import ModalEnum from '@/enums/modals';

import { saveLines, sendOrders } from '@/api/now/massOrderCreation';

import router from '@/router';
import routes from '@/router/routes.js';

import { createNameWithL1Module } from '@/store/modules/massOrderCreation/utils.js';

import { showSuccessBanner, showErrorBanner } from '@/utils/notifications';
import { setSubmissionIdToStorage } from '@/pages/MassOrderCreation/submissionMethods';

import {
  ADD_ISSUE,
  REMOVE_ISSUE,
  SELECT_ISSUE,
  SUBMIT_ORDERS,
  REPLACE_ISSUE,
  RESET_GRID_DATA,
  SELECT_PREV_ISSUE,
  SELECT_NEXT_ISSUE,
  START_EDITING_CELL,
  SAVE_MODIFIED_LINES,
  STORE_MODIFIED_LINE,
  RESET_SELECTED_ISSUE,
  OPEN_ISSUE_NAVIGATOR,
  CLOSE_ISSUE_NAVIGATOR,
  SET_VALIDATION_STATUS_FOR_CELL,
} from '@/store/modules/massOrderCreation/L2/constansts/actions';

import {
  GET_ISSUES,
  GET_BE_ISSUES,
  GET_UI_ISSUES,
  GET_ISSUES_LIST,
  GET_MODIFIED_LINES,
  GET_FIXED_BE_ISSUES,
  GET_SELECTED_FILE_ID,
  GET_SELECTED_ORDER_IDS,
  GET_SELECTED_ISSUE_INDEX,
  GET_COLUMN_NAME_BY_FIELD_NAME,
} from '@/store/modules/massOrderCreation/L2/constansts/getters';

import mutationTypes from '@/store/modules/massOrderCreation/L2/constansts/mutationsTypes.js';

import ModifiedLinesTransformer from './ModifiedLinesTransformer';
import { determineOrderTypeConfig } from '../gridCreators/SuperUser';

import { ADDRESS_ID } from '../constansts/config';

export default {
  [SELECT_ISSUE]({ commit, getters }, issueDetails) {
    const issue = getters[GET_ISSUES][issueDetails.rowId]?.find(
      (issue) => issue.colId === issueDetails.colId,
    );

    commit(mutationTypes.SET_SELECTED_ISSUE, issue ? issueDetails : null);
  },

  [RESET_SELECTED_ISSUE]({ commit }) {
    commit(mutationTypes.SET_SELECTED_ISSUE, null);
  },

  [ADD_ISSUE]({
    commit, getters, dispatch,
  }, { rowId, validationIssue }) {
    const uiIssues = getters[GET_UI_ISSUES];

    validationIssue.isUiIssue = true;
    commit(mutationTypes.SET_UI_ISSUES, {
      ...uiIssues,
      [rowId]: [...(uiIssues[rowId] || []), validationIssue],
    });

    dispatch('updateValidationForField', {
      field: validationIssue.columnName,
      issueForField: validationIssue,
      productEngine: validationIssue.productEngine,
      rowId,
    });

    commit(mutationTypes.INCREASE_TOTAL_ISSUES_COUNT);
  },

  [REMOVE_ISSUE]({
    commit, getters, dispatch,
  }, { rowId, colId }) {
    const issueToRemove = getters[GET_ISSUES][rowId]?.find((issue) => issue.colId === colId);
    const isUiIssue = issueToRemove?.isUiIssue;

    const issues = isUiIssue ? getters[GET_UI_ISSUES] : getters[GET_BE_ISSUES];
    const mutation = isUiIssue ? mutationTypes.SET_UI_ISSUES : mutationTypes.SET_BE_ISSUES;
    const fixedIssue = issues[rowId]?.find((issue) => issue.colId === colId);
    const filteredIssueRow = issues[rowId]?.filter((issue) => issue.colId !== colId) || [];

    if (!filteredIssueRow.length) {
      commit(mutation, omit(issues, rowId));
    } else {
      commit(mutation, {
        ...issues,
        [rowId]: filteredIssueRow,
      });
    }

    if (!isUiIssue) {
      commit(mutationTypes.SET_FIXED_BE_ISSUES, [...getters[GET_FIXED_BE_ISSUES], fixedIssue]);
    }

    if (issueToRemove) {
      dispatch('updateValidationForField', {
        field: issueToRemove.columnName,
        productEngine: issueToRemove.productEngine,
        rowId,
      });
      commit(mutationTypes.DECREASE_TOTAL_ISSUES_COUNT);
    }
  },

  [REPLACE_ISSUE]({ commit, getters }, { validationIssue, rowId }) {
    const issueToReplace = getters[GET_ISSUES][rowId]?.find(
      (issue) => issue.colId === validationIssue.colId,
    );
    const isUiIssue = issueToReplace?.isUiIssue;

    const issues = isUiIssue ? getters[GET_UI_ISSUES] : getters[GET_BE_ISSUES];
    const newRow = issues[rowId];
    const mutation = isUiIssue ? mutationTypes.SET_UI_ISSUES : mutationTypes.SET_BE_ISSUES;
    const issueToReplaceIndex = issues[rowId].findIndex(
      (issue) => issue.colId === validationIssue.colId,
    );

    newRow.splice(issueToReplaceIndex, 1, validationIssue);

    validationIssue.isUiIssue = isUiIssue;
    commit(mutation, {
      ...issues,
      [rowId]: newRow,
    });
  },

  [OPEN_ISSUE_NAVIGATOR]({ commit }) {
    commit(mutationTypes.SET_IS_ISSUE_NAVIGATOR_OPEN, true);
  },

  [CLOSE_ISSUE_NAVIGATOR]({ commit }) {
    commit(mutationTypes.SET_IS_ISSUE_NAVIGATOR_OPEN, false);
  },

  [SELECT_PREV_ISSUE]({ dispatch, getters }) {
    const prevIssue = getters[GET_ISSUES_LIST][getters[GET_SELECTED_ISSUE_INDEX] - 1];

    if (prevIssue) {
      dispatch(SELECT_ISSUE, {
        rowId: prevIssue.rowId,
        columnName: prevIssue.columnName,
        colId: prevIssue.colId,
      });
      dispatch(START_EDITING_CELL, prevIssue);
    }
  },

  [SELECT_NEXT_ISSUE]({ dispatch, getters }) {
    const nextIssue = getters[GET_ISSUES_LIST][getters[GET_SELECTED_ISSUE_INDEX] + 1];

    if (nextIssue) {
      dispatch(SELECT_ISSUE, {
        rowId: nextIssue.rowId,
        columnName: nextIssue.columnName,
        colId: nextIssue.colId,
      });
      dispatch(START_EDITING_CELL, nextIssue);
    }
  },

  async [STORE_MODIFIED_LINE](
    {
      commit, state, dispatch, getters, rootState,
    },
    { data: lineInternals, colDef: { field } },
  ) {
    const { selectedOrderTypeId } = rootState.massOrderCreation.L1;

    const orderTypeConfig = determineOrderTypeConfig(selectedOrderTypeId);

    const modifiedLines = getters[GET_MODIFIED_LINES];
    const savedLine = modifiedLines[lineInternals.rowId];

    const isKeyGroupingFieldChanged = orderTypeConfig.GROUPING_FIELD_KEYS.includes(field);

    const lineInternalAfterNameChecks = checkNameExistance({
      lineInternals,
      fieldKey: field,
      orderTypeConfig,
      columnNames: getters[GET_COLUMN_NAME_BY_FIELD_NAME],
    });

    const linesToSave = {
      ...modifiedLines,
      [lineInternals.rowId]: {
        ...lineInternalAfterNameChecks,
        regrouped: savedLine?.regrouped || isKeyGroupingFieldChanged,
      },
    };

    commit(mutationTypes.SET_MODIFIED_LINES, linesToSave);

    if (isKeyGroupingFieldChanged) {
      commit(mutationTypes.SET_IS_KEY_GROUPING_FIELD_CHANGED, true);
    }

    if (Object.keys(linesToSave).length >= state.maxModifiedLinesNumber) {
      await dispatch('modal/onOpenModal', ModalEnum.MAX_MODIFIED_LINES_MODAL, { root: true });
    }
  },

  async [SAVE_MODIFIED_LINES]({
    commit, getters, rootState, dispatch,
  }) {
    commit(mutationTypes.SET_IS_LINES_SAVING, true);

    dispatch('modal/onCloseModal', ModalEnum.LINES_SAVE_WARNING_MODAL, { root: true });

    try {
      await saveLines({
        nowFileId: getters[GET_SELECTED_FILE_ID],
        lines: transformLinesBeforeSave({ getters, rootState }),
      });

      showSuccessBanner({
        title: 'Saving',
        group: 'network',
        text: 'Changes were successfully saved',
      });

      dispatch(RESET_GRID_DATA);

      commit(createNameWithL1Module('SET_SELECTED_FILENAME_IDS'), [], { root: true });
      commit(createNameWithL1Module('SET_SELECTED_ORDER_TYPE_ID'), null, { root: true });

      router.push(routes.MOC.L1);
    } catch (err) {
      showErrorBanner({ title: 'Changes can not be saved', group: 'network' });
    }

    commit(mutationTypes.SET_IS_LINES_SAVING, false);
  },

  async [SUBMIT_ORDERS]({
    commit, getters, dispatch, rootState,
  }) {
    commit(mutationTypes.SET_IS_LINES_SUBMITTING, true);

    try {
      if (Object.keys(getters[GET_MODIFIED_LINES]).length) {
        await saveLines({
          nowFileId: getters[GET_SELECTED_FILE_ID],
          lines: transformLinesBeforeSave({ getters, rootState }),
        });
      }

      const { data } = await sendOrders(getters[GET_SELECTED_ORDER_IDS]);

      showSuccessBanner({ title: 'Success', text: 'Orders sent for submission' });
      setSubmissionIdToStorage(data.submissionId);

      dispatch(RESET_GRID_DATA);

      router.push(routes.MOC.L1);
    } catch (error) {
      showErrorBanner({ title: 'Error', text: error.message });
    }

    commit(mutationTypes.SET_IS_LINES_SUBMITTING, false);
  },

  updateValidationForField({ dispatch }, {
    rowId, field, issueForField, productEngine,
  }) {
    const hasIssuesForField = !!issueForField;

    dispatch(SET_VALIDATION_STATUS_FOR_CELL, {
      lineId: rowId,
      key: field,
      value: hasIssuesForField,
      productEngine,
    });
  },
};

export function checkNameExistance({
  lineInternals, fieldKey, columnNames, orderTypeConfig,
}) {
  const localLineInternals = cloneDeep(lineInternals);

  if (
    typeof localLineInternals[fieldKey] === 'object'
    && !orderTypeConfig.PRODUCT_ENGINE_KEYS.includes(fieldKey)
    && !lineInternals[fieldKey]?.name
  ) {
    localLineInternals[fieldKey].name = columnNames[fieldKey];

    if (orderTypeConfig?.ADDRESS_OVERRIDE_FIELDS) {
      localLineInternals[fieldKey].id = lineInternals[ADDRESS_ID];
    }
  }

  return localLineInternals;
}

export function transformLinesBeforeSave({ getters, rootState }) {
  const { selectedOrderTypeId } = rootState.massOrderCreation.L1;
  const orderTypeConfig = determineOrderTypeConfig(selectedOrderTypeId);

  const modifiedLines = new ModifiedLinesTransformer({
    orderTypeConfig,
    modifiedLines: getters[GET_MODIFIED_LINES],
    fieldNamesMapper: getters[GET_COLUMN_NAME_BY_FIELD_NAME],
  }).createSaveStructure();

  return Object.values(modifiedLines).map((line) => line);
}

export function groupSubmittedOrders(submittedOrders) {
  const sendOrdersPONumbers = [];
  const failedOrdersPONumbers = [];

  submittedOrders.forEach(({ ofoaId = null, customerPoNumber }) => {
    if (ofoaId) {
      sendOrdersPONumbers.push(customerPoNumber);
    } else {
      failedOrdersPONumbers.push(customerPoNumber);
    }
  });

  return {
    sendOrdersPONumbers,
    failedOrdersPONumbers,
  };
}

export function showSubmitedOrdersNotification(sendOrdersPONumbers) {
  const PONumbers = sendOrdersPONumbers.join(', ');
  const text = sendOrdersPONumbers.length === 1
    ? `Order with PO Number: ${PONumbers} was successfuly submitted`
    : `Orders with PO Number: ${PONumbers}  were successfuly submitted`;

  showSuccessBanner({
    text,
    title: 'Success',
  });
}

export function showNotSubmittedOrdersNotification(failedOrdersPONumbers) {
  const PONumbers = failedOrdersPONumbers.join(', ');
  const text = failedOrdersPONumbers.length === 1
    ? `Order with PO Number: ${PONumbers} wasn't submitted`
    : `Orders with PO Number: ${PONumbers}  weren't submitted`;

  showErrorBanner({
    text,
    title: 'Error',
    width: 400,
  });
}
