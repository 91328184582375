export const GET_SUCCEEDED_FILES = 'getSucceededFiles';
export const GET_NOT_SUCCEEDED_FILES = 'getNotSucceededFiles';
export const GET_SUCCEEDED_FILES_MAPPED_BY_ID = 'getSucceededFilesMappedById';

export const GET_FILENAMES = 'getFilenames';
export const GET_SEARCH_TEXT = 'getSearchText';
export const GET_PROCESSING_FILENAMES = 'getProcessingFilenames';
export const GET_SELECTED_FILENAME_IDS = 'getSelectedFilenameIds';

export const GET_LIST_OF_ORDER_TYPES = 'getListOfOrderTypes';
export const GET_IS_ORDER_TYPE_SELECTED = 'getIsOrderTypeSelected';

export const GET_DEFINED_ORDERS = 'getDefinedOrders';
export const GET_SELECTED_ORDERS = 'getSelectedOrders';
export const GET_SELECTED_ORDER_IDS = 'getSelectedOrderIds';
export const GET_IS_ALL_ORDERS_SELECTED = 'getIsAllOrdersSelected';
export const GET_SELECTED_ORDER_PO_NUMBER = 'getSelectedOrderPoNumber';
export const GET_GROUPED_UNDEFINED_ORDERS = 'getGroupedUndefinedOrders';
export const GET_ORDERS_OF_SELECTED_ORDER_TYPE = 'getOrdersOfSelectedOrderType';

export const GET_LIST_OF_ISSUE_TYPES = 'getListOfIssueTypes';
export const GET_SELECTED_ORDERS_FILTERED_BY_ISSUE_TYPE = 'getSelectedOrdersFilteredByIssueType';

export const GET_IS_DUPLICATE_LINES_AVAILABLE = 'getIsDuplicateLinesAvailable';
