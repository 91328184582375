import mutationTypes from '../constansts/mutationTypes';
import mutationsTypesFromL2 from '@/store/modules/massOrderCreation/L2/constansts/mutationsTypes.js';

import * as actionsEnum from '../constansts/actions';

import { getOrderIssueTypes } from '@/api/now/massOrderCreation';
import { createNameWithL2Module } from '@/store/modules/massOrderCreation/utils.js';

import { mapIssueTypesToCategories } from '@/store/modules/massOrderCreation/L2/filterPanel/actions.js';

const {
  SET_ISSUE_FILTERS_FOR_L2, SET_SELECTED_ISSUE_TYPE_ID, RESET_SELECTED_ISSUE_TYPE_ID,
} = actionsEnum;

export default {
  [SET_SELECTED_ISSUE_TYPE_ID]: ({
    commit, state, dispatch,
  }, issueTypeId) => {
    if (issueTypeId === state.selectedIssueTypeId) {
      dispatch(RESET_SELECTED_ISSUE_TYPE_ID);
    } else {
      commit(mutationTypes.SET_SELECTED_ISSUE_TYPE_ID, issueTypeId);
    }
  },

  [SET_ISSUE_FILTERS_FOR_L2]: async({ commit, rootState }, errorType = '') => {
    const {
      SET_ISSUE_TYPES_CATEGORIES,
      SET_SELECTED_ISSUE_TYPES_FILTERS,
      SET_SELECTED_ISSUE_TYPES_CATEGORY,
    } = mutationsTypesFromL2;

    let savedIssueTypes = rootState.massOrderCreation?.L2.issueTypesCategories;

    if (!savedIssueTypes?.length) {
      const { data: issueTypes = [] } = await getOrderIssueTypes();
      const mappedIssueTypes = mapIssueTypesToCategories(issueTypes);

      savedIssueTypes = mappedIssueTypes;

      commit(createNameWithL2Module(SET_ISSUE_TYPES_CATEGORIES), mappedIssueTypes, { root: true });
    }

    const { errorGroup = '' } = savedIssueTypes.find((issue) => issue.issueTypes.map(({ errorType }) => errorType).includes(errorType),
    );

    commit(createNameWithL2Module(SET_SELECTED_ISSUE_TYPES_FILTERS), [errorType], { root: true });
    commit(createNameWithL2Module(SET_SELECTED_ISSUE_TYPES_CATEGORY), errorGroup, { root: true });
  },

  [RESET_SELECTED_ISSUE_TYPE_ID]: ({ commit }) => {
    commit(mutationTypes.SET_SELECTED_ISSUE_TYPE_ID, null);
  },
};
