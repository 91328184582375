import {
  intersection, last, uniqBy, unionBy,
} from 'lodash';

import {
  fetchOrders, saveDuplicates, fetchDuplicates,
} from '@/api/now/massOrderCreation';

import {
  RESET_STORE,
  UNDO_CHANGE,
  SET_MODAL_MODE,
  UNDO_ALL_CHANGES,
  FETCH_DUPLICATE_LINES,
  GO_TO_NEXT_DUPLICATES_SET,
  GO_TO_PREV_DUPLICATES_SET,
  SAVE_DUPLICATE_LINES_CHANGES,
  SET_SELECTED_DUPLICATE_LINES,
  MERGE_SELECTED_DUPLICATE_LINES,
  DELETE_SELECTED_DUPLICATE_LINES,
  UPDATE_ISSUES_AFTER_DUPLICATES_RESOLUTION,
} from './constants/actions';
import {
  GET_MODAL_MODE,
  GET_DUPLICATES_CNAHGELOG,
  GET_ALL_DISPLAYED_DUPLICATES,
  GET_SELECTED_DUPLICATE_LINES,
  GET_DISPLAYED_DUPLICATES_ROW_DATA,
  GET_INDEX_OF_DISPLAYED_DUPLICATES_SET,
} from './constants/getters';
import mutationTypes from './constants/mutationTypes';

import {
  createNameWithL1Module,
  createNameWithL2Module,
  createNameWithFileUploadModule,
} from '@/store/modules/massOrderCreation/utils';
import {
  GET_SUCCEEDED_FILES,
  GET_SELECTED_ORDER_IDS,
  GET_SELECTED_FILENAME_IDS,
} from '@/store/modules/massOrderCreation/L1/constansts/getters';
import L1Mutations from '@/store/modules/massOrderCreation/L1/constansts/mutationTypes';
import {
  GET_SELECTED_FILE_ID,
  GET_SELECTED_ORDER_IDS as GET_SELECTED_ORDER_IDS_L2,
} from '@/store/modules/massOrderCreation/L2/constansts/getters';
import { REFETCH_DATA } from '@/store/modules/massOrderCreation/L2/constansts/actions';
import { GET_SUCCESSFULLY_PROCESSED_FILE_IDS } from '@/store/modules/massOrderCreation/fileUpload/constansts/getters';

import { DUPLICATE_LINES_MODAL_MODES } from '@/enums/massOrderCreation/duplicateLines/modalModes';
import { DUPLICATE_LINES_ACTION_TYPES } from '@/enums/massOrderCreation/duplicateLines/actionTypes';
import { showSuccessBanner, showErrorBanner } from '@/utils/notifications';

export default {
  [SET_MODAL_MODE]({ commit }, mode) {
    commit(mutationTypes.SET_MODAL_MODE, mode);
  },

  [UNDO_CHANGE]({ commit, getters }) {
    const index = getters[GET_INDEX_OF_DISPLAYED_DUPLICATES_SET];
    const duplicatesChangelog = getters[GET_DUPLICATES_CNAHGELOG];
    const changelogForSet = duplicatesChangelog[index].slice(0, -1);

    updateChangelogForDisplayedSet({
      getters, commit, changelogForSet,
    });

    commit(mutationTypes.SET_SELECTED_DUPLICATE_LINES, []);
    commit(
      mutationTypes.SET_DISPLAYED_DUPLICATES,
      changelogForSet[changelogForSet.length - 1].lines,
    );
  },

  [UNDO_ALL_CHANGES]({ commit, getters }) {
    const index = getters[GET_INDEX_OF_DISPLAYED_DUPLICATES_SET];
    const duplicatesChangelog = getters[GET_DUPLICATES_CNAHGELOG];
    const changelogForSet = duplicatesChangelog[index][0];

    updateChangelogForDisplayedSet({
      getters, commit, changelogForSet: [changelogForSet],
    });

    commit(mutationTypes.SET_SELECTED_DUPLICATE_LINES, []);
    commit(mutationTypes.SET_DISPLAYED_DUPLICATES, changelogForSet.lines);
  },

  async [FETCH_DUPLICATE_LINES]({
    commit, getters, rootGetters,
  }) {
    const mode = getters[GET_MODAL_MODE];

    commit(mutationTypes.SET_IS_DUPLICATES_LOADING, true);

    try {
      const params = compileParamsForFetchDuplicates(rootGetters, mode);
      const { data } = await fetchDuplicates(params);
      const duplicatesSets = mapDuplicates(data);

      commit(mutationTypes.SET_INITIAL_DATA, duplicatesSets);

      if (duplicatesSets.length) {
        const duplicatesChangelog = duplicatesSets.map(({ lines }) => {
          return [{ action: null, lines }];
        });

        commit(mutationTypes.SET_DISPLAYED_DUPLICATES, duplicatesSets[0].lines);
        commit(mutationTypes.SET_DUPLICATES_CHANGELOG, duplicatesChangelog);
        commit(mutationTypes.SET_INDEX_OF_DISPLAYED_DUPLICATES_SET, 0);
      }
    } catch (err) {
      console.error(err);
    } finally {
      commit(mutationTypes.SET_IS_DUPLICATES_LOADING, false);
    }
  },

  [GO_TO_NEXT_DUPLICATES_SET]({
    commit,
    getters: { getDuplicatesChangelog, getIndexOfDisplayedDuplicatesSet },
  }) {
    const newIndex = getIndexOfDisplayedDuplicatesSet + 1;
    const duplicatesSets = getDuplicatesChangelog;
    const lastChangeIndex = duplicatesSets[newIndex].length - 1;

    commit(mutationTypes.SET_DISPLAYED_DUPLICATES, duplicatesSets[newIndex][lastChangeIndex].lines);
    commit(mutationTypes.SET_INDEX_OF_DISPLAYED_DUPLICATES_SET, newIndex);
    commit(mutationTypes.SET_SELECTED_DUPLICATE_LINES, []);
  },

  [GO_TO_PREV_DUPLICATES_SET]({
    commit,
    getters: { getDuplicatesChangelog, getIndexOfDisplayedDuplicatesSet },
  }) {
    const newIndex = getIndexOfDisplayedDuplicatesSet - 1;
    const duplicatesSets = getDuplicatesChangelog;
    const lastChangeIndex = duplicatesSets[newIndex].length - 1;

    commit(mutationTypes.SET_DISPLAYED_DUPLICATES, duplicatesSets[newIndex][lastChangeIndex].lines);
    commit(mutationTypes.SET_INDEX_OF_DISPLAYED_DUPLICATES_SET, newIndex);
    commit(mutationTypes.SET_SELECTED_DUPLICATE_LINES, []);
  },

  async [SAVE_DUPLICATE_LINES_CHANGES]({
    commit, dispatch, getters,
  }) {
    const duplicatesChangelog = getters[GET_DUPLICATES_CNAHGELOG];
    const flattenedChangeLog = duplicatesChangelog
      .map((changelogForSet) => last(changelogForSet))
      .flat();

    let retries = 0;
    let changedDuplicates = [];

    flattenedChangeLog.forEach(({ lines }) => {
      changedDuplicates.push(lines.filter((line) => line?.deleted || line?.merged));
    });
    changedDuplicates = changedDuplicates.flat();

    commit(mutationTypes.SET_IS_DUPLICATES_SAVE_IN_PROGRESS, true);

    const callSaveDuplicates = () => new Promise((resolve, reject) => {
      saveDuplicates({ lines: changedDuplicates })
        .then(() => {
          const isMergedLinesAvailable = changedDuplicates.filter((item) => item?.merged).length > 0;

          showSuccessBanner({ title: 'Success', text: 'Changes to duplicate lines are saved' });

          if (isMergedLinesAvailable) {
            showSuccessBanner({
              title: 'Success',
              text: 'Please check your merged order lines in the Mass Order Create Details page to ensure accuracy.',
            });
          }

          dispatch(RESET_STORE);
          dispatch(UPDATE_ISSUES_AFTER_DUPLICATES_RESOLUTION);
          commit(mutationTypes.SET_IS_DUPLICATES_SAVE_IN_PROGRESS, false);

          resolve(true);
        })
        .catch((err) => {
          showErrorBanner({
            title: 'Error',
            text: 'Error while saving changes to duplicate lines. Don\'t close or refresh the page for a minute while we try to save your changes',
          });

          const interval = setInterval(() => {
            if (retries < 10) {
              retries++;

              showErrorBanner({
                title: 'Error',
                text: 'Error while saving changes to duplicate lines. Don\'t close or refresh the page for a minute while we try to save your changes',
              });
              callSaveDuplicates();
            } else {
              showErrorBanner({
                title: 'Error',
                text: 'Error while saving changes to duplicate lines. Please try again or contact support',
              });

              commit(mutationTypes.SET_IS_DUPLICATES_LOADING, false);
              commit(mutationTypes.SET_IS_DUPLICATES_SAVE_IN_PROGRESS, false);

              reject(err);
              clearInterval(interval);
            }
          }, 6000);
        });
    });

    return callSaveDuplicates();
  },

  [RESET_STORE]({ commit }) {
    commit(mutationTypes.SET_INITIAL_DATA, []);
    commit(mutationTypes.SET_DISPLAYED_DUPLICATES, []);
    commit(mutationTypes.SET_DUPLICATES_CHANGELOG, []);
    commit(mutationTypes.SET_INDEX_OF_DISPLAYED_DUPLICATES_SET, null);
  },

  [SET_SELECTED_DUPLICATE_LINES]({ commit }, selectedLines) {
    commit(mutationTypes.SET_SELECTED_DUPLICATE_LINES, selectedLines);
  },

  [MERGE_SELECTED_DUPLICATE_LINES]({ commit, getters }) {
    const index = getters[GET_INDEX_OF_DISPLAYED_DUPLICATES_SET];
    const duplicatesChangelog = getters[GET_DUPLICATES_CNAHGELOG];
    const displayedDuplicatesRowData = getters[GET_DISPLAYED_DUPLICATES_ROW_DATA];

    let selectedDuplicateLines = getters[GET_SELECTED_DUPLICATE_LINES];
    selectedDuplicateLines = selectedDuplicateLines.map((item) => ({ ...item, deleted: true }));

    const mergedLine = mergeLines(selectedDuplicateLines);
    const resultLines = unionBy(
      [mergedLine],
      selectedDuplicateLines,
      displayedDuplicatesRowData,
      'lineItemId',
    );

    const changelogForSet = [
      ...duplicatesChangelog[index],
      { action: DUPLICATE_LINES_ACTION_TYPES.MERGE, lines: resultLines },
    ];

    updateChangelogForDisplayedSet({
      getters, commit, changelogForSet,
    });

    commit(mutationTypes.SET_SELECTED_DUPLICATE_LINES, []);
    commit(mutationTypes.SET_DISPLAYED_DUPLICATES, resultLines);
  },

  [DELETE_SELECTED_DUPLICATE_LINES]({ commit, getters }) {
    const index = getters[GET_INDEX_OF_DISPLAYED_DUPLICATES_SET];
    const duplicatesChangelog = getters[GET_DUPLICATES_CNAHGELOG];

    let selectedDuplicateLines = getters[GET_SELECTED_DUPLICATE_LINES];
    const allDisplayedDuplicates = getters[GET_ALL_DISPLAYED_DUPLICATES];

    selectedDuplicateLines = selectedDuplicateLines.map((item) => ({ ...item, deleted: true }));

    const resultLines = unionBy(selectedDuplicateLines, allDisplayedDuplicates, 'lineItemId');

    const changelogForSet = [
      ...duplicatesChangelog[index],
      { action: DUPLICATE_LINES_ACTION_TYPES.DELETE, lines: resultLines },
    ];

    updateChangelogForDisplayedSet({
      getters, commit, changelogForSet,
    });
    commit(mutationTypes.SET_SELECTED_DUPLICATE_LINES, []);
    commit(mutationTypes.SET_DISPLAYED_DUPLICATES, resultLines);
  },

  async [UPDATE_ISSUES_AFTER_DUPLICATES_RESOLUTION]({
    commit, getters, dispatch, rootGetters,
  }) {
    const mode = getters[GET_MODAL_MODE];

    if (
      mode === DUPLICATE_LINES_MODAL_MODES.L1
      || mode === DUPLICATE_LINES_MODAL_MODES.FILE_UPLOAD
    ) {
      const succeededFiles = rootGetters[createNameWithL1Module(GET_SUCCEEDED_FILES)];
      const selectedFileIds = rootGetters[createNameWithL1Module(GET_SELECTED_FILENAME_IDS)];

      const {
        data: { files },
      } = await fetchOrders(selectedFileIds);

      commit(
        createNameWithL1Module(L1Mutations.SET_SUCCEEDED_FILES),
        uniqBy([...files, ...succeededFiles], 'fileId'),
        { root: true },
      );
    }

    if (mode === DUPLICATE_LINES_MODAL_MODES.L2) {
      dispatch(createNameWithL2Module(REFETCH_DATA), null, { root: true });
    }
  },
};

function updateChangelogForDisplayedSet({
  getters, commit, changelogForSet,
}) {
  const index = getters[GET_INDEX_OF_DISPLAYED_DUPLICATES_SET];
  const changelogForAll = getters[GET_DUPLICATES_CNAHGELOG];

  changelogForAll.splice(index, 1, changelogForSet);

  commit(mutationTypes.SET_DUPLICATES_CHANGELOG, changelogForAll);
}

function mergeLines(lines) {
  const flatSizes = lines.reduce((acc, { sizes }) => {
    return [...acc, ...sizes];
  }, []);
  const mergedSizes = mergeSizes(flatSizes);
  const resultLine = {
    ...lines[0], merged: true, sizes: mergedSizes,
  };

  delete resultLine.deleted;

  return resultLine;
}

function mergeSizes(sizes) {
  const resultMap = {};

  // Compiling map of { [size]: sumOfValues }
  sizes.forEach(({ name, value }) => {
    if (!(name in resultMap)) {
      resultMap[name] = 0;
    }

    resultMap[name] += Number(value);
  });

  // Compiling array of sizes to replace old one
  return Object.entries(resultMap).map((item) => ({
    name: item[0],
    value: item[1],
  }));
}

function mapDuplicates(data) {
  const { duplicates } = data;

  return duplicates;
}

function compileParamsForFetchDuplicates(rootGetters, mode) {
  if (mode === DUPLICATE_LINES_MODAL_MODES.L1) {
    const files = rootGetters[createNameWithL1Module(GET_SUCCEEDED_FILES)];
    const selectedFileIds = rootGetters[createNameWithL1Module(GET_SELECTED_FILENAME_IDS)];
    const selectedOrderIds = rootGetters[createNameWithL1Module(GET_SELECTED_ORDER_IDS)];

    return compileParamsForL1(files, selectedFileIds, selectedOrderIds);
  }
  if (mode === DUPLICATE_LINES_MODAL_MODES.L2) {
    const fileId = rootGetters[createNameWithL2Module(GET_SELECTED_FILE_ID)];
    const orderIds = rootGetters[createNameWithL2Module(GET_SELECTED_ORDER_IDS_L2)];

    return [{ fileId, orderIds }];
  }
  if (mode === DUPLICATE_LINES_MODAL_MODES.FILE_UPLOAD) {
    const allFiles = rootGetters[createNameWithL1Module(GET_SUCCEEDED_FILES)];
    const resentlyUploadedFileIds = rootGetters[createNameWithFileUploadModule(GET_SUCCESSFULLY_PROCESSED_FILE_IDS)];

    return compileParamsForFileUpload(allFiles, resentlyUploadedFileIds);
  }
}

function compileParamsForL1(files, selectedFileIds, selectedOrderIds) {
  const idsMap = files.map(({ fileId, orders }) => {
    return {
      fileId,
      orderIds: orders.map(({ id }) => id),
    };
  });

  if (selectedFileIds.length) {
    // In case if some files were selected by user

    return idsMap
      .filter(({ fileId }) => selectedFileIds.includes(fileId))
      .map(({ fileId, orderIds }) => ({
        fileId,
        orderIds: intersection(orderIds, selectedOrderIds),
      }));
  }
  // In case orderIds were selected without selecting fileIds

  return idsMap
    .map(({ fileId, orderIds }) => ({ fileId, orderIds: intersection(orderIds, selectedOrderIds) }))
    .filter(({ orderIds }) => orderIds.length);
}

function compileParamsForFileUpload(files, recentFilesIds) {
  const idsMap = files.map(({ fileId, orders }) => {
    return {
      fileId,
      orderIds: orders.map(({ id }) => id),
    };
  });

  return idsMap
    .filter(({ fileId }) => recentFilesIds.includes(fileId))
    .map(({ fileId, orderIds }) => {
      return {
        fileId,
        orderIds: orderIds.filter(({ hasDuplicateMaterialSizes }) => hasDuplicateMaterialSizes),
      };
    });
}
