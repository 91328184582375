const SET_PENDING_FILES = 'SET_PENDING_FILES';
const SET_RECENTLY_UPLOADED_FILES = 'SET_RECENTLY_UPLOADED_FILES';
const SET_UPLOADED_FILE_IDS_TO_SELECT = 'SET_UPLOADED_FILE_IDS_TO_SELECT';
const SET_SUCCESSFULLY_PROCESSED_FILE_IDS = 'SET_SUCCESSFULLY_PROCESSED_FILE_IDS';

export default {
  SET_PENDING_FILES,
  SET_RECENTLY_UPLOADED_FILES,
  SET_UPLOADED_FILE_IDS_TO_SELECT,
  SET_SUCCESSFULLY_PROCESSED_FILE_IDS,
};
