/* eslint-disable no-restricted-syntax */
import moment from 'moment-timezone';

import { PO_CELL_STATUS } from '@/pages/PurchaseOrder/constants';
import { COLUMN_KEYS, VAS_FIELDS } from '@/pages/PurchaseOrder/Tables/LinesTable/UngroupedExceptions/constants';
import {
  UNDELETABLE_COLUMN_KEYS,
  COLUMN_KEYS as UNGROUPED_COLUMN_KEYS,
} from '@/pages/PurchaseOrder/Tables/LinesTable/GroupedExceptions/constants';

import dateFormats from '@/enums/dates/dateFormats';

function isVasField(field) {
  return Object.values(VAS_FIELDS).includes(field);
}

export function getDefaultUserPreferences(columnDefs) {
  return columnDefs.slice(1).map(columnDef => ({
    id: columnDef.field,
    name: columnDef.headerName,
    visible: true,
    undeletable: UNDELETABLE_COLUMN_KEYS.includes(columnDef.field),
  }));
}

export function getColumnDefsFromUserPreferences(userPreferences, previousColumnDefs, groupedExceptions = false) {
  const columnDefsByKey = new Map();

  previousColumnDefs.forEach(columnDef => {
    columnDefsByKey.set(columnDef.field, columnDef);
  });

  const updatedColumnDefs = groupedExceptions
    ? [columnDefsByKey.get(UNGROUPED_COLUMN_KEYS.SELECTED)]
    : [columnDefsByKey.get(COLUMN_KEYS.INDICATOR)];

  return userPreferences.reduce((acc, column) => {
    const columnDef = columnDefsByKey.get(column.id);

    if (!columnDef) return acc;

    columnDef.headerName = column.name;
    columnDef.hide = !column.visible;

    acc.push(columnDef);

    return acc;
  }, updatedColumnDefs);
}

export function parseDateFromIso(ISOdate) {
  if (!ISOdate) {
    return;
  }

  return moment(ISOdate).format('DD/MM/YYYY');
}

export function shouldIncludeUpdateAction(wrongfulValues) {
  let result = true;

  for (let i = 1; i < wrongfulValues.length; i++) {
    const curr = wrongfulValues[i];
    const prev = wrongfulValues[i - 1];

    if (curr.length !== prev.length || !sameWrongfulValues(curr, prev)) {
      result = false;
      break;
    }
  }

  return result;
}

export function sameWrongfulValues(a, b) {
  let result = true;

  for (let j = 0; j < a.length; j++) {
    const isSameField = a[j].name === b[j].name;
    const isSameValue = a[j].value === b[j].value;

    if (!isSameField || !isSameValue) {
      result = false;
      break;
    }
  }

  return result;
}

const isoRegex = /^\d{4}-(0[1-9]|1[0-2])-([0-2][1-9]|3[0-1])$/;
const baseClassName = 'purchase-orders-table__cell';

export function getPOValue(params, columnKey) {
  if (!params?.value) {
    return '-';
  }

  const value = params.value || '';
  const isCorrectDate = isoRegex.test(value);

  if (isCorrectDate) {
    return moment(value).format(dateFormats.EU);
  }

  return isVasField(columnKey) ? value.replaceAll(',', '\n') : value;
}

export function getPOCellClass(params) {
  const isIncorrect = params?.orderDetailStatus === PO_CELL_STATUS.INCORRECT;
  const statusColorClass = isIncorrect ? `${baseClassName}__incorrect` : '';

  return [baseClassName, statusColorClass];
}

export function getCommonColDef({ columnKey, isRightAligned = false }) {
  const rightAlignedClass = 'purchase-orders-table__cell purchase-orders-table__cell--rightAlign';

  return {
    field: columnKey,
    cellClass: ({ data }) => (!isRightAligned ? getPOCellClass(data[columnKey]) : rightAlignedClass),
    valueGetter: ({ data }) => getPOValue(data[columnKey], columnKey),
    cellStyle: { 'white-space': 'pre' },
    ...(isRightAligned && { type: 'rightAligned' }),
  };
}
