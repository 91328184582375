import * as gettersEnum from '../constansts/getters';
import i18n from '@/plugins/i18n';

import { FIELD_NAMES } from '@/enums/fieldNames';
import { issueTypesMapper } from '../../constants/issueTypesMapper';

const {
  GET_SELECTED_ORDERS, GET_LIST_OF_ISSUE_TYPES, GET_SELECTED_ORDERS_FILTERED_BY_ISSUE_TYPE,
} = gettersEnum;

export default {
  [GET_LIST_OF_ISSUE_TYPES]: (_, { getIssueOfSelectedOrders }) => {
    return Object.keys(getIssueOfSelectedOrders).map((mergeId) => {
      return {
        id: mergeId,
        ...getIssueOfSelectedOrders[mergeId],
      };
    });
  },

  getIssueOfSelectedOrders: (_, { getSelectedOrders }) => {
    return getSelectedOrders.reduce((listOfIssues, order) => {
      const issueTypes = order?.issues?.types || [];

      issueTypes.forEach(({
        column, type, count,
      }) => {
        const issueType = getMappedIssueType(type);

        const mergeId = createOrderIssueMergeId(column, issueType);

        const issueCounter = listOfIssues?.[mergeId]?.issueCounter
          ? listOfIssues[mergeId].issueCounter || 0 + count
          : count;

        let text = i18n.global.t(`pages.MOC.L1.${issueType}`, { column });

        if (
          column
          && column.toLowerCase() === FIELD_NAMES.REGION
          && issueType === issueTypesMapper.INCORRECT_VALUE
        ) {
          text = `[${column}] ${i18n.global.t('pages.MOC.L2.regionCodeError')}`;
        }

        listOfIssues[mergeId] = {
          type,
          text,
          issueCounter,
        };
      });

      return listOfIssues;
    }, {});
  },

  [GET_SELECTED_ORDERS_FILTERED_BY_ISSUE_TYPE]: (_, getters, state) => {
    const selectedOrders = getters[GET_SELECTED_ORDERS];
    const issueTypeId = state.selectedIssueTypeId;

    if (!issueTypeId) {
      return selectedOrders;
    }

    return selectedOrders.filter(({ issues: { types = [] } = {} }) => {
      return types.some(({ column = '', type }) => {
        return createOrderIssueMergeId(column, getMappedIssueType(type)) === issueTypeId;
      });
    });
  },
};

function createOrderIssueMergeId(column = '', issueType = '') {
  return column ? `${issueType}-${column}` : issueType;
}

function getMappedIssueType(type) {
  return issueTypesMapper[type] ? issueTypesMapper[type] : type;
}
