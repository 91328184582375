export default {
  resourceModal: 'resourceModal',
  uploadFileModal: 'uploadFileModal',
  applyActionModal: 'applyActionModal',
  businessTypeModal: 'businessTypeModal',
  myOrdersErrorModal: 'myOrdersErrorModal',
  downloadTemplateModal: 'downloadTemplateModal',
  singleOrderCreateModal: 'singleOrderCreateModal',
  saveModifiedOrdersModal: 'saveModifiedOrdersModal',

  ORDERS_COLUMNS: 'ordersColumns',
  ADD_SIZE_MODAL: 'addSizeModal',
  DUPLICATE_LINES_MODAL: 'duplicateLinesModal',
  MAX_MODIFIED_LINES_MODAL: 'maxModifiedLinesModal',
  LINES_SAVE_WARNING_MODAL: 'linesSaveWarningModal',

  PURCHASE_ORDER_LINE_COLUMNS: 'purchaseOrderLineColumns',
  PURCHASE_ORDER_HEADER_COLUMNS: 'purchaseOrderHeaderColumns',
  PURCHASE_ORDER_GROUPED_CASES_COLUMNS: 'purchaseOrderGroupedCasesColumns',

  EXCEPTION_DETAILS: 'orderExceptionDetails',
};
