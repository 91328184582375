import { groupBy } from 'lodash';

import {
  GET_FETCHED_ORDERS,
  GET_SELECTED_FILE_ID,
  GET_FILES_WITH_ORDERS,
  GET_SELECTED_ORDER_IDS,
  GET_COLUMN_NAME_BY_FIELD_NAME,
  GET_FIELD_NAME_BY_COLUMN_NAME,
  GET_IS_DUPLICATE_LINES_AVAILABLE,
} from './constansts/getters';

import gridGetters from './grid/getters';
import filterPanelGetters from './filterPanel/getters';
import issueNavigatorGetters from './issueNavigator/getters';

export default {
  ...gridGetters,
  ...filterPanelGetters,
  ...issueNavigatorGetters,

  [GET_FILES_WITH_ORDERS]: (state, getters, rootState, rootGetters) => {
    const selectedOrdersFilteredByIssueType = rootGetters['massOrderCreation/L1/getSelectedOrdersFilteredByIssueType'];

    const ordersGroupedByFileId = groupBy(selectedOrdersFilteredByIssueType, 'fileId');

    return Object.keys(ordersGroupedByFileId).map((fileId) => {
      return {
        fileId,
        orders: ordersGroupedByFileId[fileId],
      };
    });
  },

  [GET_FETCHED_ORDERS]: ({ fetchedOrders }) => fetchedOrders || {},

  [GET_SELECTED_FILE_ID]: ({ selectedFileId }) => selectedFileId || null,

  [GET_SELECTED_ORDER_IDS]: ({ selectedOrderIds }) => selectedOrderIds || [],

  [GET_FIELD_NAME_BY_COLUMN_NAME]({ fieldMapping }) {
    return fieldMapping.reduce((acc, { fieldName, columnName }) => {
      acc[fieldName] = columnName;

      return acc;
    }, {});
  },

  [GET_COLUMN_NAME_BY_FIELD_NAME]({ fieldMapping }) {
    return fieldMapping.reduce((acc, { fieldName, columnName }) => {
      acc[columnName] = fieldName;

      return acc;
    }, {});
  },

  [GET_IS_DUPLICATE_LINES_AVAILABLE]: ({ hasDuplicateLinesError }) => hasDuplicateLinesError,
};

export function groupOrdersByFileId(orders) {
  return orders.reduce((acc, order) => {
    if (!acc[order.fileId]) {
      acc[order.fileId] = [];
    }

    acc[order.fileId].push(order);

    return acc;
  }, {});
}
