import { TABLE_KEY } from '@/pages/PurchaseOrder/constants';

export default {
  orderId: '',
  purchaseOrder: {},

  resolvedExceptions: [],

  gridApi: {
    [TABLE_KEY.LINES_TABLE]: null,
    [TABLE_KEY.HEADER_TABLE]: null,
  },
  pagination: {
    [TABLE_KEY.LINES_TABLE]: {
      pageSize: 25,
      pageNumber: 0,
    },
  },

  userPreferences: {
    [TABLE_KEY.LINES_TABLE]: null,
    [TABLE_KEY.HEADER_TABLE]: null,
    [TABLE_KEY.GROUPED_TABLES]: null,
  },

  groups: [],
  selectedGroupId: null,
  exceptionsGrouped: false,
  groupedSelectedCases: [],

  casesReloading: false,
  dataFailedToLoad: false,
};
