export const FETCH_ORDERS = 'fetchOrders';
export const FETCH_ORDER_TYPES = 'fetchOrderTypes';
export const FETCH_INITIAL_DATA = 'fetchInitialData';

export const SEND_ORDERS = 'sendOrders';
export const HANDLE_REJECTED_ORDERS = 'handleRejectedOrders';
export const HANDLE_PROCESSED_ORDERS = 'handleProcessedOrders';

export const DELETE_ORDERS_WITH_ISSUES_ONLY = 'deleteOrdersWithIssuesOnly';
export const DELETE_SELECTED_ORDERS = 'deleteSelectedOrders';
export const DELETE_FILE = 'deleteFile';

export const SET_SEARCH_TEXT = 'setSearchText';
export const SET_SELECTED_FILENAME_IDS = 'setSelectedFilenameIds';
export const RESET_SELECTED_FILENAME_IDS = 'resetSelectedFilenameIds';
export const AUTOSELECT_RECENTLY_UPLOADED_FILES = 'autoselectRecentlyUploadedFiles';

export const SET_SELECTED_ORDER_IDS = 'setSelectedOrderIds';
export const SET_ALL_ORDERS_SELECTED = 'setAllOrdersSelected';
export const RESET_SELECTED_ORDER_IDS = 'resetSelectedOrderIds';

export const SET_SELECTED_ORDER_TYPE_ID = 'setSelectedOrderTypeId';
export const RESET_SELECTED_ORDER_TYPE_ID = 'resetSelectedOrderTypeId';

export const SET_ISSUE_FILTERS_FOR_L2 = 'setIssueFiltersForL2';
export const SET_SELECTED_ISSUE_TYPE_ID = 'setSelectedIssueTypeId';
export const RESET_SELECTED_ISSUE_TYPE_ID = 'resetSelectedIssueTypeId';

export const RESET_SELECTION = 'resetSelections';
export const TOGGLE_FILTER_PANEL = 'toggleFilterPanel';
