<template>
  <VerticalNavbar :topItems="topItems" :bottomItems="bottomItems" />
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { VerticalNavbar } from "podium";

import appWrapper from "@/appInstance";
import router from "@/router";
import routes from "@/router/routes";
import ExternalServicesEnum from "@/enums/externalServices";

import { USER_MANAGAMENT_AD_GROUPS } from "./constants";

const { t } = useI18n();

const store = useStore();

const topItems = computed(() => {
  const { $auth } = appWrapper.app.config.globalProperties;
  const requiredItems = [
    {
      label: t("pages.layout.navBar.dashboard"),
      icon: "HomeLargeDefaultOnDark",
      action: () => router.push(routes.ROOT),
      active: isRouteActive(routes.ROOT),
    },
    // {
    //   label: t('pages.layout.navBar.exceptions'),
    //   icon: 'ReviewLargeDefaultOnDark',
    //   action: () => router.push(routes.EXCEPTIONS),
    //   active: isRouteActive(routes.EXCEPTIONS),
    // },
    // {
    //   label: t('pages.layout.navBar.inventoryAvailability'),
    //   icon: 'StagesLargeDefaultOnDark',
    //   action: () => router.push(routes.AVAILABILITY),
    //   active: isRouteActive(routes.AVAILABILITY),
    // },
    // NOTE: Temporarily hiding cancellation for MAGC and will unhide for MANA
    // {
    //   label: t('pages.layout.navBar.cancellations'),
    //   icon: 'NullLargeDefaultOnDark',
    //   action: () => window.open(ExternalServicesEnum.ORDER_CANCELLATION, '_blank'),
    //   fullyColored: true,
    // },
    // {
    //   label: t('pages.layout.navBar.resources'),
    //   icon: 'FeedLargeDefaultOnDark',
    //   action: () => router.push(routes.RESOURCES),
    //   active: isRouteActive(routes.RESOURCES),
    // },
    // NOTE: Temporarily hiding Business Operations Dashboard for MAGC and will unhide for MANA
    // {
    //   label: t('pages.layout.navBar.businessDashboard'),
    //   icon: 'ActivityLargeDefaultOnDark',
    //   action: () => window.open(ExternalServicesEnum.BUSINESS_DASHBOARD, '_blank'),
    // },
  ];

  if ($auth && $auth.authStateManager) {
    const groups = $auth?.authStateManager.getAuthState()?.idToken?.claims?.groups;
    const userGroups = groups ?? [];
    const assignedAdGroups = USER_MANAGAMENT_AD_GROUPS.filter((group) =>
      userGroups.includes(group)
    );

    if (assignedAdGroups.length) {
      requiredItems.push({
        label: t("pages.layout.navBar.userManagement"),
        icon: "ClubLargeDefaultOnDark",
        action: () => window.open(ExternalServicesEnum.NOW_IAM, "_blank"),
      });
    }
  }

  const adminItems = [
    {
      label: t("pages.layout.navBar.stubPage"),
      icon: "LockLargeDefaultOnDark",
      action: () => router.push(routes.STUB_PAGE_CONTROLS),
      active: isRouteActive(routes.STUB_PAGE_CONTROLS),
    },
  ];

  return store.state.user.isAdmin ? [...requiredItems, ...adminItems] : requiredItems;
});

const bottomItems = computed(() => [
  {
    label: t("pages.layout.navBar.logout"),
    icon: "material_outlined_exit_to_app",
    action: () => store.dispatch("user/logout"),
  },
]);

function isRouteActive(route) {
  return router.currentRoute.value.path === route;
}
</script>
