export default {
  CURRENT_PAGE_PO_LINES: 'currentPagePOLines',
  CURRENT_GROUPED_PAGE_PO_LINES: 'currentGroupedPagePOLines',
  GET_FORMATTED_HEADER_TABLE_DATA: 'getFormatedHeaderTableData',

  LINE_CASES: 'linesCases',
  HEADER_CASES: 'headerCases',
  EXPANDED_GROUPS: 'expandedGroups',
  SELECTED_GROUP_ID: 'selectedGroupId',

  NO_CASES_SELECTED: 'noCasesSelected',
};
