import { cloneDeep } from 'lodash';

import {
  GET_ROW_DATA,
  GET_GRID_API,
  GET_COLUMN_API,
  GET_COLUMN_DEFS,
  GET_GRID_CREATOR,
  GET_CACHED_LINES,
  GET_MODIFIED_LINES,
  GET_FROM_COLUMN_NAME,
  GET_SEARCH_DIRECTION,
  GET_LINES_VALIDATION_STATUS,
  GET_HAS_NEXT_PAGE_WITH_LINES,
} from '@/store/modules/massOrderCreation/L2/constansts/getters';

export default {
  [GET_GRID_API]: ({ gridApi }) => gridApi || null,

  [GET_COLUMN_API]: ({ columnApi }) => columnApi || null,

  [GET_GRID_CREATOR]: ({ gridCreator }) => gridCreator || null,

  [GET_CACHED_LINES]: ({ cachedLines }) => cachedLines || [],

  [GET_MODIFIED_LINES]: ({ modifiedLines }) => modifiedLines || [],

  [GET_FROM_COLUMN_NAME]: ({ fromColumnName }) => fromColumnName || null,

  [GET_SEARCH_DIRECTION]: ({ searchDirection }) => searchDirection || null,

  [GET_LINES_VALIDATION_STATUS]: ({ linesValidationStatus }) => linesValidationStatus || null,

  [GET_HAS_NEXT_PAGE_WITH_LINES]: ({ hasNextPageWithLines }) => hasNextPageWithLines || null,

  [GET_COLUMN_DEFS]: ({ maxModifiedLinesNumber }, { getGridCreator, getModifiedLines }) => {
    if (Object.keys(getModifiedLines).length >= maxModifiedLinesNumber) {
      const columDefs = getGridCreator?.getColumnDefs() || [];

      return columDefs.map((column) => ({ ...column, editable: false }));
    }

    return getGridCreator?.getColumnDefs() || [];
  },

  [GET_ROW_DATA]: (_, { getGridCreator, getModifiedLines }) => {
    const rowData = getGridCreator?.getRowData() || [];

    const rowDataWithModifiedLines = addModifiedLines(rowData, getModifiedLines);

    return rowDataWithModifiedLines || [];
  },

  getAllSizesNames(_, { getGridCreator }) {
    return Object.values(getGridCreator.sizesGroupedByProductEngine)
      .map((sizes) => Array.from(sizes))
      .flat();
  },
};

export function addModifiedLines(rowData, modifiedLines) {
  const localRowData = cloneDeep(rowData);

  Object.keys(modifiedLines).forEach((modifiedLinesRowId) => {
    const loadedRowIndex = localRowData.findIndex(({ rowId }) => rowId === modifiedLinesRowId);

    if (loadedRowIndex !== -1) {
      Object.keys(modifiedLines[modifiedLinesRowId]).forEach((columnName) => {
        localRowData[loadedRowIndex][columnName] = modifiedLines[modifiedLinesRowId][columnName];
      });
    }
  });

  return localRowData;
}
