export default {
  exceptionDetails: [],
  stats: {
    totalCases: 0,
    totalOrders: 0,
    totalSoldTosImpacted: 0,
    totalImpactedUnits: 0,
  },

  columnDefs: [],
  userPreferences: [],

  totalResults: 0,
  pageTokens: [null],
  pageSize: 10,
  pageNumber: 0,

  isLoading: false,
  metrics: {},

  caseGroups: [],
  caseTypes: [],
  caseTypesDisplayNames: {},
  isGroupedFlow: false,

  selectedExceptionGroups: [],
  selectedExceptionTypes: [],
  relatedOrders: [],
  caseRelatedValue: {},
  isRelatedOrdersLoading: false,
  isResolveCaseLoading: false,

  exportUngroupedCasesFileName: '',
  groupedExceptionApis: new Map(),
};
