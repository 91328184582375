import BaseCreator from './BaseCreator';

import { CONTRACT } from '../constansts/config';

import BaseValidator from '../gridValidators/BaseValidator';

export default class ContractCreator extends BaseCreator {
  constructor(orders, sizeColumnsToAdd) {
    super({
      orders,
      sizeColumnsToAdd,
      orderTypeName: 'Contract',
      columnOrder: CONTRACT.COLUMN_ORDER,
      requiredFields: CONTRACT.REQUIRED_FIELDS,
      viewOnlyFields: CONTRACT.VIEW_ONLY_FIELDS,
      lineLevelFields: CONTRACT.LINE_LEVEL_FIELDS,
      headerLevelFields: CONTRACT.HEADER_LEVEL_FIELDS,
      groupingFieldKeys: CONTRACT.GROUPING_FIELD_KEYS,
    });

    this._validator = new BaseValidator({
      requiredFields: CONTRACT.REQUIRED_FIELDS,
    });
  }
}
