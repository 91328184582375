import { getPaginationEntries } from '@/utils/pagination';

import { TABLE_KEY } from '@/pages/PurchaseOrder/constants';

import getterTypes from './constants/getters';

import { statusKeyToTableCellText } from '@/store/modules/myOrders/constants/table.js';

export default {
  [getterTypes.CURRENT_PAGE_PO_LINES]: ({
    purchaseOrder, pagination,
  }) => {
    const { lineItems } = purchaseOrder;
    const { pageSize, pageNumber } = pagination[TABLE_KEY.LINES_TABLE];

    const lineItemsWithStatus = lineItems.map(line => ({ ...line, status: purchaseOrder.status }));

    return getPaginationEntries(lineItemsWithStatus, pageNumber, pageSize);
  },

  [getterTypes.CURRENT_GROUPED_PAGE_PO_LINES]: ({
    purchaseOrder, pagination,
  }) => {
    const { lineItems } = purchaseOrder;
    const { pageSize, pageNumber } = pagination[TABLE_KEY.GROUPED_TABLES];

    return getPaginationEntries(lineItems, pageNumber, pageSize);
  },

  [getterTypes.GET_FORMATTED_HEADER_TABLE_DATA]: ({ purchaseOrder }) => {
    return [{
      ...purchaseOrder,
      status: statusKeyToTableCellText[purchaseOrder.status],
    }];
  },

  [getterTypes.EXPANDED_GROUPS]: ({ groups }) => {
    return groups.filter(({ expanded }) => expanded);
  },

  [getterTypes.SELECTED_GROUP_ID]: ({ groups }) => {
    return groups.find(({ selected }) => selected)?.id;
  },

  [getterTypes.HEADER_CASES]: ({ purchaseOrder }) => {
    const { cases } = purchaseOrder;

    if (!cases) {
      return;
    }

    return cases.filter(obj => ((obj.caseLevel === 'HEADER')));
  },

  [getterTypes.LINE_CASES]: ({ purchaseOrder }) => {
    const { cases } = purchaseOrder;

    if (!cases) {
      return;
    }

    return cases.filter(obj => ((obj.caseLevel === 'LINE')));
  },

  [getterTypes.NO_CASES_SELECTED]: ({ groupedSelectedCases }) => {
    return !groupedSelectedCases.length;
  },

  hasUpdatedValues: ({ resolvedExceptions }) => {
    const hasUpdates = resolvedExceptions.some(exceptionCase => {
      return exceptionCase.wrongfulValues.some(({ value, fixedValue }) => {
        return fixedValue && value !== fixedValue;
      });
    });

    return hasUpdates;
  },
};
