import mutationTypes from './constansts/mutationTypes';

const {
  SET_PENDING_FILES,
  SET_RECENTLY_UPLOADED_FILES,
  SET_UPLOADED_FILE_IDS_TO_SELECT,
  SET_SUCCESSFULLY_PROCESSED_FILE_IDS,
} = mutationTypes;

export default {
  [SET_RECENTLY_UPLOADED_FILES](state, recentlyUploadedFiles) {
    state.recentlyUploadedFiles = recentlyUploadedFiles;
  },

  [SET_PENDING_FILES](state, pendingFiles) {
    state.pendingFiles = pendingFiles;
  },

  [SET_SUCCESSFULLY_PROCESSED_FILE_IDS](state, successfullyProcessedFileIds) {
    state.successfullyProcessedFileIds = successfullyProcessedFileIds;
  },

  [SET_UPLOADED_FILE_IDS_TO_SELECT](state, uploadedFileIdsToSelect) {
    state.uploadedFileIdsToSelect = uploadedFileIdsToSelect;
  },
};
