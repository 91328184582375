import mutationTypes from '../constansts/mutationTypes';

import * as actionsEnum from '../constansts/actions';

const {
  RESET_SELECTED_ORDER_IDS,
  SET_SELECTED_ORDER_TYPE_ID,
  RESET_SELECTED_ISSUE_TYPE_ID,
  RESET_SELECTED_ORDER_TYPE_ID,
} = actionsEnum;

export default {
  [SET_SELECTED_ORDER_TYPE_ID]: ({
    commit, dispatch, state,
  }, selectedOrderTypeId) => {
    dispatch(RESET_SELECTED_ORDER_IDS);
    dispatch(RESET_SELECTED_ISSUE_TYPE_ID);

    if (selectedOrderTypeId === state.selectedOrderTypeId) {
      dispatch(RESET_SELECTED_ORDER_TYPE_ID);
    } else {
      commit(mutationTypes.SET_SELECTED_ORDER_TYPE_ID, selectedOrderTypeId);
    }
  },

  [RESET_SELECTED_ORDER_TYPE_ID]: ({ commit }) => {
    commit(mutationTypes.SET_SELECTED_ORDER_TYPE_ID, null);
  },
};
