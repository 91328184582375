import { sortBy } from 'lodash';
import { columnDefs } from '@/pages/Exceptions/grid/constants.ts';
import { filterTypes } from '@/utils/globalFilters/index.js';
import { lockedColumnsNames } from '@/pages/Exceptions/grid/constants';
import exceptionsRepository from '@/api/now/exceptions';

import { mapFieldName } from '@/utils/fieldsMapper.ts';

export const parseCases = (exceptionCases) => {
  const parsedCases = [...exceptionCases];
  const columnDefsMap = new Map();

  columnDefs.forEach((columnDef) => {
    columnDefsMap.set(columnDef.field, columnDef);
  });

  exceptionCases.forEach((exceptionCase, index) => {
    parsedCases[index]['Case Type'] = exceptionCase.caseType;

    exceptionCase.wrongfulValues.forEach((wrongfulValue) => {
      const mappedFieldName = mapFieldName(wrongfulValue.description, wrongfulValue.name);
      wrongfulValue.value = wrongfulValue?.value || '';
      parsedCases[index][mappedFieldName] = { ...wrongfulValue };

      columnDefsMap.set(wrongfulValue.name, {
        field: mappedFieldName,
        headerName: mappedFieldName,
      });
    });

    exceptionCase.decisionCriteria.forEach((decisionCriteria) => {
      const hasWrongfulValue = !!parsedCases[index][decisionCriteria.description];
      const mappedFieldName = mapFieldName(decisionCriteria.description, decisionCriteria.name);

      // add decision criteria only if it doesn't have a wrongful value with the same field
      if (!hasWrongfulValue) {
        parsedCases[index][mappedFieldName] = decisionCriteria.value;
      }

      columnDefsMap.set(decisionCriteria.name, {
        ...columnDefsMap.get(decisionCriteria.description),
        field: mappedFieldName,
        headerName: mappedFieldName,
      });
    });
  });

  return { parsedCases, columnDefs: [...columnDefsMap.values()] };
};

export const reorderLockedPreferences = (preferences) => {
  const reorderedPreferences = sortArrayByLockedColumnNames(preferences);

  return reorderedPreferences.map(preference => {
    if (lockedColumnsNames.includes(preference.id)) {
      preference.locked = true;
    }

    return preference;
  });
};

export const adjustColumnDefs = (columnDefs, userPreferences) => {
  const columnDefsByKey = new Map();

  columnDefs.forEach((columnDef) => {
    columnDefsByKey.set(columnDef.field, columnDef);
  });

  const userPreferencesByKey = new Map();

  if (userPreferences) {
    userPreferences.forEach((userPreference) => {
      userPreferencesByKey.set(userPreference.id, userPreference);
    });
  }

  const newColumns = columnDefs.filter(({ field }) => !userPreferencesByKey.has(field));

  newColumns.forEach(({ headerName, field }) => {
    userPreferencesByKey.set(field, {
      id: field, name: headerName, visible: true,
    });
  });

  const updatedUserPreferences = [...userPreferencesByKey.values()];
  const hasNewColumns = !!newColumns.length;

  const parsedColumnDefs = updatedUserPreferences.reduce((acc, preference) => {
    const columnDef = columnDefsByKey.get(preference.id);

    if (columnDef) {
      const columnField = columnDef.field?.toLowerCase();

      if (columnField?.includes('quantity')) {
        // TODO: Move atomic class inside a BEM class
        columnDef.cellClass = 'exception-details__cell exception-details__cell--quantity';
      }

      acc.push({ ...columnDef, hide: !preference.visible });
    }

    return acc;
  }, []);

  sortArrayByLockedColumnNames(parsedColumnDefs);

  return {
    parsedColumnDefs, hasNewColumns, updatedUserPreferences,
  };
};

export const getTypesByGroups = (groups, types) => {
  return groups.map(({ displayName, identifier }) => {
    const groupTypes = types.filter(({ group }) => identifier === group);

    return {
      displayName, identifier, groupTypes,
    };
  });
};

export const parseManageExceptionsFilters = (filterName, selectedFilters) => {
  if (selectedFilters.length) {
    const multiple = selectedFilters.length > 1;
    const operator = multiple ? filterTypes.IN : filterTypes.EQUALS;
    const valueProperty = multiple ? 'values' : 'value';

    return {
      column: filterName,
      operator,
      [valueProperty]: multiple ? selectedFilters : selectedFilters[0],
    };
  }
};

export const getCaseTypesDisplayNames = (caseGroups) => {
  const caseTypesDisplayNames = {};

  caseGroups.forEach(({ caseTypes }) => {
    caseTypes.forEach(({ type, displayName }) => {
      caseTypesDisplayNames[type] = displayName || type;
    });
  });

  return caseTypesDisplayNames;
};

export async function getRelatedOrders(cases, mappedCases, getters) {
  const orderIds = cases.map(exception => exception.orderId);

  const requestPayload = {
    cases: mappedCases,
    filters: getters.caseFilters.filters,
    pageSize: 100,
  };

  const multiple = orderIds.length > 1;
  const valueProperty = multiple ? 'values' : 'value';
  const filters = [
    {
      operator: 'OR',
      filters: [
        {
          column: 'OFOA_ID',
          operator: orderIds.length > 1 ? 'IN' : 'CONTAINS',
          [valueProperty]: multiple ? orderIds : orderIds[0],
        },
      ],
    },
  ];

  const selectedOrdersPos = await exceptionsRepository.fetchRelatedOrders({ ...requestPayload, filters: [...filters, ...getters.caseFilters.filters] });
  const excludedPos = await exceptionsRepository.fetchRelatedOrders({ ...requestPayload });

  return [
    ...selectedOrdersPos.data.orders.map(order => ({ ...order, isDefault: true })),
    ...excludedPos.data.orders.filter(({ ofoaId }) => !orderIds.includes(ofoaId)),
  ];
}

function sortArrayByLockedColumnNames(array) {
  return sortBy(array, preference => {
    const preferenceIndex = lockedColumnsNames.indexOf(preference.id);

    return preferenceIndex !== -1 ? preferenceIndex : array.length;
  });
}
