// FILTER PANEL
const SET_FETCHED_ORDERS = 'SET_FETCHED_ORDERS';
const SET_SELECTED_FILE_ID = 'SET_SELECTED_FILE_ID';
const SET_SELECTED_ORDER_IDS = 'SET_SELECTED_ORDER_IDS';
const SET_IS_ORDERS_FETCHING = 'SET_IS_ORDERS_FETCHING';

// ISSUE TYPES FILTER
const SET_ISSUE_TYPES_CATEGORIES = 'SET_ISSUE_TYPES_CATEGORIES';
const SET_SELECTED_ISSUE_TYPES_FILTERS = 'SET_SELECTED_ISSUE_TYPES_FILTERS';
const SET_SELECTED_ISSUE_TYPES_CATEGORY = 'SET_SELECTED_ISSUE_TYPES_CATEGORY';
const SET_IS_ISSUE_TYPES_CATEGORIES_FETCHING = 'SET_IS_ISSUE_TYPES_CATEGORIES_FETCHING';
const SET_IS_ONLY_LINES_WITH_ISSUES_FILTER_SELECTED = 'setIsOnlyLinesWithIssuesFilterSelected';

// GRID
const SET_GRID_API = 'SET_GRID_API';
const SET_COLUMN_API = 'SET_COLUMN_API';
const SET_GRID_CREATOR = 'SET_GRID_CREATOR';
const SET_CACHED_LINES = 'SET_CACHED_LINES';
const SET_FROM_LINE_ID = 'SET_FROM_LINE_ID';
const SET_FROM_ORDER_ID = 'SET_FROM_ORDER_ID';
const SET_MODIFIED_LINES = 'SET_MODIFIED_LINES';
const SET_FROM_COLUMN_NAME = 'SET_FROM_COLUMN_NAME';
const SET_SEARCH_DIRECTION = 'SET_SEARCH_DIRECTION';
const SET_SIZE_COLUMNS_TO_ADD = 'SET_SIZE_COLUMNS_TO_ADD';
const SET_HAS_NEXT_PAGE_WITH_LINES = 'SET_HAS_NEXT_PAGE_WITH_LINES';
const SET_PRODUCT_ENGINES_WITH_SIZES = 'SET_PRODUCT_ENGINE_WITH_SIZES';

// ISSUE NAVIGATOR
const SET_BE_ISSUES = 'SET_BE_ISSUES';
const SET_UI_ISSUES = 'SET_UI_ISSUES';
const SET_ORDER_ISSUES = 'SET_ORDER_ISSUES';
const SET_FIELD_MAPPING = 'SET_FIELD_MAPPING';
const SET_SELECTED_ISSUE = 'SET_SELECTED_ISSUE';
const SET_FIXED_BE_ISSUES = 'SET_FIXED_BE_ISSUES';
const SET_IS_LINES_SAVING = 'SET_IS_LINES_SAVING';
const SET_IS_ISSUES_LOADING = 'SET_IS_ISSUES_LOADING';
const SET_TOTAL_ISSUES_COUNT = 'SET_TOTAL_ISSUES_COUNT';
const SET_IS_LINES_SUBMITTING = 'SET_IS_LINES_SUBMITTING';
const SET_IS_ALL_ISSUES_LOADED = 'SET_IS_ALL_ISSUES_LOADED';
const INCREASE_TOTAL_ISSUES_COUNT = 'INCREASE_TOTAL_ISSUES_COUNT';
const DECREASE_TOTAL_ISSUES_COUNT = 'DECREASE_TOTAL_ISSUES_COUNT';
const SET_LINES_VALIDATION_STATUS = 'SET_LINES_VALIDATION_STATUS';
const SET_IS_ISSUE_NAVIGATOR_OPEN = 'SET_IS_ISSUE_NAVIGATOR_OPEN';
const SET_IS_DUPLICATE_LINES_AVAILABLE = 'SET_IS_DUPLICATE_LINES_AVAILABLE';
const SET_IS_KEY_GROUPING_FIELD_CHANGED = 'SET_IS_KEY_GROUPING_FIELD_CHANGED';

export default {
  SET_GRID_API,
  SET_BE_ISSUES,
  SET_UI_ISSUES,
  SET_COLUMN_API,
  SET_ORDER_ISSUES,
  SET_CACHED_LINES,
  SET_GRID_CREATOR,
  SET_FROM_LINE_ID,
  SET_FROM_ORDER_ID,
  SET_MODIFIED_LINES,
  SET_FIELD_MAPPING,
  SET_FETCHED_ORDERS,
  SET_SELECTED_ISSUE,
  SET_FIXED_BE_ISSUES,
  SET_IS_LINES_SAVING,
  SET_FROM_COLUMN_NAME,
  SET_SELECTED_FILE_ID,
  SET_SEARCH_DIRECTION,
  SET_IS_ISSUES_LOADING,
  SET_SELECTED_ORDER_IDS,
  SET_IS_ORDERS_FETCHING,
  SET_TOTAL_ISSUES_COUNT,
  SET_IS_LINES_SUBMITTING,
  SET_SIZE_COLUMNS_TO_ADD,
  SET_IS_ALL_ISSUES_LOADED,
  SET_ISSUE_TYPES_CATEGORIES,
  INCREASE_TOTAL_ISSUES_COUNT,
  DECREASE_TOTAL_ISSUES_COUNT,
  SET_LINES_VALIDATION_STATUS,
  SET_IS_ISSUE_NAVIGATOR_OPEN,
  SET_HAS_NEXT_PAGE_WITH_LINES,
  SET_PRODUCT_ENGINES_WITH_SIZES,
  SET_IS_DUPLICATE_LINES_AVAILABLE,
  SET_SELECTED_ISSUE_TYPES_FILTERS,
  SET_SELECTED_ISSUE_TYPES_CATEGORY,
  SET_IS_KEY_GROUPING_FIELD_CHANGED,
  SET_IS_ISSUE_TYPES_CATEGORIES_FETCHING,
  SET_IS_ONLY_LINES_WITH_ISSUES_FILTER_SELECTED,
};
