import { GridOptions, ColDef } from '@ag-grid-community/core';

import LinesOrderIndicatorRenderer from '@/components/ui/AgGridTable/CellRenderers/LinesOrderIndicatorRenderer.vue';

import {
  unSortIcon,
  filterIcon,
  sortDesIcon,
  sortAscIcon,
} from '@/components/ui/AgGridTable/icons';

import colors from '@/enums/colors';

import { translateModule } from '@/utils/string/translate';

import { getCommonColDef } from '@/pages/PurchaseOrder/utils';
import { ExceptionStatus } from '@/pages/PurchaseOrder/constants';

import { RendererParams } from './types';

import { FIELDS_ENUM } from '@/enums/language/fieldsEnum';

const translateFields = translateModule('fields');

export const COLUMN_KEYS = {
  INDICATOR: 'indicator',
  MATERIAL_NUMBER: 'materialNumber',
  CRD: 'crd',
  CANCEL_DATE: 'cancelDate',
  SIZE: 'size',
  QUANTITY: 'quantity',
  USAGE_CODE: 'usageCode',
  QUALITY_CODE: 'quality',
  INVENTORY_SEGMENTATION_CODE: 'ISEG',
  PLANT_CODE: 'plantCode',
  CONTRACT_NUMBER: 'contractNumber',
  UNIT_ALLOTMENT: 'unitAllotment',
  INVOICE_NUMBER: 'invoiceNumber',
  VAT_RATE: 'vatRate',
  SHIPMENT_TYPE_CODE: 'shipmentTypeCode',
  PROPOSED_DELIVERY_DATE: 'proposedDeliveryDate',
  STOCK_TRANSFER_REASON_CODE: 'stockTransferReasonCode',
  VENDOR_PRODUCT_CODE: 'vendorProductCode',
  DELIVERY_UNIT: 'deliveryUnit',
  BUYERS_SIZE: 'buyersSize',
  BUYERS_SIZE_DESCRIPTION: 'buyersSizeDescription',
  MSR_INNER_PACK_QUANTITY: 'musicalSizeInnerPackQuantity',
  MSR_RATIO: 'musicalSizeRunRation',
  MSR_QUANTITY: 'musicalSizeRunQuantity',
  MSR_FLAG: 'musicalSizeRunFlag',
  GTIN: 'gtin',
  VAS_CODE: 'vasCodes',
  VAS_CHARGE_CODE: 'vasChargeCode',
  VAS_LONG_TEXT: 'vasLongText',
  VAS_FIELD1: 'vasField1',
  VAS_FIELD2: 'vasField2',
  VAS_FIELD3: 'vasField3',

  SALES_ORDER_NUMBER: 'referenceDocumentNumber',
  REFERENCE_DOCUMNET_LINE_ITEM: 'referenceDocumentItemNumber',
  MATERIAL_SILHOUETEE: 'silhouetteDescription',
  LEAGUE: 'leagueDescription',
  MATERIAL_ITEM_GROUP: 'materialItemGroup',
  MANUAL_VAT_PERCENTAGE: 'manualVATPercentage',
  GENDER_AGE: 'genderAgeDescription',
  GLOBAL_CATEGORY: 'categoryCode',
  DIVISION_PRODUCT_ENGINE: 'divisionDescription',
  STOCK_TRANSFER_DOCUMENT_NUMBER: 'referenceDocumentNumber',
  STOCK_TRANSFER_DOCUMENT_ITEM_NUMBER: 'referenceDocumentItemNumber',
  DESTINATION_PLANT_CODE: 'destinationPlantCode',
};

export const VAS_FIELDS = {
  VAS_CHARGE_CODE: COLUMN_KEYS.VAS_CHARGE_CODE,
  VAS_LONG_TEXT: COLUMN_KEYS.VAS_LONG_TEXT,
  VAS_FIELD1: COLUMN_KEYS.VAS_FIELD1,
  VAS_FIELD2: COLUMN_KEYS.VAS_FIELD2,
  VAS_FIELD3: COLUMN_KEYS.VAS_FIELD3,
};

export const COLUMN_DATE_KEYS = [
  COLUMN_KEYS.CANCEL_DATE,
];

export const columnDefs: ColDef[] = [
  {
    field: COLUMN_KEYS.INDICATOR,
    headerName: '',
    width: 36,
    maxWidth: 36,
    filter: false,
    resizable: false,
    cellRenderer: LinesOrderIndicatorRenderer,
    cellClass: 'purchase-orders-table__cell purchase-orders-table__cell--indicator purchase-orders-table__cell--flex',
    cellClassRules: {
      indicatorRenderer: () => true,
      indicatorRendererSuccess: ({ data }: RendererParams) => data.status === ExceptionStatus.ACKNOWLEDGED.toUpperCase(),
      indicatorRendererException: ({ data }: RendererParams) => {
        return Object.values(data).some(value => {
          if (typeof value !== 'object' || !value.orderDetailStatus) return false;

          return value.orderDetailStatus === ExceptionStatus.INCORRECT.toUpperCase();
        });
      },
    },
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.MATERIAL_NUMBER }),
    headerName: translateFields(FIELDS_ENUM.materialNumber),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.CRD, isRightAligned: true }),
    headerName: translateFields(FIELDS_ENUM.crdLineLevel),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.CANCEL_DATE, isRightAligned: true }),
    headerName: translateFields(FIELDS_ENUM.cancelDateLineLevel),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.SIZE }),
    headerName: translateFields(FIELDS_ENUM.size),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.QUANTITY, isRightAligned: true }),
    headerName: translateFields(FIELDS_ENUM.orderItemQuantity),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.USAGE_CODE }),
    headerName: translateFields(FIELDS_ENUM.usageCode),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.QUALITY_CODE }),
    headerName: translateFields(FIELDS_ENUM.qualityCode),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.INVENTORY_SEGMENTATION_CODE }),
    headerName: translateFields(FIELDS_ENUM.iseg),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.PLANT_CODE }),
    headerName: translateFields(FIELDS_ENUM.plantCode),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.CONTRACT_NUMBER }),
    headerName: translateFields(FIELDS_ENUM.contractNumber),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.UNIT_ALLOTMENT }),
    headerName: translateFields(FIELDS_ENUM.unitAllotment),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.INVOICE_NUMBER }),
    // TODO: maybe change to smth else - lgorka
    headerName: translateFields(FIELDS_ENUM.invoiceNumber),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.VAT_RATE }),
    headerName: translateFields(FIELDS_ENUM.vatRate),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.BUYERS_SIZE }),
    headerName: translateFields(FIELDS_ENUM.buyersSize),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.BUYERS_SIZE_DESCRIPTION }),
    headerName: translateFields(FIELDS_ENUM.buyersSizeDescription),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.MSR_INNER_PACK_QUANTITY, isRightAligned: true }),
    headerName: translateFields(FIELDS_ENUM.msrInnerPackQuantity),
    width: 300,
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.MSR_RATIO }),
    headerName: translateFields(FIELDS_ENUM.msrRatio),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.MSR_QUANTITY, isRightAligned: true }),
    headerName: translateFields(FIELDS_ENUM.msrQuantity),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.MSR_FLAG }),
    headerName: translateFields(FIELDS_ENUM.msrFlag),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.MATERIAL_SILHOUETEE }),
    headerName: translateFields(FIELDS_ENUM.materialSilhouette),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.LEAGUE }),
    headerName: translateFields(FIELDS_ENUM.league),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.MATERIAL_ITEM_GROUP }),
    headerName: translateFields(FIELDS_ENUM.materialItemGroup),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.MANUAL_VAT_PERCENTAGE }),
    headerName: translateFields(FIELDS_ENUM.vatRate),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.GENDER_AGE }),
    headerName: translateFields(FIELDS_ENUM.genderAge),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.SALES_ORDER_NUMBER }),
    headerName: translateFields(FIELDS_ENUM.sapOrderNumber),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.REFERENCE_DOCUMNET_LINE_ITEM }),
    headerName: translateFields(FIELDS_ENUM.contractLineItemNumber),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.GLOBAL_CATEGORY }),
    headerName: translateFields(FIELDS_ENUM.category),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.DIVISION_PRODUCT_ENGINE }),
    headerName: translateFields(FIELDS_ENUM.division),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.GTIN }),
    headerName: translateFields('gtin'),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.VAS_CODE }),
    headerName: translateFields('vasCode'),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.VAS_CHARGE_CODE }),
    headerName: translateFields('vasChargeCode'),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.VAS_LONG_TEXT }),
    headerName: translateFields('vasLongText'),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.VAS_FIELD1 }),
    headerName: translateFields('vasField1'),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.VAS_FIELD2 }),
    headerName: translateFields('vasField2'),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.VAS_FIELD3 }),
    headerName: translateFields('vasField3'),
  },
];

export const columnDefsForSTO: ColDef[] = [
  ...columnDefs,
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.SHIPMENT_TYPE_CODE }),
    headerName: translateFields(FIELDS_ENUM.shipmentTypeCode),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.PROPOSED_DELIVERY_DATE, isRightAligned: true }),
    headerName: translateFields(FIELDS_ENUM.proposedDeliveryDate),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.STOCK_TRANSFER_REASON_CODE }),
    headerName: translateFields(FIELDS_ENUM.stockTransferReasonCode),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.STOCK_TRANSFER_DOCUMENT_NUMBER }),
    headerName: translateFields(FIELDS_ENUM.stoNumber),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.STOCK_TRANSFER_DOCUMENT_ITEM_NUMBER }),
    headerName: translateFields(FIELDS_ENUM.stoLineItemNumber),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.VENDOR_PRODUCT_CODE }),
    headerName: translateFields(FIELDS_ENUM.vendorProductCode),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.DELIVERY_UNIT }),
    headerName: translateFields(FIELDS_ENUM.deliveryUnit),
  },
  {
    ...getCommonColDef({ columnKey: COLUMN_KEYS.DESTINATION_PLANT_CODE }),
    headerName: translateFields('destinationPlantCode'),
  },
];

export const gridOptions: GridOptions = {
  animateRows: true,
  suppressMenuHide: true,
  suppressCellFocus: true,
  enableCellTextSelection: true,
  rowClass: 'purchase-orders-table__row',
  rowHeight: 50,
  defaultColDef: {
    resizable: true,
    sortable: true,
    unSortIcon: false,
    wrapText: true,
    autoHeight: true,
    cellClass: 'purchase-orders-table__cell',
    suppressMovable: true,
  },
  icons: {
    menu: filterIcon(colors.podiumGrey1),
    sortUnSort: unSortIcon(),
    sortAscending: sortAscIcon(),
    sortDescending: sortDesIcon(),
  },
};
