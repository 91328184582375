import { uniqBy, reverse } from 'lodash';

import * as gettersEnum from '../constansts/getters';

import { orderStatuses } from '@/enums/order/orderStatus';

const {
  GET_FILENAMES, GET_SEARCH_TEXT, GET_PROCESSING_FILENAMES, GET_SELECTED_FILENAME_IDS,
} = gettersEnum;

export default {
  [GET_FILENAMES]: (
    _,
    {
      getProcessedFiles, getProcessingFilenames, getParsedNotSucceededFiles,
    },
  ) => {
    return reverse(
      uniqBy(
        reverse([...getProcessingFilenames, ...getProcessedFiles, ...getParsedNotSucceededFiles]),
        'fileId',
      ),
    );
  },

  getProcessedFiles(_, { getSucceededFiles }) {
    return getSucceededFiles.map(({
      fileName, fileId, hasIssues, orders,
    }) => {
      return {
        fileId,
        fileName,
        hasIssues,
        isSentFailed: orders?.map((order) => order.status).includes(orderStatuses.SENT_FAILED),
      };
    });
  },

  [GET_PROCESSING_FILENAMES](state, getters, rootState, rootGetters) {
    const processingFiles = rootGetters['massOrderCreation/fileUpload/getFilesInProgress'];

    return processingFiles.map(({ fileName, fileId }) => ({
      fileId,
      fileName,
    }));
  },

  getParsedNotSucceededFiles(_, { getNotSucceededFiles }) {
    return getNotSucceededFiles.map(({ originalName, fileId }) => ({
      fileId,
      fileName: originalName,
    }));
  },

  [GET_SELECTED_FILENAME_IDS]: ({ selectedFilenameIds }) => selectedFilenameIds || [],

  [GET_SEARCH_TEXT]: ({ searchText }) => searchText || '',
};
