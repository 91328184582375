export const mockHeaderSTOFields = [
  {
    id: 'purchaseOrderDocumentTypeCode',
    name: 'Customer PO Document Code',
    visible: true,
  },
  {
    id: 'supplierNodeTypeCode',
    name: 'Supplier Node Type Code',
    visible: true,
  },
];

export const mockLinesSTOFields = [
  {
    id: 'shipmentTypeCode',
    name: 'Shipment Type Code',
    visible: true,
  },
  {
    id: 'proposedDeliveryDate',
    name: 'Proposed Delivery Date',
    visible: true,
  },
  {
    id: 'stockTransferReasonCode',
    name: 'Stock Transfer Reason Code',
    visible: true,
  },
  {
    id: 'referenceDocumentNumber',
    name: 'STO Number',
    visible: true,
  },
  {
    id: 'referenceDocumentItemNumber',
    name: 'STO Line Item Number',
    visible: true,
  },
  {
    id: 'vendorProductCode',
    name: 'Vendor Product Code',
    visible: true,
  },
  {
    id: 'deliveryUnit',
    name: 'Delivery Unit',
    visible: true,
  },
];
