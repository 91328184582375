// TODO: add all keys here, make this file source of truth for rule keys
export default {
  REQUIRED: 'required',
  PRODUCT_CODE_GTIN_REQUIRED: 'productCodeGtinRequired',
  MIN_LENGTH: 'minLength',
  MAX_LENGTH: 'maxLength',
  ALPHA_NUMERIC: 'alphaNumeric',
  MATERIAL_NUMBER: 'materialNumber',
  NUMERIC_NO_LIMIT: 'numericNoLimit',
  MAX_ARRAY_LENGTH: 'maxArrayLength',
  // DEPENDENCIES: 'dependencies',
};
