import mutationTypes from './constansts/mutationsTypes';

const {
  SET_GRID_API,
  SET_BE_ISSUES,
  SET_UI_ISSUES,
  SET_COLUMN_API,
  SET_FROM_LINE_ID,
  SET_GRID_CREATOR,
  SET_ORDER_ISSUES,
  SET_CACHED_LINES,
  SET_FROM_ORDER_ID,
  SET_FIELD_MAPPING,
  SET_FETCHED_ORDERS,
  SET_MODIFIED_LINES,
  SET_SELECTED_ISSUE,
  SET_FIXED_BE_ISSUES,
  SET_IS_LINES_SAVING,
  SET_FROM_COLUMN_NAME,
  SET_SELECTED_FILE_ID,
  SET_SEARCH_DIRECTION,
  SET_IS_ISSUES_LOADING,
  SET_SELECTED_ORDER_IDS,
  SET_IS_ORDERS_FETCHING,
  SET_TOTAL_ISSUES_COUNT,
  SET_IS_LINES_SUBMITTING,
  SET_SIZE_COLUMNS_TO_ADD,
  SET_IS_ALL_ISSUES_LOADED,
  SET_ISSUE_TYPES_CATEGORIES,
  INCREASE_TOTAL_ISSUES_COUNT,
  DECREASE_TOTAL_ISSUES_COUNT,
  SET_LINES_VALIDATION_STATUS,
  SET_IS_ISSUE_NAVIGATOR_OPEN,
  SET_HAS_NEXT_PAGE_WITH_LINES,
  SET_PRODUCT_ENGINES_WITH_SIZES,
  SET_IS_DUPLICATE_LINES_AVAILABLE,
  SET_SELECTED_ISSUE_TYPES_FILTERS,
  SET_SELECTED_ISSUE_TYPES_CATEGORY,
  SET_IS_KEY_GROUPING_FIELD_CHANGED,
  SET_IS_ISSUE_TYPES_CATEGORIES_FETCHING,
  SET_IS_ONLY_LINES_WITH_ISSUES_FILTER_SELECTED,
} = mutationTypes;

export default {
  [SET_SELECTED_FILE_ID](state, selectedFileId) {
    state.selectedFileId = selectedFileId;
  },

  [SET_SELECTED_ORDER_IDS](state, orderIds) {
    state.selectedOrderIds = [...orderIds];
  },

  [SET_FETCHED_ORDERS](state, fetchedOrders = []) {
    state.fetchedOrders = fetchedOrders;
  },

  [SET_IS_ORDERS_FETCHING](state, isOrdersFetching) {
    state.isOrdersFetching = isOrdersFetching;
  },

  [SET_GRID_CREATOR](state, gridCreator) {
    state.gridCreator = gridCreator;
  },

  [SET_GRID_API](state, gridApi) {
    state.gridApi = gridApi;
  },

  [SET_IS_ISSUE_NAVIGATOR_OPEN](state, isIssueNavigatorOpen) {
    state.isIssueNavigatorOpen = isIssueNavigatorOpen;
  },

  [SET_SELECTED_ISSUE](state, selectedIssue) {
    state.selectedIssue = selectedIssue ? { ...selectedIssue } : null;
  },

  [SET_ISSUE_TYPES_CATEGORIES](state, issueTypesCategories) {
    state.issueTypesCategories = issueTypesCategories;
  },

  [SET_SELECTED_ISSUE_TYPES_FILTERS](state, selectedIssueTypesFilters) {
    state.selectedIssueTypesFilters = selectedIssueTypesFilters;
  },

  [SET_SELECTED_ISSUE_TYPES_CATEGORY](state, selectedIssueTypesCategory) {
    state.selectedIssueTypesCategory = selectedIssueTypesCategory;
  },

  [SET_IS_ISSUE_TYPES_CATEGORIES_FETCHING](state, isIssueTypesCategoriesFetching) {
    state.isIssueTypesCategoriesFetching = isIssueTypesCategoriesFetching;
  },

  [SET_FIELD_MAPPING](state, fieldMapping) {
    state.fieldMapping = fieldMapping;
  },

  [SET_IS_ALL_ISSUES_LOADED](state, isAllIssuesLoaded) {
    state.isAllIssuesLoaded = isAllIssuesLoaded;
  },

  [SET_IS_ISSUES_LOADING](state, isIssuesLoading) {
    state.isIssuesLoading = isIssuesLoading;
  },

  [SET_FROM_LINE_ID](state, fromLineId) {
    state.fromLineId = fromLineId;
  },

  [SET_FROM_ORDER_ID](state, fromOrderId) {
    state.fromOrderId = fromOrderId;
  },

  [SET_FROM_COLUMN_NAME](state, fromColumnName) {
    state.fromColumnName = fromColumnName;
  },

  [SET_COLUMN_API](state, columnApi) {
    state.columnApi = columnApi;
  },

  [SET_SEARCH_DIRECTION](state, searchDirection) {
    state.searchDirection = searchDirection;
  },

  [SET_HAS_NEXT_PAGE_WITH_LINES](state, hasNextPageWithLines) {
    state.hasNextPageWithLines = hasNextPageWithLines;
  },

  [SET_CACHED_LINES](state, cachedLines) {
    state.cachedLines = cachedLines;
  },

  [SET_MODIFIED_LINES](state, modifiedLines) {
    state.modifiedLines = modifiedLines;
  },

  [SET_IS_LINES_SAVING](state, isLinesSaving) {
    state.isLinesSaving = isLinesSaving;
  },

  [SET_IS_LINES_SUBMITTING](state, isLinesSubmitting) {
    state.isLinesSubmitting = isLinesSubmitting;
  },

  [SET_LINES_VALIDATION_STATUS](state, linesValidationStatus) {
    state.linesValidationStatus = linesValidationStatus;
  },

  [SET_ORDER_ISSUES](state, orderIssues) {
    state.orderIssues = orderIssues;
  },

  [SET_FIXED_BE_ISSUES](state, fixedBeIssues) {
    state.fixedBeIssues = fixedBeIssues;
  },

  [SET_TOTAL_ISSUES_COUNT](state, count) {
    state.totalIssuesCount = count;
  },

  [INCREASE_TOTAL_ISSUES_COUNT](state) {
    state.totalIssuesCount = ++state.totalIssuesCount;
  },

  [DECREASE_TOTAL_ISSUES_COUNT](state) {
    state.totalIssuesCount = --state.totalIssuesCount;
  },

  [SET_IS_ONLY_LINES_WITH_ISSUES_FILTER_SELECTED](state, isOnlyLinesWithIssuesFilterSelected) {
    state.isOnlyLinesWithIssuesFilterSelected = isOnlyLinesWithIssuesFilterSelected;
  },

  [SET_BE_ISSUES](state, beIssues) {
    state.beIssues = beIssues;
  },

  [SET_UI_ISSUES](state, uiIssues) {
    state.uiIssues = uiIssues;
  },

  [SET_IS_KEY_GROUPING_FIELD_CHANGED](state, isKeyGroupingFieldChanged) {
    state.isKeyGroupingFieldChanged = isKeyGroupingFieldChanged;
  },

  [SET_SIZE_COLUMNS_TO_ADD](state, sizeColumnsToAdd) {
    state.sizeColumnsToAdd = sizeColumnsToAdd;
  },

  [SET_PRODUCT_ENGINES_WITH_SIZES](state, productEnginesWithSizes) {
    state.productEnginesWithSizes = productEnginesWithSizes;
  },

  [SET_IS_DUPLICATE_LINES_AVAILABLE](state, hasDuplicateLinesError) {
    state.hasDuplicateLinesError = hasDuplicateLinesError;
  },
};
