import { parseProductionSupportFilters } from "@/utils/globalFilters";

const filtersSelection = {
  getRequestFilterParams: ({ appliedFilterFields, pageSize, pageNumber, pageTokens, region }) => {
    const paginationToken =
      pageTokens.length >= pageNumber ? pageTokens[pageNumber] : pageTokens[pageTokens.length - 1];

    const filters = [
      ...parseProductionSupportFilters(appliedFilterFields),
      { key: "region", value: region },
    ];

    return {
      pageSize,
      pageNumber,
      // sortBy: sortedColumnName,
      // sortOrder: sortedColumnOrder,
      filters,
      paginationToken,
    };
  },
};

export default {
  getFormatedOrdersData: ({ orderList }) => orderList,
  getFormatedOrderDetailData: ({ orderDetail }) => {
    let orderDetailData = [];
    orderDetail.forEach((module) => {
      module.validations?.forEach((validation) => {
        orderDetailData.push({
          ...validation.validationstepData,
          validationstepName: validation.validationstepName,
          moduleName: module.moduleName,
        });
      });
    });

    return orderDetailData;
  },
  ...filtersSelection,
};
