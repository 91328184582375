import { difference } from 'lodash';

import { orderStatuses } from '@/enums/order/orderStatus';

import * as gettersEnum from '../constansts/getters';

const {
  GET_DEFINED_ORDERS,
  GET_SELECTED_ORDERS,
  GET_SELECTED_ORDER_IDS,
  GET_IS_ALL_ORDERS_SELECTED,
  GET_GROUPED_UNDEFINED_ORDERS,
  GET_ORDERS_OF_SELECTED_ORDER_TYPE,
} = gettersEnum;

export default {
  [GET_SELECTED_ORDER_IDS]: ({ selectedOrderIds }) => selectedOrderIds || [],

  [GET_ORDERS_OF_SELECTED_ORDER_TYPE]: (state, { getOrdersOfSelectedFilenames }) => {
    return getOrdersOfSelectedFilenames.filter(
      ({ orderType: { id } }) => id === state.selectedOrderTypeId,
    );
  },

  [GET_DEFINED_ORDERS]: (_, { getOrdersOfSelectedOrderType }) => {
    return getOrdersOfSelectedOrderType.filter(({ hasUngroupedLines }) => !hasUngroupedLines);
  },

  getUndefinedOrders: (_, { getOrdersOfSelectedOrderType }) => {
    return getOrdersOfSelectedOrderType.filter(({ hasUngroupedLines }) => hasUngroupedLines);
  },

  [GET_GROUPED_UNDEFINED_ORDERS]: (_, { getUndefinedOrders }) => {
    return getUndefinedOrders.reduce(
      (acc, order) => {
        acc.groupedIds.push(order.id);
        acc.isSentFailed = order.status === orderStatuses.SENT_FAILED;

        acc.issues = {
          totalCount: acc.issues.totalCount + order.issues.totalCount,
          types: [...acc.issues.types, ...order.issues.types],
        };

        return acc;
      },
      {
        groupedIds: [],
        issues: {
          totalCount: 0,
          types: [],
        },
        text: 'Not grouped lines',
      },
    );
  },

  [GET_IS_ALL_ORDERS_SELECTED]: (_, { getOrdersOfSelectedOrderType, getSelectedOrderIds }) => {
    return getOrdersOfSelectedOrderType.length
      ? !difference(
        getOrdersOfSelectedOrderType.map(({ id }) => id),
        getSelectedOrderIds,
      ).length
      : false;
  },

  [GET_SELECTED_ORDERS]: (_, { getSelectedOrderIds, getOrdersOfSelectedOrderType }) => {
    return getOrdersOfSelectedOrderType.filter(({ id }) => getSelectedOrderIds.includes(id));
  },
};
