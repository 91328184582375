const myOrdersData = {
  SET_MY_ORDERS: 'SET_MY_ORDERS',
  SET_ORDER_TYPES: 'SET_ORDER_TYPES',
  SET_IS_ORDERS_EMPTY: 'SET_IS_ORDERS_EMPTY',

  SET_IS_FETCHING_ORDERS: 'SET_IS_FETCHING_ORDERS',

  SET_TABLE_DATA_LOADING_ERROR: 'SET_TABLE_DATA_LOADING_ERROR',
  SET_FILTERS_DATA_LOADING_ERROR: 'SET_FILTERS_DATA_LOADING_ERROR',

  SET_MY_ORDERS_SUMMARY: 'SET_MY_ORDERS_SUMMARY',

  SET_MY_ORDERS_ERRORS: 'SET_MY_ORDERS_ERRORS',
  SET_IS_MY_ORDERS_ERROR_LOADING: 'SET_IS_MY_ORDERS_ERROR_LOADING',
};

const filtersSelection = {
  SET_SELECTED_ORDER_TYPES: 'SET_SELECTED_ORDER_TYPES',
  SET_SELECTED_ORDER_OWNERSHIP: 'SET_SELECTED_ORDER_OWNERSHIP',
};

const myOrdersTable = {
  SET_GRID_API: 'SET_GRID_API',
  SET_TABLE_PARAMS: 'SET_TABLE_PARAMS',
  SET_DATA_SOURCE: 'SET_DATA_SOURCE',

  SET_COLUMN_DEF: 'SET_COLUMN_DEF',

  SET_ORDER_ID: 'SET_ORDER_ID',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_PAGE_TOKEN: 'SET_PAGE_TOKEN',
  RESET_PAGE_TOKEN: 'RESET_PAGE_TOKEN',

  SET_SORTED_COLUMN_NAME: 'SET_SORTED_COLUMN_NAME',
  SET_SORTED_COLUMN_ORDER: 'SET_SORTED_COLUMN_ORDER',

  SET_TABLE_FILTERS: 'SET_TABLE_FILTERS',

  SET_TOTAL_AVAILABLE_ORDERS_NUMBER: 'SET_TOTAL_AVAILABLE_ORDERS_NUMBER',
  SET_TOTAL_AVAILABLE_PAGES_NUMBER: 'SET_TOTAL_AVAILABLE_PAGES_NUMBER',

  SET_USER_PREFERENCES: 'SET_USER_PREFERENCES',
};

export default {
  ...myOrdersData,
  ...myOrdersTable,
  ...filtersSelection,
};
