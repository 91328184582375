<template>
  <div :class="[baseClassName, statusColorClass]" />
</template>

<script setup>
import { computed, toRef } from 'vue';

import { ExceptionStatus } from '@/pages/PurchaseOrder/constants';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const baseClassName = 'indicator-renderer';

const caseData = toRef(props.params.data);

const isException = computed(() => {
  return Object.values(caseData.value)
    .some(({ orderDetailStatus }) => orderDetailStatus === ExceptionStatus.INCORRECT.toUpperCase());
});

const statusColorClass = computed(() => {
  if (caseData.value.status === ExceptionStatus.ACKNOWLEDGED.toUpperCase()) {
    return `${baseClassName}__success`;
  }

  return isException.value ? `${baseClassName}__exception` : '';
});
</script>
