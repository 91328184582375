import repository from "@/api/repositories/productionSupport";
import { PRODUCTION_SUPPORT } from "@/api/endpoints";

export default {
  fetchOrderSummary: (filters) => repository.get(PRODUCTION_SUPPORT.SUMMARY, { params: filters }),

  fetchOrderList: (statusOrType, filters) =>
    repository.get(`${PRODUCTION_SUPPORT.LIST}/${statusOrType}`, { params: filters }),

  fetchOrderStats: (filters) => repository.get(PRODUCTION_SUPPORT.ORDER_STATS, { params: filters }),

  fetchOrderDetail: (filters) => repository.get(PRODUCTION_SUPPORT.DETAIL, { params: filters }),

  fetchDownloadPayload: (params, headers) =>
    repository.get(PRODUCTION_SUPPORT.DOWNLOAD_PAYLOAD, { params, headers }),

  getOrderById: (orderId = "") => orderId && repository.get(`${ORDER.ORDERS}/${orderId}`),

  submitOrders: (orderIDs = []) => orderIDs.length && repository.post(ORDER.SUBMIT_ALL, orderIDs),

  saveOrders: (ordersToSave = []) =>
    ordersToSave.length && repository.post(ORDER.SAVE_ALL, ordersToSave),

  deleteOrders: (orderIDs = []) => orderIDs.length && repository.post(ORDER.DELETE, orderIDs),

  // ! Deprecated - API endpoint will be removed eventually
  getOrdersByFileNames: (fileNames = []) =>
    fileNames.length && repository.post(ORDER.GET_BY_FILE_NAMES, fileNames),

  getSOCDataFromServer() {
    repository.__addFilter(new RegExp(TEMPLATE.VALID_SINGLE_ORDER_TRAITS)); // adds caching for http calls when we expect non-dynamic data

    return repository.get(TEMPLATE.VALID_SINGLE_ORDER_TRAITS);
  },

  fetchUserActivityOverview: () => repository.get(LO_FILTERING.USER_ACTIVITY_OVERVIEW),

  fetchUserActivityOverviewByFilters: (filters) => {
    return repository.post(LO_FILTERING.USER_ACTIVITY_OVERVIEW, filters);
  },
};
