import { xor } from 'lodash';

import mutationTypes from '../constansts/mutationTypes';

import * as actionsEnum from '../constansts/actions';
import * as gettersEnum from '../constansts/getters';

import {
  deleteFile, fetchOrders, fetchNotSucceededFiles,
} from '@/api/now/massOrderCreation';

const {
  DELETE_FILE,
  FETCH_ORDERS,
  SET_SEARCH_TEXT,
  RESET_SELECTION,
  TOGGLE_FILTER_PANEL,
  RESET_SELECTED_ORDER_IDS,
  SET_SELECTED_FILENAME_IDS,
  RESET_SELECTED_FILENAME_IDS,
  RESET_SELECTED_ISSUE_TYPE_ID,
  RESET_SELECTED_ORDER_TYPE_ID,
  AUTOSELECT_RECENTLY_UPLOADED_FILES,
} = actionsEnum;

export default {
  [SET_SELECTED_FILENAME_IDS]({
    commit, getters, dispatch,
  }, selectedFilenameIds) {
    const savedSelectedFilenameIds = getters[gettersEnum.GET_SELECTED_FILENAME_IDS] || [];
    const recentlySelectedFilenameIds = Array.isArray(selectedFilenameIds)
      ? selectedFilenameIds
      : [selectedFilenameIds];

    dispatch(RESET_SELECTION);

    commit(
      mutationTypes.SET_SELECTED_FILENAME_IDS,
      xor(savedSelectedFilenameIds, recentlySelectedFilenameIds),
    );
  },

  [RESET_SELECTED_FILENAME_IDS]({ commit, dispatch }) {
    dispatch(RESET_SELECTION);

    commit(mutationTypes.SET_SELECTED_FILENAME_IDS, []);
  },

  [AUTOSELECT_RECENTLY_UPLOADED_FILES]({ dispatch }, fileIdsToSelect) {
    dispatch(RESET_SELECTED_FILENAME_IDS);
    dispatch(SET_SELECTED_FILENAME_IDS, fileIdsToSelect);
  },

  resetSelections({ dispatch }) {
    dispatch(RESET_SELECTED_ORDER_TYPE_ID);
    dispatch(RESET_SELECTED_ORDER_IDS);
    dispatch(RESET_SELECTED_ISSUE_TYPE_ID);
  },

  async [FETCH_ORDERS]({ commit }) {
    // TODO change to show loader only in filelist
    commit(mutationTypes.SET_IS_INITIAL_DATA_FETCHING, true);

    try {
      const { data } = await fetchOrders();

      commit(mutationTypes.SET_SUCCEEDED_FILES, data.files);
    } catch (err) {
      console.error(err);
    }

    commit(mutationTypes.SET_IS_INITIAL_DATA_FETCHING, false);
  },

  async [DELETE_FILE]({ commit, dispatch }, fileId) {
    // TODO change to show loader only in filelist
    // TODO Duplicaton of loaders with FETCH_ORDERS
    commit(mutationTypes.SET_IS_INITIAL_DATA_FETCHING, true);

    try {
      await deleteFile(fileId);

      await dispatch(FETCH_ORDERS);

      const { data } = await fetchNotSucceededFiles();

      commit(mutationTypes.SET_NOT_SUCCEEDED_FILES, data);
    } catch (err) {
      console.error(err);
    }

    commit(mutationTypes.SET_IS_INITIAL_DATA_FETCHING, false);
  },

  [TOGGLE_FILTER_PANEL]({ commit }) {
    commit(mutationTypes.SET_IS_FILTER_PANEL_VISIBLE);
  },

  [SET_SEARCH_TEXT]({ commit }, searchText) {
    commit(mutationTypes.SET_SEARCH_TEXT, searchText);
  },
};
