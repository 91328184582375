import { ICellRendererParams, ValueGetterParams } from '@ag-grid-community/core';
import { translateModule } from '@/utils/string/translate';
import { COLUMN_KEYS } from '@/pages/PurchaseOrder/Tables/LinesTable/GroupedExceptions/constants';

import { FIELDS_ENUM } from '@/enums/language/fieldsEnum';

import { mapFieldName } from '@/utils/fieldsMapper';

const translateFields = translateModule('fields');

export const hardcodedFields = {
  WRONGFUL_VALUES: 'wrongfulValues',
  CASE_TYPE: 'Case Type',
  CUSTOMER_PO_TYPE: 'Source System',
  CUSTOMER_PURCHASE_ORDER: 'Purchase Order Number',
  CUSTOMER_SHIP_TO: 'Ship To',
  CUSTOMER_SOLD_TO: 'Sold To',
  ORDER_CREATED_DATE: 'Create Date',
  REQUESTED_DELIVERY_DATE: 'CRD',
  SALES_ORDER_TYPE: 'Order Type',
  SALES_ORG_CODE: 'Sales Org',
  SALES_ORDER_NUMBER: 'SAP Order Number',
  CANCELLATION_DATE: 'Cancel Date',
};
interface wrongfulValueParams {
  value: string;
  description: string;
  name: string;
}

export const columnDefs = [
  {
    field: hardcodedFields.WRONGFUL_VALUES,
    headerName: translateFields(FIELDS_ENUM.wrongfulValues),
    wrapText: true,
    autoHeight: true,
    cellStyle: { wordBreak: 'normal' },
    lockPosition: true,
    cellClass: 'exception-details__cell exception-details__cell--error',
    valueGetter: (params: ValueGetterParams) => params.data.wrongfulValues
      .map(({ value, description, name }: wrongfulValueParams) => `${mapFieldName(description, name)}: ${value}`).join('; '),
    cellRenderer: (params: ICellRendererParams) => params.value.split(';').join('<br>'),
  },
  {
    field: hardcodedFields.CASE_TYPE,
    headerName: translateFields(FIELDS_ENUM.exceptionType),
    lockPosition: true,
  },
  {
    field: hardcodedFields.CUSTOMER_PO_TYPE,
    headerName: translateFields(FIELDS_ENUM.sourceSystem),
  },
  {
    field: hardcodedFields.CUSTOMER_PURCHASE_ORDER,
    headerName: translateFields(FIELDS_ENUM.purchaseOrderNumber),
  },
  {
    field: hardcodedFields.CUSTOMER_SHIP_TO,
    headerName: translateFields(FIELDS_ENUM.shipTo),
  },
  {
    field: hardcodedFields.CUSTOMER_SOLD_TO,
    headerName: translateFields(FIELDS_ENUM.soldTo),
  },
  {
    field: hardcodedFields.ORDER_CREATED_DATE,
    headerName: translateFields(FIELDS_ENUM.createDate),
  },
  {
    field: hardcodedFields.REQUESTED_DELIVERY_DATE,
    headerName: translateFields(FIELDS_ENUM.crd),
  },
  {
    field: hardcodedFields.SALES_ORDER_TYPE,
    headerName: translateFields(FIELDS_ENUM.orderType),
  },
  {
    field: hardcodedFields.SALES_ORG_CODE,
    headerName: translateFields(FIELDS_ENUM.salesOrg),
  },
  {
    field: hardcodedFields.SALES_ORDER_NUMBER,
    headerName: translateFields(FIELDS_ENUM.sapOrderNumber),
  },
  {
    field: hardcodedFields.CANCELLATION_DATE,
    headerName: translateFields(FIELDS_ENUM.cancelDate),
  },
];

export const checkboxDef = {
  width: 50,
  maxWidth: 50,
  resizable: false,
  field: COLUMN_KEYS.SELECTED,
  headerName: '',
  lockPosition: true,
  checkboxSelection: true,
  headerCheckboxSelection: true,
  sortable: false,
  cellClass: 'exception-details__checkboxes',
};

export const lockedColumnsNames = columnDefs.filter(column => column.lockPosition).map(lockedColumn => lockedColumn.field);
