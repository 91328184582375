import {
  FILE,
  ORDER,
  TEMPLATE,
  DUPLICATES,
  ISSUE_DETAILS,
  ISSUE_OVERVIEW,
  GET_ORDER_TYPE_MAPPING,
} from '@/api/endpoints';
import repository from '@/api/repositories/serverRepository';

export function fetchOrders(fileIds) {
  return repository.post(ISSUE_OVERVIEW.GET_ISSUES, { fileIds } || {});
}

export function fetchOrderTypesMapping() {
  return repository.get(GET_ORDER_TYPE_MAPPING);
}

export function fetchOrderById(orderId) {
  return repository.get(`${ORDER.ORDERS}/${orderId}`);
}

export function deleteOrders(orderIds) {
  return repository.post(ORDER.DELETE, orderIds);
}

export function fetchFileProcessStatus(fileIds) {
  return repository.post(FILE.FILE_PROCESS_STATUS, fileIds);
}

export function fetchNotSucceededFiles() {
  return repository.get(FILE.GET_NOT_SUCCEEDED_FILES);
}

export function deleteFile(fileId) {
  return repository.delete(`${FILE.DELETE_FILE}/${fileId}`);
}

export function sendOrders(orderIds = []) {
  return repository.post(ORDER.SUBMISSION, orderIds);
}

export function getSubmissionById(submissionId) {
  return repository.get(`${ORDER.SUBMISSION}/${submissionId}`);
}

export function getSubmissionDetailsById(submissionId) {
  return repository.get(`${ORDER.SUBMISSION}/${submissionId}/details`);
}

export function saveLines(linesDetails = {}) {
  return repository.post(ISSUE_DETAILS.SAVE_LINES, linesDetails);
}

export function getOrderIssueTypes() {
  return repository.get(ISSUE_OVERVIEW.GET_ORDER_ISSUE_TYPES);
}

export function fetchIssueTypeFilterCounts(fileId, orderIds = []) {
  const body = orderIds.length ? { fileId, orderIds } : { fileId };

  return repository.post(ISSUE_DETAILS.GET_ISSUE_TYPES_FILTER_COUNTS, body);
}
export function fetchLines(params) {
  return repository.post(ISSUE_DETAILS.GET_LINES, params);
}

export function fetchFieldMapping() {
  return repository.get(TEMPLATE.FIELD_MAPPING);
}

/**
 * Param of this function is expected to be of this structure
 * [
 *  {
 *    nowFileId: 1,
 *    orderIds: [1, 2, 3]
 *  },
 *  {
 *    nowFileId: 2,
 *    orderIds: [4]
 *  },
 * ]
 */
export function fetchDuplicates(params) {
  return repository.post(DUPLICATES.GET_DUPLICATES_FOR_MULTIPLE, params);
}

export function saveDuplicates(params) {
  return repository.post(DUPLICATES.DUPLICATES, params);
}

export default {
  fetchOrders,
  fetchOrderTypesMapping,
  fetchOrderById,
  deleteOrders,
  fetchFileProcessStatus,
  fetchNotSucceededFiles,
  deleteFile,
  sendOrders,
  getSubmissionById,
  getSubmissionDetailsById,
  saveLines,
  getOrderIssueTypes,
  fetchIssueTypeFilterCounts,
  fetchLines,
  fetchFieldMapping,
  fetchDuplicates,
  saveDuplicates,
};
