import state from './exceptions.state';
import getters from './exceptions.getters';
import actions from './exceptions.actions';
import mutations from './exceptions.mutations';

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
