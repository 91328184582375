import mutations from './constants/mutations';

export default {
  [mutations.SET_GRID_API](state, { tableId, gridApi }) {
    state.gridApi[tableId] = gridApi;
  },

  [mutations.SET_ORDER_ID](state, data) {
    state.orderId = data;
  },

  [mutations.SET_PURCHASE_ORDER](state, data) {
    state.purchaseOrder = data;
  },

  [mutations.INIT_GRID_PAGINATION](state, tableId) {
    state.pagination[tableId] = {
      pageSize: 25,
      pageNumber: 0,
    };
  },

  [mutations.SET_PAGE_SIZE](state, { tableId, pageSize }) {
    state.pagination[tableId].pageSize = pageSize;
  },

  [mutations.SET_PAGE_NUMBER](state, { tableId, pageNumber }) {
    state.pagination[tableId].pageNumber = pageNumber;
  },

  [mutations.SET_USER_PREFERENCES](state, { tableId, userPreferences }) {
    state.userPreferences[tableId] = userPreferences;
  },

  [mutations.SET_GROUPS](state, groups) {
    state.groups = groups;
  },

  [mutations.SET_EXCEPTIONS_GROUPED](state, exceptionsGrouped) {
    state.exceptionsGrouped = exceptionsGrouped;
  },

  [mutations.SET_GROUPED_SELECTED_CASES](state, selectedCases) {
    state.groupedSelectedCases = selectedCases;
  },

  [mutations.SET_RESOLVED_EXCEPTIONS](state, resolvedExceptions) {
    state.resolvedExceptions = resolvedExceptions;
  },

  [mutations.SET_CASES_RELOADING](state, casesReloading) {
    state.casesReloading = casesReloading;
  },

  [mutations.DATA_FAILED_TO_LOAD](state, failed) {
    state.dataFailedToLoad = failed;
  },

  [mutations.SET_SELECTED_GROUP_ID](state, selectedGroupId) {
    state.selectedGroupId = selectedGroupId;
  },
};
