import { isDateField } from '@/utils/dates';
import { ACTIONS } from '@/pages/Exceptions/constants.js';

export function adjustCases(selectedCases) {
  return selectedCases.reduce((acc, singleCase) => {
    if (!singleCase.caseAction) return acc;

    const isUpdateAction = singleCase.caseAction === 'UPDATE';

    if (isUpdateAction) {
      const hasUpdatedWrongfulValues = singleCase.wrongfulValues
        .some(wrongfulValue => checkIsValueUpdated(wrongfulValue));

      if (!hasUpdatedWrongfulValues) return acc;
    }

    const wrongfulValues = singleCase.wrongfulValues.map(wrongfulValue => {
      const updatedWrongfulValue = { ...wrongfulValue };

      const hasUpdatedValue = checkIsValueUpdated(wrongfulValue);

      if (isDateField(wrongfulValue && updatedWrongfulValue.fixedValue !== undefined && updatedWrongfulValue.fixedValue !== null)) {
        updatedWrongfulValue.fixedValue = `${updatedWrongfulValue.fixedValue}T00:00:00.000Z`;
      }

      if (isUpdateAction && !hasUpdatedValue) {
        updatedWrongfulValue.action = ACTIONS.REPROCESS;
      }

      if (updatedWrongfulValue.action !== ACTIONS.UPDATE) {
        delete updatedWrongfulValue.fixedValue;
      }

      return updatedWrongfulValue;
    });

    acc.push({ ...singleCase, wrongfulValues });

    return acc;
  }, []);
}

function checkIsValueUpdated(wrongfulValue) {
  return wrongfulValue.fixedValue && wrongfulValue.fixedValue !== wrongfulValue.value;
}
