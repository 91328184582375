import { ICellRendererParams, ValueGetterParams } from '@ag-grid-community/core';
import { translateModule } from '@/utils/string/translate';

import { mapFieldName } from '@/utils/fieldsMapper';

import { FIELDS_ENUM } from '@/enums/language/fieldsEnum';

const translateFields = translateModule('fields');

export const COLUMN_KEYS = {
  SELECTED: 'selected',
  WRONGFUL_VALUE: 'wrongfulValue',
  CASE_TYPE: 'caseType',
  CUSTOMER_PO_TYPE: 'customerPOType',
  CUSTOMER_PO_TYPE_CODE: 'customerPOTypeCode',
  CUSTOMER_PURCHASE_ORDER: 'customerPurchaseOrder',
  CUSTOMER_SHIP_TO: 'shipToNumber',
  CUSTOMER_SOLD_TO: 'soldToNumber',
  ORDER_CREATED_DATE: 'createdAt',
  ORDER_SUBMIT_DATE: 'orderSubmitDate',
  REQUESTED_DELIVERY_DATE: 'crd',
  SALES_ORDER_TYPE: 'orderType',
  SALES_ORG_CODE: 'salesOrg',
  BUSINESS_TYPE_CODE: 'businessType',
  CANCELLATION_DATE: 'cancelDate',
  COMPANY_CODE: 'companyCode',
  CONTRACT_CUSTOMER_PO_NUMBER: 'contractCustomerPONumber',
  CONTRACT_VALID_END_DATE: 'contractValidEndDate',
  CONTRACT_VALID_START_DATE: 'contractValidStartDate',
  CUSTOMER_BUSINESS_TYPE_CODE: 'customerBusinessTypeCode',
  CUSTOMER_CHANNEL_CODE: 'customerChannelCode',
  CUSTOMER_DISTRIBUTION_CANNEL: 'customerDistributionChannel',
  CUSTOMER_DIVISION: 'customerDivision',
  CUSTOMER_PO_DOCUMENT_CODE: 'customerPODocumentCode',
  DEPARTMENT_CODE: 'departmentCode',
  DESTINATION_CODE: 'destinationCode',
  DISTRIBUTION_CHANNEL: 'distributionChannel',
  EDI_PO_TYPE: 'ediPOType',
  INACTIVE_CUSTOMER: 'inactiveCustomer',
  INACTIVE_CUSTOMER_SOLD_TO: 'inactiveCustomerSoldTo',
  INTERNAL_ORDER_REFERENCE_NUMBER: 'internalOrderReferenceNumber',
  MARK_FOR: 'markFor',
  ORDER_CANCELLATION_DATE: 'orderCancellationDate',
  REQUESTED_DELIVERY_DATE_HEADER: 'requestedDeliveryDateHeader',
  RSO_INIVOICE_ITEM_NUMBER: 'RSOInvoiceItemNumber',
  RSO_REASON_CODE: 'RSOReasonCode',
  SALES_ORDER_BLOCK: 'salesOrderBlock',
  SHIPPING_CONDITION_CODE: 'shippingConditionCode',
  STOCK_TRANSFER_REASON_CODE: 'stockTransferReasonCode',
  BUYERS_CATALOG_NUMBER: 'buyersCatalogNumber',
  BUYERS_SIZE: 'buyersSize',
  BUYERS_SIZE_DESCRIPTION: 'buyersSizeDescription',
  BUYERS_STYLE_DESCRIPTION: 'buyersStyleDescription',
  BUYERS_STYLE_ID: 'buyersStyleId',
  DELIVERY_UNIT: 'deliveryUnit',
  DESTINATION_PLANT_CODE: 'destinationPlantCode',
  DIVISION_PRODUCT_ENGINE: 'divisionProductEngineItem',
  GENDER_AGE: 'genderAge',
  GLOBAL_CATEGORY: 'globalCategory',
  GTIN: 'GTIN',
  INERNAL_VENDOR_NUMBER: 'internalVendorNumber',
  ITEM_CANCELLATION_DATE: 'itemCancellationDate',
  MATERIAL_PRODUCT_ID: 'materialProductID',
  MATERIAL_DESCRIPTION: 'materialDescription',
  MATERIAL_ITEM_GROUP: 'materialItemGroup',
  ORDER_ITEM_QUANTITY_SIZE: 'orderItemQuantitySize',
  PLANT: 'plantCode',
  PRODUCT_CODE: 'productCode',
  PRODUCT_REQUESTED_DELIVERY_DATE: 'productRequestedDeliveryDate',
  PROPOSED_DELIVERY_DATE: 'proposedDeliveryDate',
  REASON_CODE: 'reasonCode',
  REFERENCE_DOCUMENT_ITEM_NUMBER: 'referenceDocumentItemNumber',
  REFERENCE_DOCUMENT_NUMBER: 'referenceDocumentNumber',
  REFERENCED_CONTRACT_CHECK_PARTNER: 'referencedContractCheckPartner',
  REFERENCED_CONTRACT_SOLD_TO: 'referencedContractSoldTo',
  REQUIREMENT_SEGMENT: 'requirementSegment',
  REQUIREMENT_SEGMENT_CODE: 'requirementSegmentCode',
  ROUTE_NUMBER: 'routeNumber',
  SIZE: 'size',
  STOCK_TRANSPORT_REASON_CODE: 'stockTransportReasonCode',
  SUPPLIER_NUMBER: 'supplierNumber',
  USAGE_INDICATOR: 'usageIndicator',
  VARIANT_MATERIAL: 'variantMaterial',
  VENDOR_PRODUCT_CODE: 'vendorProductCode',
  VENDOR_STYLE_DESCRIPTION: 'vendorStyleDescription',
  STOCK_TRANSFER_VAS1: 'stockTransferVAS1',
  STOCK_TRANSFER_VAS2: 'stockTransferVAS2',
  VAS_FIELD_TEXT3: 'vasFieldText3',
  VAS_FIELD3: 'vasField3',
  VAS_LONG_TEXT: 'vasLongText',
  VAS_TYPE_CODE: 'vasTypeCode',
  MANUAL_VAT_PERCENTAGE: 'manualVATPercentage',
  VAT_REGISTRATION_COUNTRY_CODE: 'vatRegistrationCountryCode',
  CASE_ID: 'caseId',
  SOURCE_UNIQUE_ID: 'sourceUniqueId',
  SALES_ORGANIZATION: 'salesOrganization',
  SALES_ORDER_NUMBER: 'salesOrderNumber',
  SHIPMENT_TYPE_CODE: 'shipmentTypeCode',
  MSR_INNER_PACK_QUANTITY: 'musicalSizeInnerPackQuantity',
  MSR_RATIO: 'musicalSizeRunRation',
  MSR_QUANTITY: 'musicalSizeRunQuantity',
  MSR_FLAG: 'musicalSizeRunFlag',
  MATERIAL_SILHOUETEE: 'silhouetteDescription',
  LEAGUE: 'leagueDescription',
};

export const hardcodedFields = {
  WRONGFUL_VALUES: 'wrongfulValues',
  CASE_TYPE: 'Case Type',
  CUSTOMER_PO_TYPE: 'Customer PO Type',
  CUSTOMER_PURCHASE_ORDER: 'Customer Purchase Order',
  CUSTOMER_SHIP_TO: 'Customer Ship To',
  MATERIAL_PRODUCT_ID: 'Material (Product ID)',
  CUSTOMER_SOLD_TO: 'Customer Sold To',
  ORDER_CREATED_DATE: 'Order Created Date',
  ORDER_ITEM_QUANTITY_SIZE: 'Order Item Quantity (Size)',
  REQUESTED_DELIVERY_DATE: 'Requested Delivery Date',
  RSO_INIVOICE_ITEM_NUMBER: 'RSO Invoice Item Number',
  RSO_REASON_CODE: 'RSO Reason Code',
  SALES_ORDER_TYPE: 'Sales Order Type',
  SALES_ORG_CODE: 'Sales Org Code',
  SALES_ORDER_NUMBER: 'Sales Order Number',
  SIZE: 'Size',
  CANCELLATION_DATE: 'Cancellation Date',
};

export const COLUMN_DATE_KEYS = [
  COLUMN_KEYS.ORDER_CREATED_DATE,
  COLUMN_KEYS.ORDER_SUBMIT_DATE,
  COLUMN_KEYS.REQUESTED_DELIVERY_DATE,
  COLUMN_KEYS.CANCELLATION_DATE,
  COLUMN_KEYS.CONTRACT_VALID_END_DATE,
  COLUMN_KEYS.CONTRACT_VALID_START_DATE,
  COLUMN_KEYS.ORDER_CANCELLATION_DATE,
  COLUMN_KEYS.REQUESTED_DELIVERY_DATE_HEADER,
  COLUMN_KEYS.ITEM_CANCELLATION_DATE,
  COLUMN_KEYS.PRODUCT_REQUESTED_DELIVERY_DATE,
  COLUMN_KEYS.PROPOSED_DELIVERY_DATE,
];

export const UNDELETABLE_COLUMN_KEYS = [
  COLUMN_KEYS.WRONGFUL_VALUE,
];

interface wrongfulValueParams {
  value: string;
  description: string;
  name: string;
}

export const columnDefs = [
  {
    width: 50,
    maxWidth: 50,
    resizable: false,
    field: COLUMN_KEYS.SELECTED,
    headerName: '',
    cellClass: 'purchase-orders-table__cell purchase-orders-table__cell--flex',
    checkboxSelection: (params: ValueGetterParams) => params.data.selectable,
    showDisabledCheckboxes: true,
    headerCheckboxSelection: true,
    sortable: false,
  },
  {
    field: hardcodedFields.WRONGFUL_VALUES,
    headerName: translateFields(FIELDS_ENUM.wrongfulValues),
    wrapText: true,
    autoHeight: true,
    cellStyle: { wordBreak: 'normal' },
    cellClass: 'grouped-lines__cell--error purchase-orders-table__cell',
    valueGetter: (params: ValueGetterParams) => params.data.wrongfulValues
      .map(({ value, description, name }: wrongfulValueParams) => `${mapFieldName(description, name)}: ${value}`)
      .join('; '),
    cellRenderer: (params: ICellRendererParams) => params.value.split(';').join('<br>'),
  },
  {
    field: hardcodedFields.CASE_TYPE,
    headerName: translateFields(FIELDS_ENUM.exceptionType),
  },
  {
    field: hardcodedFields.CUSTOMER_PO_TYPE,
    headerName: translateFields(FIELDS_ENUM.sourceSystem),
  },
  {
    field: hardcodedFields.CUSTOMER_PURCHASE_ORDER,
    headerName: translateFields(FIELDS_ENUM.purchaseOrderNumber),
  },
  {
    field: hardcodedFields.CUSTOMER_SHIP_TO,
    headerName: translateFields(FIELDS_ENUM.shipTo),
  },
  {
    field: hardcodedFields.CUSTOMER_SOLD_TO,
    headerName: translateFields(FIELDS_ENUM.soldTo),
  },
  {
    field: hardcodedFields.MATERIAL_PRODUCT_ID,
    headerName: translateFields(FIELDS_ENUM.materialNumber),
  },
  {
    field: hardcodedFields.ORDER_CREATED_DATE,
    headerName: translateFields(FIELDS_ENUM.cancelDateLineLevel),
    cellClass: 'purchase-orders-table__cell purchase-orders-table__cell--rightAlign',
    type: 'rightAligned',
  },
  {
    field: hardcodedFields.ORDER_ITEM_QUANTITY_SIZE,
    headerName: translateFields(FIELDS_ENUM.orderItemQuantity),
    cellClass: 'purchase-orders-table__cell purchase-orders-table__cell--quantity',
    type: 'rightAligned',
  },
  {
    field: hardcodedFields.REQUESTED_DELIVERY_DATE,
    headerName: translateFields(FIELDS_ENUM.crdLineLevel),
    cellClass: 'purchase-orders-table__cell purchase-orders-table__cell--rightAlign',
    type: 'rightAligned',
  },
  {
    field: hardcodedFields.SALES_ORDER_TYPE,
    headerName: translateFields(FIELDS_ENUM.orderType),
  },
  {
    field: hardcodedFields.RSO_INIVOICE_ITEM_NUMBER,
    headerName: translateFields(FIELDS_ENUM.contractLineItemNumber),
  },
  {
    field: hardcodedFields.RSO_REASON_CODE,
    headerName: translateFields(FIELDS_ENUM.reasonCode),
  },
  {
    field: hardcodedFields.SALES_ORG_CODE,
    headerName: translateFields(FIELDS_ENUM.salesOrg),
  },
  {
    field: hardcodedFields.SALES_ORDER_NUMBER,
    headerName: translateFields(FIELDS_ENUM.sapOrderNumber),
  },
  {
    field: hardcodedFields.SIZE,
    headerName: translateFields(FIELDS_ENUM.size),
  },
  {
    field: hardcodedFields.CANCELLATION_DATE,
    headerName: translateFields(FIELDS_ENUM.cancelDate),
    cellClass: 'purchase-orders-table__cell purchase-orders-table__cell--rightAlign',
    type: 'rightAligned',
  },
];

export const gridOptions = {
  animateRows: true,
  suppressMenuHide: true,
  suppressCellFocus: true,
  enableCellTextSelection: true,
  rowClass: 'purchase-orders-table__row',
  rowHeight: 50,
  defaultColDef: {
    sortable: true,
    cellClass: 'purchase-orders-table__cell',
    suppressMovable: true,
    wrapText: true,
    autoHeight: true,
    valueGetter: (params: ValueGetterParams) => {
      const field = params.colDef.field as string;

      if (typeof params.data[field] === 'string') {
        return params.data[field];
      }

      return params.data[field]?.value;
    },
  },
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
};

export const defaultColDef = {
  sortable: true,
  cellClass: 'purchase-orders-table__cell',
  suppressMovable: true,
  wrapText: true,
  autoHeight: true,
  resizable: true,
  valueGetter: (params: ValueGetterParams) => {
    const field = params.colDef.field as string;

    if (typeof params.data[field] === 'string') {
      return params.data[field];
    }

    return params.data[field]?.value;
  },
};
