import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

// TODO: We need better way of determining order types. UPD: Use /enums/order/orderType enums.
export function isReturnOrderType(orderType = '') {
  return orderType.includes('Return');
}

export function determineCancelDateMinDate(orderType = '') {
  return isReturnOrderType(orderType) ? 30 : 6;
}

export function determineDaysTypeName(fieldName, orderType) {
  if (isReturnOrderType(orderType) && fieldName === fieldNames.crd) {
    return ' business days ';
  }

  return ' days ';
}

export function determineBusinessDaysDiff(firstDate, secondDate) {
  return firstDate.businessDiff(secondDate, 'days');
}

export function determineCalendarDaysDiff(firstDate, secondDate) {
  return firstDate.diff(secondDate, 'days');
}
