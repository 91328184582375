import { isEmpty } from 'lodash';

import {
  ADD_ISSUE,
  REMOVE_ISSUE,
  SET_GRID_API,
  REPLACE_ISSUE,
  VALIDATE_VALUE,
  SET_COLUMN_API,
  SET_GRID_CREATOR,
  START_EDITING_CELL,
} from '@/store/modules/massOrderCreation/L2/constansts/actions';

import {
  GET_ISSUES,
  GET_FETCHED_ORDERS,
} from '@/store/modules/massOrderCreation/L2/constansts/getters';

import mutationTypes from '@/store/modules/massOrderCreation/L2/constansts/mutationsTypes.js';

import BaseCreator from '@/store/modules/massOrderCreation/L2/gridCreators/BaseCreator';
import ContractCreator from '@/store/modules/massOrderCreation/L2/gridCreators/Contract';
import StandardCreator from '@/store/modules/massOrderCreation/L2/gridCreators/Standard';
import ReturnSalesCreator from '@/store/modules/massOrderCreation/L2/gridCreators/ReturnSales';
import FreeOfChargeCreator from '@/store/modules/massOrderCreation/L2/gridCreators/FreeOfCharge';
import FreeOfChargeReturnsSales from '@/store/modules/massOrderCreation/L2/gridCreators/FreeOfChargeReturnsSales';

import { FIELD_NAMES } from '@/enums/fieldNames';

export default {
  [SET_GRID_CREATOR]({
    commit, getters, rootState, state,
  }) {
    const { selectedOrderTypeId } = rootState.massOrderCreation.L1;

    const Creator = determineCreatorType(selectedOrderTypeId);

    const lines = !isEmpty(getters[GET_FETCHED_ORDERS]) ? getters[GET_FETCHED_ORDERS] : [];
    const { sizeColumnsToAdd } = state;

    if (lines.length && Creator) {
      const creator = new Creator(lines, sizeColumnsToAdd);

      commit(mutationTypes.SET_GRID_CREATOR, creator);
    } else {
      commit(mutationTypes.SET_GRID_CREATOR, new BaseCreator({}));
    }
  },

  async [START_EDITING_CELL]({ state }, params) {
    const {
      rowId, isColumnIssue, colId,
    } = params;

    const { gridApi } = state;

    const rowIndex = gridApi.getRowNode(rowId)?.rowIndex;

    gridApi.ensureColumnVisible(colId);

    if (!isColumnIssue) {
      gridApi.ensureIndexVisible(rowIndex);
      gridApi.setFocusedCell(rowIndex, colId);
      gridApi.startEditingCell({ rowIndex, colKey: colId });
    }
  },

  [SET_GRID_API]({ commit }, gridApi) {
    commit(mutationTypes.SET_GRID_API, gridApi);
  },

  [SET_COLUMN_API]({ commit }, columnApi) {
    commit(mutationTypes.SET_COLUMN_API, columnApi);
  },

  [VALIDATE_VALUE]({ getters, dispatch }, gridParams) {
    const issues = getters[GET_ISSUES];
    const { rowId } = gridParams.data;
    const { colId } = gridParams.colDef;

    const issueIndex = issues[rowId]?.findIndex((issue) => issue.colId === colId);

    const isIssueFound = issueIndex !== undefined && issueIndex !== -1;

    const validationIssue = getters.getGridCreator._validator.validateValue(gridParams);

    if (!isIssueFound && validationIssue) {
      // Add new issue
      if (validationIssue.colId === colId) {
        dispatch(ADD_ISSUE, { rowId, validationIssue });
      } else {
        // This is a case for Golden Rules check for CRD and Valid From
        // if dependant fields is invalid, but main field is valid
        const dependantIssueIndex = issues[rowId]?.findIndex(
          (issue) => issue.colId === validationIssue.colId,
        );

        if (dependantIssueIndex >= 0) {
          dispatch(REPLACE_ISSUE, {
            dependantIssueIndex, validationIssue, rowId,
          });
        } else {
          dispatch(ADD_ISSUE, { rowId, validationIssue });
        }
      }
    } else if (isIssueFound && !validationIssue) {
      // Remove issue
      if (colId === FIELD_NAMES.CRD) {
        dispatch(REMOVE_ISSUE, { rowId, colId: FIELD_NAMES.CANCEL_DATE });
      } else if (colId === FIELD_NAMES.VALID_FROM_DATE) {
        dispatch(REMOVE_ISSUE, { rowId, colId: FIELD_NAMES.VALID_TO_DATE });
      } else if (colId === FIELD_NAMES.INVOICE_NUMBER || colId === FIELD_NAMES.REASON_CODE) {
        dispatch(REMOVE_ISSUE, { rowId, colId: FIELD_NAMES.INVOICE_NUMBER });
      }

      dispatch(REMOVE_ISSUE, { rowId, colId });
    } else if (isIssueFound && validationIssue) {
      // Replace existing issue for column
      dispatch(REPLACE_ISSUE, { validationIssue, rowId });

      if (
        (colId === FIELD_NAMES.CRD || colId === FIELD_NAMES.VALID_FROM_DATE)
        && colId !== validationIssue.colId
      ) {
        dispatch(REMOVE_ISSUE, { rowId, colId });
      }
    }

    // this is the case when CRD highlighted due to orderIssue and was fixed for cell
    if (!isIssueFound && colId === FIELD_NAMES.CRD && validationIssue?.colId !== colId) {
      dispatch('updateValidationForField', {
        field: colId,
        rowId,
      });
    }
  },
};

export function determineCreatorType(orderTypeId) {
  switch (orderTypeId) {
    case 1:
      return ContractCreator;
    case 3:
      return FreeOfChargeCreator;
    case 5: case 18:
      return StandardCreator;
    case 6:
      return ReturnSalesCreator;
    case 7: case 19:
      return FreeOfChargeReturnsSales;
    default:
  }
}
