import mutationTypes from '../constansts/mutationTypes';

import * as actionsEnum from '../constansts/actions';

import { deleteOrders } from '@/api/now/massOrderCreation';

import { showSuccessBanner } from '@/utils/notifications';

const {
  FETCH_ORDERS,
  SET_SEARCH_TEXT,
  DELETE_SELECTED_ORDERS,
  RESET_SELECTED_FILENAME_IDS,
  DELETE_ORDERS_WITH_ISSUES_ONLY,
} = actionsEnum;

const performDeleteOrders = async function(commit, dispatch, orderIds) {
  deleteOrders(orderIds)
    .then(() => {
      showSuccessBanner({
        title: 'Order Has Been Successfully Deleted',
      });

      dispatch(FETCH_ORDERS);
      dispatch(RESET_SELECTED_FILENAME_IDS);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      commit(mutationTypes.SET_IS_DELETE_ORDERS_IN_PROGRESS, false);
    });
};

export default {
  async [DELETE_SELECTED_ORDERS]({
    commit, dispatch, getters: { getSelectedOrderIds },
  }) {
    commit(mutationTypes.SET_IS_DELETE_ORDERS_IN_PROGRESS, true);

    dispatch(SET_SEARCH_TEXT, '');

    await performDeleteOrders(commit, dispatch, getSelectedOrderIds);
  },

  async [DELETE_ORDERS_WITH_ISSUES_ONLY]({
    commit,
    dispatch,
    getters: { getOrdersOfSelectedOrderType },
  }) {
    const orderIds = getOrdersOfSelectedOrderType
      .filter((order) => order.issues.totalCount || order.hasUngroupedLines)
      .map((order) => order.id);

    commit(mutationTypes.SET_IS_DELETE_ORDERS_IN_PROGRESS, true);

    dispatch(SET_SEARCH_TEXT, '');

    await performDeleteOrders(commit, dispatch, orderIds);
  },
};
