<template>
  <div :class="baseClassName">
    <Header
      :isDark="false"
      :email="employeeEmail"
      :hasSearch="route.meta.hasSearch"
      :maxSearchLength="maxSearchLength"
      :appName="$t('layout.header.logo')"
      :injectedSearchQuery="searchQuery"
      @search="handleSearch"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import { Header } from 'podium';

import { USER } from '@/store/moduleNames';
import routes from '@/router/routes';

const maxSearchLength = 185;

const store = useStore();
const route = useRoute();

const baseClassName = 'now-header';

const employeeEmail = computed(() => store.state[USER].user?.email || '');
const searchQuery = computed(() => store.state.globalFilters.searchQuery);
const separatedSearchQueries = computed(() => store.getters['globalFilters/separatedSearchQueries']);

const fetchOrders = debounce(() => {
  store.dispatch('myOrders/refreshDataSource');
  store.dispatch('dashboard/fetchUserActivityOverview');
}, 1000);

const fetchExceptions = debounce(() => {
  store.dispatch('exceptions/fetchExceptions');
}, 1000);

const handleSearch = currentQuery => {
  const previousQuery = searchQuery.value;
  const isRefresh = currentQuery === previousQuery;
  const isEmpty = currentQuery === '';

  if (isRefresh && isEmpty) return;

  const previousQueries = separatedSearchQueries.value;

  store.dispatch('globalFilters/setSearchQuery', currentQuery);

  const currentQueries = separatedSearchQueries.value;

  // Adding extra space or comma should not trigger API call
  if (isEqual(currentQueries, previousQueries)) return;

  if (route.path === routes.ROOT) {
    fetchOrders();
  } else if (route.path === routes.EXCEPTIONS && isRefresh) {
    fetchExceptions();
  }
};
</script>
