export const issueTypesMapper = {
  UNDEFINED: 'UNDEFINED',
  EMPTY_SIZES: 'EMPTY_SIZES',
  DUPLICATE_SIZE: 'DUPLICATE_SIZE',
  REQUIRED_FIELD: 'REQUIRED_FIELD',
  INCORRECT_VALUE: 'INCORRECT_VALUE',
  CRD_BEFORE_TODAY: 'CRD_BEFORE_TODAY',
  VAS_DUPLICATE_CODE: 'VAS_DUPLICATE_CODE',
  VAS_MISSING_COMMENT: 'VAS_MISSING_COMMENT',
  VAS_NO_MATCHING_CODE: 'VAS_NO_MATCHING_CODE',
  VALID_TO_UNSUPPORTED: 'VALID_TO_UNSUPPORTED',
  INCORRECT_FORMATTING: 'INCORRECT_FORMATTING',
  VAS_UNSUPPORTED_CODE: 'VAS_UNSUPPORTED_CODE',
  VAS_UNSUPPORTED_ORDER_TYPE: 'VAS_UNSUPPORTED_ORDER_TYPE',
  VAS_DUPLICATE_COMMENT: 'VAS_DUPLICATE_COMMENT',
  VALID_TO_BEFORE_TODAY: 'VALID_TO_BEFORE_TODAY',
  VALID_FROM_UNSUPPORTED: 'VALID_FROM_UNSUPPORTED',
  VALID_FROM_BEFORE_TODAY: 'VALID_FROM_BEFORE_TODAY',
  DUPLICATE_MATERIAL_SIZES: 'DUPLICATE_MATERIAL_SIZES',
  NON_UNIQUE_COLUMN_VALUES: 'NON_UNIQUE_COLUMN_VALUES',
  VAS_INVALID_COMMENT_FIELD: 'VAS_INVALID_COMMENT_FIELD',
  PRODUCT_ENGINE_UNIQUENESS: 'PRODUCT_ENGINE_UNIQUENESS',
  VALID_TO_BEFORE_VALID_FROM: 'VALID_TO_BEFORE_VALID_FROM',
  CRD_BEFORE_TODAY_PLUS_5_DAYS: 'CRD_BEFORE_TODAY_PLUS_5_DAYS',
  CANCEL_DATE_BEFORE_CRD_PLUS_5_DAYS: 'CANCEL_DATE_BEFORE_CRD_PLUS_5_DAYS',
  CANCEL_DATE_BEFORE_CRD_PLUS_30_DAYS: 'CANCEL_DATE_BEFORE_CRD_PLUS_30_DAYS',
  INVOICE_NUMBER_NOT_APPLICABLE: 'INVOICE_NUMBER_NOT_APPLICABLE',
};

export const issueTypesTextMapper = {
  UNDEFINED: 'Not grouped lines',
  EMPTY_SIZES: 'Empty sizes',
  DUPLICATE_SIZE: 'Duplicate size',
  REQUIRED_FIELD: 'Missing values',
  INCORRECT_VALUE: 'Incorrect value',
  CRD_BEFORE_TODAY: 'CRD before today',
  CANCEL_DATE_BEFORE_CRD: 'CANCEL DATE is before CRD',
  VAS_DUPLICATE_CODE: 'Vas code duplicated',
  VAS_MISSING_COMMENT: 'Missing vas comment',
  VAS_NO_MATCHING_CODE: 'Vas code no matching',
  VALID_TO_UNSUPPORTED: 'Valid to unsupported',
  INCORRECT_FORMATTING: 'Incorrect formatting',
  VAS_UNSUPPORTED_CODE: 'Vas code unsupported ',
  VAS_DUPLICATE_COMMENT: 'Vas comment duplicated',
  VAS_UNSUPPORTED_ORDER_TYPE: 'VAS code is not supported for this order type',
  VALID_TO_BEFORE_TODAY: 'Valid to before today',
  VALID_FROM_UNSUPPORTED: 'Valid from unsupported',
  VALID_FROM_BEFORE_TODAY: 'Valid from before today',
  DUPLICATE_MATERIAL_SIZES: 'Duplicate lines',
  NON_UNIQUE_COLUMN_VALUES:
    'Different values in the lines. All values in this column must be identical.',
  VAS_INVALID_COMMENT_FIELD: 'Vas comment field invalid',
  PRODUCT_ENGINE_UNIQUENESS: 'Size(s) do not match selected Product Engine(s)',
  VALID_TO_BEFORE_VALID_FROM: 'Valid to before valid from',
  CRD_BEFORE_TODAY_PLUS_5_DAYS: 'CRD before today plus 5 days',
  CANCEL_DATE_BEFORE_CRD_PLUS_5_DAYS: 'Cancel date before CRD plus 5 days',
  CANCEL_DATE_BEFORE_CRD_PLUS_30_DAYS: 'Cancel date before CRD plus 30 days',
  INVOICE_NUMBER_NOT_APPLICABLE: 'Not applicable for selected reason code',
};
