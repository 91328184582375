// It is duplication of singleOrderCreation/fieldNames.js file
// but I think we should move fieldNames to "global level" becuause
// they will be used by many pages (SOC, MOC, myOrders...)

// For now I will not remove old file becuase we have to many places which used it
export const FIELD_NAMES = {
  CRD: 'crd',
  CITY: 'city',
  NAME_1: 'name1',
  NAME_2: 'name2',
  REGION: 'region',
  COUNTRY: 'country',
  MARK_FOR: 'markFor',
  VAT_RATE: 'vatRate',
  STREET_1: 'street1',
  STREET_2: 'street2',
  DISTRICT: 'district',
  TELEPHONE: 'telephone',
  VAS_CODES: 'vasCodes',
  PLANT_CODE: 'plantCode',
  UNIT_ALLOTMENT: 'unitAllotment',
  USAGE_CODE: 'usageCode',
  CREATED_AT: 'createdAt',
  VAS_FIELD_1: 'vasField1',
  VAS_FIELD_2: 'vasField2',
  VAS_FIELD_3: 'vasField3',
  VAS_CHARGE_CODE: 'vasChargeCode',
  CANCEL_DATE: 'cancelDate',
  POSTAL_CODE: 'postalCode',
  MODIFIED_AT: 'modifiedAt',
  REASON_CODE: 'reasonCode',
  VAS_LONG_TEXT: 'vasLongText',
  VALID_TO_DATE: 'validToDate',
  SHIP_TO_NUMBER: 'shipToNumber',
  SOLD_TO_NUMBER: 'soldToNumber',
  VALID_FROM_DATE: 'validFromDate',
  DELIVERY_BLOCK: 'deliveryBlock',
  CONTRACT_NUMBER: 'contractNumber',
  DEPARTMENT_CODE: 'departmentCode',
  MATERIAL_NUMBER: 'materialNumber',
  NAME_OF_ORDERER: 'nameOfOrderer',
  INVOICE_NUMBER: 'invoiceNumber',
  ADDRESS_OVERRIDE: 'addressOverride',
  FORWARDING_AGENT: 'forwardingAgent',
  NUMBER_OF_CARTONS: 'numberOfCartons',
  CUSTOMER_PO_NUMBER: 'customerPONumber',
  SOURCING_METHOD_GA: 'sourcingMethodGA',
  SHIPPING_CONDITION: 'shippingCondition',
  TRANSPORTATION_ZONE: 'transportationZone',
  REQUIREMENT_SEGMENT: 'requirementSegment',
  NIKE_INTERNAL_COMMENTS: 'nikeInternalComments',
  RETURN_AUTHORIZATION_NUMBER: 'returnAuthorizationsNumber',
  INTERNAL_ORDER_REFERENCE_NUMBER: 'internalOrderReferenceNumber',
};
