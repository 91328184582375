// FILTER PANEL
export const GET_FETCHED_ORDERS = 'getFetchedOrders';
export const GET_SELECTED_FILE_ID = 'getSelectedFileId';
export const GET_FILES_WITH_ORDERS = 'getFilesWithOrders';
export const GET_SELECTED_ORDER_IDS = 'getSelectedOrderIds';

// ISSUE TYPES FILTER

// GRID
export const GET_ROW_DATA = 'getRowData';
export const GET_GRID_API = 'getGridApi';
export const GET_COLUMN_API = 'getColumnApi';
export const GET_COLUMN_DEFS = 'getColumnDefs';
export const GET_CACHED_LINES = 'getCachedLines';
export const GET_GRID_CREATOR = 'getGridCreator';
export const GET_MODIFIED_LINES = 'getModifiedLines';
export const GET_FROM_COLUMN_NAME = 'getFromColumnName';
export const GET_SEARCH_DIRECTION = 'getSearchDirection';
export const GET_LINES_VALIDATION_STATUS = 'getLinesValidationStatus';
export const GET_HAS_NEXT_PAGE_WITH_LINES = 'getHasNextPageWithLines';

// Issue navigator
export const GET_ISSUES = 'getIssues';
export const GET_BE_ISSUES = 'getBeIssues';
export const GET_UI_ISSUES = 'getUiIssues';
export const GET_ISSUES_LIST = 'getIssuesList';
export const GET_ORDER_ISSUES = 'getOrderIssues';
export const GET_SELECTED_ISSUE = 'getSelectedIssue';
export const GET_FIXED_BE_ISSUES = 'getFixedBeIssues';
export const GET_IS_ISSUES_LOADING = 'getIsIssuesLoading';
export const GET_TOTAL_ISSUES_COUNT = 'getTotalIssuesCount';
export const GET_IS_LINES_SUBMITTING = 'getisLinesSubmitting';
export const GET_IS_ALL_ISSUES_LOADED = 'getIsAllIssuesLoaded';
export const GET_SELECTED_ISSUE_INDEX = 'getSelectedIssueIndex';
export const GET_IS_ISSUE_NAVIGATOR_OPEN = 'getIsIssueNavigatorOpen';
export const GET_COLUMN_NAME_BY_FIELD_NAME = 'getColumnNameByFieldName';
export const GET_FIELD_NAME_BY_COLUMN_NAME = 'getFieldNameByColumnName';
export const GET_IS_DUPLICATE_LINES_AVAILABLE = 'getIsDuplicateLinesAvailable';
