import { get, isEqual, isEmpty } from "lodash";
import i18n from "@/plugins/i18n";
import ordersRepository from "@/api/productionSupport";
import * as notificationUtils from "@/utils/notifications";
import { getFilterParameters } from "@/utils/productionSupport";
import { getFormatedOrderDetailData } from "@/utils/productionSupport";
import { FIELDS, FIELD_KEYS } from "@/pages/OrderSummary/GlobalFilters/config.ts";

import {
  ORDER_STATUSES,
  ORDER_HISTORY,
  REPORT_TYPE,
} from "@/pages/OrderSummary/Orders/constants.ts";
import { updateFieldsItems, validateField } from "./helpers";

const orderDetailTable = {
  refreshOrderDetailDataSource({ state, commit, dispatch }) {
    commit("SET_ORDER_DETAIL_ERRORS", {});

    dispatch("createOrderDetailTableDatasource");

    if (state.gridApi) {
      state.gridApi.setServerSideDatasource(state.datasource);
    }
  },

  createOrderDetailTableDatasource({ dispatch, commit, getters, state }) {
    const datasource = {
      getRows: (rowsCreationParams) => {
        if (rowsCreationParams.request.groupKeys.length === 0) {
          dispatch("fetchOrderDetail");
        }

        setTimeout(() => {
          const orderDetailData = getFormatedOrderDetailData(
            state.orderDetail,
            rowsCreationParams.request.groupKeys
          );
          if (rowsCreationParams.success) {
            rowsCreationParams.success({
              rowData: orderDetailData,
              rowCount: orderDetailData.length,
            });

            if (!orderDetailData.length) {
              rowsCreationParams.api.showNoRowsOverlay();
            }
          } else {
            // inform the grid request failed
            rowsCreationParams.api.showNoRowsOverlay();
          }
        }, 1000);
      },
    };

    commit("SET_DATA_SOURCE", datasource);
  },

  async fetchOrderDetail({ state, commit, getters }) {
    try {
      commit("SET_IS_ORDER_DETAIL_LOADING", true);

      const getRequestFilterParams = getters.getRequestFilterParams || {};
      const { filters } = getRequestFilterParams;

      const requestFilters = getFilterParameters(filters);
      requestFilters.level = "2";
      requestFilters.orderId = state.orderId;

      const { data, warnings } = await ordersRepository.fetchOrderDetail(requestFilters);
      const { data: response, anchor, count, total } = data || {};

      const isFetchFailed =
        warnings?.length > 0 && Object.values(data).every((value) => value === "0");

      if (isFetchFailed) {
        const errorMessage = i18n.global.t(
          "notifications.errorMessages.http.unableToGetOrderDataSummarySupport"
        );
        notificationUtils.showErrorBanner({ title: errorMessage });
      }

      const orderDetail = response && response.orderHeader?.modules && response.orderHeader.modules;

      commit("SET_ORDER_DETAIL", orderDetail || []);
      // commit("SET_TOTAL_AVAILABLE_ORDER_ROWS", total || 0);
      // commit("SET_TOTAL_AVAILABLE_ORDER_PAGES_NUMBER", Math.ceil(total / count) || 0);
    } catch (e) {
      commit("SET_ORDER_DETAIL", []);
      console.error(e);
    } finally {
      commit("SET_IS_ORDER_DETAIL_LOADING", false);
    }
  },
};
const orderListTable = {
  setGridApi({ commit }, gridApi) {
    commit("SET_GRID_API", gridApi);
  },

  refreshOrderListDataSource({ dispatch, commit, state }) {
    commit("SET_ORDER_LIST_ERRORS", {});

    dispatch("createOrderListTableDatasource");

    if (state.gridApi) {
      state.gridApi.setServerSideDatasource(state.datasource);
    }
  },

  createOrderListTableDatasource({ dispatch, commit, getters, state }) {
    const datasource = {
      getRows: async (rowsCreationParams) => {
        await dispatch("handleFiltersModel", rowsCreationParams);

        await dispatch("fetchOrderList");

        rowsCreationParams.successCallback(getters.getFormatedOrdersData, state.orderList.length);

        if (!state.orderList.length) {
          rowsCreationParams.api.showNoRowsOverlay();
        }
      },
    };

    commit("SET_DATA_SOURCE", datasource);
  },

  async handleFiltersModel({ state, dispatch }, rowsCreationParams) {
    const filterModel = rowsCreationParams?.request?.filterModel;

    if (!isEmpty(filterModel) && !isFiltersValid(filterModel)) {
      return;
    }

    if (!isEqual(state.tableFilters, filterModel)) {
      await dispatch("resetPageNumber");
    }

    // await dispatch(SET_TABLE_FILTERS, filterModel);
  },

  async fetchOrderMiscDetails({ commit }, params) {
    // commit("SET_IS_ORDER_MISC_DETAILS_LOADING", true);
    const { data, warnings } = await ordersRepository.fetchOrderStats(params);

    // const isFetchFailed =
    //   warnings?.length > 0 && Object.values(data).every((value) => value === "0");

    // if (isFetchFailed) {
    //   const errorMessage = i18n.global.t(
    //     "notifications.errorMessages.http.unableToGetOrderDataSummarySupport"
    //   );
    //   notificationUtils.showErrorBanner({ title: errorMessage });
    // }

    commit("SET_ORDER_MISC_DETAILS", data || []);
    // commit("SET_IS_ORDER_MISC_DETAILS_LOADING", false);
  },
};

const globalFitlers = {
  createFilterForm({ state, commit, dispatch }) {
    if (state.filterForm?.size) {
      return;
    }

    const filterForm = new Map();

    FIELDS.forEach((field) => {
      filterForm.set(field.key, {
        ...field,
        exclude: false,
      });
    });

    commit("SET_FILTER_FORM", filterForm);
    dispatch("getInitialFilterOptions");
  },

  async getInitialFilterOptions({ commit, dispatch, state, rootState }) {
    const updatedFilterForm = new Map(state.filterForm);

    updateFieldsItems({
      filterForm: updatedFilterForm,
      geos: rootState.user.geo,
    });

    try {
      updateFieldsItems({
        filterForm: updatedFilterForm,
        geos: rootState.user.geo,
      });

      commit("SET_FILTER_FORM", updatedFilterForm);

      dispatch("applyFilters");
    } catch (e) {
      console.error(e);
    }
  },

  updateFilterField({ commit, state, dispatch }, field) {
    const updatedField = { ...field };

    if (updatedField.value === undefined) {
      updatedField.exclude = false;
      updatedField.errorMessage = "";

      commit("REMOVE_FIELD_WITH_ERROR", updatedField.label);
    }

    if (updatedField?.value?.length && updatedField.validation) {
      const errorMessage = validateField(updatedField);

      updatedField.errorMessage = errorMessage;

      if (errorMessage) {
        commit("ADD_FIELD_WITH_ERROR", updatedField.label);
      } else {
        commit("REMOVE_FIELD_WITH_ERROR", updatedField.label);
      }
    }

    commit("SET_FILTER_FIELD", updatedField);

    if (updatedField.dependencies) {
      const { value } = updatedField;

      field.dependencies.forEach(({ field, handler }) => {
        const fieldToUpdate = handler(state.filterForm.get(field), value);

        dispatch("updateFilterField", fieldToUpdate);
      });
    }

    commit("SET_IS_FORM_EDITED", true);
  },

  updateFilters({ commit }, updatedFields) {
    commit("SET_APPLY_FILTER_FIELDS", updatedFields);
  },

  clearSingleFilter({ state, commit, dispatch }, fieldKey) {
    const filteredFields = state.appliedFilterFields.filter(({ key }) => key !== fieldKey);

    const filteredFormField = { ...state.filterForm.get(fieldKey) };

    const updatedField = {
      ...filteredFormField,
      value: undefined,
      exclude: false,
    };

    commit("SET_FILTER_FIELD", updatedField);
    commit("SET_IS_FORM_EDITED", true);

    dispatch("updateFilters", filteredFields);
  },

  clearFilters({ state, commit, dispatch }) {
    state.filterForm.forEach((field) => {
      const updatedField = {
        ...field,
        value: undefined,
        exclude: false,
        errorMessage: "",
      };

      commit("SET_FILTER_FIELD", updatedField);
    });

    commit("RESET_FIELD_ERRORS");
    commit("SET_SELECTED_FILTERS_NAME", "");
    dispatch("updateFilters", []);

    commit("SET_IS_FORM_EDITED", true);
  },

  applyFilters({ state, dispatch }) {
    const filterFields = [];

    state.filterForm.forEach(({ key, label, value, exclude }) => {
      if (value) {
        const isDatePicker = FIELDS.find(
          (configField) => configField.key === key
        ).isRangeDatepicker;
        const mergeSign = isDatePicker ? " - " : ", ";
        const newValue = Array.isArray(value) ? value.join(mergeSign) : value;

        filterFields.push({
          key,
          exclude,
          name: label,
          value: newValue,
        });
      }
    });

    dispatch("updateFilters", filterFields);
  },

  setSearchQuery({ commit }, searchQuery) {
    commit("SET_SEARCH_QUERY", searchQuery);
  },
};

export default {
  setDashboardLayoutSnapshot({ commit }, snapshot) {
    commit("SET_DASHBOARD_LAYOUT_SNAPSHOT", snapshot);
  },

  async fetchOrderSummary({ state, commit, getters }) {
    if (!state.savedFilters) {
      return;
    }

    try {
      commit("SET_IS_ORDERS_INFO_LOADING", true);

      const getRequestFilterParams = getters.getRequestFilterParams || {};
      const { filters } = getRequestFilterParams;

      const requestFilters = getFilterParameters(filters);
      requestFilters.level = "0";

      const { data, warnings } = await ordersRepository.fetchOrderSummary(requestFilters);
      const { overallStatus, received, split } = data || {};

      const isFetchFailed =
        warnings?.length > 0 && Object.values(data).every((value) => value === "0");

      if (isFetchFailed) {
        const errorMessage = i18n.global.t(
          "notifications.errorMessages.http.unableToGetOrderDataSummarySupport"
        );
        notificationUtils.showErrorBanner({ title: errorMessage });
      }

      const orderLifeCycleCount = {};

      const orderSummaryStatuses = [...ORDER_STATUSES, ...ORDER_HISTORY];

      // Order Life Cycle Count
      orderSummaryStatuses.forEach((status) => {
        if (status.scope.group === "received" || status.scope.group === "split") {
          const orderStatus = status.scope.group === "received" ? received : split;

          orderLifeCycleCount[status.key] = orderStatus?.total || 0;
        } else if (status.scope.status === "SUCCESS_IN_OFOA") {
          const s1 = overallStatus["Success in OFOA"] ? overallStatus["Success in OFOA"].total : 0;
          const s2 = overallStatus["Success In OFOA"] ? overallStatus["Success In OFOA"].total : 0;
          const s3 = overallStatus["SuccessInOFOA"] ? overallStatus["SuccessInOFOA"].total : 0;
          const successInOFOA = s1 + s2 + s3;

          orderLifeCycleCount[status.key] = successInOFOA;
        } else if (status.scope.status === "SUBMITTED_SCPI") {
          const countSubmitted = overallStatus?.Submitted?.total || 0;
          const countSUBMITTED = overallStatus?.SUBMITTED?.total || 0;
          const submittedSCPI = countSubmitted + countSUBMITTED;

          orderLifeCycleCount[status.key] = submittedSCPI;
        } else {
          const statusCount = overallStatus[status.scope.status]?.total || 0;

          orderLifeCycleCount[status.key] = statusCount;
        }
      });

      // Order type summary chart data
      const orderTypeSummary = {};
      Object.keys(overallStatus).forEach((key) => {
        overallStatus[key].data.forEach((item) => {
          orderTypeSummary[item.orderType] = orderTypeSummary[item.orderType]
            ? orderTypeSummary[item.orderType] + item.count
            : item.count;
        });
      });

      commit("SET_SUMMARY", {
        totalExceptions: 0,
        totalPOExceptions: 0,
        totalUnitsImpacted: 0,
        orderLifeCycleCount,
        orderTypeSummary,
      });
    } catch (e) {
      commit("SET_SUMMARY", {
        totalExceptions: 0,
        totalPOExceptions: 0,
        totalUnitsImpacted: 0,
        orderLifeCycleCount: {},
        orderTypeSummary: {},
      });

      console.error(e);
    } finally {
      commit("SET_IS_ORDERS_INFO_LOADING", false);
    }
  },

  async fetchOrderList({ state, commit, getters, rootState }) {
    try {
      commit("SET_IS_ORDERS_LIST_LOADING", true);

      const getRequestFilterParams = getters.getRequestFilterParams || {};
      const { filters } = getRequestFilterParams;

      const requestFilters = getFilterParameters(filters);
      requestFilters.level = "1";
      requestFilters.anchor = state.pageNumber;
      requestFilters.count = state.pageSize;

      const { data, warnings } = await ordersRepository.fetchOrderList(
        state.reportType,
        requestFilters
      );
      const { data: response, anchor, count, total } = data || {};

      const isFetchFailed =
        warnings?.length > 0 && Object.values(data).every((value) => value === "0");

      if (isFetchFailed) {
        const errorMessage = i18n.global.t(
          "notifications.errorMessages.http.unableToGetOrderDataSummarySupport"
        );
        notificationUtils.showErrorBanner({ title: errorMessage });
      }

      const orderList =
        response &&
        response.map((orderHeader) => {
          return {
            ...orderHeader,
            id: orderHeader.orderId,
            dlqErrorInfo: orderHeader.dlqErrorInfo ?? "-",
            exception: orderHeader.exception ?? "-",
            totalCallsOfCM: orderHeader.totalCallsOfCM ?? "-",
            totalCallsOfMM: orderHeader.totalCallsOfMM ?? "-",
            splitOrder: orderHeader.splitOrder === 0 ? "true" : "false",
            orderStats: orderHeader.orderStats ?? REPORT_TYPE[state.reportType],
          };
        });

      commit("SET_ORDER_LIST", orderList || []);
      commit("SET_TOTAL_AVAILABLE_ORDERS_NUMBER", total || 0);
      commit("SET_TOTAL_AVAILABLE_PAGES_NUMBER", Math.ceil(total / count) || 0);
      // commit("SET_PAGE_TOKEN", 0);
    } catch (e) {
      commit("SET_ORDER_LIST", []);
      console.error(e);
    } finally {
      commit("SET_IS_ORDERS_LIST_LOADING", false);
    }
  },

  setRegion({ commit }, region) {
    commit("SET_REGION", region);
  },

  setReportType({ commit }, reportType) {
    commit("SET_REPORT_TYPE", reportType);
  },

  setOrderTypeFilter({ state, commit, dispatch }, orderType) {
    const type = FIELDS.find((field) => field.key === FIELD_KEYS.ORDER_TYPE);
    const appliedFilterField = [...state.appliedFilterFields];
    const orderTypeFilterField = state.appliedFilterFields.findIndex(
      (field) => field.key === FIELD_KEYS.ORDER_TYPE
    );

    if (orderTypeFilterField >= 0) {
      appliedFilterField[orderTypeFilterField].value = orderType;
    } else {
      appliedFilterField.push({
        key: type.key,
        name: type.label,
        exclude: false,
        value: orderType,
      });
    }

    const field = state.filterForm.get(FIELD_KEYS.ORDER_TYPE);
    field.value = orderType;

    dispatch("updateFilterField", field);

    dispatch("updateFilters", appliedFilterField);
    commit("SET_REPORT_TYPE", "RECEIVED");
    dispatch("setOrderType", orderType);
  },

  setOrderType({ commit }, orderType) {
    commit("SET_ORDER_TYPE", orderType);
  },

  setTableParams({ commit }, params) {
    commit("SET_TABLE_PARAMS", params);
  },

  setOrderId({ commit }, orderId) {
    commit("SET_ORDER_ID", orderId);
  },

  setDownloadPayloadParams({ commit }, params) {
    commit("SET_DOWNLOAD_PAYLOAD_ORDER_PARAMS", params);
  },

  async downloadPayload({ state, getters }, payloadParams) {
    const headers = {
      "Content-Type": "application/json",
      geoDetect: state.region,
      orderType: state.downloadPayloadParams.orderType,
      downloadType: payloadParams.downloadType,
    };

    const { filters } = getters.getRequestFilterParams || {};

    const requestFilters = getFilterParameters(filters);
    requestFilters.level = "1";
    requestFilters.orderId = state.downloadPayloadParams.orderId;

    const { data, warnings } = await ordersRepository.fetchDownloadPayload(requestFilters, headers);

    const payloadBlob = new Blob([JSON.stringify(data)], { type: "application/json" });
    const payloadBlobUrl = URL.createObjectURL(payloadBlob);

    const payloadAnchor = window.document.createElement("a");
    payloadAnchor.href = payloadBlobUrl;
    payloadAnchor.download = `${state.downloadPayloadParams.orderId}__${payloadParams.downloadType}.json`;
    document.body.appendChild(payloadAnchor);
    payloadAnchor.click();
    document.body.removeChild(payloadAnchor);
  },

  setPageSize({ commit }, pageSize) {
    commit("SET_PAGE_SIZE", pageSize);
  },

  setPageNumber({ commit }, pageNumber) {
    commit("SET_PAGE_NUMBER", pageNumber);
  },

  resetPageNumber({ commit }) {
    commit("SET_PAGE_NUMBER", 0);
    // commit("RESET_PAGE_TOKEN");
  },

  ...orderListTable,
  ...globalFitlers,
  ...orderDetailTable,
};
