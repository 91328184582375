import {
  GET_ISSUES,
  GET_BE_ISSUES,
  GET_UI_ISSUES,
  GET_ISSUES_LIST,
  GET_ORDER_ISSUES,
  GET_SELECTED_ISSUE,
  GET_FIXED_BE_ISSUES,
  GET_IS_ISSUES_LOADING,
  GET_TOTAL_ISSUES_COUNT,
  GET_IS_LINES_SUBMITTING,
  GET_IS_ALL_ISSUES_LOADED,
  GET_SELECTED_ISSUE_INDEX,
  GET_IS_ISSUE_NAVIGATOR_OPEN,
} from '@/store/modules/massOrderCreation/L2/constansts/getters';

export default {
  [GET_ISSUES]: (_, {
    getBeIssues, getUiIssues, getCachedLines,
  }) => mergeWithUiIssues(getBeIssues, getUiIssues, getCachedLines),

  [GET_BE_ISSUES]: ({ beIssues }) => beIssues || {},

  [GET_UI_ISSUES]: ({ uiIssues }) => uiIssues || {},

  [GET_ORDER_ISSUES]: ({ orderIssues }) => orderIssues || {},

  [GET_FIXED_BE_ISSUES]: ({ fixedBeIssues }) => fixedBeIssues || {},

  [GET_ISSUES_LIST]: (_, { getIssues }) => Object.values(getIssues).flat() || [],

  [GET_IS_ISSUE_NAVIGATOR_OPEN]: ({ isIssueNavigatorOpen }) => isIssueNavigatorOpen || false,

  [GET_SELECTED_ISSUE]: ({ selectedIssue }) => selectedIssue || null,

  [GET_SELECTED_ISSUE_INDEX]: (_, { getIssuesList, getSelectedIssue }) => {
    return getIssuesList.findIndex((issue) => {
      return getSelectedIssue?.rowId === issue.rowId && getSelectedIssue?.colId === issue.colId;
    });
  },

  [GET_IS_ALL_ISSUES_LOADED]: ({ isAllIssuesLoaded }) => isAllIssuesLoaded || false,

  [GET_IS_ISSUES_LOADING]: ({ isIssuesLoading }) => isIssuesLoading || false,

  [GET_IS_LINES_SUBMITTING]: ({ isLinesSubmitting }) => isLinesSubmitting || false,

  [GET_TOTAL_ISSUES_COUNT]: ({ totalIssuesCount }) => totalIssuesCount || 0,
};

function mergeWithUiIssues(beIssues, uiIssues, lines) {
  const result = { ...beIssues };
  const newIssues = {};

  Object.keys(uiIssues).forEach((rowId) => {
    const beIssuesForLine = beIssues[rowId];
    const uiIssuesForLine = uiIssues[rowId];
    const isLineDisplayed = lines.find((line) => line.rowId === rowId);

    if (beIssuesForLine) {
      result[rowId] = [...beIssuesForLine, ...uiIssuesForLine];
    } else if (isLineDisplayed) {
      newIssues[rowId] = uiIssuesForLine;
    }
  });

  return { ...result, ...newIssues };
}

export function mergeWithUiIssuesExportedForTesting(beIssues, uiIssues, lines) {
  return mergeWithUiIssues(beIssues, uiIssues, lines);
}
