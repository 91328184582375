import { xor } from 'lodash';

import mutationTypes from '../constansts/mutationTypes';

import * as actionsEnum from '../constansts/actions';
import * as gettersEnum from '../constansts/getters';

const {
  SET_SELECTED_ORDER_IDS,
  SET_ALL_ORDERS_SELECTED,
  RESET_SELECTED_ORDER_IDS,
  RESET_SELECTED_ISSUE_TYPE_ID,
} = actionsEnum;

export default {
  [SET_SELECTED_ORDER_IDS]: ({
    state, commit, dispatch,
  }, selectedOrderIds) => {
    dispatch(RESET_SELECTED_ISSUE_TYPE_ID);

    commit(mutationTypes.SET_SELECTED_ORDER_IDS, xor(state.selectedOrderIds, selectedOrderIds));
  },

  [SET_ALL_ORDERS_SELECTED]: ({
    getters, commit, dispatch,
  }) => {
    const ordersOfSelectedOrderType = getters[gettersEnum.GET_ORDERS_OF_SELECTED_ORDER_TYPE];

    dispatch(RESET_SELECTED_ISSUE_TYPE_ID);

    commit(
      mutationTypes.SET_SELECTED_ORDER_IDS,
      ordersOfSelectedOrderType.map(({ id }) => id),
    );
  },

  [RESET_SELECTED_ORDER_IDS]: ({ commit, dispatch }) => {
    dispatch(RESET_SELECTED_ISSUE_TYPE_ID);

    commit(mutationTypes.SET_SELECTED_ORDER_IDS, []);
  },
};
