import * as gettersEnum from '../constansts/getters';

const { GET_SELECTED_ORDER_PO_NUMBER } = gettersEnum;

export default {
  [GET_SELECTED_ORDER_PO_NUMBER]: (_, { getSelectedOrderIds, getOrdersOfSelectedOrderType }) => {
    const firstSelectedOrderId = getSelectedOrderIds[0];

    // TODO not for every order we will have customer PO number,
    // TODO we will have undefined orders
    // HAndle case - One undefined order - what text will be visible
    return getOrdersOfSelectedOrderType.find(({ id }) => firstSelectedOrderId === id)
      ?.customerPoNumber;
  },
};
