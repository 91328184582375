import mutationTypes from './constansts/mutationTypes';

const {
  RESET_STATE,
  SET_SEARCH_TEXT,
  SET_SUCCEEDED_FILES,
  SET_ORDER_ISSUE_TYPES,
  SET_SELECTED_ORDER_IDS,
  SET_NOT_SUCCEEDED_FILES,
  SET_ORDER_TYPES_MAPPING,
  SET_SELECTED_FILENAME_IDS,
  SET_SELECTED_ISSUE_TYPE_ID,
  SET_SELECTED_ORDER_TYPE_ID,
  SET_IS_FILTER_PANEL_VISIBLE,
  SET_IS_INITIAL_DATA_FETCHING,
  SET_SELECTED_ORDER_ISSUE_TYPES,
  SET_IS_DELETE_ORDERS_IN_PROGRESS,
  SET_IS_SUBMIT_ORDERS_IN_PROGRESS,
} = mutationTypes;

export default {
  [SET_SUCCEEDED_FILES](state, succeededFiles) {
    state.succeededFiles = [...succeededFiles];
  },

  [SET_NOT_SUCCEEDED_FILES](state, notSucceededFiles) {
    state.notSucceededFiles = [...notSucceededFiles];
  },

  [SET_ORDER_TYPES_MAPPING](state, orderTypesMapping) {
    state.orderTypesMapping = [...orderTypesMapping];
  },

  [SET_SELECTED_FILENAME_IDS](state, selectedFilenameIds) {
    state.selectedFilenameIds = [...selectedFilenameIds];
  },

  [SET_SEARCH_TEXT](state, searchText) {
    state.searchText = searchText;
  },

  [SET_IS_INITIAL_DATA_FETCHING](state, isInitialDataFetching) {
    state.isInitialDataFetching = isInitialDataFetching;
  },

  [SET_SELECTED_ORDER_TYPE_ID](state, selectedOrderTypeId) {
    state.selectedOrderTypeId = selectedOrderTypeId;
  },

  [SET_SELECTED_ORDER_IDS](state, selectedOrderIds) {
    state.selectedOrderIds = [...selectedOrderIds];
  },

  [SET_SELECTED_ISSUE_TYPE_ID](state, selectedIssueTypeId) {
    state.selectedIssueTypeId = selectedIssueTypeId;
  },

  [SET_ORDER_ISSUE_TYPES](state, orderIssueTypes) {
    state.orderIssueTypes = orderIssueTypes;
  },

  [SET_SELECTED_ORDER_ISSUE_TYPES](state, selectedOrderIssueTypes) {
    state.selectedOrderIssueTypes = selectedOrderIssueTypes;
  },

  [SET_IS_DELETE_ORDERS_IN_PROGRESS](state, isDeleteOrdersInProgress) {
    state.isDeleteOrdersInProgress = isDeleteOrdersInProgress;
  },

  [SET_IS_FILTER_PANEL_VISIBLE](state) {
    state.isFilterPanelVisible = !state.isFilterPanelVisible;
  },

  [SET_IS_SUBMIT_ORDERS_IN_PROGRESS](state, isSubmitOrdersInProgress) {
    state.isSubmitOrdersInProgress = isSubmitOrdersInProgress;
  },

  [RESET_STATE](state, initialState) {
    Object.assign(state, initialState);
  },
};
