import mutationTypes from './constants/mutationTypes';

const {
  SET_MODAL_MODE,
  SET_INITIAL_DATA,
  SET_DISPLAYED_DUPLICATES,
  SET_DUPLICATES_CHANGELOG,
  SET_IS_DUPLICATES_LOADING,
  SET_SELECTED_DUPLICATE_LINES,
  SET_IS_DUPLICATES_SAVE_IN_PROGRESS,
  SET_INDEX_OF_DISPLAYED_DUPLICATES_SET,
} = mutationTypes;

export default {
  [SET_MODAL_MODE](state, mode) {
    state.modalMode = mode;
  },

  [SET_INITIAL_DATA](state, data) {
    state.initialData = data;
  },

  [SET_DISPLAYED_DUPLICATES](state, duplicates) {
    state.displayedDuplicates = duplicates;
  },

  [SET_DUPLICATES_CHANGELOG](state, changes) {
    state.duplicatesChangelog = changes;
  },

  [SET_IS_DUPLICATES_LOADING](state, isLoading) {
    state.isDuplicatesLoading = isLoading;
  },

  [SET_IS_DUPLICATES_SAVE_IN_PROGRESS](state, isInProgress) {
    state.isDuplicatesSaveInProgress = isInProgress;
  },

  [SET_INDEX_OF_DISPLAYED_DUPLICATES_SET](state, index) {
    state.indexOfDisplayedDuplicatesSet = index;
  },

  [SET_SELECTED_DUPLICATE_LINES](state, selectedDuplicateLines) {
    state.selectedDuplicateLines = selectedDuplicateLines;
  },
};
