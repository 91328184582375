export default {
  succeededFiles: [],
  notSucceededFiles: [],

  isOnlyIssuesFilterSelected: null,

  orderTypesMapping: [],
  isInitialDataFetching: false,

  searchText: '',
  selectedOrderIds: [],
  selectedFilenameIds: [],
  selectedOrderTypeId: null,
  selectedIssueTypeId: null,

  isDeleteOrdersInProgress: false,

  isFilterPanelVisible: true,

  isSubmitOrdersInProgress: false,
};
