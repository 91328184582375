export const GET_MODAL_MODE = 'getModalMode';
export const GET_DUPLICATES_CNAHGELOG = 'getDuplicatesChangelog';
export const GET_IS_DUPLICATES_LOADING = 'getIsDuplicatesLoading';
export const GET_COUNT_OF_DUPLICATES_SETS = 'getCountOfDuplicatesSets';
export const GET_SELECTED_DUPLICATE_LINES = 'getSelectedDuplicateLines';
export const GET_ALL_DISPLAYED_DUPLICATES = 'getAllDisplayedDuplicates';
export const GET_DUPLICATE_LINES_INITIAL_DATA = 'getDuplicateLinesInitialData';
export const GET_DISPLAYED_DUPLICATES_ROW_DATA = 'getDisplayedDuplicatesRowData';
export const GET_IS_DUPLICATES_SAVE_IN_PROGRESS = 'getisDuplicatesSaveInProgress';
export const GET_INDEX_OF_DISPLAYED_DUPLICATES_SET = 'getIndexOfDisplayedDuplicatesSet';
export const GET_DUPLICATE_LINES_COLUMN_DEFINITIONS = 'getDuplicateLinesColumnDefinitions';
export const GET_DUPLICATES_CNAHGELOG_FOR_DISPLAYED_SET = 'getDuplicatesChangelogForDisplayedSet';
