const purchaseOrderLinesTable = {
  SET_ORDER_ID: 'SET_ORDER_ID',
  SET_PURCHASE_ORDER: 'SET_PURCHASE_ORDER',

  SET_GRID_API: 'SET_GRID_API',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  INIT_GRID_PAGINATION: 'INIT_GRID_PAGINATION',

  SET_USER_PREFERENCES: 'SET_USER_PREFERENCES',

  SET_GROUPS: 'SET_GROUPS',
  SET_EXCEPTIONS_GROUPED: 'SET_EXCEPTIONS_GROUPED',
  SET_GROUPED_SELECTED_CASES: 'SET_GROUPED_SELECTED_CASES',
  SET_SELECTED_GROUP_ID: 'SET_SELECTED_GROUP_ID',

  SET_RESOLVED_EXCEPTIONS: 'SET_RESOLVED_EXCEPTIONS',

  DATA_FAILED_TO_LOAD: 'DATA_FAILED_TO_LOAD',
  SET_CASES_RELOADING: 'SET_CASES_RELOADING',
};

export default {
  ...purchaseOrderLinesTable,
};
