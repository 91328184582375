import RegExpEnum from '@/enums/RegExp';
import { FIELD_NAMES } from '@/enums/fieldNames';
import dateFormats from '@/enums/dates/dateFormats';
import { COLUMN_NAMES_MAPPER } from '@/enums/massOrderCreation/L2/ColumnNamesMapper';

import {
  formatDate,
  createISODate,
  isValidISOFormat,
  convertDateFormat,
  isDateFormatValid,
  isShortDateFormatValid,
} from '@/utils/dates';

import {
  FW_SIZES,
  DATE_KEYS,
  ERROR_KEY,
  APP_SIZES,
  EQP_SIZES,
  ROW_ID_KEY,
  ADDRESS_ID,
  LINE_ID_KEY,
  CUSTOM_SIZES,
  ORDER_ID_KEY,
  ORDER_TYPE_ID,
  TOTAL_QUANTITY,
  ORDER_TYPE_KEY,
  PRODUCT_ENGINE_KEYS,
  SUPER_USER_TEMPALTE,
  DISABLED_CELL_CLASS,
} from '../constansts/config';

export default class BaseCreator {
  constructor({
    orders,
    columnOrder,
    orderTypeName,
    viewOnlyFields,
    requiredFields,
    lineLevelFields,
    sizeColumnsToAdd,
    headerLevelFields,
    groupingFieldKeys,
    addressOverrideFields,
  }) {
    this.sizesGroupedByProductEngine = null;

    this._orders = orders || [];
    this._sizeColumnsToAdd = sizeColumnsToAdd || [];
    this._orderTypeName = orderTypeName;
    this._columnOrder = columnOrder || [];
    this._requiredFields = requiredFields || [];
    this._viewOnlyFields = viewOnlyFields || [];
    this._lineLevelFields = lineLevelFields || [];
    this._headerLevelFields = headerLevelFields || [];
    this._groupingFieldKeys = groupingFieldKeys || [];
    this._addressOverrideFields = addressOverrideFields || [];
  }

  _createHeaderLevelRowData(line) {
    return this._headerLevelFields.reduce((acc, fieldKey) => {
      const field = line[fieldKey];

      if (field?.value) {
        acc[fieldKey] = {
          value: field?.value,
          name: field?.name,
          incorrect: field?.incorrect,
        };
      }

      return acc;
    }, {});
  }

  _createLineLevelRowData(line) {
    return this._lineLevelFields?.reduce((acc, fieldKey) => {
      const field = line[fieldKey];

      if (fieldKey === FIELD_NAMES.VAS_CODES) {
        acc[fieldKey] = {
          value: this.createRowDataForVasCodes(line),
          name: 'VAS CODE',
        };
      } else if (field?.value) {
        acc[fieldKey] = {
          value: field?.value,
          name: field?.name,
          incorrect: field?.incorrect,
        };

        if (this._isAddressOverrideField(fieldKey)) {
          acc[fieldKey] = {
            ...acc[fieldKey],
            id: field.id,
            incorrect: field?.incorrect,
          };
        }
      }

      return acc;
    }, {});
  }

  createRowDataForVasCodes(line) {
    return line[FIELD_NAMES.VAS_CODES]?.map(({ value }) => value).join(',');
  }

  getRowData() {
    const rowData = this._orders?.map((order) => {
      return order.map((line) => {
        return {
          [ORDER_ID_KEY]: line.orderId,
          [ADDRESS_ID]: line[ADDRESS_ID],
          [ERROR_KEY]: this._isError(line),
          [ORDER_TYPE_ID]: line.orderType.id,
          [TOTAL_QUANTITY]: line.totalQuantity,
          [ROW_ID_KEY]: this.createRowId(line),
          [ORDER_TYPE_KEY]: this._orderTypeName,
          [LINE_ID_KEY]: line.lineId.toString(),
          [SUPER_USER_TEMPALTE]: line.superUserTemplate,
          [FW_SIZES]: this._createSizesRowData(line[FW_SIZES]),
          [APP_SIZES]: this._createSizesRowData(line[APP_SIZES]),
          [EQP_SIZES]: this._createSizesRowData(line[EQP_SIZES]),
          [CUSTOM_SIZES]: this._createSizesRowData(line[CUSTOM_SIZES]),
          ...this._createHeaderLevelRowData(line),
          ...this._createLineLevelRowData(line),
          issues: line.issues,
        };
      });
    });

    return rowData.flat();
  }

  _createSizesRowData(sizes = []) {
    return sizes.reduce((acc, size) => {
      const modifiedSizeName = size.name?.replace('.', '_');
      const key = size.subId ? `${modifiedSizeName}_${size.subId}` : modifiedSizeName;

      acc[key] = {
        value: size.value, name: size.name, incorrect: size.incorrect,
      };

      return acc;
    }, {});
  }

  createRowId(line) {
    return `${line.orderId}_${line.lineId}`;
  }

  getColumnDefs() {
    // TODO Add mapper to control header names
    // { soldToNumber: "Sold To Number", .... }
    const columnDefs = this._columnOrder.map((fieldKey) => {
      if (this._isDateField(fieldKey)) {
        return this._getColumnDefForDates(fieldKey);
      }
      if (
        this._isLineLevelField(fieldKey)
        || this._isHeaderLevelField(fieldKey)
        || this._isAddressOverrideField(fieldKey)
      ) {
        return {
          ...this._getDefaultColumnDef(fieldKey),
          valueGetter: (params) => {
            return params.data && params.data[params.colDef.field]?.value;
          },
          valueSetter: (params) => this._getDefaultValueSetter(params),
        };
      }

      return this._getDefaultColumnDef(fieldKey);
    });

    if (columnDefs.length) {
      return [this._getErrorColumnDef(), ...columnDefs, ...this._getColumnDefForSizes()];
    }

    // Do not show errors column if no other columns
    return [...columnDefs, ...this._getColumnDefForSizes()];
  }

  _getDefaultColumnDef(fieldKey) {
    return {
      field: fieldKey,
      colId: fieldKey,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      headerName: COLUMN_NAMES_MAPPER[fieldKey].toUpperCase() || fieldKey,
      isViewOnly: this._isViewOnlyField(fieldKey),
      editable: (params) => this._isCellEditable(params, fieldKey),
      cellRenderer: 'SimpleCellRenderer',
      cellClassRules: {
        [DISABLED_CELL_CLASS]: (params) => this._isCellDisabled(params, fieldKey),
      },
    };
  }

  _getColumnDefForDates(fieldKey) {
    return {
      ...this._getDefaultColumnDef(fieldKey),

      valueGetter: (params) => this._getValueGetterForDates(params.data, fieldKey),

      valueSetter: (params) => this._getValueSetterForDates(params, fieldKey),
    };
  }

  _getColumnDefForSizes() {
    const sizesColumnDef = [];
    this.sizesGroupedByProductEngine = this.getSizeNamesGroupedByProductEngine();

    Object.keys(this.sizesGroupedByProductEngine).forEach((productEngine) => {
      const sizeColumnsToAdd = this._sizeColumnsToAdd
        .filter((sizeColumn) => sizeColumn.productEngine === productEngine)
        .map(({ field }) => field);

      let sizes = Array.from(this.sizesGroupedByProductEngine[productEngine]);

      sizes = [...sizes, ...sizeColumnsToAdd];

      if (sizes.length) {
        sizesColumnDef.push(this._getColumnDefForProductEngine(productEngine));

        sizes.forEach((sizeName) => {
          const subId = sizeName.split('_')[1];

          subId
            ? sizesColumnDef.push(
              this._getColumnDefForSize({
                sizeName: sizeName.split('_')[0],
                productEngine,
                subId,
              }),
            )
            : sizesColumnDef.push(this._getColumnDefForSize({ sizeName, productEngine }));
        });
      }
    });

    return sizesColumnDef;
  }

  _getColumnDefForProductEngine(productEngine) {
    const productEngineMapper = {
      [APP_SIZES]: 'APPAREL',
      [FW_SIZES]: 'FOOTWEAR',
      [EQP_SIZES]: 'EQUIPMENT',
      [CUSTOM_SIZES]: 'CUSTOM',
    };

    return {
      editable: false,
      isViewOnly: true,
      field: productEngine,
      colId: productEngine,
      cellRenderer: 'SimpleCellRenderer',
      headerName: productEngineMapper[productEngine],
      cellClassRules: {
        [DISABLED_CELL_CLASS]: () => true,
      },
      valueGetter: () => null,
    };
  }

  _getColumnDefForSize({
    sizeName, productEngine, subId,
  }) {
    return {
      subId,
      productEngine,
      field: sizeName,
      colId: subId ? `${sizeName}_${subId}_${productEngine}` : `${sizeName}_${productEngine}`,
      cellRenderer: 'SimpleCellRenderer',
      headerName: sizeName,
      editable: (params) => this._isCellEditable(params, sizeName),
      cellClassRules: {
        [DISABLED_CELL_CLASS]: (params) => this._isCellDisabled(params, sizeName),
      },
      valueGetter: (params) => this._getValueGetterForSizes(params, sizeName, subId),

      valueSetter: (params) => this._getValueSetterForSizes(params, sizeName, subId),
    };
  }

  getSizeNamesGroupedByProductEngine() {
    const columnDefs = {
      [APP_SIZES]: [],
      [EQP_SIZES]: [],
      [FW_SIZES]: [],
      [CUSTOM_SIZES]: [],
    };

    // TODO Potential performance issue
    this._orders?.forEach((order) => {
      order?.forEach((line) => {
        PRODUCT_ENGINE_KEYS.forEach((productEngine) => {
          if (line[productEngine].length) {
            line[productEngine].forEach((size) => {
              const modifiedSizeName = size.name.replaceAll('.', '_');
              const sizeName = size.subId ? `${modifiedSizeName}_${size.subId}` : modifiedSizeName;

              columnDefs[productEngine].push(sizeName);
            });
          }
        });
      });
    });

    // Order of columns need to be the same like in the template
    // We need to have only uniqe values
    return {
      [FW_SIZES]: new Set(columnDefs[FW_SIZES]),
      [EQP_SIZES]: new Set(columnDefs[EQP_SIZES]),
      [APP_SIZES]: new Set(columnDefs[APP_SIZES]),
      [CUSTOM_SIZES]: new Set(columnDefs[CUSTOM_SIZES]),
    };
  }

  _getDefaultValueSetter(params) {
    if (!params.data[params.colDef.field]) {
      params.data[params.colDef.field] = {};
    }

    params.data[params.colDef.field].value = params.newValue.trim();

    return true;
  }

  _getValueGetterForSizes({ data, colDef }, fieldKey, subId) {
    const key = subId ? `${fieldKey}_${subId}` : fieldKey;

    return data[colDef.productEngine][key]?.value;
  }

  _getValueSetterForSizes({
    data, colDef, newValue,
  }, fieldKey, subId) {
    const key = subId ? `${fieldKey}_${subId}` : fieldKey;

    if (!data[colDef.productEngine][key]) {
      data[colDef.productEngine][key] = {};
    }

    data[colDef.productEngine][key].value = newValue;

    return true;
  }

  _getValueGetterForDates(data, fieldKey) {
    if (data) {
      const value = data[fieldKey]?.value;
      const isDateValid = isValidISOFormat(value);

      return isDateValid ? formatDate(value) : value;
    }
  }

  _getValueSetterForDates(params, fieldKey) {
    if (!params.data[fieldKey]) {
      params.data[fieldKey] = {};
    }

    const uiDate = params.newValue;

    if (isDateFormatValid(uiDate) || isShortDateFormatValid(uiDate)) {
      params.data[fieldKey].value = createISODate(convertDateFormat(uiDate, dateFormats.EU));
    } else {
      params.data[fieldKey].value = uiDate;
    }

    return true;
  }

  _isCellEditable(params, fieldKey) {
    if (this._isAddressOverrideField(fieldKey)) {
      return this._isAddressOverrideEnabled(params.data[FIELD_NAMES.ADDRESS_OVERRIDE]?.value);
    }

    return !this._isViewOnlyField(fieldKey);
  }

  _isCellDisabled(params, fieldKey) {
    return (
      this._isAddressOverrideField(fieldKey)
      && !this._isAddressOverrideEnabled(params.data[FIELD_NAMES.ADDRESS_OVERRIDE]?.value)
    );
  }

  _isViewOnlyField(fieldKey) {
    return this._viewOnlyFields?.includes(fieldKey);
  }

  _isAddressOverrideEnabled(addressOverrideValue = '') {
    const testValue = addressOverrideValue.trim();

    return !testValue || new RegExp(RegExpEnum.Y_1).test(String(testValue));
  }

  _isAddressOverrideField(fieldKey) {
    return this._addressOverrideFields?.includes(fieldKey);
  }

  _isDateField(fieldKey) {
    return DATE_KEYS.includes(fieldKey);
  }

  _isHeaderLevelField(fieldKey) {
    return this._headerLevelFields.includes(fieldKey);
  }

  _isLineLevelField(fieldKey) {
    return this._lineLevelFields.includes(fieldKey);
  }

  _getErrorColumnDef() {
    return {
      width: 100,
      field: ERROR_KEY,
      colId: ERROR_KEY,
      editable: false,
      headerName: ERROR_KEY.toUpperCase(),
      cellRenderer: 'ErrorColumnRenderer',
    };
  }

  _isError(line) {
    return line.hasErrors;
  }

  _isRequiredField(fieldKey) {
    return this._requiredFields.includes(fieldKey);
  }
}
