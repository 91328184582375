import { type ChartOptions } from "chart.js";
import { translateModule } from "@/utils/string/translate";

const translateOrderList = translateModule("pages.orderList");

export const CHART_COLOR_CODES = [
  "#300080",
  "#0034E3",
  "#1190FF",
  "#007D48",
  "#1EAA52",
  "#FCA600",
  "#FF5000",
  "#A50767",
  "#CF2996",
  "#4965C4",
  "#7F6378",
  "#E9B66A",
  "#C2966A",
  "#279893",
  "#8E5041",
  "#c095e4",
  "#fcedf2",
  "#ffd1d4",
  "#ffb7c5",
  "#ffa0c5",
  "#ffd6a5",
  "#ffadad",
  "#caffbf",
  "#9bf6ff",
];

export const formatChartData = (data: any, color: string = "", hoverColor: string = "") => ({
  labels: Object.keys(data).map((label) => label),
  datasets: [
    {
      label: "Count ",
      backgroundColor: CHART_COLOR_CODES.filter((_, index) => index < Object.keys(data).length).map(
        (barColor) => (color !== "" ? color : barColor)
      ),
      data: Object.values(data).map((value) => parseInt(value as string)),
    },
  ],
});

export const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
} as any; // as ChartOptions<"bar">

export const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
} as any;

export const PIE_CHART_REPORT_TYPE: { [key: string]: string } = {
  RECEIVED_V2: translateOrderList("RECEIVED_V2"),
  IN_PROGRESS: translateOrderList("IN_PROGRESS"),
  SENT_EXCEPTION: translateOrderList("SENT_EXCEPTION"),
  SENT_DLQ: translateOrderList("SENT_DLQ"),
  DLQ: translateOrderList("DLQ"),
  SUCCESS_IN_OFOA: translateOrderList("SUCCESS_IN_OFOA"),
  SUBMITTED_SCPI: translateOrderList("SUBMITTED_SCPI"),
  SPLIT: translateOrderList("SPLIT"),
  RECEIVED: translateOrderList("RECEIVED"),
  ARCHIVED: translateOrderList("ARCHIVED"),
  ACKNOWLEDGED: translateOrderList("ACKNOWLEDGED"),
  FAILED_IN_S4: translateOrderList("FAILED_IN_S4"),
  FAILED_IN_SCPI: translateOrderList("FAILED_IN_SCPI"),
  STO_IDOC: translateOrderList("STO_IDOC"),
  MISC: translateOrderList("MISC"),
  IN_CONTROLLED_ALLOCATION: translateOrderList("IN_CONTROLLED_ALLOCATION"),
};

export const ORDER_STATUSES = [
  {
    type: PIE_CHART_REPORT_TYPE.RECEIVED_V2,
    scope: {
      group: "overallStatus",
      status: "Received",
    },
    key: "RECEIVED_V2",
  },
  {
    type: PIE_CHART_REPORT_TYPE.IN_PROGRESS,
    scope: {
      group: "overallStatus",
      status: "IN_PROGRESS",
    },
    key: "IN_PROGRESS",
  },
  {
    type: PIE_CHART_REPORT_TYPE.SENT_EXCEPTION,
    scope: {
      group: "overallStatus",
      status: "Exception",
    },
    key: "SENT_EXCEPTION",
  },
  {
    type: PIE_CHART_REPORT_TYPE.SENT_DLQ,
    scope: {
      group: "overallStatus",
      status: "DLQ",
    },
    key: "SENT_DLQ",
  },
  {
    type: PIE_CHART_REPORT_TYPE.IN_CONTROLLED_ALLOCATION,
    scope: {
      group: "overallStatus",
      status: "IN_CONTROLLED_ALLOCATION",
    },
    key: "IN_CONTROLLED_ALLOCATION",
  },
  {
    type: PIE_CHART_REPORT_TYPE.SUCCESS_IN_OFOA,
    scope: {
      group: "overallStatus",
      status: "SUCCESS_IN_OFOA",
    },
    key: "SUCCESS_IN_OFOA",
  },
  {
    type: PIE_CHART_REPORT_TYPE.SUBMITTED_SCPI,
    scope: {
      group: "overallStatus",
      status: "SUBMITTED_SCPI",
    },
    key: "SUBMITTED_SCPI",
  },
  {
    type: PIE_CHART_REPORT_TYPE.FAILED_IN_SCPI,
    scope: {
      group: "overallStatus",
      status: "Failed In SCP",
    },
    key: "FAILED_IN_SCPI",
  },
  {
    type: PIE_CHART_REPORT_TYPE.STO_IDOC,
    scope: {
      group: "overallStatus",
      status: "STO_IDOC_CREATED",
    },
    key: "STO_IDOC",
  },
  {
    type: PIE_CHART_REPORT_TYPE.FAILED_IN_S4,
    scope: {
      group: "overallStatus",
      status: "Failed In S4",
    },
    key: "FAILED_IN_S4",
  },
  {
    type: PIE_CHART_REPORT_TYPE.ACKNOWLEDGED,
    scope: {
      group: "overallStatus",
      status: "Acknowledged",
    },
    key: "ACKNOWLEDGED",
  },
  {
    type: PIE_CHART_REPORT_TYPE.MISC,
    scope: {
      group: "overallStatus",
      status: "Miscellaneous",
    },
    key: "MISC",
  },
];

export const ORDER_HISTORY = [
  {
    type: PIE_CHART_REPORT_TYPE.RECEIVED,
    scope: {
      group: "received",
    },
    key: "RECEIVED",
  },
  {
    type: PIE_CHART_REPORT_TYPE.ARCHIVED,
    scope: {
      group: "overallStatus",
      status: "HAS_SPLIT",
    },
    key: "ARCHIVED",
  },
  {
    type: PIE_CHART_REPORT_TYPE.SPLIT,
    scope: {
      group: "split",
    },
    key: "SPLIT",
  },
];

export const REPORT_TYPE = {
  IN_PROGRESS: "In Progress",
  SENT_EXCEPTION: "Exception",
  SENT_DLQ: "Exception",
  SUBMITTED_SCPI: "Submitted to SCPI",
};

export const CHART_TABS = [
  { name: "Bar Chart", id: "bar-chart" },
  { name: "Pie Chart", id: "doughnut-chart" },
];

export const REGIONS = [
  { value: "GC", text: "MAGC" },
  { value: "NA", text: "MANA" },
];
