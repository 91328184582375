export const issueTypesCategories = [
  {
    errorGroupLabel: 'Data integrity',
    errorGroup: 'INTEGRITY',
    count: 0,
    issueTypes: [],
  },
  {
    errorGroupLabel: 'Missing or incorrect data',
    errorGroup: 'MISSING_OR_INCORRECT_DATA',
    count: 0,
    issueTypes: [],
  },
  {
    errorGroupLabel: 'Date issues',
    errorGroup: 'DATE',
    count: 0,
    issueTypes: [],
  },
  {
    errorGroupLabel: 'VAS issues',
    errorGroup: 'VAS',
    count: 0,
    issueTypes: [],
  },
];
