import { uniqBy, reverse } from 'lodash';

import {
  fetchOrders,
  fetchFileProcessStatus,
  fetchNotSucceededFiles,
} from '@/api/now/massOrderCreation';

import ModalEnum from '@/enums/modals';
import { DUPLICATE_LINES_MODAL_MODES } from '@/enums/massOrderCreation/duplicateLines/modalModes';

import {
  HANDLE_PENDING_FILES,
  POLL_FILES_PROCESS_STATUS,
  FETCH_NOT_SUCCEEDED_FILES,
  SET_RECENTLY_UPLOADED_FILES,
  SPLIT_FILES_BY_UPLOAD_STATUS,
  SET_UPLOADED_FILE_IDS_TO_SELECT,
  HANDLE_SUCCESSFULLY_PROCESSED_FILES,
} from './constansts/actions';
import mutationTypes from './constansts/mutationTypes';
import { GET_RECENTLY_UPLOADED_FILES } from './constansts/getters';
import { fileUploadStatuses } from './constansts/fileUploadStatuses';

import {
  createNameWithL1Module,
  createNameWithDuplicateLinesModule,
} from '@/store/modules/massOrderCreation/utils.js';
import L1Mutations from '@/store/modules/massOrderCreation/L1/constansts/mutationTypes';
import { SET_MODAL_MODE } from '@/store/modules/massOrderCreation/duplicateLines/constants/actions';
import { AUTOSELECT_RECENTLY_UPLOADED_FILES } from '@/store/modules/massOrderCreation/L1/constansts/actions';
import {
  GET_SUCCEEDED_FILES,
  GET_SELECTED_FILENAME_IDS,
} from '@/store/modules/massOrderCreation/L1/constansts/getters';

const { SET_NOT_SUCCEEDED_FILES, SET_SUCCEEDED_FILES } = L1Mutations;

export default {
  [SET_RECENTLY_UPLOADED_FILES]({ commit }, recentlyUploadedFiles) {
    commit(mutationTypes.SET_RECENTLY_UPLOADED_FILES, recentlyUploadedFiles);
  },

  [SET_UPLOADED_FILE_IDS_TO_SELECT]({ commit }, uploadedFileIdsToSelect) {
    commit(mutationTypes.SET_UPLOADED_FILE_IDS_TO_SELECT, uploadedFileIdsToSelect);
  },

  async [POLL_FILES_PROCESS_STATUS]({ getters, dispatch }) {
    const recentlyUploadedFileIds = getters[GET_RECENTLY_UPLOADED_FILES].map(
      ({ fileId }) => fileId,
    );

    const { data: processingFiles } = await fetchFileProcessStatus(recentlyUploadedFileIds);

    dispatch(SPLIT_FILES_BY_UPLOAD_STATUS, processingFiles);

    dispatch(HANDLE_PENDING_FILES);

    dispatch(HANDLE_SUCCESSFULLY_PROCESSED_FILES);
  },

  async [FETCH_NOT_SUCCEEDED_FILES]({ commit }) {
    try {
      const { data: notSucceededFiles } = await fetchNotSucceededFiles();

      commit(
        createNameWithL1Module(SET_NOT_SUCCEEDED_FILES),
        notSucceededFiles.filter(({ status }) => status === fileUploadStatuses.FAIL),
        { root: true },
      );
    } catch (err) {
      console.error(err);
    }
  },

  [SPLIT_FILES_BY_UPLOAD_STATUS]({ commit }, processingFiles) {
    const {
      PENDING, SUCCESS, FAIL,
    } = fileUploadStatuses;

    const failedFiles = [];
    const pendingFiles = [];
    const successfullyProcessedFileIds = [];

    processingFiles.forEach((file) => {
      switch (file.status) {
        case SUCCESS:
          successfullyProcessedFileIds.push(file.fileId);
          break;

        case PENDING:
          // TODO change to ids
          pendingFiles.push(file);
          break;

        case FAIL:
          failedFiles.push(file);
          break;
        default:
      }
    });

    commit(createNameWithL1Module(SET_NOT_SUCCEEDED_FILES), failedFiles, { root: true });

    commit(mutationTypes.SET_PENDING_FILES, pendingFiles);

    commit(mutationTypes.SET_SUCCESSFULLY_PROCESSED_FILE_IDS, successfullyProcessedFileIds);
  },

  [HANDLE_PENDING_FILES]({ dispatch, getters }) {
    const pendingFiles = getters.getPendingFiles;

    if (!pendingFiles.length) {
      dispatch(SET_RECENTLY_UPLOADED_FILES, []);

      return;
    }

    setTimeout(() => {
      dispatch(POLL_FILES_PROCESS_STATUS);
    }, 1000);
  },

  async [HANDLE_SUCCESSFULLY_PROCESSED_FILES]({
    commit, getters, dispatch, rootGetters,
  }) {
    const successfullyProcessedFileIds = getters.getSuccessfullyProcessedFileIds;

    const succeededFiles = rootGetters[createNameWithL1Module(GET_SUCCEEDED_FILES)];

    if (successfullyProcessedFileIds.length) {
      try {
        const selectedFiles = rootGetters[createNameWithL1Module(GET_SELECTED_FILENAME_IDS)];
        const pendingFiles = getters.getPendingFiles;

        const {
          data: { files },
        } = await fetchOrders(successfullyProcessedFileIds);

        commit(
          createNameWithL1Module(SET_SUCCEEDED_FILES),
          reverse(uniqBy(reverse([...files, ...succeededFiles]), 'fileId')),
          { root: true },
        );

        if (hasDuplicateLinesInFiles(files)) {
          dispatch(
            createNameWithDuplicateLinesModule(SET_MODAL_MODE),
            DUPLICATE_LINES_MODAL_MODES.FILE_UPLOAD,
            { root: true },
          );
          dispatch('modal/onOpenModal', ModalEnum.DUPLICATE_LINES_MODAL, { root: true });
        }

        if (!pendingFiles.length && !selectedFiles.length) {
          const onlySucceddedIds = getters.getUploadedFileIdsToSelect.filter(
            (id) => !getters.getNotSucceededFileIds.includes(id),
          );
          const filesIds = files.map(({ fileId }) => fileId);

          const nonEmptyFileIds = onlySucceddedIds.reduce((acc, id) => {
            if (filesIds.includes(id)) {
              acc.push(id);
            }

            return acc;
          }, []);

          if (nonEmptyFileIds.length) {
            await dispatch(
              createNameWithL1Module(AUTOSELECT_RECENTLY_UPLOADED_FILES),
              nonEmptyFileIds,
              { root: true },
            );
          }

          dispatch(SET_UPLOADED_FILE_IDS_TO_SELECT, []);
        }
      } catch (err) {
        console.error(err);
      }
    }
  },
};

function hasDuplicateLinesInFiles(files) {
  return files.some(({ orders }) => orders.some(({ hasDuplicateMaterialSizes }) => hasDuplicateMaterialSizes),
  );
}
