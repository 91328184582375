// TODO: We need to combine this logic with FormValidationMixin and turn it
// into a Vue composable function.
class Validator {
  constructor(config) {
    this.config = config;
  }

  validate(name, value) {
    const validators = this.config.validators[name];

    if (!validators) return;

    const error = validators.find(validator => {
      return !validator.validate(value, validator.argument);
    });

    const errorMessage = error?.errorMessage;

    if (!errorMessage) return;

    const dynamicErrorMessage = typeof errorMessage === 'function' ? errorMessage(error.argument) : errorMessage;

    return `${this.config.errorPrefix} ${dynamicErrorMessage}`;
  }
}

export default Validator;
