export const RESET_STORE = 'resetStore';
export const UNDO_CHANGE = 'undoChange';
export const SET_MODAL_MODE = 'setModalMode';
export const UNDO_ALL_CHANGES = 'undoAllChanges';
export const FETCH_DUPLICATE_LINES = 'fetchDuplicateLines';
export const SET_DUPLICATES_CHANGELOG = 'setDuplicatesChangelog';
export const GO_TO_NEXT_DUPLICATES_SET = 'goToNextDuplicatesSet';
export const GO_TO_PREV_DUPLICATES_SET = 'goToPrevDuplicatesSet';
export const SAVE_DUPLICATE_LINES_CHANGES = 'saveDuplicateLinesChanges';
export const SET_SELECTED_DUPLICATE_LINES = 'setSelectedDuplicateLines';
export const MERGE_SELECTED_DUPLICATE_LINES = 'mergeSelectedDuplicateLines';
export const DELETE_SELECTED_DUPLICATE_LINES = 'deleteSelectedDuplicateLines';
export const UPDATE_ISSUES_AFTER_DUPLICATES_RESOLUTION = 'updateIssuesAfterDuplicatesResolution';
