const SET_SUCCEEDED_FILES = 'SET_SUCCEEDED_FILES';
const SET_NOT_SUCCEEDED_FILES = 'SET_NOT_SUCCEEDED_FILES';
const SET_ORDER_TYPES_MAPPING = 'SET_ORDER_TYPES_MAPPING';
const SET_IS_INITIAL_DATA_FETCHING = 'SET_IS_INITIAL_DATA_FETCHING';

const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
const SET_SELECTED_FILENAME_IDS = 'SET_SELECTED_FILENAME_IDS';

const SET_SELECTED_ORDER_TYPE_ID = 'SET_SELECTED_ORDER_TYPE_ID';

const SET_SELECTED_ORDER_IDS = 'SET_SELECTED_ORDER_IDS';

const SET_ORDER_ISSUE_TYPES = 'SET_ORDER_ISSUE_TYPES';
const SET_SELECTED_ISSUE_TYPE_ID = 'SET_SELECTED_ISSUE_TYPE_ID';
const SET_SELECTED_ORDER_ISSUE_TYPES = 'SET_SELECTED_ORDER_ISSUE_TYPES';

const SET_IS_DELETE_ORDERS_IN_PROGRESS = 'SET_IS_DELETE_ORDERS_IN_PROGRESS';

const SET_IS_FILTER_PANEL_VISIBLE = 'SET_IS_FILTER_PANEL_VISIBLE';

const RESET_STATE = 'RESET_STATE';

const SET_IS_SUBMIT_ORDERS_IN_PROGRESS = 'SET_IS_SUBMIT_ORDERS_IN_PROGRESS';

export default {
  RESET_STATE,
  SET_SEARCH_TEXT,
  SET_SUCCEEDED_FILES,
  SET_ORDER_ISSUE_TYPES,
  SET_SELECTED_ORDER_IDS,
  SET_NOT_SUCCEEDED_FILES,
  SET_ORDER_TYPES_MAPPING,
  SET_SELECTED_FILENAME_IDS,
  SET_SELECTED_ISSUE_TYPE_ID,
  SET_SELECTED_ORDER_TYPE_ID,
  SET_IS_FILTER_PANEL_VISIBLE,
  SET_IS_INITIAL_DATA_FETCHING,
  SET_SELECTED_ORDER_ISSUE_TYPES,
  SET_IS_DELETE_ORDERS_IN_PROGRESS,
  SET_IS_SUBMIT_ORDERS_IN_PROGRESS,
};
