import { uniqBy } from 'lodash';

import { GET_NOT_SUCCEEDED_FILES } from '../L1/constansts/getters';

import {
  GET_RECENTLY_UPLOADED_FILES,
  GET_SUCCESSFULLY_PROCESSED_FILE_IDS,
} from './constansts/getters';

import { createNameWithL1Module } from '@/store/modules/massOrderCreation/utils.js';

export default {
  [GET_RECENTLY_UPLOADED_FILES]: ({ recentlyUploadedFiles }) => recentlyUploadedFiles || [],

  [GET_SUCCESSFULLY_PROCESSED_FILE_IDS]: ({ successfullyProcessedFileIds }) => successfullyProcessedFileIds || [],

  getFilesInProgress: (
    _,
    {
      getUniquePendingFiles, getSuccessfullyProcessedFileIds, getNotSucceededFileIds,
    },
  ) => {
    return getUniquePendingFiles.filter(({ fileId }) => {
      return !(
        getSuccessfullyProcessedFileIds.includes(fileId) || getNotSucceededFileIds.includes(fileId)
      );
    });
  },

  getUploadedFileIdsToSelect: ({ uploadedFileIdsToSelect }) => uploadedFileIdsToSelect || [],

  getPendingFiles: ({ pendingFiles }) => pendingFiles || [],

  getNotSucceededFileIds: (state, getters, rootState, rootGetters) => {
    return rootGetters[createNameWithL1Module(GET_NOT_SUCCEEDED_FILES)].map(({ fileId }) => fileId);
  },

  getUniquePendingFiles: (_, { getPendingFiles, getRecentlyUploadedFiles }) => {
    return uniqBy([...getRecentlyUploadedFiles, ...getPendingFiles], 'fileId');
  },
};
