import isNil from 'lodash/isNil';

export function setErrorMessage(updatedCase, wrongfulValueIndex, validator) {
  const updatedWrongfulValue = updatedCase.wrongfulValues[wrongfulValueIndex];

  const validate = ({ name, value, fixedValue }, isBulkValidation) => {
    let fieldValue = fixedValue;

    if (isBulkValidation) {
      fieldValue = isNil(fixedValue) ? value : fixedValue;
    }

    return validator.validate(name, fieldValue);
  };

  if (updatedWrongfulValue) {
    updatedWrongfulValue.errorMessage = validate(updatedWrongfulValue);
  } else {
    updatedCase.wrongfulValues.forEach(wrongfulValue => {
      wrongfulValue.errorMessage = updatedCase.caseAction === 'UPDATE' ? validate(wrongfulValue, true) : undefined;
    });
  }
}
