import {
  get, isEqual, isEmpty,
} from 'lodash';
import i18n from '@/plugins/i18n';
import router from '@/router';

import singleOrdersRepository from '@/api/now/orders';
import myOrdersRepository from '@/api/now/myOrders';
import { showSuccessBanner, showErrorBanner } from '@/utils/notifications';

import {
  FETCH_MY_ORDERS,
  RESET_PAGE_NUMBER,
  SET_TABLE_FILTERS,
  HANDLE_FILTERS_MODEL,
  HANDLE_SORTING_ORDER,
  SET_SORTED_COLUMN_NAME,
  SET_SORTED_COLUMN_ORDER,
} from '@/store/modules/myOrders/constants/actions.js';

import { DATE_FILTER_TYPE } from '@/store/modules/myOrders/constants/table.js';

import { orderStatuses } from '@/enums/order/orderStatus';

import { OrderTypeSapIdsEnum } from '@/enums/order/orderType';

// ! We are importing into vuex store from pages. Separation of concerns violated.
// * Common functionality used by different concerns should be encapsulated into dedicated modules.
import { redirectToCmOrderException } from '@/pages/Home/MpoUserView/CaseManagement/helpers/redirection';

// TODO-Columns: To be removed into a global constant
import { getDefaultUserPreferences, mergeUserPreferences } from '@/pages/Home/Orders/utils';

import constants from './constants';
import mutations from './mutation-type';

const myOrdersData = {

  async fetchMyOrders({ commit, getters }) {
    try {
      commit(mutations.SET_IS_FETCHING_ORDERS, true);

      const request = getters.getRequestFilterParams;
      const { data } = await myOrdersRepository.fetchMyOrders({
        ...request,
      });

      data.orders.forEach((el) => {
        if (el.orderType === OrderTypeSapIdsEnum.NOW_SAMPLE_STD) {
          el.orderType = OrderTypeSapIdsEnum.ZSTA;
        } else if (el.orderType === OrderTypeSapIdsEnum.NOW_SAMPLE_FREE) {
          el.orderType = OrderTypeSapIdsEnum.ZFRE;
        }
      });

      commit(mutations.SET_MY_ORDERS, data?.orders);
      commit(mutations.SET_TOTAL_AVAILABLE_ORDERS_NUMBER, data?.totalOrders);
      commit(mutations.SET_TOTAL_AVAILABLE_PAGES_NUMBER, data?.totalPages);
      commit(mutations.SET_PAGE_TOKEN, {
        pageNumber: request.pageNumber + 1,
        token: data?.nextPaginationToken,
      });
      commit(mutations.SET_IS_FETCHING_ORDERS, false);
    } catch (error) {
      commit(mutations.SET_TABLE_DATA_LOADING_ERROR, error);
    }
  },

  async fetchMyOrdersSummary({
    commit, getters,
  }) {
    try {
      const { data } = await myOrdersRepository.fetchMyOrdersSummary(
        getters.getRequestFilterParams,
      );

      commit(mutations.SET_MY_ORDERS_SUMMARY, data);
    } catch (error) {
      commit(mutations.SET_FILTERS_DATA_LOADING_ERROR, error);
    }
  },

  async fetchOrderTypes({ commit }) {
    try {
      commit(mutations.SET_IS_PRE_REQUIRED_DATA_LOADING, true);

      const { data } = await myOrdersRepository.fetchOrderTypes();

      commit(mutations.SET_ORDER_TYPES, data);
    } catch (error) {
      console.error(error);
    } finally {
      commit(mutations.SET_IS_PRE_REQUIRED_DATA_LOADING, false);
    }
  },

  async fetchMyOrdersError({
    commit, state,
  }, {
    errorRef, rowIndex, message,
  }) {
    const getMyOrdersErrors = state.myOrdersErrors;
    const keysNotIncluded = !Object.keys(getMyOrdersErrors).includes(String(rowIndex));

    commit(mutations.SET_IS_MY_ORDERS_ERROR_LOADING, true);

    try {
      if (!errorRef && !message && keysNotIncluded) {
        commit(mutations.SET_MY_ORDERS_ERRORS, []);
      } else if (!errorRef && keysNotIncluded) {
        const errors = { ...getMyOrdersErrors };

        errors[rowIndex] = message;

        commit(mutations.SET_MY_ORDERS_ERRORS, errors);
      } else if (errorRef && keysNotIncluded) {
        const { data } = await myOrdersRepository.fetchOrderErrorDetails({
          errorMessageReference: errorRef,
        });

        const errors = { ...getMyOrdersErrors };
        const formattedData = data.length ? JSON.stringify(JSON.parse(data), null, 2) : '';

        errors[rowIndex] = formattedData;

        commit(mutations.SET_MY_ORDERS_ERRORS, errors);
      }
    } catch (err) {
      console.error(err);
    } finally {
      commit(mutations.SET_IS_MY_ORDERS_ERROR_LOADING, false);
    }
  },
};

const filtersSelection = {

  async setSelectedOrderOwnership({ commit, dispatch }, selectedOrderOwnership) {
    commit(mutations.SET_SELECTED_ORDER_OWNERSHIP, selectedOrderOwnership);
    commit(mutations.SET_SELECTED_ORDER_TYPES, []);
    dispatch('resetPageNumber');

    await dispatch('refreshDataSource');
  },
};

const myOrdersTable = {
  async fetchUserPreferences({ commit }) {
    let { data } = { };

    data = await myOrdersRepository.fetchUserPreferences();

    const defaultPreferences = getDefaultUserPreferences();
    const mergedPreferences = mergeUserPreferences(defaultPreferences, data.data.myOrderColumns);

    commit(mutations.SET_USER_PREFERENCES, mergedPreferences);
  },

  async saveUserPreferences({ commit }, userPreferences) {
    try {
      await myOrdersRepository.saveUserPreferences(userPreferences);

      commit(mutations.SET_USER_PREFERENCES, userPreferences.myOrderColumns);
    } catch (error) {
      console.log(error);
    }
  },

  setGridApi({ commit }, gridApi) {
    commit(mutations.SET_GRID_API, gridApi);
  },

  refreshDataSource({
    dispatch, commit, state,
  }) {
    commit(mutations.SET_MY_ORDERS_ERRORS, {});

    dispatch('createTableDatasource');

    if (state.gridApi) {
      state.gridApi.setServerSideDatasource(state.datasource);
    }
  },

  createTableColumns({ commit, dispatch }) {
    const editHandler = ({ data: orderData } = {}) => {
      if (orderData && orderData.nowId) {
        router.push(`/myOrders/${orderData.nowId}`);
      }
    };

    const deleteActionHandler = ({ data: orderData } = {}) => {
      if (orderData && orderData.nowId) {
        dispatch('setOrderId', orderData.nowId);
      }

      dispatch('modal/onOpenModal', 'applyActionModal', { root: true });
    };

    const errorDetailActionHandler = ({ data: orderData, rowIndex } = {}) => {
      const errorRef = orderData?.errorMessageReference;
      const message = orderData?.message;

      dispatch('fetchMyOrdersError', {
        errorRef, rowIndex, message,
      });

      dispatch('modal/onOpenModal', 'myOrdersErrorModal', { root: true });
    };

    const resolveActionHandler = ({ data: orderData } = {}) => {
      redirectToCmOrderException(orderData.poNumber);
    };

    const resubmitActionHandler = ({ data: orderData } = {}) => {
      if (orderData && orderData.nowId) {
        dispatch('resubmitOrder', orderData.nowId);
      }
    };

    const { columnsDefinition, actionsRendererConfig } = constants;

    const actionsConfig = {
      ...actionsRendererConfig,
      cellStyle: {
        'justify-content': 'center',
      },

      cellRenderer: null,

      cellRendererSelector: (params) => {
        const orderStatus = params?.data?.status?.replaceAll(' ', '_').toUpperCase();

        const defaultParams = {
          component: 'actionsRenderer',
          params: {
            menuOptions: [],
          },
        };

        const statusesAllowedToBeDeleted = [
          orderStatuses.DRAFT,
          orderStatuses.REJECTED,
          orderStatuses.SENT_FAILED,
          orderStatuses.CONTAINS_ERRORS,
          orderStatuses.PENDING_APPROVAL,
          orderStatuses.REWORK,
        ];

        if (
          [orderStatuses.DRAFT, orderStatuses.REWORK, orderStatuses.CONTAINS_ERRORS].includes(
            orderStatus,
          )
        ) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t('pages.myOrders.edit'),
            handler: editHandler,
          });
        }

        if (orderStatus === orderStatuses.SENT_FAILED) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t('pages.myOrders.resubmit'),
            handler: resubmitActionHandler,
          });
        }

        if (statusesAllowedToBeDeleted.includes(orderStatus)) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t('pages.myOrders.deleteOrder'),
            handler: deleteActionHandler,
          });
        }

        if (orderStatus === orderStatuses.FAILED_IN_S4) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t('pages.myOrders.errorDetail'),
            handler: errorDetailActionHandler,
          });
        }

        if (orderStatus === orderStatuses.FAILED_IN_SCP) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t('pages.myOrders.errorDetail'),
            handler: errorDetailActionHandler,
          });
        }

        if (orderStatus === orderStatuses.EXCEPTION) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t('pages.myOrders.resolve'),
            handler: resolveActionHandler,
          });
        }

        return defaultParams;
      },
    };

    commit(mutations.SET_COLUMN_DEF, [...columnsDefinition, actionsConfig]);
  },

  createTableDatasource({
    dispatch, commit, getters, state,
  }) {
    const datasource = {
      getRows: async(rowsCreationParams) => {
        dispatch(HANDLE_SORTING_ORDER, rowsCreationParams);

        await dispatch(HANDLE_FILTERS_MODEL, rowsCreationParams);

        await dispatch(FETCH_MY_ORDERS);

        rowsCreationParams.successCallback(getters.getFormatedTableData, state.myOrders.length);

        if (!state.myOrders.length) {
          rowsCreationParams.api.showNoRowsOverlay();
        }
      },
    };

    commit(mutations.SET_DATA_SOURCE, datasource);
  },

  async [HANDLE_SORTING_ORDER]({ dispatch, state }, rowsCreationParams) {
    const { colId = 'createdAt', sort = 'desc' } = get(
      rowsCreationParams,
      'request.sortModel[0]',
      {},
    );

    const sortedColumnName = constants.columnNames[colId];
    const sortedColumnOrder = constants.sortedColumnOrders[sort];

    if (
      state.sortedColumnName !== sortedColumnName
      || state.sortedColumnOrder !== sortedColumnOrder
    ) {
      await dispatch(RESET_PAGE_NUMBER);
    }

    await dispatch(SET_SORTED_COLUMN_NAME, sortedColumnName);
    await dispatch(SET_SORTED_COLUMN_ORDER, sortedColumnOrder);
  },

  async [HANDLE_FILTERS_MODEL]({ state, dispatch }, rowsCreationParams) {
    const filterModel = rowsCreationParams?.request?.filterModel;

    if (!isEmpty(filterModel) && !isFiltersValid(filterModel)) {
      return;
    }

    if (!isEqual(state.tableFilters, filterModel)) {
      await dispatch(RESET_PAGE_NUMBER);
    }

    await dispatch(SET_TABLE_FILTERS, filterModel);
  },

  async deleteOrderHandler({ dispatch }) {
    await dispatch('deleteOrder');
    await dispatch('fetchMyOrdersSummary');
    await dispatch('refreshDataSource');
  },

  setTableParams({ commit }, params) {
    commit(mutations.SET_TABLE_PARAMS, params);
  },

  setOrderId({ commit }, orderId) {
    commit(mutations.SET_ORDER_ID, orderId);
  },

  setPageSize({ commit }, pageSize) {
    commit(mutations.SET_PAGE_SIZE, pageSize);
  },

  setSortedColumnName({ commit }, sortedColumnName) {
    commit(mutations.SET_SORTED_COLUMN_NAME, sortedColumnName);
  },

  setSortedColumnOrder({ commit }, sortedColumnOrder) {
    commit(mutations.SET_SORTED_COLUMN_ORDER, sortedColumnOrder);
  },

  setTableFilters({ commit }, tableFilters) {
    commit(mutations.SET_TABLE_FILTERS, tableFilters);
  },

  setPageNumber({ commit }, pageNumber) {
    commit(mutations.SET_PAGE_NUMBER, pageNumber);
  },

  resetPageNumber({ commit }) {
    commit(mutations.SET_PAGE_NUMBER, 0);
    commit(mutations.RESET_PAGE_TOKEN);
  },

  async deleteOrder({ state }) {
    try {
      await singleOrdersRepository.deleteOrders([Number(state.orderId)]);

      showSuccessBanner({
        title: constants.DELETE_SUCCESS_MESSAGE,
      });
    } catch (error) {
      console.error(error);
    }
  },

  async resubmitOrder({ dispatch }, orderId) {
    try {
      const { data: { error } = {} } = await singleOrdersRepository.resubmitOrder({
        orderId: String(orderId),
      });

      if (error) {
        await dispatch('fetchMyOrdersSummary');
        await dispatch('refreshDataSource');

        showErrorBanner({
          width: 300,
          title: error.message,
        });
      } else {
        await dispatch('fetchMyOrdersSummary');
        await dispatch('refreshDataSource');

        showSuccessBanner({
          title: 'Success',
        });
      }
    } catch (error) {
      showErrorBanner({
        width: 300,
        title: 'Sent failed',
      });
      console.error(error);
    }
  },
};

function isFiltersValid(filterModel) {
  if (isEmpty(filterModel)) {
    return false;
  }

  return Object.values(filterModel).every((filterParams) => {
    if (DATE_FILTER_TYPE === filterParams.filterType) {
      if (!validateDateFilters(filterParams)) {
        showErrorBanner({
          title: 'Selected year is outside of supported range',
          text: `
            Min year: 2020
            Max year: 2999
          `,
        });

        return false;
      }
    }

    return true;
  });
}

function validateDateFilters(dateFilters) {
  const isDateFromValid = dateFilters?.dateFrom === null ? true : isYearInRange(dateFilters?.dateFrom.split('-')[0]);
  const isDateToValid = dateFilters?.dateTo === null ? true : isYearInRange(dateFilters?.dateTo.split('-')[0]);

  return isDateFromValid && isDateToValid;
}

function isYearInRange(year, minYear = 2020, maxYear = 2999) {
  return Number(year) >= minYear && Number(year) <= maxYear;
}

const newOrdersTable = {
  deleteOrderActionHandler({ dispatch }, orderData) {
    if (orderData?.nowId) {
      dispatch('setOrderId', orderData.nowId);
    }

    dispatch('modal/onOpenModal', 'applyActionModal', { root: true });
  },

  async errorDetailOrder({
    state, commit, dispatch,
  }, orderData) {
    if (!orderData) {
      return;
    }

    const {
      poNumber, errorMessageReference: errorRef, message,
    } = orderData;

    const errors = {
      cache: { ...state.myOrdersErrors.cache },
      current: '',
    };

    const errorAlreadyExists = Object.keys(errors.cache).includes(poNumber);

    if (errorAlreadyExists) {
      errors.current = poNumber;
      commit(mutations.SET_MY_ORDERS_ERRORS, errors);
    } else if (errorRef) {
      try {
        commit(mutations.SET_IS_MY_ORDERS_ERROR_LOADING, true);

        const { data } = await myOrdersRepository.fetchOrderErrorDetails({ errorMessageReference: errorRef });
        const formattedData = data.length ? JSON.stringify(JSON.parse(data), null, 2) : '';

        errors.cache[poNumber] = formattedData;
        errors.current = poNumber;

        commit(mutations.SET_MY_ORDERS_ERRORS, errors);
      } catch (err) {
        console.error(err);
      } finally {
        commit(mutations.SET_IS_MY_ORDERS_ERROR_LOADING, false);
      }
    } else if (message) {
      errors.cache[poNumber] = message;
      errors.current = poNumber;

      commit(mutations.SET_MY_ORDERS_ERRORS, errors);
    }

    dispatch('modal/onOpenModal', 'myOrdersErrorModal', { root: true });
  },
};

export default {
  ...myOrdersData,
  ...myOrdersTable,
  ...filtersSelection,
  ...newOrdersTable,
};
