import {
  xor, groupBy, isEmpty,
} from 'lodash';

import { fetchLines, fetchFieldMapping } from '@/api/now/massOrderCreation';
import gridActions from '@/store/modules/massOrderCreation/L2/grid/actions';
import OrderIssue from '@/store/modules/massOrderCreation/L2/gridValidators/Issue';
import filterPanelActions from '@/store/modules/massOrderCreation/L2/filterPanel/actions';
import issueNavigatorActions from '@/store/modules/massOrderCreation/L2/issueNavigator/actions';
import {
  issueTypesMapper,
  issueTypesTextMapper,
} from '@/store/modules/massOrderCreation/constants/issueTypesMapper.js';
import i18n from '@/plugins/i18n';

import { FIELD_NAMES } from '@/enums/fieldNames';
import ModalEnum from '@/enums/modals';
import ValidationRulesEnum from '@/enums/validationRules/MOC';

import { transformGridLineDates } from '@/utils/agGrid';

import {
  STORE_ISSUES,
  REFETCH_DATA,
  RESET_GRID_DATA,
  SET_GRID_CREATOR,
  DETERMINE_ISSUES,
  REFRESH_DATA_SOURCE,
  SET_SELECTED_FILE_ID,
  RESET_SELECTED_ISSUE,
  RESET_ON_ROUTE_CHANGE,
  DETERMINE_CACHED_LINES,
  SET_SELECTED_ORDER_IDS,
  SET_SIZE_COLUMNS_TO_ADD,
  RESET_SELECTED_ORDER_IDS,
  SET_VALIDATION_STATUS_FOR_CELL,
  SET_PRODUCT_ENGINES_WITH_SIZES,
  FETCH_ISSUE_TYPE_FILTER_COUNTS,
  SET_ALL_ORDERS_OF_FILE_SELECTED,
  FETCH_LINES_FOR_SELECTED_ORDERS,
  RESET_PAGINATION_RELATED_FIELDS,
  REFRESH_VIEW_ON_ORDER_ID_SELECTION,
  RESET_ISSUE_NAVIGATOR_RELATED_FIELDS,
} from './constansts/actions';

import {
  GET_GRID_API,
  GET_COLUMN_DEFS,
  GET_ORDER_ISSUES,
  GET_GRID_CREATOR,
  GET_CACHED_LINES,
  GET_SELECTED_ISSUE,
  GET_FIXED_BE_ISSUES,
  GET_SEARCH_DIRECTION,
  GET_FROM_COLUMN_NAME,
  GET_SELECTED_FILE_ID,
  GET_SELECTED_ORDER_IDS,
  GET_LINES_VALIDATION_STATUS,
  GET_HAS_NEXT_PAGE_WITH_LINES,
  GET_FIELD_NAME_BY_COLUMN_NAME,
} from './constansts/getters';

import {
  DATE_KEYS, PRODUCT_ENGINE_KEYS, PRODUCT_ENGINE_MAPPER,
} from './constansts/config';

import mutationTypes from './constansts/mutationsTypes';
import { createNameWithL1Module } from '../utils';

const SEARCH_DIRECTION = {
  NEXT: 'NEXT',
  PREV: 'PREV',
};

export default {
  ...gridActions,
  ...filterPanelActions,
  ...issueNavigatorActions,

  [SET_SELECTED_FILE_ID]({
    commit, dispatch, getters,
  }, fileId) {
    dispatch(RESET_SELECTED_ORDER_IDS);

    if (getters[GET_SELECTED_FILE_ID]?.toString() === fileId?.toString()) {
      commit(mutationTypes.SET_SELECTED_FILE_ID, null);

      commit(mutationTypes.SET_FETCHED_ORDERS, {});
      commit(mutationTypes.SET_SELECTED_FILE_ID, null);
      commit(mutationTypes.SET_SELECTED_ORDER_IDS, []);
      commit(mutationTypes.SET_IS_KEY_GROUPING_FIELD_CHANGED, false);

      dispatch(RESET_PAGINATION_RELATED_FIELDS);
      dispatch(RESET_ISSUE_NAVIGATOR_RELATED_FIELDS);
    } else {
      commit(mutationTypes.SET_SELECTED_FILE_ID, Number(fileId));
    }
  },

  [RESET_SELECTED_ORDER_IDS]({ commit, dispatch }) {
    commit(mutationTypes.SET_SELECTED_ORDER_IDS, []);

    dispatch(REFRESH_VIEW_ON_ORDER_ID_SELECTION);
  },

  [REFETCH_DATA]({
    commit, dispatch, getters,
  }) {
    const selectedOrderIds = getters[GET_SELECTED_ORDER_IDS];

    dispatch(FETCH_ISSUE_TYPE_FILTER_COUNTS, {
      orderIds: selectedOrderIds,
      fileId: selectedOrderIds.length ? Number(getters[GET_SELECTED_FILE_ID]) : 0,
    });

    commit(mutationTypes.SET_FROM_LINE_ID, -1);
    commit(mutationTypes.SET_FROM_ORDER_ID, -1);

    dispatch(REFRESH_DATA_SOURCE);
  },

  [SET_ALL_ORDERS_OF_FILE_SELECTED]({
    commit, dispatch, getters,
  }, orderIds) {
    commit(mutationTypes.SET_SELECTED_ORDER_IDS, orderIds);

    dispatch(REFRESH_VIEW_ON_ORDER_ID_SELECTION);

    const selectedOrderIds = getters[GET_SELECTED_ORDER_IDS];

    if (selectedOrderIds.length) {
      dispatch(REFETCH_DATA);
    }
  },

  [SET_SELECTED_ORDER_IDS]({
    commit, getters, dispatch,
  }, orderIds) {
    commit(mutationTypes.SET_SELECTED_ORDER_IDS, xor(getters[GET_SELECTED_ORDER_IDS], orderIds));

    dispatch(REFRESH_VIEW_ON_ORDER_ID_SELECTION);

    const selectedOrderIds = getters[GET_SELECTED_ORDER_IDS];

    if (selectedOrderIds.length) {
      dispatch(REFETCH_DATA);
    }
  },

  async [FETCH_LINES_FOR_SELECTED_ORDERS]({
    commit, getters, dispatch, state,
  }) {
    commit(mutationTypes.SET_IS_ISSUES_LOADING, true);
    commit(mutationTypes.SET_IS_ORDERS_FETCHING, true);

    const searchDirection = getters[GET_SEARCH_DIRECTION];

    const { fromLineId, fromOrderId } = determineIdsForFetch(getters, state);

    try {
      if (isEmpty(getters[GET_FIELD_NAME_BY_COLUMN_NAME])) {
        const { data: fieldMapping } = await fetchFieldMapping();

        commit(mutationTypes.SET_FIELD_MAPPING, fieldMapping);
      }

      const { data } = await fetchLines({
        types: state.selectedIssueTypesFilters,
        fromLineId: Number(fromLineId),
        fromOrderId: Number(fromOrderId),
        limit: state.linesLimit,
        nowFileId: getters[GET_SELECTED_FILE_ID],
        orderIds: getters[GET_SELECTED_ORDER_IDS],
        direction: searchDirection,
        withIssues:
          !state.selectedIssueTypesFilters.length && state.isOnlyLinesWithIssuesFilterSelected,
      });

      const {
        orderIssues, hasNext, details,
      } = data;

      let { lines } = data;

      if (searchDirection === SEARCH_DIRECTION.PREV) {
        lines = lines.reverse();
      } else {
        commit(mutationTypes.SET_HAS_NEXT_PAGE_WITH_LINES, hasNext);
      }

      const transformedLines = lines.map((line) => ({
        ...transformGridLineDates(line, DATE_KEYS),
        lineId: line.id,
      }));

      const orders = Object.values(groupBy(transformedLines, 'orderId')).map(
        (orderLines) => orderLines,
      );

      commit(mutationTypes.SET_ORDER_ISSUES, orderIssues);
      commit(mutationTypes.SET_FETCHED_ORDERS, orders);
      commit(mutationTypes.SET_IS_DUPLICATE_LINES_AVAILABLE, details.hasDuplicateLinesError);

      dispatch(SET_GRID_CREATOR);
    } catch (err) {
      console.error(err);
    } finally {
      commit(mutationTypes.SET_IS_ISSUES_LOADING, false);
      commit(mutationTypes.SET_IS_ORDERS_FETCHING, false);
    }
  },

  [REFRESH_DATA_SOURCE]({
    dispatch, commit, getters,
  }) {
    let requestStartRow = null;

    const dataSource = {
      getRows: async(rowsCreationParams) => {
        commit(
          mutationTypes.SET_SEARCH_DIRECTION,
          requestStartRow > rowsCreationParams.request.startRow
            ? SEARCH_DIRECTION.PREV
            : SEARCH_DIRECTION.NEXT,
        );

        requestStartRow = rowsCreationParams.request.startRow;

        await dispatch(FETCH_LINES_FOR_SELECTED_ORDERS);

        const lastRowNumber = rowsCreationParams.request.startRow + getters.getRowData.length;

        rowsCreationParams.successCallback(
          getters.getRowData,
          getters[GET_SEARCH_DIRECTION] === SEARCH_DIRECTION.NEXT
            && !getters[GET_HAS_NEXT_PAGE_WITH_LINES]
            ? lastRowNumber
            : null,
        );

        await dispatch(DETERMINE_CACHED_LINES);
        await dispatch(DETERMINE_ISSUES);

        dispatch(RESET_SELECTED_ISSUE);

        focusCell(getters);

        commit(mutationTypes.SET_FROM_LINE_ID, null);
        commit(mutationTypes.SET_FROM_ORDER_ID, null);
        commit(mutationTypes.SET_FROM_COLUMN_NAME, null);
      },
    };

    getters[GET_GRID_API].setServerSideDatasource(dataSource);

    return dataSource;
  },

  [RESET_ON_ROUTE_CHANGE]({ commit, dispatch }) {
    dispatch(RESET_GRID_DATA);
    dispatch(RESET_PAGINATION_RELATED_FIELDS);
    dispatch(RESET_ISSUE_NAVIGATOR_RELATED_FIELDS);

    commit(mutationTypes.SET_FETCHED_ORDERS, {});
    commit(mutationTypes.SET_SELECTED_FILE_ID, null);
    commit(mutationTypes.SET_SELECTED_ORDER_IDS, []);

    commit(mutationTypes.SET_COLUMN_API, null);

    commit(mutationTypes.SET_IS_KEY_GROUPING_FIELD_CHANGED, false);
    commit(mutationTypes.SET_IS_ONLY_LINES_WITH_ISSUES_FILTER_SELECTED, false);

    commit(mutationTypes.SET_SELECTED_ISSUE_TYPES_CATEGORY, '');
    commit(mutationTypes.SET_SELECTED_ISSUE_TYPES_FILTERS, []);
    commit(mutationTypes.SET_ISSUE_TYPES_CATEGORIES, []);

    dispatch('modal/onCloseModal', ModalEnum.MAX_MODIFIED_LINES_MODAL, { root: true });
    dispatch('modal/onCloseModal', ModalEnum.LINES_SAVE_WARNING_MODAL, { root: true });
    dispatch('modal/onCloseModal', ModalEnum.ADD_SIZE_MODAL, { root: true });

    commit(createNameWithL1Module(mutationTypes.SET_SELECTED_ORDER_IDS), [], { root: true });
  },

  [DETERMINE_CACHED_LINES]({ commit, getters }) {
    const cachedLines = [];

    getters[GET_GRID_API]?.forEachNode((node) => {
      if (node.data) {
        cachedLines.push(node.data);
      }
    });

    commit(mutationTypes.SET_CACHED_LINES, cachedLines);
  },

  [DETERMINE_ISSUES]({
    commit, getters, dispatch,
  }) {
    const creator = getters[GET_GRID_CREATOR];
    const fixedIssues = getters[GET_FIXED_BE_ISSUES];
    const namesMapping = getters[GET_FIELD_NAME_BY_COLUMN_NAME];
    const linesValidationStatus = {};

    // TODO: maybe move filtering of fixed issues to SET_BE_ISSUES / GET_ISSUES
    const lineIssues = getters[GET_CACHED_LINES].reduce((prev, curr) => {
      const rowId = creator.createRowId(curr);
      const currIssues = curr.issues.map((issue) => ({
        ...issue,
        lineItemId: curr.lineId,
        orderId: curr.orderId,
      }));
      const fixedIssuesForLine = fixedIssues.filter((issue) => issue.rowId === curr.rowId);

      linesValidationStatus[rowId] = getValidationStatusForLine(curr, fixedIssuesForLine);

      return [...prev, ...currIssues];
    }, []).filter((issue) => {
      return !fixedIssues.some((fixedIssue) => {
        if (`${issue.orderId}_${issue.lineItemId}` === fixedIssue.rowId) {
          if (PRODUCT_ENGINE_KEYS.includes(fixedIssue.productEngine)) {
            return (
              `${issue.issueColumn}_${PRODUCT_ENGINE_MAPPER[issue.productEngine]}`
              === fixedIssue.colId
            );
          }

          return namesMapping[issue.issueColumn] === fixedIssue.colId;
        }

        return false;
      });
    });

    const issues = [...getters[GET_ORDER_ISSUES], ...lineIssues];
    const productEngineIssues = issues.filter(
      ({ issueType }) => issueType === issueTypesMapper.PRODUCT_ENGINE_UNIQUENESS,
    );

    if (productEngineIssues.length) {
      markSizesInvalid(getters, productEngineIssues, linesValidationStatus);
    }

    dispatch(STORE_ISSUES, issues);

    commit(mutationTypes.SET_LINES_VALIDATION_STATUS, {
      ...linesValidationStatus,
      ...getters[GET_LINES_VALIDATION_STATUS],
    });
  },

  [SET_VALIDATION_STATUS_FOR_CELL](
    { commit, getters },
    {
      lineId, key, value, productEngine = null,
    },
  ) {
    const savedValidationStatus = getters[GET_LINES_VALIDATION_STATUS];

    if (productEngine) {
      if (!Object.keys(savedValidationStatus[lineId][productEngine] || {}).length) {
        savedValidationStatus[lineId][productEngine] = {};
      }

      savedValidationStatus[lineId][productEngine][key] = value;
    } else {
      savedValidationStatus[lineId][key] = value;
    }

    commit(mutationTypes.SET_LINES_VALIDATION_STATUS, { ...savedValidationStatus });
  },

  [RESET_GRID_DATA]({ commit }) {
    commit(mutationTypes.SET_GRID_API, null);
    commit(mutationTypes.SET_GRID_CREATOR, null);
  },

  [RESET_ISSUE_NAVIGATOR_RELATED_FIELDS]({ commit }) {
    commit(mutationTypes.SET_BE_ISSUES, {});
    commit(mutationTypes.SET_UI_ISSUES, {});
    commit(mutationTypes.SET_SELECTED_ISSUE, null);
    commit(mutationTypes.SET_IS_ALL_ISSUES_LOADED, false);
    commit(mutationTypes.SET_IS_ISSUE_NAVIGATOR_OPEN, true);
  },

  [RESET_PAGINATION_RELATED_FIELDS]({ commit }) {
    commit(mutationTypes.SET_FROM_LINE_ID, null);
    commit(mutationTypes.SET_FROM_ORDER_ID, null);
    commit(mutationTypes.SET_FROM_COLUMN_NAME, null);
    commit(mutationTypes.SET_HAS_NEXT_PAGE_WITH_LINES, null);

    commit(mutationTypes.SET_MODIFIED_LINES, {});
    commit(mutationTypes.SET_CACHED_LINES, []);
    commit(mutationTypes.SET_SEARCH_DIRECTION, null);

    commit(mutationTypes.SET_FIXED_BE_ISSUES, []);
    commit(mutationTypes.SET_LINES_VALIDATION_STATUS, {});

    commit(mutationTypes.SET_SIZE_COLUMNS_TO_ADD, []);
  },

  [REFRESH_VIEW_ON_ORDER_ID_SELECTION]({ commit, dispatch }) {
    dispatch(RESET_PAGINATION_RELATED_FIELDS);
    dispatch(RESET_ISSUE_NAVIGATOR_RELATED_FIELDS);

    commit(mutationTypes.SET_FETCHED_ORDERS, {});
    commit(mutationTypes.SET_GRID_CREATOR, null);

    commit(mutationTypes.SET_TOTAL_ISSUES_COUNT, 0);

    commit(mutationTypes.SET_IS_KEY_GROUPING_FIELD_CHANGED, false);
  },

  [STORE_ISSUES]({ getters, commit }, issues) {
    const validationIssues = transformToValidationIssues(getters, issues);

    commit(mutationTypes.SET_BE_ISSUES, validationIssues);
  },

  [SET_SIZE_COLUMNS_TO_ADD]({
    commit, dispatch, state,
  }, sizeToAdd) {
    commit(mutationTypes.SET_SIZE_COLUMNS_TO_ADD, [...state.sizeColumnsToAdd, sizeToAdd]);

    dispatch(SET_GRID_CREATOR);
  },

  [SET_PRODUCT_ENGINES_WITH_SIZES]({ commit }, productEnginesWithSizes) {
    commit(mutationTypes.SET_PRODUCT_ENGINES_WITH_SIZES, productEnginesWithSizes);
  },
};

export function determineIdsForFetch(getters, state) {
  let { fromLineId } = state;
  let { fromOrderId } = state;

  const cachedLines = getters[GET_CACHED_LINES] || [];
  const lastLine = cachedLines?.[cachedLines.length - 1];

  const isNextSearchDirection = getters[GET_SEARCH_DIRECTION] === SEARCH_DIRECTION.NEXT;

  if (fromLineId === null) {
    if (isNextSearchDirection) {
      fromLineId = lastLine?.lineId || -1;
    } else {
      fromLineId = cachedLines[0]?.lineId || -1;
    }
  }

  if (fromOrderId === null) {
    if (isNextSearchDirection) {
      fromOrderId = lastLine?.orderId || -1;
    } else {
      fromOrderId = cachedLines[0]?.orderId || -1;
    }
  }

  return {
    fromLineId,
    fromOrderId,
  };
}

export function focusCell(getters) {
  const gridApi = getters[GET_GRID_API];
  const columnName = getters[GET_FROM_COLUMN_NAME];

  if (columnName) {
    const selectedIssue = getters[GET_SELECTED_ISSUE];

    gridApi.ensureColumnVisible(columnName);

    if (selectedIssue && !selectedIssue?.isColumnIssue) {
      const rowIndex = gridApi.getRowNode(selectedIssue?.rowId)?.rowIndex;

      gridApi.ensureIndexVisible(rowIndex);
      gridApi.setFocusedCell(rowIndex, columnName);
      gridApi.startEditingCell({ rowIndex, colKey: columnName });
    }
  }
}

function transformToValidationIssues(getters, issues) {
  const columnsDefs = getters[GET_COLUMN_DEFS];

  return issues.reduce((acc, param) => {
    const {
      subId, issueColumn, issueType, lineItemId, orderId, productEngine,
    } = param;

    let expectedFormat = null;
    let validationIssue = null;
    let productEngineColumnIndex = null;

    // TODO: Use createRowId method for creating id
    const rowId = `${orderId}_${lineItemId || -1}`;

    if (
      getters.getAllSizesNames.includes(issueColumn)
      || !getIsIssueTypeRequiresExpectedFormat(issueType)
    ) {
      expectedFormat = '';
    } else {
      expectedFormat = ValidationRulesEnum?.formatRules?.[getters[GET_FIELD_NAME_BY_COLUMN_NAME][issueColumn]]
        ?.expectedFormat;
    }

    const expectedFormatDescription = expectedFormat ? ` (expected format: ${expectedFormat})` : '';

    let description = issueTypesTextMapper[issueType] + expectedFormatDescription;

    if (
      issueColumn
      && issueColumn.toLowerCase() === FIELD_NAMES.REGION
      && issueType === issueTypesMapper.INCORRECT_VALUE
    ) {
      description = i18n.global.t('pages.MOC.L2.regionCodeError');
    }

    if (issueType === issueTypesMapper.PRODUCT_ENGINE_UNIQUENESS) {
      productEngineColumnIndex = columnsDefs.findIndex(({ colId }) => PRODUCT_ENGINE_KEYS.includes(colId),
      );
    }

    validationIssue = new OrderIssue({
      rowId,
      subId,
      orderId,
      lineId: lineItemId,
      description,
      isLineIssue: issueType === issueTypesMapper.DUPLICATE_MATERIAL_SIZES,
      isColumnIssue: !lineItemId || lineItemId === -1,
      productEngine: PRODUCT_ENGINE_MAPPER[productEngine],
      columnName: getters[GET_FIELD_NAME_BY_COLUMN_NAME][issueColumn] || issueColumn,
      colId: productEngineColumnIndex ? columnsDefs[productEngineColumnIndex].colId : null,
    });

    acc = {
      ...acc,
      [rowId]: [...(acc[rowId] || []), validationIssue],
    };

    return acc;
  }, {});
}

function getValidationStatusForLine(line, fixedIssuesForLine) {
  return Object.keys(line).reduce((acc, fieldKey) => {
    if (PRODUCT_ENGINE_KEYS.includes(fieldKey)) {
      const productEngine = fieldKey;

      const sizeIssues = getValidationStatusForSizes(line, fieldKey, fixedIssuesForLine);

      if (Object.keys(sizeIssues).length) {
        acc[productEngine] = sizeIssues;
      }

      return acc;
    }
    const isIssueFixed = fixedIssuesForLine.some(
      (issue) => issue.rowId === line.rowId && issue.colId === fieldKey,
    );

    if (line[fieldKey]?.incorrect && !isIssueFixed) {
      acc[fieldKey] = line[fieldKey]?.incorrect;
    }

    return acc;
  }, {});
}

function getValidationStatusForSizes(line, productEngine, fixedIssuesForLine) {
  return Object.keys(line[productEngine]).reduce((productEngineAcc, size) => {
    const isIssueFixed = fixedIssuesForLine.some(
      (issue) => issue.colId === `${size}_${productEngine}`,
    );

    if (line[productEngine][size]?.incorrect && !isIssueFixed) {
      productEngineAcc[size] = line[productEngine][size]?.incorrect;
    }

    return productEngineAcc;
  }, {});
}

function getIsIssueTypeRequiresExpectedFormat(issueType) {
  const issueTypes = [
    issueTypesMapper.REQUIRED_FIELD,
    issueTypesMapper.INCORRECT_VALUE,
    issueTypesMapper.VAS_MISSING_COMMENT,
    issueTypesMapper.INCORRECT_FORMATTING,
    issueTypesMapper.DUPLICATE_SIZE,
    issueTypesMapper.VAS_INVALID_COMMENT_FIELD,
    issueTypesMapper.INVOICE_NUMBER_NOT_APPLICABLE,
  ];

  return issueTypes.includes(issueType);
}

function markSizesInvalid(getters, productEngineIssues, linesValidationStatus) {
  const columnsDefs = getters[GET_COLUMN_DEFS];
  const sizesValidationStatus = {};

  columnsDefs.forEach(({ productEngine, field }) => {
    if (PRODUCT_ENGINE_KEYS.includes(productEngine)) {
      sizesValidationStatus[productEngine] = {
        ...sizesValidationStatus[productEngine],
        [field]: true,
      };
    }
  });

  productEngineIssues.forEach(({ lineItemId, orderId }) => {
    // TODO: Use createRowId method for creating id
    const rowId = `${orderId}_${lineItemId}`;

    linesValidationStatus[rowId] = {
      ...linesValidationStatus[rowId],
      ...JSON.parse(JSON.stringify(sizesValidationStatus)),
    };
  });
}
