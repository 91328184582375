// TODO: This whole constant should be moved to i18n json
export default {
  missingValues: 'Missing values',
  numericOnly: 'Numeric values only.',
  fieldIsRequired: 'This field is required',
  incorrectFormatting: 'Incorrect formatting',
  nonUniqueValues: 'Non-unique values in the lines',
  invalidContractOwnerName: 'Invalid Contract Owner Name',

  maxLengthExceeded: maxLength => `Max length - ${maxLength}.`,
};
