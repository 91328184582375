import {
  GET_MODAL_MODE,
  GET_DUPLICATES_CNAHGELOG,
  GET_IS_DUPLICATES_LOADING,
  GET_COUNT_OF_DUPLICATES_SETS,
  GET_SELECTED_DUPLICATE_LINES,
  GET_ALL_DISPLAYED_DUPLICATES,
  GET_DUPLICATE_LINES_INITIAL_DATA,
  GET_DISPLAYED_DUPLICATES_ROW_DATA,
  GET_IS_DUPLICATES_SAVE_IN_PROGRESS,
  GET_INDEX_OF_DISPLAYED_DUPLICATES_SET,
  GET_DUPLICATE_LINES_COLUMN_DEFINITIONS,
  GET_DUPLICATES_CNAHGELOG_FOR_DISPLAYED_SET,
} from './constants/getters';

import { duplicateLinesDefaultColDefs } from './constants/columnDefinitions';

export default {
  // TODO: change this to return current state data, fix when pagination is implemented
  [GET_MODAL_MODE]: ({ modalMode }) => modalMode || '',

  [GET_DUPLICATES_CNAHGELOG]: ({ duplicatesChangelog }) => duplicatesChangelog || [],

  [GET_IS_DUPLICATES_LOADING]: ({ isDuplicatesLoading }) => isDuplicatesLoading || false,

  [GET_COUNT_OF_DUPLICATES_SETS]: ({ initialData }) => (initialData ? initialData.length : 0),

  [GET_DUPLICATE_LINES_INITIAL_DATA]: ({ initialData }) => initialData || [],

  [GET_ALL_DISPLAYED_DUPLICATES]: ({ displayedDuplicates }) => displayedDuplicates || [],

  [GET_DISPLAYED_DUPLICATES_ROW_DATA]: ({ displayedDuplicates }) => {
    return displayedDuplicates
      .filter((line) => !line.deleted)
      .map((line) => ({ ...line, selected: false }));
  },

  [GET_IS_DUPLICATES_SAVE_IN_PROGRESS]: ({ isDuplicatesSaveInProgress }) => isDuplicatesSaveInProgress || false,

  [GET_SELECTED_DUPLICATE_LINES]: ({ selectedDuplicateLines }) => selectedDuplicateLines || [],

  [GET_INDEX_OF_DISPLAYED_DUPLICATES_SET]: ({ indexOfDisplayedDuplicatesSet }) => indexOfDisplayedDuplicatesSet || 0,

  [GET_DUPLICATES_CNAHGELOG_FOR_DISPLAYED_SET]: ({
    duplicatesChangelog,
    indexOfDisplayedDuplicatesSet,
  }) => {
    return duplicatesChangelog[indexOfDisplayedDuplicatesSet] || [];
  },

  [GET_DUPLICATE_LINES_COLUMN_DEFINITIONS]: ({ displayedDuplicates }) => {
    const sizeKeys = getSizeKeysForDisplayedDuplicateLines(displayedDuplicates);

    if (displayedDuplicates.length) {
      const sizesColDefs = sizeKeys.map((key) => {
        return {
          headerName: key,
          valueGetter: (params) => {
            const value = params.data.sizes.find((size) => size.name === key)?.value;

            return value || 0;
          },
          width: 100,
        };
      });

      return [...duplicateLinesDefaultColDefs, ...sizesColDefs];
    }

    return duplicateLinesDefaultColDefs || [];
  },
};

function getSizeKeysForDisplayedDuplicateLines(lines) {
  const flatSizes = lines.reduce((acc, { sizes }) => {
    return [...acc, ...sizes];
  }, []);
  const uniqueSizes = [];

  flatSizes.forEach(({ name }) => {
    if (!uniqueSizes.includes(name)) {
      uniqueSizes.push(name);
    }
  });

  return uniqueSizes;
}
