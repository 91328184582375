import * as gettersEnum from '../constansts/getters';
import { orderStatuses } from '@/enums/order/orderStatus';

const { GET_LIST_OF_ORDER_TYPES, GET_IS_ORDER_TYPE_SELECTED } = gettersEnum;

export default {
  [GET_LIST_OF_ORDER_TYPES]: (
    _,
    {
      getOrderTypesIssueCounters, getOrderTypesGroupedByName, getOrdersOfSelectedFilenames,
    },
  ) => {
    return Object.keys(getOrderTypesIssueCounters).map((orderTypeName) => {
      const issueCounter = getOrderTypesIssueCounters[orderTypeName];

      return {
        issueCounter,
        text: orderTypeName,
        id: getOrderTypesGroupedByName[orderTypeName],
        isSentFailed: getOrdersOfSelectedFilenames
          .filter((order) => order.orderType.name === orderTypeName)
          .map((order) => order.status)
          .includes(orderStatuses.SENT_FAILED),
      };
    });
  },

  [GET_IS_ORDER_TYPE_SELECTED]: (state) => !!state.selectedOrderTypeId,

  getOrderTypesIssueCounters: (_, { getOrdersOfSelectedFilenames }) => {
    return getOrdersOfSelectedFilenames.reduce((acc, order) => {
      const orderName = order.orderType.name;
      // WHAT IF NO ISSUES
      if (acc[orderName]) {
        acc[orderName] += order.issues.totalCount;

        return acc;
      }

      acc[orderName] = order.issues.totalCount;

      return acc;
    }, {});
  },

  // TODO Why in browser this value is empty
  getOrderTypesGroupedByName: (_, { getOrderTypeMapping }) => {
    return getOrderTypeMapping.reduce((acc, { id, name }) => {
      acc[name] = id;

      return acc;
    }, []);
  },
};
